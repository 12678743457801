import React, { Component } from 'react';
import { Grid, Row, Col, Alert, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { animateScroll as scroll } from 'react-scroll';
import cpf_cnpj from "cpf_cnpj";
import axios from "axios";

import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import Radio from '../../components/CustomRadio/CustomRadio.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import api from '../../services/api';

export default class FranchiseCreate extends Component {
  constructor(props) {
    super(props);

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onBlurAddressZipcode = this.onBlurAddressZipcode.bind(this);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      banks: [],
      email: '',
      phone: '',
      cellphone: '',
      site: '',
      regional_council_number: '',
      responsible_admin_name: '',
      responsible_admin_cpf: '',
      responsible_admin_phone: '',
      responsible_admin_email: '',
      cnpj: '',
      social_name: '',
      fantasy_name: '',
      bank_code: '',
      bank_holder: '',
      bank_cpf_cnpj: '',
      bank_ag: '',
      bank_cc: '',
      state_id: '',
      zipcode: '',
      address: '',
      number: '',
      neighborhood: '',
      complement: '',
      city: '',
      states: [],
      user_full_name: '', 
      user_usertypes: '', 
      user_cnpj: '', 
      user_cpf: '', 
      user_email: '', 

      // validation
      error: '',
      emailError: '',
      phoneError: '',
      cellphoneError: '',
      siteError: '',
      regional_council_numberError: '',
      responsible_admin_nameError: '',
      responsible_admin_cpfError: '',
      responsible_admin_phoneError: '',
      responsible_admin_emailError: '',
      cnpjError: '',
      social_nameError: '',
      fantasy_nameError: '',
      regional_council_numberError: '',
      bank_codeError: '',
      bank_holderError: '',
      bank_cpf_cnpjError: '',
      bank_agError: '',
      bank_ccError: '',
      state_idError: '',
      zipcodeError: '',
      addressError: '',
      numberError: '',
      neighborhoodError: '',
      complementError: '',
      cityError: '',
      user_full_nameError: '', 
      user_usertypesError: '', 
      user_cnpjError: '', 
      user_cpfError: '', 
      user_emailError: '', 

      // Loading
      blocking: false,
    }

  }

  componentDidMount() {
    if (check(getPermissions(), 'franchise_add') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadBanks()
    this.loadStates()
  }

  onChangeInput = e => {
    this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    e.target.value === "" ? this.setState({ [e.target.name + "Error"]: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ [e.target.name + "Error"]: null, error: null });
  }

  async loadStates() {
    const resp = await api.get('states/')
    this.setState({ states: resp.data });
  }
  
  async loadBanks(){
    const resp = await api.get('banks')
    this.setState({
      banks: resp.data
    });
  }

  async onBlurAddressZipcode(e) {
    var cep = e.target.value.replace('-', '')
    cep = parseInt(cep)
    if (cep.toString().length === 8) {
      var respAddress = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      if (respAddress.data.erro) {
        this.setState({
          address: '',
          neighborhood: '',
          city: '',
          state_id: '',
        })
      } else {
        var respState = await api.get(`states/${respAddress.data.uf}`)
        this.setState({
          address: respAddress.data.logradouro,
          neighborhood: respAddress.data.bairro,
          city: respAddress.data.localidade,
          state_id: respState.data.state[0].id,
          addressError: null,
          neighborhoodError: null,
          cityError: null,
          state_idError: null,
        })
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();

    var {
      zipcode,
      email,
      phone,
      cellphone,
      site,
      cnpj,
      social_name,
      fantasy_name,
      regional_council_number,
      responsible_admin_name,
      responsible_admin_cpf,
      responsible_admin_phone,
      responsible_admin_email,
      address,
      number,
      neighborhood,
      complement,
      state_id,
      city,
      bank_code,
      bank_holder,
      bank_cpf_cnpj,
      bank_ag,
      bank_cc,
      user_full_name,
      user_usertypes,
      user_cnpj,
      user_cpf,
      user_email
    } = this.state

    regional_council_number === "" ? this.setState({ regional_council_numberError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ regional_council_numberError: null });
    email === "" ? this.setState({ emailError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ emailError: null });
    phone === "" ? this.setState({ phoneError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ phoneError: null });
    cellphone === "" ? this.setState({ cellphoneError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ cellphoneError: null });
    site === "" ? this.setState({ siteError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ siteError: null });
    responsible_admin_name === "" ? this.setState({ responsible_admin_nameError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ responsible_admin_nameError: null });
    responsible_admin_cpf === "" ? this.setState({ responsible_admin_cpfError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ responsible_admin_cpfError: null });
    responsible_admin_phone === "" ? this.setState({ responsible_admin_phoneError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ responsible_admin_phoneError: null });
    responsible_admin_email === "" ? this.setState({ responsible_admin_emailError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ responsible_admin_emailError: null });
    cnpj === "" ? this.setState({ cnpjError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ cnpjError: null });
    social_name === "" ? this.setState({ social_nameError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ social_nameError: null });
    fantasy_name === "" ? this.setState({ fantasy_nameError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ fantasy_nameError: null });
    regional_council_number === "" ? this.setState({ regional_council_numberError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ regional_council_numberError: null });
    bank_code === "" ? this.setState({ bank_codeError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ bank_codeError: null });
    bank_holder === "" ? this.setState({ bank_holderError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ bank_holderError: null });
    bank_cpf_cnpj === "" ? this.setState({ bank_cpf_cnpjError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ bank_cpf_cnpjError: null });
    bank_ag === "" ? this.setState({ bank_agError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ bank_agError: null });
    bank_cc === "" ? this.setState({ bank_ccError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ bank_ccError: null });
    state_id === "" ? this.setState({ state_idError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ state_idError: null });
    zipcode === "" ? this.setState({ zipcodeError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ zipcodeError: null });
    address === "" ? this.setState({ addressError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressError: null });
    number === "" ? this.setState({ numberError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ numberError: null });
    neighborhood === "" ? this.setState({ neighborhoodError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ neighborhoodError: null });
    city === "" ? this.setState({ cityError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ cityError: null });
    user_usertypes === "" ? this.setState({ user_usertypesError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ user_usertypesError: null });
    user_full_name === "" ? this.setState({ user_full_nameError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ user_full_nameError: null });
    user_usertypes === "" ? this.setState({ user_usertypesError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ user_usertypesError: null });
    user_cnpj === "" && user_usertypes === "PF" ? this.setState({ user_cnpjError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ user_cnpjError: null });
    user_cpf === "" && user_usertypes === "PJ" ? this.setState({ user_cpfError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ user_cpfError: null });
    user_email === "" ? this.setState({ user_emailError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ user_emailError: null });

    if (
      zipcode !== "" && 
      address !== "" && 
      number !== "" && 
      neighborhood !== "" && 
      city !== "" && 
      state_id !== "" && 
      cnpj !== "" &&
      social_name !== "" &&
      fantasy_name !== "" &&
      regional_council_number !== "" &&
      email !== "" &&
      phone !== "" &&
      cellphone !== "" &&
      site !== "" &&
      responsible_admin_name !== "" &&
      responsible_admin_cpf !== "" &&
      responsible_admin_phone !== "" &&
      responsible_admin_email !== "" &&
      bank_code !== "" &&
      bank_holder !== "" &&
      bank_cpf_cnpj !== "" &&
      bank_ag !== "" &&
      bank_cc !== ""&& 
      user_full_name !== "" && 
      user_email !== "" && 
      user_usertypes !== "" && 
      (
        (user_usertypes === "PF" && user_cpf !== "") ||
        (user_usertypes === "PJ" && user_cnpj !== "")
      )
    ) {

      this.setState({ blocking: true })

      var cnpjValidate = cpf_cnpj.CNPJ,
          cpfValidate = cpf_cnpj.CPF,
          zipcode = zipcode.replace('-', '')

      const obj = {
        email: email,
        phone: phone,
        cellphone: cellphone,
        site: site,
        regional_council_number: regional_council_number,
        cnpj: cnpjValidate.strip(cnpj),
        social_name: social_name,
        fantasy_name: fantasy_name,
        regional_council_number: regional_council_number,
        responsible_admin_name: responsible_admin_name,
        responsible_admin_cpf: cpfValidate.strip(responsible_admin_cpf),
        responsible_admin_phone: responsible_admin_phone,
        responsible_admin_email: responsible_admin_email,
        zipcode: zipcode,
        address: address,
        number: number,
        neighborhood: neighborhood,
        complement: complement,
        state_id: state_id,
        city: city,
        bank_code: bank_code,
        bank_holder: bank_holder,
        bank_cpf_cnpj: bank_cpf_cnpj.length == 14 ? cpfValidate.strip(bank_cpf_cnpj) : cnpjValidate.strip(bank_cpf_cnpj),
        bank_ag: bank_ag,
        bank_cc: bank_cc,
        user_full_name: user_full_name,
        user_usertypes: user_usertypes,
        user_cnpj: cnpjValidate.strip(user_cnpj),
        user_cpf: cpfValidate.strip(user_cpf),
        user_email: user_email,
      };

      const resp = await api.post('franchises', obj)

      if (resp.status === 200) {

        this.props.handleClick('tr', 'success', 'Cadastrado com sucesso!')

        this.props.history.push("/credenciador");

      } else {
        scroll.scrollToTop();
        this.setState({
          error: resp.data,
          blocking: false
        })
      }
    }else{
      scroll.scrollToTop();
    }
  }

  render() {
    var {
      modalLock, blocking, error, cnpj, cnpjError, social_name, social_nameError,
      fantasy_name, fantasy_nameError, email, emailError, phone, phoneError,
      cellphone, cellphoneError, siteError, site, regional_council_number,
      regional_council_numberError, zipcode, zipcodeError, address, addressError,
      neighborhood, neighborhoodError, number, numberError, complement, city,
      cityError, state_id, states, state_idError, responsible_admin_name, 
      responsible_admin_nameError, responsible_admin_cpf, responsible_admin_cpfError,
      responsible_admin_phone, responsible_admin_phoneError, responsible_admin_email,
      responsible_admin_emailError, user_full_name, user_full_nameError, user_cnpj,
      user_cnpjError, user_usertypes, user_cpf, user_cpfError, user_email, user_emailError,
      bank_codeError, bank_code, banks, bank_holder, bank_holderError, bank_cpf_cnpj,
      bank_cpf_cnpjError, bank_agError, bank_ag, bank_ccError, bank_cc
    } = this.state
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={modalLock} />
        <Grid fluid>
          <Row>
            <Col md={6}>
              <Breadcrumb routes={[{ label: 'Credenciadores', link: '/credenciador' }]} active="Novo credenciador" />
              <BlockUi tag="div" blocking={blocking} message="Carregando, por favor aguarde">
                <form onSubmit={this.onSubmit}>
                  <Card
                    content={
                      <div>
                        {error &&
                          <Alert bsStyle="danger">
                            {
                              error.map((e, i) => {
                                return (
                                  <li key={i}>{e.message}</li>
                                )
                              })
                            }
                          </Alert>
                        }
                        <h4 className="mt-10">Dados da credenciador:</h4>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}><ControlLabel> CNPJ: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <InputMask
                                    mask={'99.999.999/9999-99'}
                                    maskChar=''
                                    className={cnpjError ? 'error form-control' : 'form-control'}
                                    value={cnpj ? cnpj : ''}
                                    name="cnpj"
                                    onChange={this.onChangeInput}
                                  />
                                  {cnpjError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Razão social: </ControlLabel> </Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={social_nameError ? 'error' : ''}
                                    name="social_name"
                                    value={social_name ? social_name : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {social_nameError}
                              </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Nome Fantasia: </ControlLabel> </Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={fantasy_nameError ? 'error' : ''}
                                    name="fantasy_name"
                                    value={fantasy_name ? fantasy_name : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {fantasy_nameError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> E-mail: </ControlLabel> </Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={emailError ? 'error' : ''}
                                    name="email"
                                    value={email ? email : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {emailError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Telefone: </ControlLabel> </Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <InputMask
                                    mask="(99) 99999-9999"
                                    maskChar=''
                                    className={phoneError ? 'error form-control' : 'form-control'}
                                    value={phone ? phone : ''}
                                    name="phone"
                                    onChange={this.onChangeInput}
                                  />
                                  {phoneError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col xs={6}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Celular: </ControlLabel> </Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <InputMask
                                    mask="(99) 99999-9999"
                                    maskChar=''
                                    className={cellphoneError ? 'error form-control' : 'form-control'}
                                    value={cellphone ? cellphone : ''}
                                    name="cellphone"
                                    onChange={this.onChangeInput}
                                  />
                                  {cellphoneError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Site: </ControlLabel> </Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={siteError ? 'error' : ''}
                                    name="site"
                                    value={site ? site : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {siteError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Nº Conselho Regional: </ControlLabel> </Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={regional_council_numberError ? 'error' : ''}
                                    name="regional_council_number"
                                    value={regional_council_number ? regional_council_number : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {regional_council_numberError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <h4 className="mt-10">Endereço da empresa:</h4>
                        <Row>
                          <Col xs={3}>
                            <FormGroup>
                              <ControlLabel>CEP:</ControlLabel>
                              <InputMask
                                mask="99999-999"
                                maskChar='_'
                                className={zipcodeError ? 'error form-control' : 'form-control'}
                                value={zipcode ? zipcode : ''}
                                name="zipcode"
                                onChange={this.onChangeInput}
                                onBlur={this.onBlurAddressZipcode}
                              />
                              {zipcodeError}
                            </FormGroup>
                          </Col>
                          <Col xs={9}>
                            <FormGroup>
                              <ControlLabel>Endereço:</ControlLabel>
                              <FormControl
                                type="text"
                                className={addressError ? 'error' : ''}
                                value={address ? address : ''}
                                name="address"
                                onChange={this.onChangeInput}
                              />
                              {addressError}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <FormGroup>
                              <ControlLabel>Bairro:</ControlLabel>
                              <FormControl
                                type="text"
                                className={neighborhoodError ? 'error' : ''}
                                value={neighborhood ? neighborhood : ''}
                                name="neighborhood"
                                onChange={this.onChangeInput}
                              />
                              {neighborhoodError}
                            </FormGroup></Col>
                          <Col xs={3}>
                            <FormGroup>
                              <ControlLabel>Número:</ControlLabel>
                              <FormControl
                                type="text"
                                className={numberError ? 'error' : ''}
                                value={number ? number : ''}
                                name="number"
                                onChange={this.onChangeInput}
                              />
                              {numberError}
                            </FormGroup>
                          </Col>
                          <Col xs={3}>
                            <FormGroup>
                              <ControlLabel>Complemento:</ControlLabel>
                              <FormControl
                                type="text"
                                value={complement ? complement : ''}
                                name="complement"
                                onChange={this.onChangeInput}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <FormGroup>
                              <ControlLabel>Cidade:</ControlLabel>
                              <FormControl
                                type="text"
                                className={cityError ? 'error' : ''}
                                value={city ? city : ''}
                                name="city"
                                onChange={this.onChangeInput}
                              />
                              {cityError}
                            </FormGroup>
                          </Col>
                          <Col xs={6}>
                            <FormGroup>
                              <ControlLabel>Estado:</ControlLabel>
                              <select
                                className={state_idError ? 'form-control error' : 'form-control'}
                                value={state_id ? state_id : ''}
                                name="state_id"
                                onChange={this.onChangeInput}>
                                <option value="">Selecione</option>
                                {
                                  states.map(element => {
                                    return (
                                      <option key={element.id} value={element.id}>{element.name}</option>
                                    )
                                  })
                                }
                              </select>
                              {state_idError}
                            </FormGroup>
                          </Col>
                        </Row>
                        <h4 className="mt-10">Responsável Administrativo:</h4>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Nome: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={responsible_admin_nameError ? 'error' : ''}
                                    name="responsible_admin_name"
                                    value={responsible_admin_name ? responsible_admin_name : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {responsible_admin_nameError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> CPF: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <InputMask
                                    mask={'999.999.999-99'}
                                    className={responsible_admin_cpfError ? 'error form-control' : 'form-control'}
                                    value={responsible_admin_cpf ? responsible_admin_cpf : ''}
                                    name="responsible_admin_cpf"
                                    onChange={this.onChangeInput}
                                  />
                                  {responsible_admin_cpfError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col xs={12} md={6}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Telefone: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <InputMask
                                    mask="(99) 99999-9999"
                                    maskChar=''
                                    className={responsible_admin_phoneError ? 'error form-control' : 'form-control'}
                                    value={responsible_admin_phone ? responsible_admin_phone : ''}
                                    name="responsible_admin_phone"
                                    onChange={this.onChangeInput}
                                  />
                                  {responsible_admin_phoneError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> E-mail: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={responsible_admin_emailError ? 'error' : ''}
                                    name="responsible_admin_email"
                                    value={responsible_admin_email ? responsible_admin_email : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {responsible_admin_emailError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <h4 className="mt-10">Dados do usuário de acesso</h4>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Nome: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={user_full_nameError ? 'error' : ''}
                                    name="user_full_name"
                                    value={user_full_name ? user_full_name : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {user_full_nameError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Tipo de pessoa: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <Radio
                                    number="user_usertypes_PF"
                                    option="PF"
                                    name="user_usertypes"
                                    onChange={this.onChangeInput}
                                    checked={user_usertypes === "PF"}
                                    label="Física"
                                  />
                                  <Radio
                                    number="user_usertypes_PJ"
                                    option="PJ"
                                    name="user_usertypes"
                                    onChange={this.onChangeInput}
                                    checked={user_usertypes === "PJ"}
                                    label="Jurídica"
                                    className="mb-0"
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          {user_usertypes === "PJ" ?
                            <Col xs={12} md={6}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> CNPJ: </ControlLabel></Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <InputMask
                                      mask={'99.999.999/9999-99'}
                                      className={user_cnpjError ? 'error form-control' : 'form-control'}
                                      value={user_cnpj ? user_cnpj : ''}
                                      name="user_cnpj"
                                      onChange={this.onChangeInput}
                                    />
                                    {user_cnpjError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            : null}
                            {user_usertypes === "PF" ?
                              <Col xs={12} md={6}>
                                <FormGroup>
                                  <Row>
                                    <Col xs={12}> <ControlLabel> CPF: </ControlLabel></Col>
                                  </Row>
                                  <Row>
                                    <Col xs={12}>
                                      <InputMask
                                        mask={'999.999.999-99'}
                                        className={user_cpfError ? 'error form-control' : 'form-control'}
                                        value={user_cpf ? user_cpf : ''}
                                        name="user_cpf"
                                        onChange={this.onChangeInput}
                                      />
                                      {user_cpfError}
                                    </Col>
                                  </Row>
                                </FormGroup>
                              </Col>
                            : null}
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> E-mail: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={user_emailError ? 'error' : ''}
                                    name="user_email"
                                    value={user_email ? user_email : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {user_emailError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <h4 className="mt-10">Dados bancários:</h4>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Banco: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <select
                                    className={bank_codeError ? 'error form-control' : 'form-control'}
                                    value={bank_code}
                                    name="bank_code"
                                    onChange={this.onChangeInput}
                                  >
                                    <option value="">Selecione o banco</option>
                                    {
                                      banks &&
                                        banks.map((e, i) => {
                                          return (
                                            <option value={e.value} key={i}>{e.label} - {e.value}</option>
                                          )
                                        })
                                    }
                                  </select>
                                  {bank_codeError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={8}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Titular: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={bank_holderError ? 'error' : ''}
                                    name="bank_holder"
                                    value={bank_holder ? bank_holder : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {bank_holderError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col xs={12} md={4}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> CPF/CNPJ: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <InputMask
                                    mask={bank_cpf_cnpj.length <= 14 ? '999.999.999-99????' : '99.999.999/9999-99'}
                                    formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                                    maskChar=''
                                    className={bank_cpf_cnpjError ? 'error form-control' : 'form-control'}
                                    value={bank_cpf_cnpj ? bank_cpf_cnpj : ''}
                                    name="bank_cpf_cnpj"
                                    onChange={this.onChangeInput}
                                  />
                                  {bank_cpf_cnpjError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Agência: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={bank_agError ? 'error' : ''}
                                    name="bank_ag"
                                    value={bank_ag ? bank_ag : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {bank_agError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col xs={12} md={6}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Conta corrente: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={bank_ccError ? 'error' : ''}
                                    name="bank_cc"
                                    value={bank_cc ? bank_cc : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {bank_ccError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    }
                    ftTextCenter
                    legend={
                      <div>
                        <Button type="submit" bsStyle="info" fill> Salvar </Button>
                        <Link to={"/credenciador/"} className="btn-fill btn btn-neutral btn-back">Voltar</Link>
                      </div>
                    }
                  />
                </form>
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}