import React, { Component } from 'react';
import {
    Nav, NavDropdown, MenuItem, Alert, Modal, FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import Countdown from 'react-countdown-now';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import logo from '../../assets/img/logo.png';   

import api from '../../services/api';
import { getUser, getReshToken, removeAuth, setBlock, removeBlock } from '../../services/auth';

const reshToken = getReshToken()

const user = JSON.parse(getUser())

const timeLogout = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        window.location.href = "/auth/login";
    } else {
        return <span style={{ display: 'block' }} className="text-center text-primary small">Tempo de expiração: {minutes}:{seconds}</span>;
    }
};

class HeaderLinks extends Component {

    constructor(props) {
        super(props);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.state = {
            modal: false,
            password: "",

            error: "",
            passwordError: "",

            blocking: false
        }
    }

    onChangePassword(e) {
        this.setState({ password: e.target.value });
        e.target.value === "" ? this.setState({ passwordError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ passwordError: null, error: null });
    }

    handleSignIn = async e => {
        e.preventDefault();

        this.setState({ blocking: true });

        const { password } = this.state;

        password === "" ? this.setState({ passwordError: (<small className="text-danger">Campo obrigatório.</small>), blocking: false }) : this.setState({ passwordError: null });

        if (password !== "") {
            try {
                const response = await api.post("/auth/sign-in", { email: user.email, password });
                if (response.status === 200) {

                    this.setState({
                        modal: false,
                        password: "",
                        passwordError: "",
                        error: "",
                        blocking: false,
                    });
                    removeBlock()

                } else if (response.status === 204) {
                    this.setState({
                        error: "Senha inválida",
                        blocking: false
                    });
                }
            } catch (err) {
                this.setState({
                    error: "Houve um problema, entre em contato com o suporte",
                    blocking: false
                });
            }
        }
    };

    async logout() {
        const resp = await api.post("/auth/logout", {
            refresh_token: reshToken
        })
        removeAuth()
        window.location.href = "/auth/login"
    }

    blockPage(){
        setBlock(true)
        this.setState({
            modal: true
        })
    }

    render() {
        return (
            <div>
                <Nav pullRight>
                    <NavDropdown
                        eventKey={4}
                        title={(
                            <div>
                                <p>
                                    <span className="user">
                                        {user ? user.admin == 1 ?
                                            'ClinicalCred' :
                                            user.company ?
                                                user.company.company_type === "PF" ? user.company.name : user.company.fantasy_name
                                            : user.franchise ?
                                                user.franchise.fantasy_name : ''
                                            : ''}
                                        <span className="username">{user ? user.full_name : ''}</span>
                                    </span>
                                    <b className="caret"></b>
                                </p>
                            </div>
                        )} noCaret id="basic-nav-dropdown-3" bsClass="dropdown-with-icons dropdown">
                        <MenuItem href="/meus-dados" eventKey={4.3}><i className="fa fa-user"></i> Meus Dados</MenuItem>
                        <MenuItem href="/alterar-senha" eventKey={4.3}><i className="fa fa-lock"></i> Alterar Senha</MenuItem>
                        <MenuItem divider />
                        <MenuItem onClick={() => this.blockPage() } eventKey={4.5}><i className="fas fa-user-lock"></i> Bloquear</MenuItem>
                        <MenuItem href="/auth/login" eventKey={4.5}><i className="pe-7s-close-circle"></i> Sair</MenuItem>
                    </NavDropdown>
                </Nav>
                <Modal
                    show={this.state.modal}
                    className="modal-lock"
                    >
                    <Modal.Body>
                        <BlockUi tag="div" blocking={this.state.blocking} message="Carregando, por favor aguarde">
                            <form onSubmit={this.handleSignIn}>
                                <Card
                                    hidden={this.state.cardHidden}
                                    textCenter
                                    content={
                                        <div>
                                            <img src={logo} className="auth-logo" alt="ClinicalCred" />
                                            {this.state.error && <Alert bsStyle="danger">{this.state.error}</Alert>}
                                            <Countdown
                                                date={Date.now() + 900000}
                                                renderer={timeLogout}
                                            />
                                            <FormGroup>
                                                <ControlLabel> Senha </ControlLabel>
                                                <FormControl
                                                    type="password"
                                                    className={this.state.passwordError ? 'error' : ''}
                                                    value={this.state.password}
                                                    onChange={this.onChangePassword}
                                                />
                                                {this.state.passwordError}
                                            </FormGroup>
                                            <FormGroup className="text-center">
                                                <Button type="submit" bsStyle="info" fill wd>
                                                    Desbloquear
                                                            </Button>
                                                <p className="btn-back">
                                                    <Link to={"/auth/login"} className="btn-link">Sair do sistema</Link>
                                                </p>
                                            </FormGroup>
                                        </div>
                                    }
                                />
                            </form>
                        </BlockUi>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default HeaderLinks;
