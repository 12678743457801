import { Row, Col, Alert, FormGroup, ControlLabel } from 'react-bootstrap'
import React, { Component } from 'react'
import BlockUi from 'react-block-ui'
import 'react-block-ui/style.css'
import InputMask from 'react-input-mask'
import SweetAlert from 'react-bootstrap-sweetalert'
import moment from "moment"
import cpf_cnpj from "cpf_cnpj"

import Button from '../../../components/CustomButton/CustomButton.jsx'
import Radio from '../../../components/CustomRadio/CustomRadio.jsx'

import api from '../../../services/api'

class FormAddContraction extends Component {

  constructor(props) {
    super(props)

    this.hideAlert = this.hideAlert.bind(this)
    this.dangerOperation = this.dangerOperation.bind(this)
    this.successOperation = this.successOperation.bind(this)

    this.onSubmit = this.onSubmit.bind(this)

    this.onChangeInput = this.onChangeInput.bind(this)

    this.state = {
      user_auth: {},
      blocking: false,
      alert: false,

      contraction_id: this.props.id,

      occupations: [],
      occupation: null,
      formpayments: [],
      form_of_payment: null,
      usertype: null,
      cpf: null,
      cnpj: null,

      error: null,
      occupationError: null,
      form_of_paymentError: null,
      usertypeError: null,
      cpfError: null,
      cnpjError: null
    }
  }

  componentDidMount() {
    this.userAuth()
    this.loadOccupation()
    this.loadFormpayment()
  }

  async userAuth(){
    const resp = await api.get('users/me')
    this.setState({ user_auth: resp.data.user })
  }

  async loadOccupation(){
    const restOccupation = await api.get('occupation')
    this.setState({ occupations: restOccupation.data })
  }
  
  async loadFormpayment(){
    const restFormpayment = await api.get('formpayment')
    this.setState({ formpayments: restFormpayment.data })
  }

  onChangeInput = e => {
    let name = e.target.name
    if (name === "usertype") {
      this.setState({
        cpf: null,
        cnpj: null,
        occupation: null,
        cpfError: null,
        cnpjError: null,
        occupationError: null,
      })
    }
    this.setState({ [name]: e.target.value })
    e.target.value === "" ? this.setState({ [name + "Error"]: (<small className='text-danger'>Campo obrigatório.</small>) }) : this.setState({ [name + "Error"]: null, error: null })
  }

  hideAlert() {
    this.setState({ alert: null })
  }

  dangerOperation(title, message) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title={title}
          onConfirm={this.hideAlert}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
        >
        {message}
        </SweetAlert>
      )
    });
  }

  successOperation(message) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title={message}
          onConfirm={() => {
            window.location.href = `/contratacao/${this.state.contraction_id}`;
          }}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
        >
        </SweetAlert>
      )
    });
  }

  async requestCreditall(data){
    try {

      const respRequestCreditall = await api.post('requests', {
        cnpj: data.cnpj,
        cpf: data.cpf,
        tipoconsulta: data.tipoconsulta,
        user_id: data.user_id,
        company_id: data.company_id,
        message: data.message,
        return: data.return
      })

      return respRequestCreditall
      
    } catch (error) {
      return error
    }
  }

  /* ==================================
    Integrações Creditall
  ================================== */
  async preanalysisCreditall(data) {

    try {

      const respPreanalysis = await api.post('prod/preanalysis', {
        cnpj: data.cnpj,
        cpf: data.cpf,
        tipoconsulta: data.tipoconsulta,
      })

      return respPreanalysis.data.result

    } catch (error) {
      return error
    }
  }

  /* ==================================
    Form
  ================================== */
  async onSubmit(e) {
    e.preventDefault()

    const { contraction_id, occupation, usertype, form_of_payment, cpf, cnpj } = this.state

    this.setState({ blocking: true })

    var cnpjValidate = cpf_cnpj.CNPJ,
      cpfValidate = cpf_cnpj.CPF,
      documentValidate = true,
      requestedAmount = 0

    const now = moment()

    occupation === null ? this.setState({ occupationError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ occupationError: null })
    form_of_payment === null ? this.setState({ form_of_paymentError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ form_of_paymentError: null })
    cpf === null ? this.setState({ cpfError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ cpfError: null })
    cnpj === null && usertype === "2" ? this.setState({ cnpjError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ cnpjError: null })

    if (cpf && !cpfValidate.isValid(cpfValidate.strip(cpf))) {
      documentValidate = false
      this.setState({ cpfError: (<small className="text-danger">CPF inválido</small>) })
    }

    if (cnpj && usertype === "3") {
      if (!cnpjValidate.isValid(cnpjValidate.strip(cnpj))) {
        documentValidate = false
        this.setState({ cnpjError: (<small className="text-danger">CNPJ inválido</small>) })
      }
    }

    if (usertype === "1") {
      const respContractionCustomer = await api.get(`contractions_customer_find_cpf/${contraction_id}/${cpfValidate.strip(cpf)}`)
      if (respContractionCustomer.data.length > 0) {
        documentValidate = false
        this.setState({ cpfError: (<small className="text-danger">CPF já solicitado nessa contratação, tente novamente com outro CPF</small>) })
      }
    } else if (usertype === "2") {
      const respContractionCustomer = await api.get(`contractions_customer_find_cnpj/${contraction_id}/${cnpjValidate.strip(cnpj)}/${cpfValidate.strip(cpf)}`)
      if (respContractionCustomer.data.length > 0) {
        documentValidate = false
        this.setState({ cpfError: (<small className="text-danger">CNPJ já solicitado nessa contratação, tente novamente com outro CNPJ</small>) })
      }
    }

    if (
      occupation &&
      form_of_payment &&
      cpf &&
      ((cnpj && usertype === "2") || (usertype === "1")) &&
      documentValidate) {


      const respPreanalysisCreditall = await this.preanalysisCreditall({
        cpf: cpfValidate.strip(cpf),
        cnpj: cnpjValidate.strip(cnpj),
        tipoconsulta: form_of_payment === "1" || form_of_payment === "3" ? 1 : 2 // 1-Boleto 2-Cheque
      })

      await this.requestCreditall({
        cpf: cpfValidate.strip(cpf),
        tipoconsulta: form_of_payment === "1" || form_of_payment === "3" ? 1 : 2, // 1-Boleto 2-Cheque 
        user_id: this.state.user_auth.id,
        company_id: this.state.user_auth.company.id,
        message: respPreanalysisCreditall.Resposta.Nome ? respPreanalysisCreditall.Resposta.Nome : '-',
        return: JSON.stringify(respPreanalysisCreditall),
      })

      //  1- Negado 2- Aprovado
      if (respPreanalysisCreditall.Resposta.Situacao) {
        if (respPreanalysisCreditall.Resposta.Situacao == 1) {
          this.dangerOperation('Solicitação negada', '')
        } else {
          if (respPreanalysisCreditall.Resposta.Limite_Saldo) {
            if (typeof respPreanalysisCreditall.Resposta.Data_Nascimento !== "object"){
              var limite_saldo = respPreanalysisCreditall.Resposta.Limite_Saldo
              limite_saldo = limite_saldo.replace(".", "")
              limite_saldo = limite_saldo.replace(",", ".")
              limite_saldo = parseFloat(limite_saldo)
              
              var date_of_birth = respPreanalysisCreditall.Resposta.Data_Nascimento.split('/')
              
              var dataContract = {
                contraction_id: contraction_id,
                occupation: occupation,
                formpayment_id: form_of_payment,
                name: respPreanalysisCreditall.Resposta.Nome,
                date_of_birth: `${date_of_birth[2]}-${date_of_birth[1]}-${date_of_birth[0]}`,
                cpf: cpfValidate.strip(cpf),
                cnpj: cnpjValidate.strip(cnpj),
                amount: limite_saldo.toFixed(2),
                pre_analysis: JSON.stringify(respPreanalysisCreditall.Resposta),
                first_installment: now.add(30, "days").format("YYYY-MM-DD")
              }

              const respContraction = await api.post('contractions_customer', dataContract)
              
              if (respContraction.status === 200) {
                this.successOperation('Solicitação Aprovada')
              } else if (respContraction.status === 203) {
                this.setState({
                  error: respContraction.data
                })
              }
            }else{
              this.dangerOperation('Não é possível continuar. Retorno da financeira: ', 'DOCTO NÃO DISPONIVEL NA BASE DA SINTESE CADASTRAL')
              this.setState({ blocking: false })
              return false
            }

          } else {
            this.dangerOperation('Não é possível continuar. Retorno da financeira:', 'Sistema indisponível')
            this.setState({ blocking: false })
            return false
          }
        }
      } else {
        this.dangerOperation('Não é possível continuar. Retorno da financeira:', 'Acesso Negado')
        this.setState({ blocking: false })
        return false
      }

      this.setState({ blocking: false })
    } else {
      this.setState({ blocking: false })
    }

  }

  render() {
    return (
      <BlockUi tag="div" message="Carregando, por favor aguarde" blocking={this.state.blocking}>
        {this.state.alert}
        <form onSubmit={this.onSubmit}>
          {this.state.error &&
            <Alert bsStyle="danger"> { this.state.error.map((e, i) => { return ( <li key={i}>{e.message}</li> ) }) } </Alert>
          }
          <FormGroup>
            <Row>
              <Col xs={12}>
                <ControlLabel>Forma de pagamento:</ControlLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <select
                  className={this.state.form_of_paymentError ? 'form-control error' : 'form-control'}
                  value={this.state.form_of_payment ? this.state.form_of_payment : ''}
                  name="form_of_payment"
                  onChange={this.onChangeInput}
                >
                  <option value="">Selecione</option>
                  {
                    this.state.formpayments.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>{item.description}</option>
                      )
                    })
                  }
                </select>
                {this.state.form_of_paymentError}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs={12}><ControlLabel>Tipo de Pessoa:</ControlLabel></Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Radio
                  number="usertype_1"
                  option="1"
                  name="usertype"
                  onChange={this.onChangeInput}
                  checked={this.state.usertype === "1"}
                  label="Física"
                />
                <Radio
                  number="usertype_2"
                  option="2"
                  name="usertype"
                  onChange={this.onChangeInput}
                  checked={this.state.usertype === "2"}
                  label="Jurídica"
                />
                {this.state.usertypeError}
              </Col>
            </Row>
          </FormGroup>
          {this.state.usertype === "2" &&
            <FormGroup>
              <Row>
                <Col xs={12}>
                  <ControlLabel>
                    CNPJ:
                </ControlLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <InputMask
                    mask={'99.999.999/9999-99'}
                    className={this.state.rf_cnpjError ? 'error form-control' : 'form-control'}
                    value={this.state.rf_cnpj ? this.state.rf_cnpj : ''}
                    name="rf_cnpj"
                    onChange={this.onChangeInput}
                  />
                  {this.state.rf_cnpjError}
                </Col>
              </Row>
            </FormGroup>
          }
          {this.state.usertype !== null &&
            <FormGroup>
              <Row>
                <Col xs={12}>
                  <ControlLabel>
                    {this.state.usertype === "1" ? "CPF:" : "CPF do Representante do CNPJ"}
                </ControlLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <InputMask
                    mask={'999.999.999-99'}
                    className={this.state.cpfError ? 'error form-control' : 'form-control'}
                    value={this.state.cpf ? this.state.cpf : ''}
                    name="cpf"
                    onChange={this.onChangeInput}
                  />
                  {this.state.cpfError}
                </Col>
              </Row>
            </FormGroup>
          }
          <FormGroup>
            <Row>
              <Col xs={12}>
                <ControlLabel>Ocupação:</ControlLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <select
                  className={this.state.occupationError ? 'form-control error' : 'form-control'}
                  value={this.state.occupation ? this.state.occupation : ''}
                  name="occupation"
                  onChange={this.onChangeInput}
                >
                  <option value="">Selecione</option>
                  {
                    this.state.occupations.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>{item.name}</option>
                      )
                    })
                  }
                </select>
                {this.state.occupationError}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="text-center">
            <Button type="submit" bsStyle="info" fill> Enviar para análise de crédito </Button>
          </FormGroup>
        </form>
      </BlockUi>
    )
  }

}

export default FormAddContraction