import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

import Card from '../../components/Card/Card.jsx';

export default class Page404 extends Component {

  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div>
                    Página não encontrada
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}