import React, { Component } from 'react';
import { Grid, Row, Col, Alert, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';
import { IMAGEM_URL } from '../../config/constants';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import api from '../../services/api';

export default class CompanyEdit extends Component {
  constructor(props) {
    super(props);

    this.statusChange = this.statusChange.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      documents: [],
      alert: null,
      reason: null,
      
      // validation
      error: '',
      
      // Loading
      blocking: false,
    }
  }

  componentDidMount() {
    if (check(getPermissions(), 'company_edit') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadDocuments()
  }

  onChangeInput(e) {
    this.setState({ reason: e.target.value })
  }

  warningWithConfirmMessage(id, is_approved) {
    this.setState({ reason: null })
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => {
            if((is_approved === 1) ||(is_approved === 2 && this.state.reason !== '' && this.state.reason !== null)){
              this.setState({ reason: null })
              this.statusChange(id, is_approved)
            }
          }}
          onCancel={() => {
            this.setState({ reason: null })
            this.setState({ alert: null })
          }}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim"
          cancelBtnText="Cancelar"
          showCancel
        >
          {
            is_approved === 2 ?
              <FormGroup>
                <ControlLabel>
                    Motivo <small style={{ fontSize: '12px'}}>(Campo obrigatório)</small>
                </ControlLabel>
                <FormControl
                  type="text"
                  value={this.state.reason}
                  name="reason"
                  onChange={this.onChangeInput}
                />
              </FormGroup>
            : null
          }
        </SweetAlert>
      )
    });
  }

  async statusChange(id, is_approved) {
    this.setState({ blocking: true })
    const resp = await api.put('company_document/approved/' + id, {
      is_approved,
      reason: this.state.reason
    })
    if (resp.status === 200) {
      this.loadDocuments()
      this.setState({
        alert: null,
        blocking: false
      })
      this.props.handleClick('tr', 'success', 'Atualizado com sucesso!')
    }else{
      this.setState({ alert: null, blocking: false })
      this.props.handleClick('tr', 'warning', 'Documento não encontrado!')
    }
  }

  async loadDocuments() {
    this.setState({ blocking: true })
    const respCompany = await api.get('companies/' + this.props.match.params.id)
    const company = respCompany.data.company
    const documents = company.document
    
    var is_document = true
    documents.map((e) => {
      if(e.is_approved === 2 || e.is_approved === 0){
        is_document = false
      }
    })
    await api.put('companies/document/' + this.props.match.params.id, {
      is_document
    })

    this.setState({
      blocking: false,
      documents: documents
    });
  }

  renderData() {
    const { documents } = this.state
    var row = [];
    for (var i = 0; i < documents.length; i++) {
      row.push(
        <tr key={documents[i].id}>
          <td>
            {
              documents[i].path && documents[i].is_send ? (<a href={`${IMAGEM_URL}/document_company/${documents[i].path}`} target="_blank">Visualizar arquivo</a>):'-'
            }
          </td>
          <td>{documents[i].type.name}</td>
          <td>
            {documents[i].is_send === 0 ? 'Não enviado' : documents[i].is_approved === 1 ? 'Aprovado': documents[i].is_approved === 2 ? 'Negado' : 'Aguardando aprovação'}
          </td>
          <td className="td-actions text-center">
            {
              documents[i].is_send && documents[i].is_approved !== 0 ?
                <Button onClick={this.warningWithConfirmMessage.bind(this, documents[i].id, documents[i].is_approved === 1 ? 2 : 1 )} bsStyle="info" fill sm>
                  {documents[i].is_approved === 1 ? (<span><i className="fas fa-thumbs-down"></i> Negar</span>) : (<span><i className="fas fa-thumbs-up"></i> Aprovar</span>) }
                </Button>
              : 
              null
            }
            {
              documents[i].path && documents[i].is_approved === 0 ?
                <div>
                  <Button onClick={this.warningWithConfirmMessage.bind(this, documents[i].id, 1)} bsStyle="info" className="mr-10 btn-fill btn-sm">
                    <span><i className="fas fa-thumbs-up"></i> Aprovar</span>
                  </Button>
                  <Button onClick={this.warningWithConfirmMessage.bind(this, documents[i].id, 2)} bsStyle="info" fill sm>
                    <span><i className="fas fa-thumbs-down"></i> Negar</span>
                  </Button>
                </div>
              : null
            }
          </td>
        </tr>
      );
    }
    return (
      <div className="table-responsive">
        <table className="table table-list table-striped">
          <thead>
            <tr>
              <th>Documento</th>
              <th>Nome</th>
              <th>Status</th>
              <th className="text-center" style={{ width: "205px" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {row}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const {  modalLock, blocking, error, alert } = this.state
    return (
      <div className="main-content">
        {alert}
        <CounterDown val={1800} modal={modalLock} />
        <Grid fluid>
          <Row>
            <Col md={9}>
              <Breadcrumb routes={[{ label: 'Empresas', link: '/empresa' }]} active="Documento empresa" />
              <BlockUi tag="div" blocking={blocking} message="Carregando, por favor aguarde">
                <Card
                  content={
                    <div>
                      {error &&
                        <Alert bsStyle="danger">
                          {
                            error.map((e, i) => {
                              return (
                                <li key={i}>{e.message}</li>
                              )
                            })
                          }
                        </Alert>
                      }
                      {this.renderData()}
                    </div>
                  }
                />
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}