import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Modal,
  Alert,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import InputMask from "react-input-mask";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";

import { check } from "../../services/Can";
import { getPermissions, getBlock } from "../../services/auth";

import Card from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import Radio from "../../components/CustomRadio/CustomRadio.jsx";
import Checkbox from "../../components/CustomCheckbox/CustomCheckbox.jsx";
import CounterDown from "../../components/CounterDown/CounterDown.jsx";

import api from "../../services/api";

export default class UserEdit extends Component {
  constructor(props) {
    super(props);

    this.handleModalAddressClose = this.handleModalAddressClose.bind(this);
    this.handleModalAddressShow = this.handleModalAddressShow.bind(this);
    this.handleModalPhoneClose = this.handleModalPhoneClose.bind(this);
    this.handleModalPhoneShow = this.handleModalPhoneShow.bind(this);

    this.onChangeRole = this.onChangeRole.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onBlurAddressZipcode = this.onBlurAddressZipcode.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitPhone = this.onSubmitPhone.bind(this);
    this.onSubmitAddresses = this.onSubmitAddresses.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      user_auth: {},

      admin: 0,
      company_id: "",
      title: "",
      usertypes: "",
      full_name: "",
      email: "",
      cpf: "",
      cnpj: "",
      roles: [],
      addresses: [],
      states: [],
      phones: [],
      companies: [],

      phoneAction: "create",
      phoneId: "",
      phoneDdd: "",
      phoneNumber: "",

      addressAction: "create",
      addressId: "",
      addressState_id: "",
      addressUser_id: "",
      addressZipcode: "",
      addressAddress: "",
      addressNumber: "",
      addressNeighborhood: "",
      addressComplement: "",
      addressLandmark: "",
      addressCity: "",

      checkedRoles: new Map(),

      // Confirm
      alert: null,
      show: false,

      // Modal
      modalAddressVisible: false,
      modalPhoneVisible: false,

      // validation
      error: "",
      errorAddress: "",
      errorPhone: "",

      usertypesError: "",
      full_nameError: "",
      emailError: "",
      cpfError: "",
      cnpjError: "",
      rolesError: "",

      phoneDddError: "",
      phoneNumberError: "",

      addressState_idError: "",
      addressUser_idError: "",
      addressZipcodeError: "",
      addressAddressError: "",
      addressNumberError: "",
      addressNeighborhoodError: "",
      addressCityError: "",

      // Loading
      blocking: false,
      blockingPhone: false,
      blockingAddress: false,
    };
  }

  componentDidMount() {
    if (check(getPermissions(), "user_edit") === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.userAuth();
    this.loadUser();
    this.loadstates();
    this.setState({ blocking: false });
  }

  async userAuth() {
    const resp = await api.get("users/me");
    this.setState({ user_auth: resp.data.user });
  }

  async loadUser() {
    const respUser = await api.get("users/" + this.props.match.params.id);

    if (respUser.data.user[0].admin == 1) {
      var respRoles = await api.get("roles?role_type_id=1&perpage=100");
    } else {
      if (respUser.data.user[0].company_id !== null) {
        var respRoles = await api.get("roles?role_type_id=3&perpage=100");
      } else if (respUser.data.user[0].franchise_id !== null) {
        var respRoles = await api.get("roles?role_type_id=2&perpage=100");
      }
    }

    var roles = [];
    for (let i = 0; i < respUser.data.user[0].roles.length; i++) {
      var role = respUser.data.user[0].roles[i];
      roles[role.id] = {
        id: role.id,
        name: role.name,
      };
    }

    var userRoles = new Map();
    respRoles.data.data.map((element) => {
      if (roles[element.id]) {
        userRoles.set(element.id.toString(), true);
      } else {
        userRoles.set(element.id.toString(), false);
      }
    });

    this.setState({
      title: respUser.data.user[0].full_name,
      email: respUser.data.user[0].email,
      usertypes: respUser.data.user[0].usertypes,
      full_name: respUser.data.user[0].full_name,
      cpf: respUser.data.user[0].cpf,
      cnpj: respUser.data.user[0].cnpj,
      addresses: respUser.data.user[0].addresses,
      phones: respUser.data.user[0].phones,
      roles: respRoles.data.data,
      checkedRoles: userRoles,
    });
  }

  async loadstates() {
    const resp = await api.get("states/");
    this.setState({ states: resp.data });
  }

  onChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value.toUpperCase() });
    e.target.value === ""
      ? this.setState({
          [e.target.name + "Error"]: (
            <small className="text-danger">Campo obrigatório.</small>
          ),
        })
      : this.setState({ [e.target.name + "Error"]: null, error: null });
  };

  async onBlurAddressZipcode(e) {
    this.setState({ blockingAddress: true });
    var cep = e.target.value.replace("-", "");
    cep = parseInt(cep);
    if (cep.toString().length === 8) {
      var respAddress = await axios.get(
        `https://viacep.com.br/ws/${cep}/json/`
      );
      if (respAddress.data.erro) {
        this.setState({
          addressAddress: "",
          addressNeighborhood: "",
          addressCity: "",
          addressState_id: "",
          blockingAddress: false,
        });
      } else {
        var respState = await api.get(`states/${respAddress.data.uf}`);
        this.setState({
          addressAddress: respAddress.data.logradouro,
          addressNeighborhood: respAddress.data.bairro,
          addressCity: respAddress.data.localidade,
          addressState_id: respState.data.state[0].id,
          addressAddressError: null,
          addressNeighborhoodError: null,
          addressCityError: null,
          addressState_idError: null,
          blockingAddress: false,
        });
      }
    }
  }

  onChangeRole(e) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      checkedRoles: prevState.checkedRoles.set(item, isChecked),
    }));
    this.setState({ rolesError: null });
  }

  async onSubmit(e) {
    e.preventDefault();

    var { usertypes, full_name, email, cpf, cnpj, checkedRoles } = this.state;

    this.setState({ blocking: true });

    usertypes === ""
      ? this.setState({
          usertypesError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ usertypesError: null });
    full_name === ""
      ? this.setState({
          full_nameError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ full_nameError: null });
    email === ""
      ? this.setState({
          emailError: <small className="text-danger">Campo obrigatório</small>,
        })
      : this.setState({ emailError: null });
    cpf === ""
      ? this.setState({
          cpfError: <small className="text-danger">Campo obrigatório</small>,
        })
      : this.setState({ cpfError: null });
    cnpj === ""
      ? this.setState({
          cnpjError: <small className="text-danger">Campo obrigatório</small>,
        })
      : this.setState({ cnpjError: null });

    if (usertypes === "PF" && cpf == "") {
      this.setState({
        cpfError: <small className="text-danger">Campo obrigatório</small>,
      });
    }
    if (usertypes === "PJ" && cnpj == "") {
      this.setState({
        cnpjError: <small className="text-danger">Campo obrigatório</small>,
      });
    }

    var roles = [];

    checkedRoles.forEach((checked, id) => {
      if (checked) {
        roles.push(id);
      }
    });

    roles.length === 0
      ? this.setState({
          rolesError: <small className="text-danger">Campo obrigatório.</small>,
        })
      : this.setState({ rolesError: null });

    if (
      usertypes !== "" &&
      ((usertypes === "PF" && cpf !== "") ||
        (usertypes === "PJ" && cnpj !== "")) &&
      full_name !== "" &&
      email !== "" &&
      roles.length > 0
    ) {
      cnpj = usertypes === "1" ? "" : "";
      cpf = usertypes === "2" ? "" : "";

      var cpf = cpf.replace(".", "");
      cpf = cpf.replace(".", "");
      cpf = cpf.replace("-", "");

      var cnpj;
      if (cnpj) {
        cnpj = cnpj.replace(".", "");
        cnpj = cnpj.replace(".", "");
        cnpj = cnpj.replace("-", "");
        cnpj = cnpj.replace("/", "");
      }

      const obj = {
        usertypes: usertypes,
        full_name: full_name,
        email: email,
        cpf: cpf,
        cnpj: cnpj,
        roles: roles,
      };

      const resp = await api.put("users/" + this.props.match.params.id, obj);

      if (resp.status === 200) {
        this.props.handleClick("tr", "success", "Atualizado com sucesso!");

        this.setState({ blocking: false });

        this.props.history.push("/usuario");
      } else if (resp.status === 203) {
        this.setState({
          error: resp.data,
          blocking: false,
        });
      } else {
        this.setState({
          error: resp.data.message,
          blocking: false,
        });
      }
    } else {
      this.setState({
        blocking: false,
      });
    }
  }

  async onSubmitAddresses(e) {
    e.preventDefault();

    this.state.addressState_id === ""
      ? this.setState({
          addressState_idError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ addressState_idError: null });
    this.state.addressZipcode === ""
      ? this.setState({
          addressZipcodeError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ addressZipcodeError: null });
    this.state.addressAddress === ""
      ? this.setState({
          addressAddressError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ addressAddressError: null });
    this.state.addressNumber === ""
      ? this.setState({
          addressNumberError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ addressNumberError: null });
    this.state.addressNeighborhood === ""
      ? this.setState({
          addressNeighborhoodError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ addressNeighborhoodError: null });
    this.state.addressCity === ""
      ? this.setState({
          addressCityError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ addressCityError: null });

    if (
      this.state.addressState_id !== "" &&
      this.state.addressZipcode !== "" &&
      this.state.addressAddress !== "" &&
      this.state.addressNumber !== "" &&
      this.state.addressNeighborhood !== "" &&
      this.state.addressCity !== ""
    ) {
      this.setState({ blockingAddress: true });

      var zipcode = this.state.addressZipcode;
      zipcode = parseInt(zipcode.replace("-", ""));

      if (this.state.addressAction === "create") {
        const obj = {
          user_id: this.props.match.params.id,
          state_id: this.state.addressState_id,
          zipcode: zipcode,
          address: this.state.addressAddress,
          number: this.state.addressNumber,
          neighborhood: this.state.addressNeighborhood,
          complement: this.state.addressComplement,
          landmark: this.state.addressLandmark,
          city: this.state.addressCity,
        };

        const resp = await api.post("address/", obj);

        if (resp.status === 200) {
          this.loadUser();
          this.setState({ modalAddressVisible: false, blockingAddress: false });

          this.props.handleClick("tr", "success", "Cadastrado com sucesso!");
        } else {
          this.setState({
            errorAddress: resp.data.message,
            blockingAddress: false,
          });
        }
      } else if (this.state.addressAction === "edit") {
        const obj = {
          user_id: this.props.match.params.id,
          state_id: this.state.addressState_id,
          zipcode: zipcode,
          address: this.state.addressAddress,
          number: this.state.addressNumber,
          neighborhood: this.state.addressNeighborhood,
          complement: this.state.addressComplement,
          landmark: this.state.addressLandmark,
          city: this.state.addressCity,
        };

        const resp = await api.put(`address/${this.state.addressId}`, obj);

        if (resp.status === 200) {
          this.loadUser();
          this.setState({ modalAddressVisible: false, blockingAddress: false });

          this.props.handleClick("tr", "success", "Atualizado com sucesso!");
        } else {
          this.setState({
            errorAddress: resp.data.message,
            blockingAddress: false,
          });
        }
      }
    }
  }

  async onSubmitPhone(e) {
    e.preventDefault();

    this.state.phoneDdd === ""
      ? this.setState({
          phoneDddError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ phoneDddError: null });
    this.state.phoneNumber === ""
      ? this.setState({
          phoneNumberError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ phoneNumberError: null });

    if (this.state.phoneDdd !== "" && this.state.phoneNumber !== "") {
      this.setState({ blockingPhone: true });

      if (this.state.phoneAction === "create") {
        const obj = {
          user_id: this.props.match.params.id,
          ddd: this.state.phoneDdd,
          number: this.state.phoneNumber,
        };
        const resp = await api.post("phone/", obj);

        if (resp.status === 200) {
          this.loadUser();
          this.setState({ modalPhoneVisible: false, blockingPhone: false });

          this.props.handleClick("tr", "success", "Cadastrado com sucesso!");
        } else {
          this.setState({
            errorPhone: resp.data.message,
            blockingPhone: false,
          });
        }
      } else if (this.state.phoneAction === "edit") {
        const obj = {
          ddd: this.state.phoneDdd,
          number: this.state.phoneNumber,
        };
        const resp = await api.put(`phones/${this.state.phoneId}`, obj);
        if (resp.status === 200) {
          this.loadUser();
          this.setState({ modalPhoneVisible: false, blockingPhone: false });

          this.props.handleClick("tr", "success", "Atualizado com sucesso!");
        } else {
          this.setState({
            errorPhone: resp.data.message,
            blockingPhone: false,
          });
        }
      }
    }
  }

  // Address
  handleModalAddressClose() {
    this.setState({ modalAddressVisible: false });
  }
  handleModalAddressShow() {
    this.setState({
      addressAction: "create",
      addressId: null,
      addressState_id: "",
      addressUser_id: "",
      addressZipcode: "",
      addressAddress: "",
      addressNumber: "",
      addressNeighborhood: "",
      addressComplement: "",
      addressLandmark: "",
      addressCity: "",
      addressState_idError: "",
      addressUser_idError: "",
      addressZipcodeError: "",
      addressAddressError: "",
      addressNumberError: "",
      addressNeighborhoodError: "",
      addressCityError: "",
      modalAddressVisible: true,
    });
  }
  async editAddressModal(id) {
    const resp = await api.get("address/" + id);
    this.setState({
      addressAction: "edit",
      addressId: resp.data.address.id,
      addressState_id: resp.data.address.state_id,
      addressUser_id: resp.data.address.user_id,
      addressZipcode: resp.data.address.zipcode,
      addressAddress: resp.data.address.address,
      addressNumber: resp.data.address.number,
      addressNeighborhood: resp.data.address.neighborhood,
      addressComplement: resp.data.address.complement,
      addressLandmark: resp.data.address.landmark,
      addressCity: resp.data.address.city,
      addressState_idError: "",
      addressUser_idError: "",
      addressZipcodeError: "",
      addressAddressError: "",
      addressNumberError: "",
      addressNeighborhoodError: "",
      addressCityError: "",
      modalAddressVisible: true,
    });
  }
  removeAddressConfirmMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.successRemoveAddress(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim, exclua!"
          cancelBtnText="Cancelar"
          showCancel
        ></SweetAlert>
      ),
    });
  }
  async successRemoveAddress(id) {
    const resp = await api.delete("address/" + id);

    if (resp.status === 200) {
      this.loadUser();
      this.hideAlert();
      this.props.handleClick("tr", "success", "Excluído com sucesso!");
    }
  }

  // Phone
  handleModalPhoneClose() {
    this.setState({ modalPhoneVisible: false });
  }
  handleModalPhoneShow() {
    this.setState({
      phoneAction: "create",
      phoneId: null,
      phoneDdd: "",
      phoneNumber: "",
      phoneDddError: "",
      phoneNumberError: "",
      modalPhoneVisible: true,
    });
  }
  async editPhoneModal(id) {
    const resp = await api.get("phones/" + id);
    this.setState({
      phoneAction: "edit",
      phoneId: resp.data.phone.id,
      phoneDdd: resp.data.phone.ddd,
      phoneNumber: resp.data.phone.number,
      phoneDddError: "",
      phoneNumberError: "",
      modalPhoneVisible: true,
    });
  }
  removePhoneConfirmMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.successRemovePhone(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim, exclua!"
          cancelBtnText="Cancelar"
          showCancel
        ></SweetAlert>
      ),
    });
  }
  async successRemovePhone(id) {
    const resp = await api.delete("phones/" + id);
    if (resp.status === 200) {
      this.loadUser();
      this.hideAlert();
      this.props.handleClick("tr", "success", "Excluído com sucesso!");
    }
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  renderRole() {
    const { roles, checkedRoles } = this.state;
    return roles.map((checkbox) => (
      <div key={checkbox.id}>
        <Checkbox
          number={checkbox.id}
          value={checkbox.id}
          label={checkbox.name}
          name={checkbox.id}
          checked={checkedRoles.get(checkbox.id.toString())}
          onChange={this.onChangeRole}
        />
      </div>
    ));
  }

  renderAddresses() {
    var {
      modalAddressVisible,
      blockingAddress,
      errorAddress,
      addressZipcode,
      addressZipcodeError,
      addressAddress,
      addressAddressError,
      addressNeighborhood,
      addressNeighborhoodError,
      addressNumber,
      addressNumberError,
      addressComplement,
      addressCity,
      addressCityError,
      addressState_id,
      addressState_idError,
      states,
      addressLandmark,
    } = this.state;
    return (
      <Modal show={modalAddressVisible} onHide={this.handleModalAddressClose}>
        <BlockUi
          tag="div"
          blocking={blockingAddress}
          className="card"
          style={{ margin: "0px" }}
          message="Carregando, por favor aguarde"
        >
          <Modal.Header closeButton>
            <Modal.Title>Endereço</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errorAddress && (
              <Alert bsStyle="danger">
                {errorAddress.map((e, i) => {
                  return <li key={i}>{e.message}</li>;
                })}
              </Alert>
            )}
            <Row style={{ padding: "0px" }}>
              <Col xs={3}>
                <FormGroup>
                  <ControlLabel>CEP:</ControlLabel>
                  <InputMask
                    mask="99999-999"
                    maskChar="_"
                    className={
                      addressZipcodeError
                        ? "error form-control"
                        : "form-control"
                    }
                    value={addressZipcode ? addressZipcode : ""}
                    name="addressZipcode"
                    onChange={this.onChangeInput}
                    onBlur={this.onBlurAddressZipcode}
                  />
                  {addressZipcodeError}
                </FormGroup>
              </Col>
              <Col xs={9}>
                <FormGroup>
                  <ControlLabel>Endereço:</ControlLabel>
                  <FormControl
                    type="text"
                    className={addressAddressError ? "error" : ""}
                    value={addressAddress ? addressAddress : ""}
                    name="addressAddress"
                    onChange={this.onChangeInput}
                  />
                  {addressAddressError}
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ padding: "0px" }}>
              <Col xs={6}>
                <FormGroup>
                  <ControlLabel>Bairro:</ControlLabel>
                  <FormControl
                    type="text"
                    className={addressNeighborhoodError ? "error" : ""}
                    value={addressNeighborhood ? addressNeighborhood : ""}
                    name="addressNeighborhood"
                    onChange={this.onChangeInput}
                  />
                  {addressNeighborhoodError}
                </FormGroup>
              </Col>
              <Col xs={3}>
                <FormGroup>
                  <ControlLabel>Número:</ControlLabel>
                  <FormControl
                    type="text"
                    className={addressNumberError ? "error" : ""}
                    value={addressNumber ? addressNumber : ""}
                    name="addressNumber"
                    onChange={this.onChangeInput}
                  />
                  {addressNumberError}
                </FormGroup>
              </Col>
              <Col xs={3}>
                <FormGroup>
                  <ControlLabel>Complemento:</ControlLabel>
                  <FormControl
                    type="text"
                    value={addressComplement ? addressComplement : ""}
                    name="addressComplement"
                    onChange={this.onChangeInput}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ padding: "0px" }}>
              <Col xs={6}>
                <FormGroup>
                  <ControlLabel>Cidade:</ControlLabel>
                  <FormControl
                    type="text"
                    className={addressCityError ? "error" : ""}
                    value={addressCity ? addressCity : ""}
                    name="addressCity"
                    onChange={this.onChangeInput}
                  />
                  {addressCityError}
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <ControlLabel>Estado:</ControlLabel>
                  <select
                    className={
                      addressState_idError
                        ? "form-control error"
                        : "form-control"
                    }
                    value={addressState_id ? addressState_id : ""}
                    name="addressState_id"
                    onChange={this.onChangeInput}
                  >
                    <option value="">Selecione</option>
                    {states.map((element) => {
                      return (
                        <option key={element.id} value={element.id}>
                          {element.name}
                        </option>
                      );
                    })}
                  </select>
                  {addressState_idError}
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ padding: "0px" }}>
              <Col xs={12}>
                <FormGroup>
                  <ControlLabel>Ponto de referência:</ControlLabel>
                  <FormControl
                    type="text"
                    value={addressLandmark ? addressLandmark : ""}
                    name="addressLandmark"
                    onChange={this.onChangeInput}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.handleModalAddressClose}
              className="btn-fill btn btn-neutral btn-back"
            >
              Cancelar
            </Button>
            <Button onClick={this.onSubmitAddresses} bsStyle="info" fill>
              Salvar
            </Button>
          </Modal.Footer>
        </BlockUi>
      </Modal>
    );
  }

  renderPhone() {
    var {
      modalPhoneVisible,
      blockingPhone,
      errorPhone,
      phoneDdd,
      phoneDddError,
      phoneNumber,
      phoneNumberError,
    } = this.state;
    return (
      <Modal show={modalPhoneVisible} onHide={this.handleModalPhoneClose}>
        <BlockUi
          tag="div"
          blocking={blockingPhone}
          className="card"
          style={{ margin: "0px" }}
          message="Carregando, por favor aguarde"
        >
          <Modal.Header closeButton>
            <Modal.Title>Telefone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errorPhone && (
              <Alert bsStyle="danger">
                {errorPhone.map((e, i) => {
                  return <li key={i}>{e.message}</li>;
                })}
              </Alert>
            )}
            <Row style={{ padding: "0px" }}>
              <Col xs={3}>
                <FormGroup>
                  <ControlLabel>DDD:</ControlLabel>
                  <InputMask
                    mask="99"
                    maskChar=""
                    className={
                      phoneDddError ? "error form-control" : "form-control"
                    }
                    value={phoneDdd ? phoneDdd : ""}
                    name="phoneDdd"
                    onChange={this.onChangeInput}
                  />
                  {phoneDddError}
                </FormGroup>
              </Col>
              <Col xs={9}>
                <FormGroup>
                  <ControlLabel>Telefone:</ControlLabel>
                  <InputMask
                    mask="99999-9999"
                    maskChar="_"
                    className={
                      phoneNumberError ? "error form-control" : "form-control"
                    }
                    value={phoneNumber ? phoneNumber : ""}
                    name="phoneNumber"
                    onChange={this.onChangeInput}
                  />
                  {phoneNumberError}
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.handleModalPhoneClose}
              className="btn-fill btn btn-neutral btn-back"
            >
              Cancelar
            </Button>
            <Button onClick={this.onSubmitPhone} bsStyle="info" fill>
              Salvar
            </Button>
          </Modal.Footer>
        </BlockUi>
      </Modal>
    );
  }

  render() {
    var {
      modalLock,
      alert,
      title,
      blocking,
      error,
      full_name,
      full_nameError,
      email,
      emailError,
      usertypes,
      usertypesError,
      cpf,
      cpfError,
      cnpj,
      cnpjError,
      rolesError,
      addresses,
      phones,
    } = this.state;
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={modalLock} />
        {alert}
        {this.renderAddresses()}
        {this.renderPhone()}
        <Grid fluid>
          <Row>
            <Col md={6}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-links">
                  <li className="breadcrumb-item">
                    <Link to={"/usuario"}>Usuários</Link>
                  </li>
                  <li className="breadcrumb-item active">Editar - {title}</li>
                </ol>
              </nav>
              <BlockUi
                tag="div"
                blocking={blocking}
                message="Carregando, por favor aguarde"
              >
                <form onSubmit={this.onSubmit}>
                  <Card
                    content={
                      <div>
                        {error && (
                          <Alert bsStyle="danger">
                            {error.map((e, i) => {
                              return <li key={i}>{e.message}</li>;
                            })}
                          </Alert>
                        )}
                        <h4 style={{ marginTop: "0px" }}>Dados Pessoais</h4>
                        <FormGroup>
                          <ControlLabel>Nome completo:</ControlLabel>
                          <FormControl
                            type="text"
                            className={full_nameError ? "error" : ""}
                            value={full_name ? full_name : ""}
                            name="full_name"
                            onChange={this.onChangeInput}
                          />
                          {full_nameError}
                        </FormGroup>

                        <FormGroup>
                          <ControlLabel>E-mail:</ControlLabel>
                          <FormControl
                            type="text"
                            className={emailError ? "error" : ""}
                            value={email ? email : ""}
                            name="email"
                            onChange={this.onChangeInput}
                          />
                          {emailError}
                        </FormGroup>

                        <FormGroup>
                          <ControlLabel>Tipo de pessoa:</ControlLabel>
                          <FormGroup>
                            <Radio
                              number="usertypes_PF"
                              option="PF"
                              name="usertypes"
                              onChange={this.onChangeInput}
                              checked={usertypes === "PF"}
                              label="Física"
                            />
                            <Radio
                              number="usertypes_PJ"
                              option="PJ"
                              name="usertypes"
                              onChange={this.onChangeInput}
                              checked={usertypes === "PJ"}
                              label="Jurídica"
                            />
                          </FormGroup>
                          {usertypesError}
                        </FormGroup>

                        {usertypes == "PF" && (
                          <FormGroup>
                            <ControlLabel>CPF:</ControlLabel>
                            <InputMask
                              mask="999.999.999-99"
                              maskChar="_"
                              className={
                                cpfError ? "error form-control" : "form-control"
                              }
                              value={cpf ? cpf : ""}
                              name="cpf"
                              onChange={this.onChangeInput}
                            />
                            {cpfError}
                          </FormGroup>
                        )}
                        {usertypes == "PJ" && (
                          <FormGroup>
                            <ControlLabel>CNPJ:</ControlLabel>
                            <InputMask
                              mask="99.999.999/9999-99"
                              maskChar="_"
                              className={
                                cnpjError
                                  ? "error form-control"
                                  : "form-control"
                              }
                              value={cnpj ? cnpj : ""}
                              name="cnpj"
                              onChange={this.onChangeInput}
                            />
                            {cnpjError}
                          </FormGroup>
                        )}
                        <FormGroup>
                          <ControlLabel>Perfil:</ControlLabel>
                          {this.renderRole()}
                          {rolesError}
                        </FormGroup>

                        <div className="box-address">
                          <header>
                            <h4>Endereços</h4>
                            <Button onClick={this.handleModalAddressShow}>
                              Adicionar
                            </Button>
                          </header>
                          <section>
                            {addresses.map((element) => {
                              return (
                                <article key={element.id}>
                                  <p>
                                    {element.address}, {element.number}{" "}
                                    {element.complement} -{" "}
                                    {element.neighborhood}, {element.city} -{" "}
                                    {element.states.initials}, {element.zipcode}
                                    <small>{element.landmark}</small>
                                  </p>
                                  <div className="buttons">
                                    <button
                                      type="button"
                                      onClick={this.editAddressModal.bind(
                                        this,
                                        element.id
                                      )}
                                      className="btn btn-primary btn-round btn-xs"
                                    >
                                      <i className="fa fa-edit"></i> Editar
                                    </button>
                                    <button
                                      type="button"
                                      onClick={this.removeAddressConfirmMessage.bind(
                                        this,
                                        element.id
                                      )}
                                      className="btn btn-primary btn-round btn-xs"
                                    >
                                      <i className="fa fa-trash"></i> Excluir
                                    </button>
                                  </div>
                                </article>
                              );
                            })}
                          </section>
                        </div>

                        <div className="box-phone">
                          <header>
                            <h4>Telefones</h4>
                            <Button onClick={this.handleModalPhoneShow}>
                              Adicionar
                            </Button>
                          </header>
                          <section>
                            {phones.map((element) => {
                              return (
                                <article key={element.id}>
                                  <p>
                                    ({element.ddd}) {element.number}
                                  </p>
                                  <div className="buttons">
                                    <button
                                      type="button"
                                      onClick={this.editPhoneModal.bind(
                                        this,
                                        element.id
                                      )}
                                      className="btn btn-primary btn-round btn-xs"
                                    >
                                      <i className="fa fa-edit"></i> Editar
                                    </button>
                                    <button
                                      type="button"
                                      onClick={this.removePhoneConfirmMessage.bind(
                                        this,
                                        element.id
                                      )}
                                      className="btn btn-primary btn-round btn-xs"
                                    >
                                      <i className="fa fa-trash"></i> Excluir
                                    </button>
                                  </div>
                                </article>
                              );
                            })}
                          </section>
                        </div>
                      </div>
                    }
                    ftTextCenter
                    legend={
                      <div>
                        <Button type="submit" bsStyle="info" fill>
                          {" "}
                          Salvar{" "}
                        </Button>
                        <Link
                          to={"/usuario/"}
                          className="btn-fill btn btn-neutral btn-back"
                        >
                          Voltar
                        </Link>
                      </div>
                    }
                  />
                </form>
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
