import React, { Component } from 'react';
import { Grid, Row, Col, Alert } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import axios from "axios";

import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';

import Card from '../../components/Card/Card.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import api from '../../services/api';

export default class FranchiseDocument extends Component {
  constructor(props) {
    super(props);

    this.onChangeInputFile1 = this.onChangeInputFile1.bind(this);
    this.onChangeInputFile2 = this.onChangeInputFile2.bind(this);
    this.onChangeInputFile3 = this.onChangeInputFile3.bind(this);
    this.onChangeInputFile4 = this.onChangeInputFile4.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      document_1: {},
      document_2: {},
      document_3: {},
      document_4: {},
      documents: [],
      alert: null,
      id: this.props.match.params.id,
      
      // validation
      error: '',
      
      // Loading
      blocking: false,
    }
  }

  componentDidMount() {
    if (check(getPermissions(), 'franchise_edit') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadDocuments()
  }

  onChangeInputFile1 = e => {
    if (e.target.files[0].type === "application/pdf"){
      if (e.target.files[0].size <= 5000000){
        e.target.files[0] === null ?
          this.setState({ document_1: { file: null, error: (<small className='text-danger'>Campo obrigatório.</small>) } })
          :
          this.setState({ document_1: { file: e.target.files[0], error: null } })
      }else{
        this.setState({ document_1: { file: null, error: (<small className='text-danger'>Enviar documento no tamanho máximo de 5MB.</small>) } })
      }
    }else{
      this.setState({ document_1: { file: null, error: (<small className='text-danger'>Enviar no formato de PDF.</small>) } })
    }
  }
  onChangeInputFile2 = e => {
    if (e.target.files[0].type === "application/pdf"){
      if (e.target.files[0].size <= 5000000){
        e.target.files[0] === null ?
          this.setState({ document_2: { file: null, error: (<small className='text-danger'>Campo obrigatório.</small>) } })
          :
          this.setState({ document_2: { file: e.target.files[0], error: null } })
      }else{
        this.setState({ document_2: { file: null, error: (<small className='text-danger'>Enviar documento no tamanho máximo de 5MB.</small>) } })
      }
    }else{
      this.setState({ document_2: { file: null, error: (<small className='text-danger'>Enviar no formato de PDF.</small>) } })
    }
  }
  onChangeInputFile3 = e => {
    if (e.target.files[0].type === "application/pdf"){
      if (e.target.files[0].size <= 5000000){
        e.target.files[0] === null ?
          this.setState({ document_3: { file: null, error: (<small className='text-danger'>Campo obrigatório.</small>) } })
          :
          this.setState({ document_3: { file: e.target.files[0], error: null } })
      }else{
        this.setState({ document_3: { file: null, error: (<small className='text-danger'>Enviar documento no tamanho máximo de 5MB.</small>) } })
      }
    }else{
      this.setState({ document_3: { file: null, error: (<small className='text-danger'>Enviar no formato de PDF.</small>) } })
    }
  }
  onChangeInputFile4 = e => {
    if (e.target.files[0].type === "application/pdf"){
      if (e.target.files[0].size <= 5000000){
        e.target.files[0] === null ?
          this.setState({ document_4: { file: null, error: (<small className='text-danger'>Campo obrigatório.</small>) } })
          :
          this.setState({ document_4: { file: e.target.files[0], error: null } })
      }else{
        this.setState({ document_4: { file: null, error: (<small className='text-danger'>Enviar documento no tamanho máximo de 5MB.</small>) } })
      }
    }else{
      this.setState({ document_4: { file: null, error: (<small className='text-danger'>Enviar no formato de PDF.</small>) } })
    }
  }

  successOperation(message) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title={message}
          onConfirm={() => {
            this.setState({ alert: null})
          }}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
        >
        </SweetAlert>
      )
    });
  }
  
  infoOperation(message) {
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block" }}
          title={message}
          onConfirm={() => {
            this.setState({ alert: null})
          }}
          confirmBtnBsStyle="info"
          confirmBtnText="Ok"
        >
        </SweetAlert>
      )
    });
  }

  async loadDocuments() {
    this.setState({ blocking: true })
    const respFranchise = await api.get('franchises/' + this.props.match.params.id)
    const franchise = respFranchise.data.franchise
    const documents = franchise.document
    this.setState({
      blocking: false,
      documents: documents
    });
  }

  async addDocument1(){

    const { id, document_1 } = this.state;

    if(!document_1.file){
      this.setState({ document_1: { file: null, error: (<small className='text-danger'>Campo obrigatório.</small>) } })
    }else{
      this.setState({ blocking: true })
  
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      
      var data = new FormData();
      data.append('franchise_id', id);
      data.append('franchise_document_type_id', 1);
      data.append('file', document_1.file);
      
      const response = await axios.post('https://ws-staging2.clinicalcred.com.br/document_franchise.php', data, config)
      
      if(response.data.status === 200){
        this.successOperation(response.data.message)  
      }else if(response.data.status === 204){
        this.infoOperation(response.data.message)  
      }else if(response.data.status === 203){
        this.setState({ error: response.data})
      }
      this.setState({ blocking: false })
    }

  }

  async addDocument2(){

    const { id, document_2 } = this.state;

    if(!document_2.file){
      this.setState({ document_2: { file: null, error: (<small className='text-danger'>Campo obrigatório.</small>) } })
    }else{
      this.setState({ blocking: true })
  
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      
      var data = new FormData();
      data.append('franchise_id', id);
      data.append('franchise_document_type_id', 2);
      data.append('file', document_2.file);
      
      const response = await axios.post('https://ws-staging2.clinicalcred.com.br/document_franchise.php', data, config)
      
      if(response.data.status === 200){
        this.successOperation(response.data.message)  
      }else if(response.data.status === 204){
        this.infoOperation(response.data.message)  
      }else if(response.data.status === 203){
        this.setState({ error: response.data})
      }
      this.setState({ blocking: false })
    }

  }

  async addDocument3(){

    const { id, document_3 } = this.state;

    if(!document_3.file){
      this.setState({ document_3: { file: null, error: (<small className='text-danger'>Campo obrigatório.</small>) } })
    }else{
      this.setState({ blocking: true })
  
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      
      var data = new FormData();
      data.append('franchise_id', id);
      data.append('franchise_document_type_id', 3);
      data.append('file', document_3.file);
      
      const response = await axios.post('https://ws-staging2.clinicalcred.com.br/document_franchise.php', data, config)
      
      if(response.data.status === 200){
        this.successOperation(response.data.message)  
      }else if(response.data.status === 204){
        this.infoOperation(response.data.message)  
      }else if(response.data.status === 203){
        this.setState({ error: response.data})
      }
      this.setState({ blocking: false })
    }

  }

  async addDocument4(){

    const { id, document_4 } = this.state;

    if(!document_4.file){
      this.setState({ document_4: { file: null, error: (<small className='text-danger'>Campo obrigatório.</small>) } })
    }else{
      this.setState({ blocking: true })
  
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      
      var data = new FormData();
      data.append('franchise_id', id);
      data.append('franchise_document_type_id', 4);
      data.append('file', document_4.file);
      
      const response = await axios.post('https://ws-staging2.clinicalcred.com.br/document_franchise.php', data, config)
      
      if(response.data.status === 200){
        this.successOperation(response.data.message)  
      }else if(response.data.status === 204){
        this.infoOperation(response.data.message)  
      }else if(response.data.status === 203){
        this.setState({ error: response.data})
      }
      this.setState({ blocking: false })
    }

  }

  render() {
    const {
      documents, document_1, document_2, document_3, document_4, modalLock, blocking, alert, error } = this.state
    return (
      <div className="main-content">
        {alert}
        <CounterDown val={1800} modal={modalLock} />
        <Grid fluid>
          <Row>
            <Col md={9}>
              <Breadcrumb routes={[{ label: 'Credenciadores', link: '/credenciador' }]} active="Documento credenciador" />
              <BlockUi tag="div" blocking={blocking} message="Carregando, por favor aguarde">
                <Card
                  content={
                    <div>
                      {error &&
                        <Alert bsStyle="danger">
                          {
                            error.map((e, i) => {
                              return (
                                <li key={i}>{e.message}</li>
                              )
                            })
                          }
                        </Alert>
                      }
                      {
                        documents.length > 0 ?
                          <div className="content">
                            <h4 className="mt-10 text-center">Envio de Documentos</h4>
                            <div className="row">
                              <div className="col-xs-6 mb-20 text-center">
                                <form
                                  onSubmit={event => {
                                    event.preventDefault()

                                    this.addDocument1()
                                  }}
                                >
                                  <div className="form-group">
                                    <label className="control-label">Contrato Social: <span className="small">(Última alteração)</span></label>
                                    <input
                                      type='file'
                                      className={'form-control'}
                                      name="document_1"
                                      onChange={this.onChangeInputFile1}
                                      />
                                      {document_1.error}
                                  </div>
                                  <input
                                    type="submit"
                                    name="submit"
                                    className="btn-fill btn btn-info"
                                    value={documents && documents[0].is_send === 1 ? "Enviar outro arquivo" : "Enviar arquivo"}
                                  />
                                </form>
                              </div>
                              <div className="col-xs-6 mb-20 text-center">
                                <form
                                    onSubmit={event => {
                                      event.preventDefault()

                                      this.addDocument2()
                                    }}
                                  >
                                  <div className="form-group">
                                  <label className="control-label">RG/CPF: <span className="small">(CNH do sócio administrador)</span></label>
                                    <input
                                      type='file'
                                      className={'form-control'}
                                      name="document_2"
                                      onChange={this.onChangeInputFile2}
                                      />
                                      {document_2.error}
                                  </div>
                                  <input
                                    type="submit"
                                    name="submit"
                                    className="btn-fill btn btn-info"
                                    value={documents && documents[1].is_send === 1 ? "Enviar outro arquivo" : "Enviar arquivo"}
                                  />
                                </form>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-6 mb-20 text-center">
                                <form
                                  onSubmit={event => {
                                    event.preventDefault()

                                    this.addDocument3()
                                  }}
                                >
                                  <div className="form-group">
                                    <label className="control-label">Comprovante de Endereço</label>
                                    <input
                                      type='file'
                                      className={'form-control'}
                                      name="document_3"
                                      onChange={this.onChangeInputFile3}
                                      />
                                      {document_3.error}
                                  </div>
                                  <input
                                    type="submit"
                                    name="submit"
                                    className="btn-fill btn btn-info"
                                    value={documents && documents[2].is_send === 1 ? "Enviar outro arquivo" : "Enviar arquivo"}
                                  />
                                </form>
                              </div>
                              <div className="col-xs-6 mb-20 text-center">
                                <form
                                  onSubmit={event => {
                                    event.preventDefault()

                                    this.addDocument4()
                                  }}
                                >
                                  <div className="form-group">
                                    <label className="control-label">Comprovante de Domicílio Bancário</label>
                                    <input
                                      type='file'
                                      className={'form-control'}
                                      name="document_4"
                                      onChange={this.onChangeInputFile4}
                                      />
                                      {document_4.error}
                                  </div>
                                  <input
                                    type="submit"
                                    name="submit"
                                    className="btn-fill btn btn-info"
                                    value={documents && documents[3].is_send === 1 ? "Enviar outro arquivo" : "Enviar arquivo"}
                                  />
                                </form>
                              </div>
                            </div>
                          </div>
                        : null
                      }
                    </div>
                  }
                />
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}