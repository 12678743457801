import React, { Component } from 'react';
import { Grid, Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import axios from "axios";

import api from '../../services/api';
import { getUser, getBlock } from '../../services/auth';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import terms from '../../assets/doc/clinicalcred_termos_e_condicoes_de_uso.pdf';
import { API_URL } from '../../config/constants';

const user = JSON.parse(getUser())

export default class RegisterDocument extends Component {

  constructor(props) {
    super(props);

    this.handleTerms = this.handleTerms.bind(this);
    this.dangerAlert = this.dangerAlert.bind(this);
    this.successAlert = this.successAlert.bind(this);
    this.onChangeInputFile1 = this.onChangeInputFile1.bind(this);
    this.onChangeInputFile2 = this.onChangeInputFile2.bind(this);
    this.onChangeInputFile3 = this.onChangeInputFile3.bind(this);
    this.onChangeInputFile4 = this.onChangeInputFile4.bind(this);

    this.onSubmitFile1 = this.onSubmitFile1.bind(this);
    this.onSubmitFile2 = this.onSubmitFile2.bind(this);
    this.onSubmitFile3 = this.onSubmitFile3.bind(this);
    this.onSubmitFile4 = this.onSubmitFile4.bind(this);

    this.state = {
      company: null,
      document_1: null,
      document_2: null,
      document_3: null,
      document_4: null,
      document_1Error: null,
      document_2Error: null,
      document_3Error: null,
      document_4Error: null,
      alert: null,
      blocking: true,
    };
  }

  componentDidMount() {
    this.loadCompany()
  }

  async loadCompany(){

    this.setState({ alert: null })
    
    const respUser = await api.get('users/' + user.id)
    const company_id = respUser.data.user[0].company_id

    const respCompany = await api.get(`companies/${company_id}`)
    const company = respCompany.data.company

    if(company.is_document === 1 && company.is_terms === 1){
      window.location.href = "/auth/login";
    }
    
    const documents = company.document

    var document_1 = documents.filter(obj => { return obj.company_document_type_id === 1 })
    var document_2 = documents.filter(obj => { return obj.company_document_type_id === 2 })
    var document_3 = documents.filter(obj => { return obj.company_document_type_id === 3 })
    var document_4 = documents.filter(obj => { return obj.company_document_type_id === 4 })

    document_1 = {
      ...document_1[0],
      file: ''
    };
    document_2 = {
      ...document_2[0],
      file: ''
    };
    document_3 = {
      ...document_3[0],
      file: ''
    };
    document_4 = {
      ...document_4[0],
      file: ''
    };
    
    this.setState({
      company: company,
      blocking: false,
      document_1: document_1,
      document_2: document_2,
      document_3: document_3,
      document_4: document_4
    })
  }

  async handleTerms(){
    this.setState({ blocking: true })
    const { company } = this.state
    const resp = await api.put(`companies/terms/${company.id}`, {
      is_terms: true
    })
    this.loadCompany()
  }

  dangerAlert(title, error) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title={title}
          onConfirm={()=> this.setState({ alert: null })}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
        >
        {
          error.map((e, i) => {
            return (
              <div key={i}>
                <strong>{e.title}</strong>
                <p>{e.message}</p><br/>
              </div>
            )
          })
        }
        </SweetAlert>
      )
    });
  }

  successAlert(title) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title={title}
          onConfirm={()=> this.loadCompany()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
        >
        </SweetAlert>
      )
    });
  }
  onChangeInputFile1 = e => {
    if (e.target.files[0].type === "application/pdf"){
      if (e.target.files[0].size <= 5000000){
        this.setState({
          document_1: { ...this.state.document_1, file: e.target.files[0] },
          document_1Error: ''
        });
      }else{
        this.setState({
          document_1: { ...this.state.document_1, file: null },
          document_1Error: (<small className='text-danger'>Enviar documento no tamanho máximo de 5MB.</small>)
        })
      }
    }else{
      this.setState({
        document_1: { ...this.state.document_1, file: null },
        document_1Error: (<small className='text-danger'>Enviar no formato de PDF.</small>)
      })
    }  
  }
  onChangeInputFile2 = e => {
    if (e.target.files[0].type === "application/pdf"){
      if (e.target.files[0].size <= 5000000){
        this.setState({
          document_2: { ...this.state.document_2, file: e.target.files[0] },
          document_2Error: ''
        });
      }else{
        this.setState({
          document_2: { ...this.state.document_2, file: null },
          document_2Error: (<small className='text-danger'>Enviar documento no tamanho máximo de 5MB.</small>)
        })
      }
    }else{
      this.setState({
        document_2: { ...this.state.document_2, file: null },
        document_2Error: (<small className='text-danger'>Enviar no formato de PDF.</small>)
      })
    }  
  }
  onChangeInputFile3 = e => {
    if (e.target.files[0].type === "application/pdf"){
      if (e.target.files[0].size <= 5000000){
        this.setState({
          document_3: { ...this.state.document_3, file: e.target.files[0] },
          document_3Error: ''
        });
      }else{
        this.setState({
          document_3: { ...this.state.document_3, file: null },
          document_3Error: (<small className='text-danger'>Enviar documento no tamanho máximo de 5MB.</small>)
        })
      }
    }else{
      this.setState({
        document_3: { ...this.state.document_3, file: null },
        document_3Error: (<small className='text-danger'>Enviar no formato de PDF.</small>)
      })
    }  
  }
  onChangeInputFile4 = e => {
    if (e.target.files[0].type === "application/pdf"){
      if (e.target.files[0].size <= 5000000){
        this.setState({
          document_4: { ...this.state.document_4, file: e.target.files[0] },
          document_4Error: ''
        });
      }else{
        this.setState({
          document_4: { ...this.state.document_4, file: null },
          document_4Error: (<small className='text-danger'>Enviar documento no tamanho máximo de 5MB.</small>)
        })
      }
    }else{
      this.setState({
        document_4: { ...this.state.document_4, file: null },
        document_4Error: (<small className='text-danger'>Enviar no formato de PDF.</small>)
      })
    }  
  }

  async onSubmitFile1(e) {
    e.preventDefault();
    
    const { document_1, company } = this.state;

    if(document_1.is_send === 0 && (document_1.file === null || document_1.file === '')) {
      this.setState({ document_1Error: (<small className="text-danger">Campo obrigatório.</small>) }) 
    } else {
      this.setState({ document_1Error: null });
    }

    if (document_1.file !== null && document_1.file !== '') {
      this.setState({ blocking: true })
  
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      var data1 = new FormData();
      data1.append('company_id', company.id);
      data1.append('company_document_type_id', 1);
      data1.append('file', document_1.file);
      const response = await axios.post('https://ws-staging2.clinicalcred.com.br/document_company.php', data1, config)
      
      if(response.data.status === 200){
        this.successAlert(response.data.message)
      }else if(response.data.status === 204){
        this.dangerAlert('Erro ao enviar documentos', [{
          title: 'Contrato Social',
          message: response.data.message
        }])
      }else if(response.data.status === 203){
        this.dangerAlert('Erro ao enviar documentos', [{
          title: 'Contrato Social',
          message: response.data.message
        }])
      }
      this.setState({ blocking: false })
    } else {
      this.setState({
        document_1: { ...this.state.document_1, file: null },
        document_1Error: (<small className='text-danger'>Campo obrigatório.</small>)
      })
    }
  }

  async onSubmitFile2(e) {
    e.preventDefault();

    const { document_2, company } = this.state;

    if(document_2.is_send === 0 && (document_2.file === null || document_2.file === '')) {
      this.setState({ document_2Error: (<small className="text-danger">Campo obrigatório.</small>) }) 
    } else {
      this.setState({ document_2Error: null });
    }

    if (document_2.file !== null && document_2.file !== '') {

      this.setState({ blocking: true })

      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      
      var data2 = new FormData();
      data2.append('company_id', company.id);
      data2.append('company_document_type_id', 2);
      data2.append('file', document_2.file);
      const response = await axios.post('https://ws-staging2.clinicalcred.com.br/document_company.php', data2, config)
      
      if(response.data.status === 200){
        this.successAlert(response.data.message)
      }else if(response.data.status === 204){
        this.dangerAlert('Erro ao enviar documentos', [{
          title: 'RG/CPF',
          message: response.data.message
        }])
      }else if(response.data.status === 203){
        this.dangerAlert('Erro ao enviar documentos', [{
          title: 'RG/CPF',
          message: response.data.message
        }])
      }
      this.setState({ blocking: false })
    } else {
      this.setState({
        document_2: { ...this.state.document_2, file: null },
        document_2Error: (<small className='text-danger'>Campo obrigatório.</small>)
      })
    }

  }

  async onSubmitFile3(e) {
    e.preventDefault();

    const { document_3, company } = this.state;

    if(document_3.is_send === 0 && (document_3.file === null || document_3.file === '')) {
      this.setState({ document_3Error: (<small className="text-danger">Campo obrigatório.</small>) }) 
    } else {
      this.setState({ document_3Error: null });
    }

    if (document_3.file !== null && document_3.file !== '') {

      this.setState({ blocking: true })

      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      
      var data3 = new FormData();
      data3.append('company_id', company.id);
      data3.append('company_document_type_id', 3);
      data3.append('file', document_3.file);
      const response = await axios.post('https://ws-staging2.clinicalcred.com.br/document_company.php', data3, config)
      
      if(response.data.status === 200){
        this.successAlert(response.data.message)
      }else if(response.data.status === 204){
        this.dangerAlert('Erro ao enviar documentos', [{
          title: 'Comprovante de Endereço',
          message: response.data.message
        }])
      }else if(response.data.status === 203){
        this.dangerAlert('Erro ao enviar documentos', [{
          title: 'Comprovante de Endereço',
          message: response.data.message
        }])
      }
      this.setState({ blocking: false })
    } else {
      this.setState({
        document_3: { ...this.state.document_3, file: null },
        document_3Error: (<small className='text-danger'>Campo obrigatório.</small>)
      })
    }
    
  }

  async onSubmitFile4(e) {
    e.preventDefault();
    
    const { document_4, company } = this.state;

    if(document_4.is_send === 0 && (document_4.file === null || document_4.file === '')) {
      this.setState({ document_4Error: (<small className="text-danger">Campo obrigatório.</small>) }) 
    } else {
      this.setState({ document_4Error: null });
    }

    if (document_4.file !== null && document_4.file !== '') {

      this.setState({ blocking: true })

      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      
      var data4 = new FormData();
      data4.append('company_id', company.id);
      data4.append('company_document_type_id', 4);
      data4.append('file', document_4.file);
      const response = await axios.post('https://ws-staging2.clinicalcred.com.br/document_company.php', data4, config)
      
      if(response.data.status === 200){
        this.successAlert(response.data.message)
      }else if(response.data.status === 204){
        this.dangerAlert('Erro ao enviar documentos', [{
          title: 'Comprovante de Domicílio Bancário',
          message: response.data.message
        }])
      }else if(response.data.status === 203){
        this.dangerAlert('Erro ao enviar documentos', [{
          title: 'Comprovante de Domicílio Bancário',
          message: response.data.message
        }])
      }
      this.setState({ blocking: false })
    } else {
      this.setState({
        document_4: { ...this.state.document_4, file: null },
        document_4Error: (<small className='text-danger'>Campo obrigatório.</small>)
      })
    }

  }

  render() {
    const { alert, blocking, company, document_1, document_2, document_3, document_4 } = this.state
    return (
      <div className="main-content">
        {alert}
        <CounterDown val={1800} modal={this.state.modalLock} />
        {
          company ? (
            <Grid fluid>
              <Row>
                <Col lg={10} xs={12}>
                  <BlockUi tag="div" blocking={blocking} message="Carregando, por favor aguarde">
                    <Card
                      content={
                        <div>
                          {
                            company.is_terms === 0 ? (
                              <div>
                                <h3 className="text-center mb-15">Termo de uso</h3>
                                <iframe src={terms} style={{width: '100%', height: '500px'}}></iframe>
                                <div className="mb-15 mt-15 text-center">
                                  <Button className="btn-fill btn-wd btn btn-info" onClick={this.handleTerms}>Aceitar termo de uso</Button>
                                </div>
                              </div>
                            ) : null
                          }
                          {
                            company.is_document === 0 && company.is_terms === 1 ? (
                              <div>
                                <h3 className="text-center mb-15">Documentações necessárias</h3>
                                <p className="text-center mb-25">Após enviar os arquivos, nossa equipe estará analisando e liberando o acesso à plataforma.<br></br>Você receberá um email com a liberação.</p> 
                                <Row>
                                    {
                                      document_1 && document_1.is_approved !== 1 ?
                                      <form onSubmit={this.onSubmitFile1}>
                                        <Col xs={6}>
                                          <FormGroup>
                                            { company.company_type === "PF" ? (
                                              <ControlLabel>Foto do Titular <span className="small">(selfie)</span> { document_1.is_approved === 2 ? (<small className="text-danger">(Documento negado)</small>) : null }</ControlLabel>
                                            ) : (
                                              <ControlLabel>Contrato Social <span className="small">(Última alteração)</span> { document_1.is_approved === 2 ? (<small className="text-danger">(Documento negado)</small>) : null }</ControlLabel>
                                            ) }
                                            { document_1.is_send && document_1.path ? (<a href={`https://ws-staging2.clinicalcred.com.br/document_company/${document_1.path}`} className="mt-5 pull-right small" target="_blank">Visualizar arquivo</a>): null}
                                            <input
                                              type='file'
                                              className={'form-control'}
                                              name="document_1"
                                              onChange={this.onChangeInputFile1}
                                              />
                                              {this.state.document_1Error}
                                            <div className="text-center mt-15">
                                              <Button type="submit" bsStyle="info" fill> Enviar </Button>
                                            </div>
                                          </FormGroup>
                                        </Col>
                                      </form>
                                      : null
                                    }
                                    {
                                      document_2 && document_2.is_approved !== 1 ?
                                        <form onSubmit={this.onSubmitFile2}>
                                          <Col xs={6}>
                                            <FormGroup>
                                              <ControlLabel>RG/CPF: <span className="small">(CNH do sócio administrador)</span> { document_2.is_approved === 2 ? (<small className="text-danger">(Documento negado)</small>) : null }</ControlLabel>
                                              { document_2.is_send && document_2.path ? (<a href={`https://ws-staging2.clinicalcred.com.br/document_company/${document_2.path}`} className="mt-5 pull-right small" target="_blank">Visualizar arquivo</a>): null}
                                              <input
                                                type='file'
                                                className={'form-control'}
                                                name="document_2"
                                                onChange={this.onChangeInputFile2} />
                                              {this.state.document_2Error}
                                              <div className="text-center mt-15">
                                                <Button type="submit" bsStyle="info" fill> Enviar </Button>
                                              </div>
                                            </FormGroup>
                                          </Col>
                                        </form>
                                      : null
                                    }
                                    {
                                      document_3 && document_3.is_approved !== 1 ?
                                        <form onSubmit={this.onSubmitFile3}>
                                          <Col xs={6}>
                                            <FormGroup>
                                              <ControlLabel>Comprovante de Endereço { document_3.is_approved === 2 ? (<small className="text-danger">(Documento negado)</small>) : null }</ControlLabel>
                                              { document_3.is_send && document_3.path ? (<a href={`https://ws-staging2.clinicalcred.com.br/document_company/${document_3.path}`} className="mt-5 pull-right small" target="_blank">Visualizar arquivo</a>): null}
                                              <input
                                                type='file'
                                                className={'form-control'}
                                                name="document_3"
                                                onChange={this.onChangeInputFile3} />
                                              {this.state.document_3Error}
                                              <div className="text-center mt-15">
                                                <Button type="submit" bsStyle="info" fill> Enviar </Button>
                                              </div>
                                            </FormGroup>
                                          </Col>
                                        </form>
                                      : null
                                    }
                                    {
                                      document_4 && document_4.is_approved !== 1 ?
                                        <form onSubmit={this.onSubmitFile4}>
                                          <Col xs={6}>
                                            <FormGroup>
                                              <ControlLabel>Comprovante de Domicílio Bancário { document_4.is_approved === 2 ? (<small className="text-danger">(Documento negado)</small>) : null }</ControlLabel>
                                              { document_4.is_send && document_4.path ? (<a href={`https://ws-staging2.clinicalcred.com.br/document_company/${document_4.path}`} className="mt-5 pull-right small" target="_blank">Visualizar arquivo</a>): null}
                                              <input
                                                type='file'
                                                className={'form-control'}
                                                name="document_4"
                                                onChange={this.onChangeInputFile4} />
                                              {this.state.document_4Error}
                                              <div className="text-center mt-15">
                                                <Button type="submit" bsStyle="info" fill> Enviar </Button>
                                              </div>
                                            </FormGroup>
                                          </Col>
                                        </form>
                                      : null
                                    }
                                </Row>
                              </div>
                            ) : 
                            null
                          }
                        </div>
                      }
                    />
                  </BlockUi>
                </Col>
              </Row>
            </Grid>
          ) : null
        }
      
      </div>
    )
  }
}