import React, { Component } from 'react';
import {
  Grid, Row, Col, Modal, Alert, FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import InputMask from 'react-input-mask';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from "axios";

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import api from '../../services/api';
import { getUser, setUser, getBlock } from '../../services/auth';

const user = JSON.parse(getUser())

export default class ProfileIndex extends Component {
  constructor(props) {
    super(props);
    
    this.handleModalAddressClose = this.handleModalAddressClose.bind(this);
    this.handleModalAddressShow = this.handleModalAddressShow.bind(this);
    this.handleModalPhoneClose = this.handleModalPhoneClose.bind(this);
    this.handleModalPhoneShow = this.handleModalPhoneShow.bind(this);

    this.onChangeFull_name = this.onChangeFull_name.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePhoneDdd = this.onChangePhoneDdd.bind(this);
    this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
    this.onChangeAddressState_id = this.onChangeAddressState_id.bind(this);
    this.onChangeAddressUser_id = this.onChangeAddressUser_id.bind(this);
    this.onChangeAddressZipcode = this.onChangeAddressZipcode.bind(this);
    this.onBlurAddressZipcode = this.onBlurAddressZipcode.bind(this);
    this.onChangeAddressAddress = this.onChangeAddressAddress.bind(this);
    this.onChangeAddressNumber = this.onChangeAddressNumber.bind(this);
    this.onChangeAddressNeighborhood = this.onChangeAddressNeighborhood.bind(this);
    this.onChangeAddressComplement = this.onChangeAddressComplement.bind(this);
    this.onChangeAddressLandmark = this.onChangeAddressLandmark.bind(this);
    this.onChangeAddressCity = this.onChangeAddressCity.bind(this);
    
    this.onSubmitUser = this.onSubmitUser.bind(this);
    this.onSubmitPhone = this.onSubmitPhone.bind(this);
    this.onSubmitAddresses = this.onSubmitAddresses.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      admin: 0,
      company_id: '',
      title: '',
      usertypes: '',
      full_name: '',
      email: '',
      cpf: '',
      cnpj: '',
      addresses: [],
      states: [],
      phones: [],
      companies: [],

      phoneAction: 'create',
      phoneId: '',
      phoneDdd: '',
      phoneNumber: '',

      addressAction: 'create',
      addressId: '',
      addressState_id: '',
      addressUser_id: '',
      addressZipcode: '',
      addressAddress: '',
      addressNumber: '',
      addressNeighborhood: '',
      addressComplement: '',
      addressLandmark: '',
      addressCity: '',

      // Confirm
      alert: null,
      show: false,

      // Modal
      modalAddressVisible: false,
      modalPhoneVisible: false,

      // validation
      error: '',
      errorAddress: '',
      errorPhone: '',

      full_nameError: '',
      emailError: '',
      
      phoneDddError: '',
      phoneNumberError: '',

      addressState_idError: '',
      addressUser_idError: '',
      addressZipcodeError: '',
      addressAddressError: '',
      addressNumberError: '',
      addressNeighborhoodError: '',
      addressCityError: '',

      // Loading
      blocking: true,
      blockingUser: false,
      blockingPhone: false,
      blockingAddress: false,

    }
  }

  componentDidMount() {
    this.loadUser()
    this.loadstates()
    this.setState({ blocking: false })
  }

  async loadUser() {
    const respUser = await api.get('users/' + user.id)

    this.setState({
      usertypes: respUser.data.user[0].usertypes,
      title: respUser.data.user[0].full_name,
      email: respUser.data.user[0].email,
      full_name: respUser.data.user[0].full_name,
      cpf: respUser.data.user[0].cpf,
      cnpj: respUser.data.user[0].cnpj,
      addresses: respUser.data.user[0].addresses,
      phones: respUser.data.user[0].phones
    });

  }

  async loadstates() {
    const resp = await api.get('states/')
    this.setState({ states: resp.data });
  }

  onChangeFull_name(e) {
    this.setState({ full_name: e.target.value.toUpperCase() });
    e.target.value === "" ? this.setState({ full_nameError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ full_nameError: null, error: null });
  }
  onChangeEmail(e) {
    this.setState({ email: e.target.value.toUpperCase() });
    e.target.value === "" ? this.setState({ emailError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ emailError: null, error: null });
  }
  onChangePhoneDdd(e) {
    this.setState({ phoneDdd: e.target.value.toUpperCase() });
    e.target.value === "" ? this.setState({ phoneDddError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ phoneDddError: null, error: null });
  }
  onChangePhoneNumber(e) {
    this.setState({ phoneNumber: e.target.value.toUpperCase() });
    e.target.value === "" ? this.setState({ phoneNumberError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ phoneNumberError: null, error: null });
  }
  onChangeAddressState_id(e) {
    this.setState({ addressState_id: e.target.value.toUpperCase() });
    e.target.value === "" ? this.setState({ addressState_idError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ addressState_idError: null, error: null });
  }
  onChangeAddressUser_id(e) {
    this.setState({ addressUser_id: e.target.value.toUpperCase() });
    e.target.value === "" ? this.setState({ addressUser_idError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ addressUser_idError: null, error: null });
  }
  onChangeAddressZipcode(e) {
    this.setState({ addressZipcode: e.target.value.toUpperCase() });
    e.target.value === "" ? this.setState({ addressZipcodeError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ addressZipcodeError: null, error: null });
  }
  async onBlurAddressZipcode(e) {
    this.setState({ blockingAddress: true })
    var cep = e.target.value.replace('-', '')
    cep = parseInt(cep, 10)
    if (cep.toString().length === 8) {
      var respAddress = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      if (respAddress.data.erro) {
        this.setState({
          addressAddress: '',
          addressNeighborhood: '',
          addressCity: '',
          addressState_id: '',
          blockingAddress: false
        })
      } else {
        var respState = await api.get(`states/${respAddress.data.uf}`)
        this.setState({
          addressAddress: respAddress.data.logradouro.toUpperCase(),
          addressNeighborhood: respAddress.data.bairro.toUpperCase(),
          addressCity: respAddress.data.localidade.toUpperCase(),
          addressState_id: respState.data.state[0].id,
          addressAddressError: null,
          addressNeighborhoodError: null,
          addressCityError: null,
          addressState_idError: null,
          blockingAddress: false
        })
      }
    }
  }
  onChangeAddressAddress(e) {
    this.setState({ addressAddress: e.target.value.toUpperCase() });
    e.target.value === "" ? this.setState({ addressAddressError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ addressAddressError: null, error: null });
  }
  onChangeAddressNumber(e) {
    this.setState({ addressNumber: e.target.value.toUpperCase() });
    e.target.value === "" ? this.setState({ addressNumberError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ addressNumberError: null, error: null });
  }
  onChangeAddressNeighborhood(e) {
    this.setState({ addressNeighborhood: e.target.value.toUpperCase() });
    e.target.value === "" ? this.setState({ addressNeighborhoodError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ addressNeighborhoodError: null, error: null });
  }
  onChangeAddressComplement(e) {
    this.setState({ addressComplement: e.target.value.toUpperCase() });
  }
  onChangeAddressLandmark(e) {
    this.setState({ addressLandmark: e.target.value.toUpperCase() });
  }
  onChangeAddressCity(e) {
    this.setState({ addressCity: e.target.value.toUpperCase() });
    e.target.value === "" ? this.setState({ addressCityError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ addressCityError: null, error: null });
  }

  async onSubmitUser(e) {
    e.preventDefault();

    this.state.full_name === "" ? this.setState({ full_nameError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ full_nameError: null });
    this.state.email === "" ? this.setState({ emailError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ emailError: null });

    if (
      this.state.full_name !== "" &&
      this.state.email !== ""
    ) {

      const obj = {
        full_name: this.state.full_name,
        email: this.state.email
      };

      const resp = await api.put('users_profile/' + user.id, obj)

      if (resp.status === 200) {

        const user = await api.get("/users/me")

        setUser(JSON.stringify(user.data.user))

        this.props.handleClick('tr', 'success', 'Atualizado com sucesso!')

      } else {
        this.setState({
          error: resp.data.message
        })
      }
    }
  }

  async onSubmitAddresses(e) {
    e.preventDefault();

    this.state.addressState_id === "" ? this.setState({ addressState_idError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressState_idError: null });
    this.state.addressZipcode === "" ? this.setState({ addressZipcodeError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressZipcodeError: null });
    this.state.addressAddress === "" ? this.setState({ addressAddressError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressAddressError: null });
    this.state.addressNumber === "" ? this.setState({ addressNumberError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressNumberError: null });
    this.state.addressNeighborhood === "" ? this.setState({ addressNeighborhoodError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressNeighborhoodError: null });
    this.state.addressCity === "" ? this.setState({ addressCityError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressCityError: null });

    if (
      this.state.addressState_id !== "" &&
      this.state.addressZipcode !== "" &&
      this.state.addressAddress !== "" &&
      this.state.addressNumber !== "" &&
      this.state.addressNeighborhood !== "" &&
      this.state.addressCity !== ""
    ) {

      this.setState({ blockingAddress: true })

      var zipcode = this.state.addressZipcode
      zipcode = parseInt(zipcode.replace('-', ''), 10)

      if (this.state.addressAction === "create") {

        const obj = {
          user_id: user.id,
          state_id: this.state.addressState_id,
          zipcode: zipcode,
          address: this.state.addressAddress,
          number: this.state.addressNumber,
          neighborhood: this.state.addressNeighborhood,
          complement: this.state.addressComplement,
          landmark: this.state.addressLandmark,
          city: this.state.addressCity
        };

        const resp = await api.post('address/', obj)

        if (resp.status === 200) {

          this.loadUser()
          this.setState({ modalAddressVisible: false, blockingAddress: false });

          this.props.handleClick('tr', 'success', 'Cadastrado com sucesso!')

        } else {
          this.setState({
            errorAddress: resp.data.message,
            blockingAddress: false
          })
        }

      } else if (this.state.addressAction === "edit") {

        const obj = {
          user_id: user.id,
          state_id: this.state.addressState_id,
          zipcode: zipcode,
          address: this.state.addressAddress,
          number: this.state.addressNumber,
          neighborhood: this.state.addressNeighborhood,
          complement: this.state.addressComplement,
          landmark: this.state.addressLandmark,
          city: this.state.addressCity
        };

        const resp = await api.put(`address/${this.state.addressId}`, obj)

        if (resp.status === 200) {

          this.loadUser()
          this.setState({ modalAddressVisible: false, blockingAddress: false });

          this.props.handleClick('tr', 'success', 'Atualizado com sucesso!')

        } else {
          this.setState({
            errorAddress: resp.data.message,
            blockingAddress: false
          })
        }
      }

    }
  }

  async onSubmitPhone(e) {
    e.preventDefault();

    this.state.phoneDdd === "" ? this.setState({ phoneDddError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ phoneDddError: null });
    this.state.phoneNumber === "" ? this.setState({ phoneNumberError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ phoneNumberError: null });

    if (
      this.state.phoneDdd !== "" &&
      this.state.phoneNumber !== ""
    ) {

      this.setState({ blockingPhone: true })

      if (this.state.phoneAction === "create") {

        const obj = {
          user_id: user.id,
          ddd: this.state.phoneDdd,
          number: this.state.phoneNumber
        };
        const resp = await api.post('phone/', obj)

        if (resp.status === 200) {

          this.loadUser()
          this.setState({ modalPhoneVisible: false, blockingPhone: false });

          this.props.handleClick('tr', 'success', 'Cadastrado com sucesso!')

        } else {
          this.setState({
            errorPhone: resp.data.message,
            blockingPhone: false
          })
        }

      } else if (this.state.phoneAction === "edit") {
        const obj = {
          ddd: this.state.phoneDdd,
          number: this.state.phoneNumber
        };
        const resp = await api.put(`phones/${this.state.phoneId}`, obj)
        if (resp.status === 200) {

          this.loadUser()
          this.setState({ modalPhoneVisible: false, blockingPhone: false });

          this.props.handleClick('tr', 'success', 'Atualizado com sucesso!')

        } else {
          this.setState({
            errorPhone: resp.data.message,
            blockingPhone: false
          })
        }
      }

    }
  }

  // Address
  handleModalAddressClose() {
    this.setState({ modalAddressVisible: false });
  }
  handleModalAddressShow() {
    this.setState({
      addressAction: 'create',
      addressId: null,
      addressState_id: '',
      addressUser_id: '',
      addressZipcode: '',
      addressAddress: '',
      addressNumber: '',
      addressNeighborhood: '',
      addressComplement: '',
      addressLandmark: '',
      addressCity: '',
      addressState_idError: '',
      addressUser_idError: '',
      addressZipcodeError: '',
      addressAddressError: '',
      addressNumberError: '',
      addressNeighborhoodError: '',
      addressCityError: '',
      modalAddressVisible: true
    });
  }
  async editAddressModal(id) {
    const resp = await api.get('address/' + id)
    this.setState({
      addressAction: 'edit',
      addressId: resp.data.address.id,
      addressState_id: resp.data.address.state_id,
      addressUser_id: resp.data.address.user_id,
      addressZipcode: resp.data.address.zipcode,
      addressAddress: resp.data.address.address,
      addressNumber: resp.data.address.number,
      addressNeighborhood: resp.data.address.neighborhood,
      addressComplement: resp.data.address.complement,
      addressLandmark: resp.data.address.landmark,
      addressCity: resp.data.address.city,
      addressState_idError: '',
      addressUser_idError: '',
      addressZipcodeError: '',
      addressAddressError: '',
      addressNumberError: '',
      addressNeighborhoodError: '',
      addressCityError: '',
      modalAddressVisible: true
    })
  }
  removeAddressConfirmMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.successRemoveAddress(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim, exclua!"
          cancelBtnText="Cancelar"
          showCancel
        >
        </SweetAlert>
      )
    });
  }
  async successRemoveAddress(id) {
    const resp = await api.delete('address/' + id)

    if (resp.status === 200) {
      this.loadUser()
      this.hideAlert()
      this.props.handleClick('tr', 'success', 'Excluído com sucesso!')
    }
  }

  // Phone
  handleModalPhoneClose() {
    this.setState({ modalPhoneVisible: false });
  }
  handleModalPhoneShow() {
    this.setState({
      phoneAction: 'create',
      phoneId: null,
      phoneDdd: '',
      phoneNumber: '',
      phoneDddError: '',
      phoneNumberError: '',
      modalPhoneVisible: true
    })
  }
  async editPhoneModal(id) {
    const resp = await api.get('phones/' + id)
    this.setState({
      phoneAction: 'edit',
      phoneId: resp.data.phone.id,
      phoneDdd: resp.data.phone.ddd,
      phoneNumber: resp.data.phone.number,
      phoneDddError: '',
      phoneNumberError: '',
      modalPhoneVisible: true
    })
  }
  removePhoneConfirmMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.successRemovePhone(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim, exclua!"
          cancelBtnText="Cancelar"
          showCancel
        >
        </SweetAlert>
      )
    });
  }
  async successRemovePhone(id) {
    const resp = await api.delete('phones/' + id)
    if (resp.status === 200) {
      this.loadUser()
      this.hideAlert()
      this.props.handleClick('tr', 'success', 'Excluído com sucesso!')
    }
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  renderAddresses() {
    return (
      <Modal show={this.state.modalAddressVisible} onHide={this.handleModalAddressClose}>
        <BlockUi tag="div" blocking={this.state.blockingAddress} className="card" style={{ margin: '0px' }} message="Carregando, por favor aguarde">
          <Modal.Header closeButton>
            <Modal.Title>Endereço</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.errorAddress &&
              <Alert bsStyle="danger">
                {
                  this.state.errorAddress.map((e, i) => {
                    return (
                      <li key={i}>{e.message}</li>
                    )
                  })
                }
              </Alert>
            }
            <Row style={{ padding: '0px' }}>
              <Col xs={3}>
                <FormGroup>
                  <ControlLabel>CEP:</ControlLabel>
                  <InputMask
                    mask="99999-999"
                    maskChar='_'
                    className={this.state.addressZipcodeError ? 'error form-control' : 'form-control'}
                    value={this.state.addressZipcode ? this.state.addressZipcode : ''}
                    onChange={this.onChangeAddressZipcode}
                    onBlur={this.onBlurAddressZipcode}
                  />
                  {this.state.addressZipcodeError}
                </FormGroup>
              </Col>
              <Col xs={9}>
                <FormGroup>
                  <ControlLabel>Endereço:</ControlLabel>
                  <FormControl
                    type="text"
                    className={this.state.addressAddressError ? 'error' : ''}
                    value={this.state.addressAddress ? this.state.addressAddress : ''}
                    onChange={this.onChangeAddressAddress}
                  />
                  {this.state.addressAddressError}
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ padding: '0px' }}>
              <Col xs={6}>
                <FormGroup>
                  <ControlLabel>Bairro:</ControlLabel>
                  <FormControl
                    type="text"
                    className={this.state.addressNeighborhoodError ? 'error' : ''}
                    value={this.state.addressNeighborhood ? this.state.addressNeighborhood : ''}
                    onChange={this.onChangeAddressNeighborhood}
                  />
                  {this.state.addressNeighborhoodError}
                </FormGroup></Col>
              <Col xs={3}>
                <FormGroup>
                  <ControlLabel>Número:</ControlLabel>
                  <FormControl
                    type="text"
                    className={this.state.addressNumberError ? 'error' : ''}
                    value={this.state.addressNumber ? this.state.addressNumber : ''}
                    onChange={this.onChangeAddressNumber}
                  />
                  {this.state.addressNumberError}
                </FormGroup>
              </Col>
              <Col xs={3}>
                <FormGroup>
                  <ControlLabel>Complemento:</ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.addressComplement ? this.state.addressComplement : ''}
                    onChange={this.onChangeAddressComplement}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ padding: '0px' }}>
              <Col xs={6}>
                <FormGroup>
                  <ControlLabel>Cidade:</ControlLabel>
                  <FormControl
                    type="text"
                    className={this.state.addressCityError ? 'error' : ''}
                    value={this.state.addressCity ? this.state.addressCity : ''}
                    onChange={this.onChangeAddressCity}
                  />
                  {this.state.addressCityError}
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <ControlLabel>Estado:</ControlLabel>
                  <select
                    className={this.state.addressState_idError ? 'form-control error' : 'form-control'}
                    value={this.state.addressState_id ? this.state.addressState_id : ''}
                    onChange={this.onChangeAddressState_id}>
                    <option value="">Selecione</option>
                    {
                      this.state.states.map(element => {
                        return (
                          <option key={element.id} value={element.id}>{element.name}</option>
                        )
                      })
                    }
                  </select>
                  {this.state.addressState_idError}
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ padding: '0px' }}>
              <Col xs={12}>
                <FormGroup>
                  <ControlLabel>Ponto de referência:</ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.addressLandmark ? this.state.addressLandmark : ''}
                    onChange={this.onChangeAddressLandmark}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleModalAddressClose} className="btn-fill btn btn-neutral btn-back">
              Cancelar
            </Button>
            <Button onClick={this.onSubmitAddresses} bsStyle="info" fill>
              Salvar
            </Button>
          </Modal.Footer>
        </BlockUi>
      </Modal>
    )
  }

  renderPhone() {
    return (
      <Modal show={this.state.modalPhoneVisible} onHide={this.handleModalPhoneClose}>
        <BlockUi tag="div" blocking={this.state.blockingPhone} className="card" style={{ margin: '0px' }} message="Carregando, por favor aguarde">
          <Modal.Header closeButton>
            <Modal.Title>Telefone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.errorPhone &&
              <Alert bsStyle="danger">
                {
                  this.state.errorPhone.map((e, i) => {
                    return (
                      <li key={i}>{e.message}</li>
                    )
                  })
                }
              </Alert>
            }
            <Row style={{ padding: '0px' }}>
              <Col xs={3}>
                <FormGroup>
                  <ControlLabel>DDD:</ControlLabel>
                  <InputMask
                    mask="99"
                    maskChar=''
                    className={this.state.phoneDddError ? 'error form-control' : 'form-control'}
                    value={this.state.phoneDdd ? this.state.phoneDdd : ''}
                    onChange={this.onChangePhoneDdd}
                  />
                  {this.state.phoneDddError}
                </FormGroup>
              </Col>
              <Col xs={9}>
                <FormGroup>
                  <ControlLabel>Telefone:</ControlLabel>
                  <InputMask
                    mask="99999-9999"
                    maskChar='_'
                    className={this.state.phoneNumberError ? 'error form-control' : 'form-control'}
                    value={this.state.phoneNumber ? this.state.phoneNumber : ''}
                    onChange={this.onChangePhoneNumber}
                  />
                  {this.state.phoneNumberError}
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleModalPhoneClose} className="btn-fill btn btn-neutral btn-back">
              Cancelar
            </Button>
            <Button onClick={this.onSubmitPhone} bsStyle="info" fill>
              Salvar
            </Button>
          </Modal.Footer>
        </BlockUi>
      </Modal>
    )
  }

  render() {
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={this.state.modalLock} />
        {this.state.alert}
        {this.renderAddresses()}
        {this.renderPhone()}
        <Grid fluid>
          <Row>
            <Col md={6}>
              <Breadcrumb active="Meus dados" />
              <BlockUi tag="div" blocking={this.state.blockingUser} message="Carregando, por favor aguarde">
                <form onSubmit={this.onSubmitUser}>
                  <Card
                    content={
                      <div>
                        {this.state.error &&
                          <Alert bsStyle="danger">
                            {
                              this.state.error.map((e, i) => {
                                return (
                                  <li key={i}>{e.message}</li>
                                )
                              })
                            }
                          </Alert>
                        }
                        <h4 style={{ marginTop: '0px' }}>Dados Pessoais</h4>
                        <FormGroup>
                          <ControlLabel>
                            Nome completo:
                          </ControlLabel>
                          <FormControl
                            type="text"
                            className={this.state.full_nameError ? 'error' : ''}
                            value={this.state.full_name ? this.state.full_name : ''}
                            onChange={this.onChangeFull_name}
                          />
                          {this.state.full_nameError}
                        </FormGroup>

                        <FormGroup>
                          <ControlLabel>
                            E-mail:
                          </ControlLabel>
                          <FormControl
                            type="text"
                            className={this.state.emailError ? 'error' : ''}
                            value={this.state.email ? this.state.email : ''}
                            onChange={this.onChangeEmail}
                          />
                          {this.state.emailError}
                        </FormGroup>
                        {this.state.usertypes === 'PF' &&
                          <FormGroup>
                            <ControlLabel>
                              CPF:
                            </ControlLabel>
                            <InputMask
                              mask="999.999.999-99"
                              maskChar='_'
                              className={this.state.cpfError ? 'error form-control' : 'form-control'}
                              value={this.state.cpf ? this.state.cpf : ''}
                              disabled
                            />
                          </FormGroup>
                        }
                        {this.state.usertypes === 'PJ' &&
                          <FormGroup>
                            <ControlLabel>
                              CNPJ:
                            </ControlLabel>
                            <InputMask
                              mask="99.999.999/9999-99"
                              maskChar='_'
                              className={this.state.cnpjError ? 'error form-control' : 'form-control'}
                              value={this.state.cnpj ? this.state.cnpj : ''}
                            />
                            {this.state.cnpjError}
                          </FormGroup>
                        }

                        <div className="box-address">
                          <header>
                            <h4>Endereços</h4>
                            <Button onClick={this.handleModalAddressShow}>
                              Adicionar
                            </Button>
                          </header>
                          <section>
                            {
                              this.state.addresses.map(element => {
                                return (
                                  <article key={element.id}>
                                    <p>
                                      {element.address}, {element.number} {element.complement} - {element.neighborhood}, {element.city} - {element.states.initials}, {element.zipcode}
                                      <small>{element.landmark}</small>
                                    </p>
                                    <div className="buttons">
                                      <button type="button" onClick={this.editAddressModal.bind(this, element.id)} className="btn btn-primary btn-round btn-xs">
                                        <i className="fa fa-edit"></i> Editar
                                      </button>
                                      <button type="button" onClick={this.removeAddressConfirmMessage.bind(this, element.id)} className="btn btn-primary btn-round btn-xs">
                                        <i className="fa fa-trash"></i> Excluir
                                      </button>
                                    </div>
                                  </article>
                                )
                              })
                            }
                          </section>
                        </div>

                        <div className="box-phone">
                          <header>
                            <h4>Telefones</h4>
                            <Button onClick={this.handleModalPhoneShow}>
                              Adicionar
                            </Button>
                          </header>
                          <section>
                            {
                              this.state.phones.map(element => {
                                return (
                                  <article key={element.id}>
                                    <p>({element.ddd}) {element.number}</p>
                                    <div className="buttons">
                                      <button type="button" onClick={this.editPhoneModal.bind(this, element.id)} className="btn btn-primary btn-round btn-xs">
                                        <i className="fa fa-edit"></i> Editar
                                      </button>
                                      <button type="button" onClick={this.removePhoneConfirmMessage.bind(this, element.id)} className="btn btn-primary btn-round btn-xs">
                                        <i className="fa fa-trash"></i> Excluir
                                      </button>
                                    </div>
                                  </article>
                                )
                              })
                            }
                          </section>
                        </div>
                      </div>
                    }
                    ftTextCenter
                    legend={
                      <div>
                        <Button type="submit" bsStyle="info" fill> Salvar </Button>
                      </div>
                    }
                  />
                </form>
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}