import React, { Component } from 'react';
import {
    Switch,
    Route
} from 'react-router-dom';


import Login from '../../views/Auth/Login.jsx';
import Lock from '../../views/Auth/Lock.jsx';
import ForgotPassword from '../../views/Auth/ForgotPassword.jsx';
import RecoverPassword from '../../views/Auth/RecoverPassword.jsx';
import createPassword from '../../views/Auth/createPassword.jsx';

import bgImage from '../../assets/img/bg-auth.jpg';

class Auth extends Component {
    getPageClass() {
        var pageClass = "";
        switch (this.props.location.pathname) {
            case "/auth/login":
                pageClass = " login-page";
                break;
            case "/auth/lock":
                pageClass = " lock-page";
                break;
            case "/auth/esqueci-senha":
                pageClass = " forgotpassword-page";
                break;
            default:
                pageClass = " recoverpassword-page";
                break;
        }
        return pageClass;
    }
    componentWillMount() {
        if (document.documentElement.className.indexOf('nav-open') !== -1) {
            document.documentElement.classList.toggle('nav-open');
        }
    }
    render() {
        return (
            <div>
                <div className="wrapper wrapper-full-page">
                    <div className={"full-page" + this.getPageClass()} data-color="black" data-image={bgImage}>
                        <div className="content">
                            <Switch>
                                <Route exact path="/auth/login" component={Login} />
                                <Route exact path="/auth/lock" component={Lock} />
                                <Route exact path="/auth/esqueci-senha" component={ForgotPassword} />
                                <Route path="/auth/recuperar-senha/:token/:email" component={RecoverPassword} />
                                <Route path="/auth/criar-senha/:token_active/:email" component={createPassword} />
                            </Switch>
                        </div>
                        <div className="full-page-background" style={{ backgroundImage: "url(" + bgImage + ")" }}></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Auth;
