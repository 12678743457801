import React, { Component } from 'react';
import {
	Route,
	Switch,
	Redirect
} from 'react-router-dom';
// this is used to create scrollbars on windows devices like the ones from apple devices
import 'perfect-scrollbar/dist/css/perfect-scrollbar.min.css';

import NotificationSystem from "react-notification-system";

import Sidebar from '../../components/Sidebar/Sidebar.jsx';
import Header from '../../components/Header/Header.jsx';
import Footer from "../../components/Footer/Footer.jsx";

// dinamically create dashboard routes

import ProfileIndex from '../../views/Pages/Profile.jsx';
import PasswordIndex from '../../views/Pages/Password.jsx';

import UserIndex from '../../views/User/Index.jsx';
import UserCreate from '../../views/User/Create.jsx';
import UserEdit from '../../views/User/Edit.jsx';
import UserPassword from '../../views/User/Password.jsx';

import CompanyIndex from '../../views/Company/Index.jsx';
import CompanyCreate from '../../views/Company/Create.jsx';
import CompanyEdit from '../../views/Company/Edit.jsx';
import CompanyIntegrator from '../../views/Company/Integrator.jsx';
import CompanyDocument from '../../views/Company/Document.jsx';

import FranchiseIndex from '../../views/Franchise/Index.jsx';
import FranchiseCreate from '../../views/Franchise/Create.jsx';
import FranchiseEdit from '../../views/Franchise/Edit.jsx';
import FranchiseDocument from '../../views/Franchise/Document.jsx';
import FranchiseDocumentApproved from '../../views/Franchise/DocumentApproved.jsx';

import RoleIndex from '../../views/Role/Index.jsx';
import RoleCreate from '../../views/Role/Create.jsx';
import RoleEdit from '../../views/Role/Edit.jsx';

import NoticeIndex from '../../views/Notice/Index.jsx';
import NoticeCreate from '../../views/Notice/Create.jsx';
import NoticeEdit from '../../views/Notice/Edit.jsx';

import PermissionIndex from '../../views/Permission/Index.jsx';
import PermissionCreate from '../../views/Permission/Create.jsx';
import PermissionEdit from '../../views/Permission/Edit.jsx';

import FinancialIndex from '../../views/Financial/Index.jsx';
import FinancialCreate from '../../views/Financial/Create.jsx';
import FinancialEdit from '../../views/Financial/Edit.jsx';

import SimulatorCreate from '../../views/Simulator/Create.jsx';

import ContractionIndex from '../../views/Contraction/Index.jsx';
import ContractionView from '../../views/Contraction/View.jsx';
import ContratcionCreate from '../../views/Contraction/Create.jsx';

import CustomerIndex from '../../views/Customer/Index.jsx';
import CustomerEdit from '../../views/Customer/Edit.jsx';

import ReportContractionIndex from '../../views/Report/Contraction';
import RequestIndex from '../../views/Report/Request';

import IntegratorIndex from '../../views/Integrator/Index.jsx';
import IntegratorCreate from '../../views/Integrator/Create.jsx';
import IntegratorEdit from '../../views/Integrator/Edit.jsx';

import RegisterDocument from '../../views/Pages/RegisterDocument.jsx';
import RegisterDocumentFranchise from '../../views/Pages/RegisterDocumentFranchise.jsx';
import Intro from '../../views/Pages/Intro.jsx';
import Page404 from '../../views/Pages/Page404.jsx';
import Page401 from '../../views/Pages/Page401.jsx';

import { isAuthenticated } from '../../services/auth';

// style for notifications
import { style } from '../../variables/Variables.jsx';

class App extends Component {

	constructor(props) {
		super(props);
		this.componentDidMount = this.componentDidMount.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
		this.state = {
			_notificationSystem: null
		};
	}
	handleNotificationClick(position, type, message) {
		this.state._notificationSystem.addNotification({
			message: (
				<div>
					{message}
				</div>
			),
			level: type,
			position: position,
			autoDismiss: 5,
		});
	}
	componentDidMount() {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
	}
	componentDidUpdate(e) {
		if (
			window.innerWidth < 993 &&
			e.history.location.pathname !== e.location.pathname &&
			document.documentElement.className.indexOf("nav-open") !== -1
		) {
			document.documentElement.classList.toggle("nav-open");
		}
		if (e.history.action === "PUSH") {
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
			this.refs.mainPanel.scrollTop = 0;
		}
	}

	render() {
		return (
			<div className="wrapper">
				<NotificationSystem ref="notificationSystem" style={style} />
				<Sidebar {...this.props} />
				<div className={"main-panel" + (this.props.location.pathname === "/maps/full-screen-maps" ? " main-panel-maps" : "")} ref="mainPanel">
					<Header {...this.props} />
					{
						isAuthenticated() ? (
							<Switch>
								<Route exact path='/' render={(props) => <Intro {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/meus-dados" render={(props) => <ProfileIndex {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/alterar-senha" render={(props) => <PasswordIndex {...props} handleClick={this.handleNotificationClick} />} />

								<Route exact path='/aprovacao-de-credito' render={(props) => <ContratcionCreate {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/contratacao/:id" render={(props) => <ContractionView {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/contratacao" render={(props) => <ContractionIndex {...props} handleClick={this.handleNotificationClick} />} />
								
								<Route exact path="/simulador" render={(props) => <SimulatorCreate {...props} handleClick={this.handleNotificationClick} />} />

								<Route exact path="/relatorio/contratacoes" render={(props) => <ReportContractionIndex {...props} handleClick={this.handleNotificationClick} />} />								
								<Route exact path="/relatorio/solicitacoes" render={(props) => <RequestIndex {...props} handleClick={this.handleNotificationClick} />} />

								<Route exact path="/cliente" render={(props) => <CustomerIndex {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/cliente/edit/:id" render={(props) => <CustomerEdit {...props} handleClick={this.handleNotificationClick} />} />
								
								<Route exact path="/empresa" render={(props) => <CompanyIndex {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/empresa/create" render={(props) => <CompanyCreate {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/empresa/edit/:id" render={(props) => <CompanyEdit {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/empresa/integrador/:id" render={(props) => <CompanyIntegrator {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/empresa/documento/:id" render={(props) => <CompanyDocument {...props} handleClick={this.handleNotificationClick} />} />
								
								<Route exact path="/credenciador" render={(props) => <FranchiseIndex {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/credenciador/create" render={(props) => <FranchiseCreate {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/credenciador/edit/:id" render={(props) => <FranchiseEdit {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/credenciador/documento/:id" render={(props) => <FranchiseDocument {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/credenciador/documento/analise/:id" render={(props) => <FranchiseDocumentApproved {...props} handleClick={this.handleNotificationClick} />} />

								<Route exact path="/usuario" render={(props) => <UserIndex {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/usuario/create" render={(props) => <UserCreate {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/usuario/edit/:id" render={(props) => <UserEdit {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/usuario/senha/:id" render={(props) => <UserPassword {...props} handleClick={this.handleNotificationClick} />} />

								<Route exact path="/perfil" render={(props) => <RoleIndex {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/perfil/create" render={(props) => <RoleCreate {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/perfil/edit/:id" render={(props) => <RoleEdit {...props} handleClick={this.handleNotificationClick} />} />

								<Route exact path="/aviso" render={(props) => <NoticeIndex {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/aviso/create" render={(props) => <NoticeCreate {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/aviso/edit/:id" render={(props) => <NoticeEdit {...props} handleClick={this.handleNotificationClick} />} />

								<Route exact path="/permissao" render={(props) => <PermissionIndex {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/permissao/create" render={(props) => <PermissionCreate {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/permissao/edit/:id" render={(props) => <PermissionEdit {...props} handleClick={this.handleNotificationClick} />} />

								<Route exact path="/financeira" render={(props) => <FinancialIndex {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/financeira/create" render={(props) => <FinancialCreate {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/financeira/edit/:id" render={(props) => <FinancialEdit {...props} handleClick={this.handleNotificationClick} />} />

								<Route exact path="/integrador" render={(props) => <IntegratorIndex {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/integrador/create" render={(props) => <IntegratorCreate {...props} handleClick={this.handleNotificationClick} />} />
								<Route exact path="/integrador/edit/:id" render={(props) => <IntegratorEdit {...props} handleClick={this.handleNotificationClick} />} />

								<Route exact path="/cadastro-de-documento" render={(props) => <RegisterDocument {...props} />} />
								<Route exact path="/cadastro-de-documento-credenciador" render={(props) => <RegisterDocumentFranchise {...props} />} />

								<Route component={Page404} />
								<Route exact path="/nao-autorizado" render={(props) => <Page401 {...props} />} />

							</Switch>
						) : (
								<Redirect to="/auth/login" />
							)
					}
					<Footer fluid />
				</div>
			</div>
		);
	}
}

export default App;
