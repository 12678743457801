import React, { Component } from 'react';
import { Grid, Row, Col, Alert, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { animateScroll as scroll } from 'react-scroll';

import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';
import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import api from '../../services/api';

export default class PermissionEdit extends Component {
  constructor(props) {
    super(props);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      title: '',
      name: '',
      slug: '',
    
      // validation
      error: '',
      nameError: '',
      slugError: '',
    
      // Loading
      blocking: false,
    }
  }

  componentDidMount() {
    if (check(getPermissions(), 'permission_edit') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadPermission()
  }

  async loadPermission(){
    const resp = await api.get('permissions/' + this.props.match.params.id)
    this.setState({
      title: resp.data.permission.name,
      name: resp.data.permission.name,
      slug: resp.data.permission.slug
    });
  }

  onChangeInput = e => {
    this.setState({ [e.target.name]: e.target.value })
    e.target.value === "" ? this.setState({ [e.target.name + "Error"]: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ [e.target.name + "Error"]: null, error: null });
  }

  async onSubmit(e) {
    e.preventDefault();

    const { name, slug } = this.state

    name === "" ? this.setState({ nameError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ nameError: null });
    slug === "" ? this.setState({ slugError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ slugError: null });
    
    if (name !== "" && slug) {

      this.setState({ blocking: true })

      const obj = {
        name,
        slug
      };

      const resp = await api.put('permissions/' + this.props.match.params.id, obj)

      if (resp.status === 200) {

        this.props.handleClick('tr', 'success', 'Atualizado com sucesso!')

        this.props.history.push("/permissao");

      } else {
        scroll.scrollToTop();
        this.setState({ error: resp.data, blocking: false })
      }

    }else{
      scroll.scrollToTop();
    }
    
  }

  render() {
    var { title, name, slug, nameError, slugError, blocking, modalLock, error } = this.state
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={modalLock} />
        <Grid fluid>
          <Row>
            <Col md={6}>
              <Breadcrumb routes={[{ label: 'Permissões', link: '/permissao' }]} active="Editar Permissão" />
              <BlockUi tag="div" blocking={blocking} message="Carregando, por favor aguarde">
                <form onSubmit={this.onSubmit}>
                  <Card
                    title={`Editar Permissão - ${title}`}
                    content={
                      <div>
                        {error &&
                          <Alert bsStyle="danger">
                            {
                              error.map((e, i) => {
                                return (
                                  <li key={i}>{e.message}</li>
                                )
                              })
                            }
                          </Alert>
                        }
                        <FormGroup>
                          <ControlLabel>
                            Nome:
                          </ControlLabel>
                          <FormControl
                            type="text"
                            className={nameError ? 'error' : ''}
                            value={name}
                            name="name"
                            onChange={this.onChangeInput}
                          />
                          {nameError}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            Slug:
                          </ControlLabel>
                          <FormControl
                            type="text"
                            className={slugError ? 'error' : ''}
                            value={slug}
                            name="slug"
                            onChange={this.onChangeInput}
                          />
                          {slugError}
                        </FormGroup>
                      </div>
                    }
                    ftTextCenter
                    legend={
                      <div>
                        <Button type="submit" bsStyle="info" fill> Salvar </Button>
                        <Link to={"/permissao/"} className="btn-fill btn btn-neutral btn-back">Voltar</Link>
                      </div>
                    }
                  />
                </form>
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}