import React, { Component } from 'react';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import SweetAlert from 'react-bootstrap-sweetalert';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Grid, Row, Col, Modal, ControlLabel, Button, FormGroup, FormControl } from 'react-bootstrap';

import { Can, check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';
import Card from '../../components/Card/Card.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

export default class IntegratorIndex extends Component {

  constructor(props) {
    super(props);

    this.handleModalUserClose = this.handleModalUserClose.bind(this);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.successDelete = this.successDelete.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      // Confirm
      alert: null,
      show: false,

      // Modal
      modalVisible: false,
      integrator: [],

      // Table
      integrators: [],
      search: '',
      activePage: 10,
      total: '',
      perPage: '',
      lastPage: '',
      page: 1,

      blocking: true
    };
  }

  async handlePageChange(pageNumber) {
    this.setState({ blocking: true})
    const response = await api.get('integrators?page=' + pageNumber)
    this.setState({
      integrators: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: pageNumber,
      lastPage: response.data.lastPage,
      blocking: false
    });
  }

  componentDidMount() {
    if (check(getPermissions(), 'integrator') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadIntegrator()
  }

  async loadIntegrator() {
    this.setState({ blocking: true })
    const response = await api.get('integrators')
    this.setState({
      integrators: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: response.data.page,
      lastPage: response.data.lastPage,
      blocking: false
    });
  }

  deleteConfirmMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.successDelete(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim, exclua!"
          cancelBtnText="Cancelar"
          showCancel
        >
        </SweetAlert>
      )
    });
  }

  handleModalUserClose() {
    this.setState({ modalVisible: false, integrator: [] });
  }

  async handleModalUserShow(id) {
    const resp = await api.get('integrators/' + id)
    this.setState({
      integrator: resp.data.integrator,
      modalVisible: true
    });
  }

  renderView() {
    return (
      <Modal show={this.state.modalVisible} onHide={this.handleModalUserClose}>
        <div className="card" style={{ margin: '0px' }}>
          <Modal.Header closeButton>
            <Modal.Title>Integrador</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ padding: '0px' }}>
              <Col xs={12}>
                <FormGroup>
                  <ControlLabel>Nome:</ControlLabel>
                  <p>{this.state.integrator.name ? this.state.integrator.name : ''}</p>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Email:</ControlLabel>
                  <p>{this.state.integrator.email ? this.state.integrator.email : ''}</p>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>IP:</ControlLabel>
                  <p>{this.state.integrator.ip ? this.state.integrator.ip : ''}</p>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Token (Produção):</ControlLabel>
                  <p style={{ wordWrap: 'break-word'}}>{this.state.integrator.token ? this.state.integrator.token : ''}</p>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Token (Desenvolvimento):</ControlLabel>
                  <p style={{ wordWrap: 'break-word'}}>{this.state.integrator.sandbox ? this.state.integrator.sandbox : ''}</p>
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
    )
  }

  tokenConfirmMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.successToken(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim!"
          cancelBtnText="Cancelar"
          showCancel
        >
        </SweetAlert>
      )
    });
  }

  async successToken(id) {
    const resp = await api.post('integrators/token/' + id)
    if (resp.status === 200) {
      this.hideAlert()
      this.props.handleClick('tr', 'success', 'Token gerado com sucesso!')
    }
  }
  
  async successDelete(id) {
    const resp = await api.delete('integrators/' + id)
    if (resp.status === 200) {
      this.loadIntegrator()
      this.hideAlert()
      this.props.handleClick('tr', 'success', 'Excluído com sucesso!')
    }
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  async onChangeSearch(e) {
    var search = e.target.value
    this.setState({ search: search });
    const response = await api.get('integrators?search=' + search)
    this.setState({
      integrators: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: response.data.page,
      lastPage: response.data.lastPage
    });
  }

  renderData() {
    const integrators = this.state.integrators
    var row = [];
    if (integrators.length > 0){
      for (var i = 0; i < integrators.length; i++) {
        row.push(
          <tr key={integrators[i].id}>
            <td>
              {integrators[i].name}
            </td>
            <td>
              {integrators[i].email}
            </td>
            <td>
              {integrators[i].ip}
            </td>
            <td className="td-actions">
              <button onClick={this.handleModalUserShow.bind(this, integrators[i].id)} className="btn btn-primary btn-round btn-xs">
                <i className="far fa-eye"></i> Visualizar
              </button>
              <Can
                perform="integrator_token"
                data={{
                  id: integrators[i].id
                }}
                yes={(data) => (
                  <button onClick={this.tokenConfirmMessage.bind(this, data.id)} className="btn btn-primary btn-round btn-xs">
                    <i className="fa fa-key"></i> Gerar Token
                  </button>
                )} />
              <Can
                perform="integrator_edit"
                data={{
                  id: integrators[i].id
                }}
                yes={(data) => (
                  <Link to={"/integrador/edit/" + data.id} className="btn btn-primary btn-round btn-xs">
                    <i className="fa fa-edit"></i> Editar
                </Link>
                )} />
              <Can
                perform="integrator_delete"
                data={{
                  id: integrators[i].id
                }}
                yes={(data) => (
                  <button onClick={this.deleteConfirmMessage.bind(this, data.id)} className="btn btn-primary btn-round btn-xs">
                    <i className="fa fa-trash"></i> Excluir
                  </button>
                )} />
            </td>
          </tr>
        );
      }
    }else{
      row.push(
        <tr key="1">
          <td colSpan={4} className="text-center">
            Nenhum integrador encontrado
          </td>
        </tr>
      )
    }
    return (
      <div className="table-responsive">
        {this.state.alert}
        <table className="table table-list table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>IP</th>
              <th style={{ width: "375px" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {row}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={this.state.modalLock} />
        {this.renderView()}
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Breadcrumb active="Integradores" />
              <BlockUi tag="div" blocking={this.state.blocking}>
                <Card
                  title="Integradores"
                  content={
                    <div>
                      <div className="btn-create">
                        <Can
                          perform="integrator_add"
                          yes={() => (
                            <Link to={'/integrador/create'} className="btn-fill btn btn-info pull-right">Novo Integrador </Link>
                          )} />
                        <FormGroup className="pull-right btn-search">
                          <i className="fa fa-search"></i>
                          <FormControl
                            type="text"
                            placeholder="Pesquisar..."
                            value={this.state.search}
                            onChange={this.onChangeSearch}
                          />
                          {this.state.titleError}
                        </FormGroup>
                      </div>
                      {this.renderData()}
                      <Pagination
                        activePage={this.state.page}
                        itemsCountPerPage={this.state.perPage}
                        totalItemsCount={this.state.total}
                        pageRangeDisplayed={3}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  }
                />
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}