import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Modal,
  Alert,
  FormControl,
  ControlLabel,
  FormGroup,
} from "react-bootstrap";
import NumberFormat from "react-currency-format";
import SweetAlert from "react-bootstrap-sweetalert";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import moment from "moment";
import InputMask from "react-input-mask";
import QRCode from "qrcode.react";
import cpf_cnpj from "cpf_cnpj";
import axios from "axios";
import { animateScroll as scroll } from "react-scroll";

import { check } from "../../services/Can";
import { getPermissions, getBlock } from "../../services/auth";
import { IMAGEM_URL } from "../../config/constants";

import Card from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import CounterDown from "../../components/CounterDown/CounterDown.jsx";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import FormAddContraction from "../Contraction/components/FormAddContraction";

import api from "../../services/api";

import thumbnailImage from "../../assets/img/thumbnail.jpg";

export default class ContractionView extends Component {
  constructor(props) {
    super(props);

    this.onSubmitCustomer = this.onSubmitCustomer.bind(this);
    this.onSubmitInstallment = this.onSubmitInstallment.bind(this);
    this.onSubmitAuthorizer = this.onSubmitAuthorizer.bind(this);

    this.onChangeInputCustomer = this.onChangeInputCustomer.bind(this);
    this.onChangeInputCustomerAddress = this.onChangeInputCustomerAddress.bind(
      this
    );
    this.onChangeInputInstallment = this.onChangeInputInstallment.bind(this);
    this.alertMessage = this.alertMessage.bind(this);
    this.modalDocumentActive = this.modalDocumentActive.bind(this);

    this.finishContract = this.finishContract.bind(this);
    this.fakeDocument = this.fakeDocument.bind(this);
    this.confirmMessageFinish = this.confirmMessageFinish.bind(this);
    this.loadContractionCustomer = this.loadContractionCustomer.bind(this);

    this.handleModalCloseContraction = this.handleModalCloseContraction.bind(
      this
    );
    this.handleModalCloseInstallment = this.handleModalCloseInstallment.bind(
      this
    );
    this.handleModalCloseDocument = this.handleModalCloseDocument.bind(this);

    this.onBlurAddressZipcode = this.onBlurAddressZipcode.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      id: this.props.match.params.id,
      isDocument: false,

      _pendency_progress: null,
      _contraction: null,
      _customer: null,
      _documents: null,
      _pendency: null,
      _states: [],
      _installment: null,
      _installments: null,
      _documentImage: null,
      _documentName: null,
      modalVisibleContraction: false,
      modalVisibleInstallment: false,
      modalVisibleDocument: false,

      // Error
      installmentError: null,
      nameError: null,
      name_motherError: null,
      rgError: null,
      date_of_birthError: null,
      phone1_dddError: null,
      phone1_numberError: null,
      phone2_dddError: null,
      phone2_numberError: null,
      emailError: null,
      zipcodeError: null,
      addressError: null,
      neighborhoodError: null,
      numberError: null,
      cityError: null,
      state_idError: null,

      // Loading
      alert: false,
      loading: false,
      blocking: false,
    };
  }

  async componentDidMount() {
    if (check(getPermissions(), "operation") === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadContraction();
    this.loadStates();
  }

  /* ==================================
    Modal/Alert
  ================================== */
  modalDocumentActive(documentName, documentPath) {
    this.setState({
      _documentImage: documentPath,
      _documentName: documentName,
      modalVisibleDocument: true,
    });
  }

  modalDocument() {
    const { _documentImage, _documentName, modalVisibleDocument } = this.state;
    return (
      <Modal show={modalVisibleDocument} onHide={this.handleModalCloseDocument}>
        <div className="card" style={{ margin: "0px" }}>
          <Modal.Header closeButton>
            <Modal.Title>{_documentName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ padding: "0px" }}>
              <Col xs={12}>
                <img
                  src={_documentImage}
                  className="img-responsive"
                  style={{ maxHeight: "500px", margin: "auto" }}
                />
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
    );
  }
  modalInstallment() {
    var { _contraction, modalVisibleInstallment } = this.state,
      vencimento = null,
      arrParcelas = [];
    if (_contraction.pendency.creditall) {
      var creditallAuthorizer = JSON.parse(
        _contraction.pendency.creditall.authorizer
      );
      if (creditallAuthorizer && creditallAuthorizer.Parcelas) {
        var parcelas = creditallAuthorizer.Parcelas.Parcela;
        parcelas.map((element) => {
          vencimento = moment(element.Vencimento);
          arrParcelas.push({
            parcela: element.parcela,
            valor: `R$ ${element.Valor.toFixed(2)}`,
            vencimento: vencimento.format("DD/MM/YYYY"),
          });
        });
      }
    }
    return (
      <Modal
        show={modalVisibleInstallment}
        onHide={this.handleModalCloseInstallment}
      >
        <div className="card" style={{ margin: "0px" }}>
          <Modal.Header closeButton>
            <Modal.Title>Parcelas</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ padding: "0px" }}>
              <Col xs={12}>
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>Parcela</th>
                      <th>Valor</th>
                      <th>Vencimento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arrParcelas
                      ? arrParcelas.map((element) => {
                          return (
                            <tr key={element.parcela}>
                              <td>{element.parcela}</td>
                              <td>{element.valor}</td>
                              <td>{element.vencimento}</td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
    );
  }
  handleModalCloseContraction() {
    this.setState({ modalVisibleContraction: false });
  }
  handleModalCloseInstallment() {
    this.setState({ modalVisibleInstallment: false });
  }
  handleModalCloseDocument() {
    this.setState({ modalVisibleDocument: false });
  }

  /* ==================================
    Check
  ================================== */
  checkCustomer(customer) {
    if (
      customer.customer_address === null ||
      (customer.usertype === "PJ" &&
        (customer.cnpj === null || customer.cnpj === "")) ||
      (customer.usertype === "PF" &&
        (customer.cpf === null || customer.cpf === "")) ||
      customer.date_of_birth === null ||
      customer.date_of_birth === "" ||
      customer.email === null ||
      customer.email === "" ||
      customer.name === null ||
      customer.name === "" ||
      customer.name_mother === null ||
      customer.name_mother === "" ||
      customer.occupation_id === null ||
      customer.occupation_id === "" ||
      customer.phone1_ddd === null ||
      customer.phone1_ddd === "" ||
      customer.phone1_number === null ||
      customer.phone1_number === "" ||
      customer.phone2_ddd === null ||
      customer.phone2_ddd === "" ||
      customer.phone2_number === null ||
      customer.phone2_number === "" ||
      customer.rg === null ||
      customer.rg === "" ||
      customer.usertype === null ||
      customer.usertype === ""
    ) {
      return false;
    } else {
      return true;
    }
  }
  checkDocument(document) {
    if (
      ((document.find((x) => x.document_type_id === 1) &&
        document.find((x) => x.document_type_id === 2)) ||
        (document.find((x) => x.document_type_id === 3) &&
          document.find((x) => x.document_type_id === 4))) &&
      document.find((x) => x.document_type_id === 5)
    ) {
      return true;
    } else {
      return false;
    }
  }
  checkInstallment(pendency) {
    if (pendency.installment !== null && pendency.installment !== "") {
      return true;
    } else {
      return false;
    }
  }
  checkCreditallAuthorizer(pendency) {
    var creditallAuthorizer = pendency.creditall.authorizer;
    if (creditallAuthorizer) {
      return true;
    } else {
      return false;
    }
  }
  /* ==================================
    Load
  ================================== */
  async loadStates() {
    const resp = await api.get("states/");
    this.setState({ _states: resp.data });
  }
  async loadContraction() {
    this.setState({ loading: true });

    const respContraction = await api.get("contractions/" + this.state.id);

    var contraction = respContraction.data.contraction;

    var dataContraction = {
      id: contraction.id,
      requested_amount: contraction.requested_amount,
      approved_amount: 0,
      finish: contraction.finish,
      date: moment(contraction.created_at).format("DD/MM/YYYY HH:mm"),
    };

    contraction.contractions_customers.map(async (element, index) => {
      dataContraction.approved_amount +=
        element.pendency.status_id === 4 || element.pendency.status_id === 5
          ? element.pendency.amount
          : 0;
    });

    this.setState({
      customer: contraction.customer,
      contraction: dataContraction,
      contractions_customers: contraction.contractions_customers,
      loading: false,
    });
  }
  async loadContractionCustomer(id) {
    this.setState({ loading: true, isDocument: false });

    const respContractionCustomer = await api.get(
      `contractions_customer/${id}`
    );

    const contraction = respContractionCustomer.data.contractions_customer,
      contractionCustomer =
        respContractionCustomer.data.contractions_customer.customer,
      contractionPendency =
        respContractionCustomer.data.contractions_customer.pendency,
      contractionPendencyDocument =
        respContractionCustomer.data.contractions_customer.pendency.document;

    contraction.created_at = moment(contraction.created_at).format(
      "DD/MM/YYYY HH:mm:ss"
    );
    contractionCustomer.date_of_birth = contractionCustomer.date_of_birth
      ? moment(contractionCustomer.date_of_birth).format("DD/MM/YYYY")
      : null;

    this.setState({
      _customer: contractionCustomer,
      _pendency: contractionPendency,
      _documents: contractionPendencyDocument,
      _contraction: contraction,
    });

    if (
      contractionPendency.status_id !== 1 &&
      contractionPendency.status_id !== 5
    ) {
      // Verify data in customer
      if (
        !this.checkCustomer(contractionCustomer) ||
        contractionPendency.creditall.register === null
      ) {
        this.setState({
          _pendency_progress: "customer",
          loading: false,
        });
        return false;
      }

      const respStatus = await api.get(
        `creditall/status?cpf_cnpj=${contractionCustomer.cpf}`
      );
      if (respStatus.status === 200) {
        if (respStatus.data.result.status === 2) {
          this.alertMessage(
            "danger",
            "NÃO É POSSÍVEL CONTINUAR. RETORNO DA FINANCEIRA:",
            "CLIENTE BLOQUEADO"
          );
          return false;
        } else if (respStatus.data.result.status === 0) {
          this.setState({
            _pendency_progress: "customer",
            loading: false,
          });
          return false;
        }
      } else {
        this.alertMessage(
          "danger",
          "NÃO É POSSÍVEL CONTINUAR. RETORNO DA FINANCEIRA:",
          "ERRO NA CONSULTA DO CLIENTE, TENTE NOVAMENTE"
        );
        return false;
      }

      if (!contractionPendency.installment) {
        var installments = this.onListInstallment(contractionPendency);
        if (installments) {
          this.setState({
            _pendency_progress: "installment",
            _installments: installments,
            loading: false,
          });
        } else {
          this.alertMessage(
            "danger",
            "NÃO É POSSÍVEL CONTINUAR. RETORNO DA FINANCEIRA:",
            "CLIENTE NÃO POSSUI MAIS SALDO PRA FINALIZAR A CONTRATAÇÃO"
          );
        }
        return false;
      }

      if (!this.checkDocument(contractionPendencyDocument)) {
        this.setState({
          _pendency_progress: "documents",
          loading: false,
          isDocument: true,
        });
        var data = {
          id: id,
          pendency_id: contractionPendency.id,
        };
        this.isDocumentApp(data);
        return false;
      }

      if (!this.checkCreditallAuthorizer(contractionPendency)) {
        this.setState({
          _pendency_progress: "authorizer",
          loading: false,
        });
        return false;
      }
    }

    this.setState({
      _pendency_progress: "detail",
      loading: false,
    });
  }

  /* ==================================
    Submit
  ================================== */
  onChangeInputCustomer = (e) => {
    this.setState({
      _customer: {
        ...this.state._customer,
        [e.target.name]: e.target.value.toUpperCase(),
      },
    });
    e.target.value === ""
      ? this.setState({
          [e.target.name + "Error"]: (
            <small className="text-danger">Campo obrigatório.</small>
          ),
        })
      : this.setState({ [e.target.name + "Error"]: null, error: null });
  };
  onChangeInputCustomerAddress = (e) => {
    this.setState({
      _customer: {
        ...this.state._customer,
        customer_address: {
          ...this.state._customer.customer_address,
          [e.target.name]: e.target.value.toUpperCase(),
        },
      },
    });
    e.target.value === ""
      ? this.setState({
          [e.target.name + "Error"]: (
            <small className="text-danger">Campo obrigatório.</small>
          ),
        })
      : this.setState({ [e.target.name + "Error"]: null, error: null });
  };
  async onBlurAddressZipcode(e) {
    var cep = e.target.value.replace("-", "");
    cep = parseInt(cep);
    if (cep.toString().length === 8) {
      this.setState({ loadingZipcode: true });
      var respAddress = await axios.get(
        `https://viacep.com.br/ws/${cep}/json/`
      );
      if (respAddress.data.erro) {
        this.setState({
          _customer: {
            ...this.state._customer,
            customer_address: {
              ...this.state._customer.customer_address,
              address: "",
              neighborhood: "",
              city: "",
              state_id: "",
            },
          },
          addressError: "",
          neighborhoodError: "",
          cityError: "",
          state_idError: "",
        });
      } else {
        var respState = await api.get(`states/${respAddress.data.uf}`);
        this.setState({
          _customer: {
            ...this.state._customer,
            customer_address: {
              ...this.state._customer.customer_address,
              address: respAddress.data.logradouro,
              neighborhood: respAddress.data.bairro,
              city: respAddress.data.localidade,
              state_id: respState.data.state[0].id,
            },
          },
          addressError: "",
          neighborhoodError: "",
          cityError: "",
          state_idError: "",
        });
      }
    }
  }
  onChangeInputInstallment = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    e.target.value === ""
      ? this.setState({
          [e.target.name + "Error"]: (
            <small className="text-danger">Campo obrigatório.</small>
          ),
        })
      : this.setState({ [e.target.name + "Error"]: null, error: null });
  };
  async onSubmitCustomer(e) {
    e.preventDefault();

    var { _pendency, _customer, _contraction } = this.state;

    _customer.name === "" || _customer.name === null
      ? this.setState({
          nameError: <small className="text-danger">Campo obrigatório</small>,
        })
      : this.setState({ nameError: null });
    _customer.name_mother === "" || _customer.name_mother === null
      ? this.setState({
          name_motherError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ name_motherError: null });
    _customer.rg === "" || _customer.rg === null
      ? this.setState({
          rgError: <small className="text-danger">Campo obrigatório</small>,
        })
      : this.setState({ rgError: null });
    _customer.date_of_birth === "" || _customer.date_of_birth === null
      ? this.setState({
          date_of_birthError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ date_of_birthError: null });
    _customer.phone1_ddd === "" || _customer.phone1_ddd === null
      ? this.setState({
          phone1_dddError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ phone1_dddError: null });
    _customer.phone1_number === "" || _customer.phone1_number === null
      ? this.setState({
          phone1_numberError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ phone1_numberError: null });
    _customer.phone2_ddd === "" || _customer.phone2_ddd === null
      ? this.setState({
          phone2_dddError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ phone2_dddError: null });
    _customer.phone2_number === "" || _customer.phone2_number === null
      ? this.setState({
          phone2_numberError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ phone2_numberError: null });
    _customer.email === "" || _customer.email === null
      ? this.setState({
          emailError: <small className="text-danger">Campo obrigatório</small>,
        })
      : this.setState({ emailError: null });
    if (_customer.customer_address === null) {
      this.setState({
        zipcodeError: <small className="text-danger">Campo obrigatório</small>,
      });
      this.setState({
        addressError: <small className="text-danger">Campo obrigatório</small>,
      });
      this.setState({
        neighborhoodError: (
          <small className="text-danger">Campo obrigatório</small>
        ),
      });
      this.setState({
        numberError: <small className="text-danger">Campo obrigatório</small>,
      });
      this.setState({
        cityError: <small className="text-danger">Campo obrigatório</small>,
      });
      this.setState({
        state_idError: <small className="text-danger">Campo obrigatório</small>,
      });
    } else {
      _customer.customer_address.zipcode === "" ||
      _customer.customer_address.zipcode === undefined
        ? this.setState({
            zipcodeError: (
              <small className="text-danger">Campo obrigatório</small>
            ),
          })
        : this.setState({ zipcodeError: null });
      _customer.customer_address.address === "" ||
      _customer.customer_address.address === undefined
        ? this.setState({
            addressError: (
              <small className="text-danger">Campo obrigatório</small>
            ),
          })
        : this.setState({ addressError: null });
      _customer.customer_address.neighborhood === "" ||
      _customer.customer_address.neighborhood === undefined
        ? this.setState({
            neighborhoodError: (
              <small className="text-danger">Campo obrigatório</small>
            ),
          })
        : this.setState({ neighborhoodError: null });
      _customer.customer_address.number === "" ||
      _customer.customer_address.number === undefined
        ? this.setState({
            numberError: (
              <small className="text-danger">Campo obrigatório</small>
            ),
          })
        : this.setState({ numberError: null });
      _customer.customer_address.city === "" ||
      _customer.customer_address.city === undefined
        ? this.setState({
            cityError: <small className="text-danger">Campo obrigatório</small>,
          })
        : this.setState({ cityError: null });
      _customer.customer_address.state_id === "" ||
      _customer.customer_address.state_id === undefined
        ? this.setState({
            state_idError: (
              <small className="text-danger">Campo obrigatório</small>
            ),
          })
        : this.setState({ state_idError: null });
    }

    if (
      _customer.cpf !== null &&
      _customer.cpf !== "" &&
      _customer.name !== null &&
      _customer.name !== "" &&
      _customer.name_mother !== null &&
      _customer.name_mother !== "" &&
      _customer.rg !== null &&
      _customer.rg !== "" &&
      _customer.date_of_birth !== null &&
      _customer.date_of_birth !== "" &&
      _customer.phone1_ddd !== null &&
      _customer.phone1_ddd !== "" &&
      _customer.phone1_number !== null &&
      _customer.phone1_number !== "" &&
      _customer.phone2_ddd !== null &&
      _customer.phone2_ddd !== "" &&
      _customer.phone2_number !== null &&
      _customer.phone2_number !== "" &&
      _customer.email !== null &&
      _customer.email !== "" &&
      _customer.customer_address &&
      _customer.customer_address.zipcode &&
      (_customer.customer_address.zipcode !== "" ||
        _customer.customer_address.zipcode !== undefined) &&
      _customer.customer_address.address &&
      (_customer.customer_address.address !== "" ||
        _customer.customer_address.address !== undefined) &&
      _customer.customer_address.neighborhood &&
      (_customer.customer_address.neighborhood !== "" ||
        _customer.customer_address.neighborhood !== undefined) &&
      _customer.customer_address.number &&
      (_customer.customer_address.number !== "" ||
        _customer.customer_address.number !== undefined) &&
      _customer.customer_address.city &&
      (_customer.customer_address.city !== "" ||
        _customer.customer_address.city !== undefined) &&
      _customer.customer_address.state_id &&
      (_customer.customer_address.state_id !== "" ||
        _customer.customer_address.state_id !== undefined)
    ) {
      this.setState({ loading: true });

      var date_birth = _customer.date_of_birth.split("/");

      const obj = {
        pendency_id: _pendency.id,
        customer_id: _customer.id,
        usertype: _customer.usertype,
        cpf: _customer.cpf,
        cnpj: _customer.cnpj,
        name: _customer.name,
        name_mother: _customer.name_mother,
        rg: _customer.rg,
        date_of_birth: `${date_birth[2]}-${date_birth[1]}-${date_birth[0]}`,
        phone1_ddd: _customer.phone1_ddd,
        phone1_number: _customer.phone1_number.replace("-", ""),
        phone2_ddd: _customer.phone2_ddd,
        phone2_number: _customer.phone2_number.replace("-", ""),
        email: _customer.email,
        zipcode: _customer.customer_address.zipcode.replace("-", ""),
        address: _customer.customer_address.address,
        neighborhood: _customer.customer_address.neighborhood,
        number: _customer.customer_address.number,
        city: _customer.customer_address.city,
        state_id: _customer.customer_address.state_id,
      };

      const resp = await api.put(`customers_creditall/${_customer.id}`, obj);

      if (resp.status === 200) {
        this.loadContractionCustomer(_contraction.id);
      } else if (resp.status === 203) {
        this.alertMessage("danger", "Error", resp.data.message);
      }

      this.setState({ loading: false });
    }
  }
  async onSubmitInstallment(e) {
    e.preventDefault();

    var { _contraction, _pendency, _installment } = this.state;

    _installment === "" || _installment === null
      ? this.setState({
          installmentError: (
            <small className="text-danger">Campo obrigatório</small>
          ),
        })
      : this.setState({ installmentError: null });

    if (_installment !== null && _installment !== "") {
      this.setState({ loading: true });

      const resp = await api.put(`pendencies/${_pendency.id}`, {
        installment: _installment,
      });

      if (resp.status === 200) {
        this.loadContraction(this.state.id);
        this.loadContractionCustomer(_contraction.id);
      }
    }

    this.setState({ loading: false });
  }
  async onSubmitAuthorizer(e) {
    e.preventDefault();

    this.setState({ loading: true });

    var { _contraction, _pendency } = this.state;

    const respAuthorizer = await api.post("pendencies/authorizer", {
      id: _pendency.contractions_customer_id,
    });

    if (respAuthorizer.status === 200) {
      this.loadContraction(this.state.id);
      this.loadContractionCustomer(_contraction.id);
    }

    this.setState({ loading: false });
  }
  /* ==================================
    Render
  ================================== */
  renderCustomer() {
    var {
      _customer,
      _states,
      nameError,
      name_motherError,
      rgError,
      _contraction,
      date_of_birthError,
      phone1_dddError,
      phone1_numberError,
      phone2_dddError,
      phone2_numberError,
      emailError,
      zipcodeError,
      addressError,
      neighborhoodError,
      numberError,
      cityError,
      state_idError,
      loading,
      blocking,
      error,
      errorRegister,
      _pendency_progress,
      finish,
    } = this.state;
    return (
      <Card
        content={
          <form onSubmit={this.onSubmitCustomer}>
            <BlockUi
              tag="div"
              blocking={blocking}
              message="Carregando, por favor aguarde"
            >
              <Button
                onClick={() =>
                  this.setState({
                    _contraction: null,
                    finish: false,
                    _pendency_progress: null,
                    isDocument: false,
                  })
                }
                className="btn-simple"
                style={{ position: "absolute", top: "15px", right: "15px" }}
              >
                X
              </Button>
              <h4 style={{ marginTop: "0px" }}>Dados Pessoais</h4>
              <p>
                Insira os dados do responsável financeiro desta contratação para
                finalizar
              </p>
              <Row>
                {_customer.usertype === "PJ" ? (
                  <Col xs={6}>
                    <FormGroup>
                      <Row>
                        <Col xs={12}>
                          <ControlLabel>CNPJ:</ControlLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <InputMask
                            mask={"99.999.999/9999-99"}
                            maskChar="_"
                            className={"form-control"}
                            value={_customer.cnpj ? _customer.cnpj : ""}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                ) : null}
                <Col xs={6}>
                  <FormGroup>
                    <Row>
                      <Col xs={12}>
                        <ControlLabel>CPF:</ControlLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <InputMask
                          mask={"999.999.999-99"}
                          maskChar="_"
                          className={"form-control"}
                          value={_customer.cpf ? _customer.cpf : ""}
                          name="cpf"
                          disabled
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Row>
                      <Col xs={12}>
                        <ControlLabel>Nome Completo:</ControlLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormControl
                          type="text"
                          className={
                            nameError ? "error form-control" : "form-control"
                          }
                          value={_customer.name ? _customer.name : ""}
                          name="name"
                          onChange={this.onChangeInputCustomer}
                        />
                      </Col>
                    </Row>
                    {nameError}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Row>
                      <Col xs={12}>
                        <ControlLabel>Nome da mãe:</ControlLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormControl
                          type="text"
                          className={
                            name_motherError
                              ? "error form-control"
                              : "form-control"
                          }
                          value={
                            _customer.name_mother ? _customer.name_mother : ""
                          }
                          name="name_mother"
                          onChange={this.onChangeInputCustomer}
                        />
                      </Col>
                    </Row>
                    {name_motherError}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Row>
                      <Col xs={12}>
                        <ControlLabel>RG:</ControlLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormControl
                          type="text"
                          className={
                            rgError ? "error form-control" : "form-control"
                          }
                          value={_customer.rg ? _customer.rg : ""}
                          name="rg"
                          onChange={this.onChangeInputCustomer}
                        />
                      </Col>
                    </Row>
                    {rgError}
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Row>
                      <Col xs={12}>
                        <ControlLabel>Data de nascimento:</ControlLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <InputMask
                          mask="99/99/9999"
                          maskChar="_"
                          className={
                            date_of_birthError
                              ? "error form-control"
                              : "form-control"
                          }
                          value={
                            _customer.date_of_birth
                              ? _customer.date_of_birth
                              : ""
                          }
                          name="date_of_birth"
                          onChange={this.onChangeInputCustomer}
                        />
                      </Col>
                    </Row>
                    {date_of_birthError}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Row>
                      <Col xs={12}>
                        <ControlLabel>Telefone Celular:</ControlLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={4}>
                        <InputMask
                          mask="99"
                          maskChar=""
                          className={
                            phone1_dddError
                              ? "error form-control"
                              : "form-control"
                          }
                          value={
                            _customer.phone1_ddd ? _customer.phone1_ddd : ""
                          }
                          name="phone1_ddd"
                          onChange={this.onChangeInputCustomer}
                        />
                      </Col>
                      <Col xs={8} className="pl-0">
                        <InputMask
                          mask="99999-9999"
                          maskChar=""
                          className={
                            phone1_numberError
                              ? "error form-control"
                              : "form-control"
                          }
                          value={
                            _customer.phone1_number
                              ? _customer.phone1_number
                              : ""
                          }
                          name="phone1_number"
                          onChange={this.onChangeInputCustomer}
                        />
                      </Col>
                      {phone1_dddError || phone1_numberError
                        ? phone1_dddError
                        : null}
                    </Row>
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Row>
                      <Col xs={12}>
                        <ControlLabel>Telefone Fixo:</ControlLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={4}>
                        <InputMask
                          mask="99"
                          maskChar=""
                          className={
                            phone2_dddError
                              ? "error form-control"
                              : "form-control"
                          }
                          value={
                            _customer.phone2_ddd ? _customer.phone2_ddd : ""
                          }
                          name="phone2_ddd"
                          onChange={this.onChangeInputCustomer}
                        />
                      </Col>
                      <Col xs={8} className="pl-0">
                        <InputMask
                          mask={
                            _customer.phone2_number &&
                            _customer.phone2_number.length <= 8
                              ? "9999-9999?"
                              : "99999-999?"
                          }
                          formatChars={{ 9: "[0-9]", "?": "[0-9 ]" }}
                          maskChar=""
                          className={
                            phone2_numberError
                              ? "error form-control"
                              : "form-control"
                          }
                          value={
                            _customer.phone2_number
                              ? _customer.phone2_number
                              : ""
                          }
                          name="phone2_number"
                          onChange={this.onChangeInputCustomer}
                        />
                      </Col>
                      {phone2_dddError || phone2_numberError
                        ? phone2_dddError
                        : null}
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Row>
                  <Col xs={12}>
                    <ControlLabel>E-mail:</ControlLabel>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <FormControl
                      type="text"
                      className={emailError ? "error" : ""}
                      value={_customer.email ? _customer.email : ""}
                      name="email"
                      onChange={this.onChangeInputCustomer}
                    />
                  </Col>
                </Row>
                {emailError}
              </FormGroup>
              <h4>Endereço</h4>
              <Row>
                <Col xs={6} md={3}>
                  <FormGroup>
                    <ControlLabel>CEP:</ControlLabel>
                    <InputMask
                      mask="99999-999"
                      maskChar="_"
                      className={
                        zipcodeError ? "error form-control" : "form-control"
                      }
                      value={
                        _customer.customer_address &&
                        _customer.customer_address.zipcode
                          ? _customer.customer_address.zipcode
                          : ""
                      }
                      name="zipcode"
                      onChange={this.onChangeInputCustomerAddress}
                      onBlur={this.onBlurAddressZipcode}
                    />
                    {zipcodeError}
                  </FormGroup>
                </Col>
                <Col xs={12} md={9}>
                  <FormGroup>
                    <ControlLabel>Endereço:</ControlLabel>
                    <FormControl
                      type="text"
                      className={addressError ? "error" : ""}
                      value={
                        _customer.customer_address &&
                        _customer.customer_address.address
                          ? _customer.customer_address.address
                          : ""
                      }
                      name="address"
                      onChange={this.onChangeInputCustomerAddress}
                      disabled={_customer.loadingZipcode ? true : false}
                    />
                    {addressError}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <ControlLabel>Bairro:</ControlLabel>
                    <FormControl
                      type="text"
                      className={neighborhoodError ? "error" : ""}
                      value={
                        _customer.customer_address &&
                        _customer.customer_address.neighborhood
                          ? _customer.customer_address.neighborhood
                          : ""
                      }
                      name="neighborhood"
                      onChange={this.onChangeInputCustomerAddress}
                      disabled={_customer.loadingZipcode ? true : false}
                    />
                    {neighborhoodError}
                  </FormGroup>
                </Col>
                <Col xs={6} md={3}>
                  <FormGroup>
                    <ControlLabel>Número:</ControlLabel>
                    <FormControl
                      type="text"
                      className={numberError ? "error" : ""}
                      value={
                        _customer.customer_address &&
                        _customer.customer_address.number
                          ? _customer.customer_address.number
                          : ""
                      }
                      name="number"
                      onChange={this.onChangeInputCustomerAddress}
                    />
                    {numberError}
                  </FormGroup>
                </Col>
                <Col xs={6} md={3}>
                  <FormGroup>
                    <ControlLabel>Complemento:</ControlLabel>
                    <FormControl
                      type="text"
                      value={
                        _customer.customer_address &&
                        _customer.customer_address.complement
                          ? _customer.customer_address.complement
                          : ""
                      }
                      name="complement"
                      onChange={this.onChangeInputCustomerAddress}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <ControlLabel>Cidade:</ControlLabel>
                    <FormControl
                      type="text"
                      className={cityError ? "error" : ""}
                      value={
                        _customer.customer_address &&
                        _customer.customer_address.city
                          ? _customer.customer_address.city
                          : ""
                      }
                      name="city"
                      onChange={this.onChangeInputCustomerAddress}
                      disabled={_customer.loadingZipcode ? true : false}
                    />
                    {cityError}
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <ControlLabel>Estado:</ControlLabel>
                    <select
                      value={
                        _customer.customer_address &&
                        _customer.customer_address.state_id
                          ? _customer.customer_address.state_id
                          : ""
                      }
                      className={
                        state_idError ? "form-control error" : "form-control"
                      }
                      name="state_id"
                      onChange={this.onChangeInputCustomerAddress}
                      disabled={_customer.loadingZipcode ? true : false}
                    >
                      <option value="">Selecione</option>
                      {_states &&
                        _states.map((element) => {
                          return (
                            <option key={element.id} value={element.id}>
                              {element.name}
                            </option>
                          );
                        })}
                    </select>
                    {_customer.state_idError}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="text-center mt-15">
                  <Button type="submit" bsStyle="info" fill>
                    {" "}
                    Salvar{" "}
                  </Button>
                </Col>
              </Row>
            </BlockUi>
          </form>
        }
      />
    );
  }
  renderDocument() {
    const { _pendency } = this.state;
    return (
      <Card
        content={
          <div className="text-center">
            <Button
              onClick={() =>
                this.setState({
                  _contraction: null,
                  finish: false,
                  _pendency_progress: null,
                  isDocument: false,
                })
              }
              className="btn-simple"
              style={{ position: "absolute", top: "15px", right: "15px" }}
            >
              X
            </Button>
            <Alert
              bsStyle="info"
              style={{ display: "inline-block" }}
              className="text-center"
            >
              <span>
                Utilize do ClinicalCred Pay para finalizar esta contratação.
              </span>
            </Alert>
            <p className="text-muted">ClinicalCred Pay</p>
            <QRCode
              value={_pendency.id.toString()}
              size={100}
              renderAs="svg"
              fgColor="#333"
            />
            <br/>
            <br/>
            <Button
              onClick={() => {
                this.fakeDocument();
              }}
            >
              Simular envio de documentos
            </Button>
          </div>
        }
      />
    );
  }
  renderInstallment() {
    var {
      _installments,
      _installment,
      installmentError,
      blocking,
      errorAuthorizer,
    } = this.state;
    return (
      <Card
        content={
          <form onSubmit={this.onSubmitInstallment}>
            <BlockUi
              tag="div"
              blocking={blocking}
              message="Carregando, por favor aguarde"
            >
              <Button
                onClick={() =>
                  this.setState({
                    _contraction: null,
                    finish: false,
                    _pendency_progress: null,
                    isDocument: false,
                  })
                }
                className="btn-simple"
                style={{ position: "absolute", top: "15px", right: "15px" }}
              >
                X
              </Button>
              {errorAuthorizer && (
                <Alert bsStyle="danger" className="text-center">
                  <span>{errorAuthorizer}</span>
                </Alert>
              )}
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <ControlLabel>Parcelas:</ControlLabel>
                    <select
                      value={_installment ? _installment : ""}
                      className={
                        installmentError ? "form-control error" : "form-control"
                      }
                      name="_installment"
                      onChange={this.onChangeInputInstallment}
                    >
                      <option value="">Selecione</option>
                      {_installments &&
                        _installments.map((element) => {
                          return (
                            <option key={element.value} value={element.value}>
                              {element.label}
                            </option>
                          );
                        })}
                    </select>
                  </FormGroup>
                  {installmentError}
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="text-center mt-15">
                  <Button type="submit" bsStyle="info" fill>
                    {" "}
                    Salvar{" "}
                  </Button>
                </Col>
              </Row>
            </BlockUi>
          </form>
        }
      />
    );
  }
  renderAddContraction() {
    const { id, modalVisibleContraction } = this.state;
    return (
      <Modal
        show={modalVisibleContraction}
        onHide={this.handleModalCloseContraction}
      >
        <div className="card" style={{ margin: "0px" }}>
          <Modal.Header closeButton>
            <Modal.Title>Adicionar Contratação</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormAddContraction id={id} />
          </Modal.Body>
        </div>
      </Modal>
    );
  }
  renderAuthorizer() {
    var { blocking, errorAuthorizer } = this.state;
    return (
      <Card
        content={
          <form onSubmit={this.onSubmitAuthorizer}>
            <BlockUi
              tag="div"
              blocking={blocking}
              message="Carregando, por favor aguarde"
            >
              <Button
                onClick={() =>
                  this.setState({
                    _contraction: null,
                    finish: false,
                    _pendency_progress: null,
                    isDocument: false,
                  })
                }
                className="btn-simple"
                style={{
                  position: "absolute",
                  zIndex: "99",
                  top: "15px",
                  right: "15px",
                }}
              >
                X
              </Button>
              {errorAuthorizer && (
                <Alert bsStyle="danger" className="text-center">
                  <span>{errorAuthorizer}</span>
                </Alert>
              )}
              <Row>
                <Col xs={12}>
                  <br />
                  <p>
                    Você terminou de enviar a documentação. Deseja finalizar a
                    contratação?
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="text-center mt-15">
                  <Button type="submit" bsStyle="info" fill>
                    {" "}
                    Sim{" "}
                  </Button>
                  <Button
                    className="btn-default btn-fill ml-10"
                    onClick={() =>
                      this.setState({
                        _contraction: null,
                        finish: false,
                        _pendency_progress: null,
                        isDocument: false,
                      })
                    }
                  >
                    Não
                  </Button>
                </Col>
              </Row>
            </BlockUi>
          </form>
        }
      />
    );
  }
  renderDetail() {
    var { _contraction } = this.state;

    var CPF = cpf_cnpj.CPF,
      CNPJ = cpf_cnpj.CNPJ,
      address,
      creditall,
      motivo,
      adesao,
      fatura,
      contrato,
      numero_controle,
      numero_aprovacao;

    if (_contraction.pendency.status_id === 5) {
      address = `${_contraction.customer.customer_address.zipcode}, ${
        _contraction.customer.customer_address.address
      }, ${_contraction.customer.customer_address.number} ${
        _contraction.customer.customer_address.complement
          ? _contraction.customer.customer_address.complement
          : ""
      }, ${_contraction.customer.customer_address.neighborhood}, ${
        _contraction.customer.customer_address.city
      }-${_contraction.customer.customer_address.states.initials}`;
    }

    if (_contraction.pendency.creditall.authorizer) {
      creditall = JSON.parse(_contraction.pendency.creditall.authorizer);
      motivo = creditall.Mensagem;
      if (creditall.Adesao && creditall.Fatura && creditall.Contrato) {
        adesao = creditall.Adesao._cdata;
        fatura = creditall.Fatura._cdata;
        contrato = creditall.Contrato._cdata;
      }
      if (creditall.Numero_Controle) {
        numero_controle = creditall.Numero_Controle;
      }
      if (creditall.Numero_Aprovacao > 0) {
        numero_aprovacao = creditall.Numero_Aprovacao;
      }
    }

    return (
      <Card
        title={`Contratação #${_contraction.id}`}
        content={
          <div>
            <Button
              onClick={() =>
                this.setState({
                  _contraction: null,
                  finish: false,
                  _pendency_progress: null,
                  isDocument: false,
                })
              }
              className="btn-simple"
              style={{ position: "absolute", top: "15px", right: "15px" }}
            >
              X
            </Button>
            <Row className="mb-10">
              <Col xs={5}>
                {numero_controle && (
                  <div>
                    <p className="text-muted label">Número de Controle</p>
                    <p>{numero_controle}</p>
                  </div>
                )}
                {numero_aprovacao && (
                  <div>
                    <p className="text-muted label">Número de Aprovação</p>
                    <p>{numero_aprovacao}</p>
                  </div>
                )}
                <p className="text-muted label">Valor</p>
                <p className="">
                  <NumberFormat
                    value={_contraction.pendency.amount}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"R$ "}
                  />
                </p>
                {_contraction.pendency.installment ? (
                  <div>
                    <p className="text-muted label">Parcelas</p>
                    <p>
                      {_contraction.pendency.installment}
                      {_contraction.pendency.status_id === 5 ? (
                        <a
                          onClick={() =>
                            this.setState({ modalVisibleInstallment: true })
                          }
                          className="text-decoration fa-xs ml-10"
                        >
                          ver parcelas
                        </a>
                      ) : null}
                    </p>
                  </div>
                ) : null}
                <p className="text-muted label">Data</p>
                <p className="">{_contraction.created_at}</p>
                <p className="text-muted label">Status</p>
                <p className="">
                  {" "}
                  {_contraction.pendency.status_id === 5
                    ? "FINALIZADO"
                    : "NEGADO"}{" "}
                </p>
                {_contraction.pendency.status_id === 1 && motivo ? (
                  <div>
                    <p className="text-muted label">Motivo</p>
                    <p>{motivo}</p>
                  </div>
                ) : null}
              </Col>
              <Col xs={7} className="text-center">
                {adesao && contrato && fatura ? (
                  <div>
                    <a
                      href={adesao}
                      target="_blank"
                      style={{ marginLeft: "5px", marginRight: "5px" }}
                      className="btn-fill btn btn-primary"
                    >
                      <i
                        className="far fa-file-alt"
                        style={{ marginRight: "10px" }}
                      ></i>{" "}
                      Adesão{" "}
                    </a>
                    <a
                      href={contrato}
                      target="_blank"
                      style={{ marginLeft: "5px", marginRight: "5px" }}
                      className="btn-fill btn btn-primary"
                    >
                      <i
                        className="far fa-file-alt"
                        style={{ marginRight: "10px" }}
                      ></i>{" "}
                      Contrato{" "}
                    </a>
                    <a
                      href={fatura}
                      target="_blank"
                      style={{ marginLeft: "5px", marginRight: "5px" }}
                      className="btn-fill btn btn-primary"
                    >
                      <i
                        className="fas fa-money-check-alt"
                        style={{ marginRight: "10px" }}
                      ></i>{" "}
                      Boletos{" "}
                    </a>
                  </div>
                ) : null}
              </Col>
            </Row>
            <hr />
            <Row className="mt-15">
              <Col xs={12}>
                <h4 className="title mb-15">Dados do Responsável</h4>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="text-muted label">Nome</p>
                <p className="">{_contraction.customer.name}</p>
              </Col>
              {_contraction.customer.cnpj && (
                <Col xs={4}>
                  <p className="text-muted label">CNPJ</p>
                  <p className="">{CNPJ.format(_contraction.customer.cnpj)}</p>
                </Col>
              )}
              <Col xs={4}>
                <p className="text-muted label">CPF</p>
                <p className="">{CPF.format(_contraction.customer.cpf)}</p>
              </Col>
              {_contraction.pendency.status_id === 5 ? (
                <div>
                  <Col xs={4}>
                    <p className="text-muted label">RG</p>
                    <p className="">{_contraction.customer.rg}</p>
                  </Col>
                  <Col xs={4}>
                    <p className="text-muted label">Data de nascimento</p>
                    <p className="">{_contraction.customer.date_of_birth}</p>
                  </Col>
                  <Col xs={4}>
                    <p className="text-muted label">E-mail</p>
                    <p className="">{_contraction.customer.email}</p>
                  </Col>
                  <Col xs={4}>
                    <p className="text-muted label">Telefones</p>
                    <p className="">{`${_contraction.customer.phone1_ddd} ${_contraction.customer.phone1_number} - ${_contraction.customer.phone2_ddd} ${_contraction.customer.phone2_number}`}</p>
                  </Col>
                  <Col xs={12}>
                    <p className="text-muted label">Endereço</p>
                    <p className="">{address}</p>
                  </Col>
                </div>
              ) : null}
            </Row>
            {_contraction.pendency.status_id === 5 ? (
              <div>
                <hr />
                <Row>
                  <Col xs={12}>
                    <h4 className="title mb-15">Documentos</h4>
                  </Col>
                </Row>
                <Row>
                  {_contraction.pendency.document.find(
                    (x) => x.document_type_id === 5
                  ) ? (
                    <Col xs={4} className="text-center">
                      <p className="text-muted label">
                        Foto de Rosto do Responsável
                      </p>
                      <p className="text-center mt-10">
                        <img
                          src={
                            _contraction.pendency.document.find(
                              (x) => x.document_type_id === 5
                            )
                              ? `${IMAGEM_URL}/images/${
                                  _contraction.pendency.document.find(
                                    (x) => x.document_type_id === 5
                                  ).path
                                }`
                              : thumbnailImage
                          }
                          style={{ height: "125px", marginBottom: "10px" }}
                        />
                        <Button
                          className="btn btn-default btn-link btn-primary btn-sm text-decoration"
                          style={{ margin: "auto", display: "block" }}
                          onClick={() => {
                            this.modalDocumentActive(
                              "Foto de Rosto do Responsável",
                              `${IMAGEM_URL}/images/${
                                _contraction.pendency.document.find(
                                  (x) => x.document_type_id === 5
                                ).path
                              }`
                            );
                          }}
                        >
                          Clique aqui para ampliar
                        </Button>
                      </p>
                    </Col>
                  ) : null}
                  {_contraction.pendency.document.find(
                    (x) => x.document_type_id === 1
                  ) ? (
                    <Col xs={4} className="text-center">
                      <p className="text-muted label">CNH (Frente)</p>
                      <p className="text-center mt-10">
                        <img
                          src={
                            _contraction.pendency.document.find(
                              (x) => x.document_type_id === 1
                            )
                              ? `${IMAGEM_URL}/images/${
                                  _contraction.pendency.document.find(
                                    (x) => x.document_type_id === 1
                                  ).path
                                }`
                              : thumbnailImage
                          }
                          style={{ height: "125px", marginBottom: "10px" }}
                        />
                        <Button
                          className="btn btn-default btn-link btn-primary btn-sm text-decoration"
                          style={{ margin: "auto", display: "block" }}
                          onClick={() => {
                            this.modalDocumentActive(
                              "CNH (Frente)",
                              `${IMAGEM_URL}/images/${
                                _contraction.pendency.document.find(
                                  (x) => x.document_type_id === 1
                                ).path
                              }`
                            );
                          }}
                        >
                          Clique aqui para ampliar
                        </Button>
                      </p>
                    </Col>
                  ) : null}
                  {_contraction.pendency.document.find(
                    (x) => x.document_type_id === 2
                  ) ? (
                    <Col xs={4} className="text-center">
                      <p className="text-muted label">CNH (Verso)</p>
                      <p className="text-center mt-10">
                        <img
                          src={
                            _contraction.pendency.document.find(
                              (x) => x.document_type_id === 2
                            )
                              ? `${IMAGEM_URL}/images/${
                                  _contraction.pendency.document.find(
                                    (x) => x.document_type_id === 2
                                  ).path
                                }`
                              : thumbnailImage
                          }
                          style={{ height: "125px", marginBottom: "10px" }}
                        />
                        <Button
                          className="btn btn-default btn-link btn-primary btn-sm text-decoration"
                          style={{ margin: "auto", display: "block" }}
                          onClick={() => {
                            this.modalDocumentActive(
                              "CNH (Verso)",
                              `${IMAGEM_URL}/images/${
                                _contraction.pendency.document.find(
                                  (x) => x.document_type_id === 2
                                ).path
                              }`
                            );
                          }}
                        >
                          Clique aqui para ampliar
                        </Button>
                      </p>
                    </Col>
                  ) : null}
                  {_contraction.pendency.document.find(
                    (x) => x.document_type_id === 3
                  ) ? (
                    <Col xs={4} className="text-center">
                      <p className="text-muted label">RG (Frente)</p>
                      <p className="text-center mt-10">
                        <img
                          src={
                            _contraction.pendency.document.find(
                              (x) => x.document_type_id === 3
                            )
                              ? `${IMAGEM_URL}/images/${
                                  _contraction.pendency.document.find(
                                    (x) => x.document_type_id === 3
                                  ).path
                                }`
                              : thumbnailImage
                          }
                          style={{ height: "125px", marginBottom: "10px" }}
                        />
                        <Button
                          className="btn btn-default btn-link btn-primary btn-sm text-decoration"
                          style={{ margin: "auto", display: "block" }}
                          onClick={() => {
                            this.modalDocumentActive(
                              "RG (Frente)",
                              `${IMAGEM_URL}/images/${
                                _contraction.pendency.document.find(
                                  (x) => x.document_type_id === 3
                                ).path
                              }`
                            );
                          }}
                        >
                          Clique aqui para ampliar
                        </Button>
                      </p>
                    </Col>
                  ) : null}
                  {_contraction.pendency.document.find(
                    (x) => x.document_type_id === 4
                  ) ? (
                    <Col xs={4} className="text-center">
                      <p className="text-muted label">RG (Verso)</p>
                      <p className="text-center mt-10">
                        <img
                          src={
                            _contraction.pendency.document.find(
                              (x) => x.document_type_id === 4
                            )
                              ? `${IMAGEM_URL}/images/${
                                  _contraction.pendency.document.find(
                                    (x) => x.document_type_id === 4
                                  ).path
                                }`
                              : thumbnailImage
                          }
                          style={{ height: "125px", marginBottom: "10px" }}
                        />
                        <Button
                          className="btn btn-default btn-link btn-primary btn-sm text-decoration"
                          style={{ margin: "auto", display: "block" }}
                          onClick={() => {
                            this.modalDocumentActive(
                              "RG (Verso)",
                              `${IMAGEM_URL}/images/${
                                _contraction.pendency.document.find(
                                  (x) => x.document_type_id === 4
                                ).path
                              }`
                            );
                          }}
                        >
                          Clique aqui para ampliar
                        </Button>
                      </p>
                    </Col>
                  ) : null}
                </Row>
              </div>
            ) : null}
          </div>
        }
      />
    );
  }

  confirmMessageFinish() {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.finishContract()}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnText="Sim!"
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          showCancel
        ></SweetAlert>
      ),
    });
  }
  alertMessage(type, title, message) {
    if (type === "success") {
      this.setState({
        alert: (
          <SweetAlert
            success
            style={{ display: "block" }}
            title={title}
            onConfirm={() => this.setState({ alert: null })}
            confirmBtnBsStyle="success"
            confirmBtnText="Ok"
          >
            <p>{message}</p>
          </SweetAlert>
        ),
      });
    } else if (type === "danger") {
      this.setState({
        alert: (
          <SweetAlert
            danger
            style={{ display: "block" }}
            title={title}
            onConfirm={() => this.setState({ alert: null })}
            confirmBtnBsStyle="success"
            confirmBtnText="Ok"
          >
            <p>{message}</p>
          </SweetAlert>
        ),
      });
    }
  }

  async finishContract() {
    this.setState({ loading: true });

    await api.put(`contractions/finish/${this.state.id}`);

    this.setState({
      contraction: { finish: 1 },
      alert: null,
      loading: false,
    });
  }

  async isDocumentApp(data) {
    if (this.state.isDocument) {
      setTimeout(this.isDocumentApp.bind(this, data), 1500);
      var respDocument = await api.get(`document/pendency/${data.pendency_id}`);
      if (this.checkDocument(respDocument.data)) {
        this.setState({ isDocument: false });
        this.loadContractionCustomer(data.id);
      }
    }
  }

  /* ==================================
    Fake
  ================================== */
  async fakeDocument() {
    var { _pendency } = this.state;
    await api.post("document_customer/",{
      pendency_id: _pendency.id,
      document_type_id: 3,
      document_source_id: 1
    })
    await api.post("document_customer/",{
      pendency_id: _pendency.id,
      document_type_id: 4,
      document_source_id: 1
    })
    await api.post("document_customer/",{
      pendency_id: _pendency.id,
      document_type_id: 5,
      document_source_id: 1
    })
  }

  /* ==================================
    Helpers
  ================================== */
  onListInstallment(pendency) {
    var pre_analysis = JSON.parse(pendency.creditall.pre_analysis),
      arrInstallment = [],
      Limite_Saldo_Parcela = pre_analysis.Limite_Saldo_Parcela;
    Limite_Saldo_Parcela = Limite_Saldo_Parcela.replace(".", "");
    Limite_Saldo_Parcela = Limite_Saldo_Parcela.replace(".", "");
    Limite_Saldo_Parcela = Limite_Saldo_Parcela.replace(",", ".");
    Limite_Saldo_Parcela = parseFloat(Limite_Saldo_Parcela);

    if (Limite_Saldo_Parcela > 0) {
      for (let i = 1; i <= 12; i++) {
        if (pendency.amount / i <= Limite_Saldo_Parcela) {
          arrInstallment.push({
            value: i,
            label: `${i}x de R$ ${(pendency.amount / i).toFixed(2)}`,
          });
        }
      }
      return arrInstallment;
    } else {
      return false;
    }
  }

  render() {
    var {
      contraction,
      contractions_customers,
      _contraction,
      customer,
      _customer,
      _pendency,
      _documents,
      _pendency_progress,
      _installments,
      info,
      id,
      alert,
      loading,
    } = this.state;
    return (
      <div className="main-content">
        {alert}
        {this.renderAddContraction()}
        {_contraction &&
        _pendency.status_id === 5 &&
        _pendency_progress === "detail"
          ? this.modalInstallment()
          : null}
        {_contraction && _pendency_progress === "detail"
          ? this.modalDocument()
          : null}
        <CounterDown val={1800} modal={this.state.modalLock} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Breadcrumb
                routes={[{ label: "Pendências", link: "/pendencias" }]}
                active={`Contratação #${id}`}
              />
            </Col>
          </Row>
          <BlockUi
            tag="div"
            blocking={loading}
            message="Carregando, por favor aguarde"
          >
            <div>
              <Row>
                {contractions_customers ? (
                  <Col md={12}>
                    <Card
                      title={`Contratações - #${id}`}
                      content={
                        <div>
                          <p>
                            Paciente: <strong>{customer.name}</strong>
                          </p>
                          <p>
                            Valor solicitado:{" "}
                            <strong>
                              <NumberFormat
                                value={contraction.requested_amount}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"R$ "}
                              />
                            </strong>
                          </p>
                          <p>
                            Status:{" "}
                            <strong>
                              {contraction.finish == 1
                                ? "Finalizado"
                                : "Aprovado parcialmente"}
                            </strong>
                          </p>
                          <div className="table-responsive">
                            <table className="table table-list table-striped">
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Id
                                  </th>
                                  <th>Responsável financeiro</th>
                                  <th>Valor</th>
                                  <th>Parcelas</th>
                                  <th>Valor da parcela</th>
                                  <th>Status</th>
                                  <th style={{ width: "125px" }}></th>
                                </tr>
                              </thead>
                              <tbody>
                                {contractions_customers.length > 0
                                  ? contractions_customers.map(
                                      (element, index) => {
                                        return (
                                          <tr key={index}>
                                            <td style={{ textAlign: "center" }}>
                                              {element.id}{" "}
                                            </td>
                                            <td>{element.customer.name} </td>
                                            <td>
                                              <NumberFormat
                                                value={element.pendency.amount}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                displayType={"text"}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                prefix={"R$ "}
                                              />
                                            </td>
                                            <td>
                                              {element.pendency.installment
                                                ? element.pendency.installment
                                                : "-"}
                                            </td>
                                            <td>
                                              {element.pendency.installment ? (
                                                <NumberFormat
                                                  value={
                                                    element.pendency.amount /
                                                    element.pendency.installment
                                                  }
                                                  fixedDecimalScale={true}
                                                  decimalScale={2}
                                                  displayType={"text"}
                                                  thousandSeparator={"."}
                                                  decimalSeparator={","}
                                                  prefix={"R$ "}
                                                />
                                              ) : (
                                                "-"
                                              )}
                                            </td>
                                            <td>
                                              {element.pendency.status_id === 1
                                                ? "NEGADO"
                                                : ""}
                                              {element.pendency.status_id === 4
                                                ? "APROVADO"
                                                : ""}
                                              {element.pendency.status_id === 5
                                                ? "FINALIZADO"
                                                : ""}
                                            </td>
                                            <td>
                                              <Button
                                                onClick={() => {
                                                  this.loadContractionCustomer(
                                                    element.id
                                                  );
                                                }}
                                                className="btn btn-primary btn-round btn-xs"
                                              >
                                                <i className="fa fa-eye"></i>{" "}
                                                Ver detalhes
                                              </Button>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  : null}
                              </tbody>
                            </table>
                          </div>
                          <p
                            style={{
                              fontSize: "14px",
                              marginTop: "10px",
                              fontStyle: "italic",
                            }}
                          >
                            Total aprovado{" "}
                            <NumberFormat
                              value={contraction.approved_amount}
                              fixedDecimalScale={true}
                              decimalScale={2}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"R$ "}
                            />
                          </p>
                        </div>
                      }
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col md={12}>
                  {_pendency_progress === "customer" && _customer
                    ? this.renderCustomer()
                    : null}
                  {_pendency_progress === "documents" && _pendency && _documents
                    ? this.renderDocument()
                    : null}
                  {_pendency_progress === "installment" && _installments
                    ? this.renderInstallment()
                    : null}
                  {_pendency_progress === "authorizer"
                    ? this.renderAuthorizer()
                    : null}
                  {_pendency_progress === "detail" ? this.renderDetail() : null}
                </Col>
              </Row>
            </div>
            <Row>
              {contraction && contraction.finish !== 1 ? (
                <Col xs={6}>
                  <Card
                    content={
                      <div>
                        <p>
                          Solicitado:{" "}
                          <strong>
                            <NumberFormat
                              value={contraction.requested_amount}
                              fixedDecimalScale={true}
                              decimalScale={2}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"R$ "}
                            />
                          </strong>
                        </p>
                        <p>
                          Aprovado:{" "}
                          <strong>
                            <NumberFormat
                              value={contraction.approved_amount}
                              fixedDecimalScale={true}
                              decimalScale={2}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"R$ "}
                            />
                          </strong>
                        </p>
                        <p>
                          Deseja incluir mais uma pessoa, amigo ou parente, para
                          complementar o valor solicitado?
                        </p>
                      </div>
                    }
                    ftTextCenter
                    legend={
                      <div>
                        <Button
                          className="btn btn-fill btn-primary"
                          onClick={() =>
                            this.setState({ modalVisibleContraction: true })
                          }
                        >
                          Sim
                        </Button>
                        <Button
                          className="btn-default btn-fill ml-10"
                          onClick={() => this.confirmMessageFinish()}
                        >
                          Não
                        </Button>
                      </div>
                    }
                  />
                </Col>
              ) : null}
            </Row>
          </BlockUi>
        </Grid>
      </div>
    );
  }
}
