export const TOKEN_KEY = "@clinicalcred-Token";
export const TOKEN_REFRESH = "@clinicalcred-RefreshToken";
export const USER = "@clinicalcred-user";
export const PERMISSIONS = "@clinicalcred-permissions";
export const BLOCK = "@clinicalcred-Block";
export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;

export const getPermissions = () => sessionStorage.getItem(PERMISSIONS);
export const getUser = () => sessionStorage.getItem(USER);
export const getToken = () => sessionStorage.getItem(TOKEN_KEY);
export const getReshToken = () => sessionStorage.getItem(TOKEN_REFRESH);
export const getBlock = () => sessionStorage.getItem(BLOCK);

export const login = token => { sessionStorage.setItem(TOKEN_KEY, token); };
export const refreshToken = refreshToken => { sessionStorage.setItem(TOKEN_REFRESH, refreshToken); };
export const setPermissions = permissions => { sessionStorage.setItem(PERMISSIONS, permissions); };
export const setUser = user => { sessionStorage.setItem(USER, user); };
export const setBlock = block => { sessionStorage.setItem(BLOCK, block); };

export const removeAuth = () => {
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(TOKEN_REFRESH);
  sessionStorage.removeItem(PERMISSIONS);
  sessionStorage.removeItem(USER);
  sessionStorage.removeItem(BLOCK);
};

export const removeBlock = () => {
  sessionStorage.removeItem(BLOCK);
};