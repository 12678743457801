import React, { Component } from 'react';
import { Grid, Row, Col, Alert, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import SweetAlert from 'react-bootstrap-sweetalert';

import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import api from '../../services/api';

export default class CompanyIntegrator extends Component {
  
  constructor(props) {
    super(props);

    this.onChangeInput = this.onChangeInput.bind(this);    
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitToken = this.onSubmitToken.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      alert: null,

      id: '',
      token: '',
      url: '',
      url_preanalysis: '',

      // validation
      error: '',

      // Loading
      blocking: false,
    }
  }

  componentDidMount() {
    if (check(getPermissions(), 'company_edit') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadIntegrator()
  }

  async loadIntegrator() {
    this.setState({ blocking: true })
    const respCompany = await api.get('companies/' + this.props.match.params.id)
    
    if(respCompany.data.company.integrator){
      this.setState({
        id: respCompany.data.company.integrator.id,
        token: respCompany.data.company.integrator.token,
        url: respCompany.data.company.integrator.url,
        url_preanalysis: respCompany.data.company.integrator.url_preanalysis,
        blocking: false
      });
    }else{
      const resp = await api.post('companies/token/', {
        id: this.props.match.params.id
      })
      if(resp.status === 200){
        const respCompanyIntegrator = await api.get('companies/' + this.props.match.params.id)
        this.setState({
          id: respCompanyIntegrator.data.company.integrator.id,
          token: respCompanyIntegrator.data.company.integrator.token,
          url: respCompanyIntegrator.data.company.integrator.url,
          url_preanalysis: respCompanyIntegrator.data.company.integrator.url_preanalysis,
          blocking: false
        });
      }
    }
  }

  warningWithConfirmMessage() {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Token"
          onConfirm={() => this.onSubmitToken()}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="GERAR NOVO TOKEN"
          cancelBtnText="CANCELAR"
          showCancel
        >
          Atenção: ao gerar um novo token, seu token antigo não funcionará mais.
        </SweetAlert>
      )
    });
  }

  async onSubmitToken(){
    this.setState({ blocking: true })
    const resp = await api.put(`companies/token/${this.props.match.params.id}`)
    if(resp.status === 200){
      this.setState({ alert: null })
      this.loadIntegrator()
      this.props.handleClick('tr', 'success', 'Atualizado com sucesso!')
    }
    this.setState({ blocking: false })
  }

  onChangeInput = e => {
    this.setState({ [e.target.name]: e.target.value })
    e.target.value === "" ? this.setState({ [e.target.name + "Error"]: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ [e.target.name + "Error"]: null, error: null });
  }

  async onSubmit(e) {
    e.preventDefault();

    const { id, url, url_preanalysis } = this.state

    url === "" ? this.setState({ urlError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ urlError: null });
    
    if ( url !== "") {

      this.setState({ blocking: true })

      const obj = {
        url,
        url_preanalysis
      };
      
      const resp = await api.put('integrators/' + id, obj)
      
      if (resp.status === 200) {

        this.props.handleClick('tr', 'success', 'Atualizado com sucesso!')

        this.props.history.push("/empresa");

      } else {
        this.setState({
          error: resp.data.message,
          blocking: false
        })
      }
    }
  }

  render() {
    var { 
      modalLock,
      alert,
      blocking,
      error,
      token,
      url,
      url_preanalysis
    } = this.state
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={modalLock} />
        {alert}
        <Grid fluid>
          <Row>
            <Col md={6}>
              <Breadcrumb routes={[{ label: 'Empresas', link: '/empresa' }]} active="Integrador" />
              <BlockUi tag="div" blocking={blocking} message="Carregando, por favor aguarde">
                <form onSubmit={this.onSubmit}>
                  <Card
                    content={
                      <div>
                        {error &&
                          <Alert bsStyle="danger">
                            {
                              error.map((e, i) => {
                                return (
                                  <li key={i}>{e.message}</li>
                                )
                              })
                            }
                          </Alert>
                        }
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Token (Produção): </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={9}>
                                  <textarea
                                    name="token"
                                    className="form-control"
                                    style={{height:80}}
                                    value={token ? token : ''}>
                                  </textarea>
                                </Col>
                                <Col xs={3}>
                                  <br />
                                  <button type="button" onClick={this.warningWithConfirmMessage.bind(this)} className="btn btn-primary btn-round btn-xs">Gerar novo token</button>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Url de retorno (Pré-analise): </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    name="url"
                                    value={url ? url : ''}
                                    onChange={this.onChangeInput}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Url de retorno (Contratação): </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    name="url_preanalysis"
                                    value={url_preanalysis ? url_preanalysis : ''}
                                    onChange={this.onChangeInput}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    }
                    ftTextCenter
                    legend={
                      <div>
                        <Button type="submit" bsStyle="info" fill> Salvar </Button>
                        <Link to={"/empresa/"} className="btn-fill btn btn-neutral btn-back">Voltar</Link>
                      </div>
                    }
                  />
                </form>
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}