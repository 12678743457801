import React, { Component } from 'react';
import {
  Grid, Row, Col, Alert, FormGroup, ControlLabel
} from 'react-bootstrap';
import SimpleCurrencyInput from 'react-simple-currency';
import NumberFormat from 'react-currency-format';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import Card from '../../components/Card/Card.jsx';

import Button from '../../components/CustomButton/CustomButton.jsx';
import Radio from '../../components/CustomRadio/CustomRadio.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';

import { getBlock } from '../../services/auth';
import api from '../../services/api';

export default class SimulatorCreate extends Component {
  constructor(props) {
    super(props)

    this.onChangeValueTreatment = this.onChangeValueTreatment.bind(this)
    this.onChangeFormPaymentId = this.onChangeFormPaymentId.bind(this)
    this.onChangeInstallment = this.onChangeInstallment.bind(this)
    this.onChangeAnticipation = this.onChangeAnticipation.bind(this)
    this.onBlurCalculate = this.onBlurCalculate.bind(this)

    this.state = {
      modalLock: getBlock() ? true : false,
      formpayment_id: 0,
      valueTreatment: 0,
      valueTreatmentMask: '',
      valueFinancing: 0,
      valueInstallment: 0,
      valueReceivable: 0,
      installment: 0,
      installments: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
      anticipation: 0,
      error: '',
      blocking: false
    }

  }

  async calculate(valueTreatment, installment, anticipation, formpayment_id) {
    if (
      valueTreatment !== 0 && 
      installment !== 0 &&
      formpayment_id !== 0
    ){
      var obj = {
        valueTreatment: valueTreatment / 100,
        installment,
        anticipation,
        formpayment_id,
      }
      this.setState({ blocking: !this.state.blocking });
      const resp = await api.post('simulators/v2/calculate', obj)
      this.setState({
        valueFinancing: resp.data.data.valueTreatment,
        taxInstallment: resp.data.data.taxInstallment,
        taxReceivable: resp.data.data.taxReceivable,
        valueInstallment: resp.data.data.valueInstallment,
        valueReceivable: resp.data.data.valueReceivable,
        blocking: !this.state.blocking
      })
    }

  }

  onBlurCalculate(){
    var {
      valueTreatment, anticipation, installment, formpayment_id
    } = this.state
    this.calculate(valueTreatment, installment, anticipation, formpayment_id)
  }

  onChangeValueTreatment(rawValue, displayValue) {
    this.setState({ 
      valueTreatment: rawValue,
      valueTreatmentMask: displayValue,
    })
  }

  onChangeFormPaymentId(e) {
    var formpayment_id = e.target.value
    this.setState({ formpayment_id })
    var {
      valueTreatment, anticipation, installment
    } = this.state
    this.calculate(valueTreatment, installment, anticipation, formpayment_id)
  }

  onChangeInstallment(e) {
    var installment = e.target.value
    this.setState({ installment })
    var {
      valueTreatment, anticipation, formpayment_id
    } = this.state
    this.calculate(valueTreatment, installment, anticipation, formpayment_id)
  }

  onChangeAnticipation(e) {
    var anticipation = e.target.value
    this.setState({ anticipation })
    var {
      valueTreatment, installment, formpayment_id
    } = this.state
    this.calculate(valueTreatment, installment, anticipation, formpayment_id)
  }

  renderPainel() {
    return (
      <div className="painelSimulator">
        <p>
          Valor do tratamento: 
          <span>{this.state.valueTreatmentMask}</span>
        </p>
        {/* <p>
          Valor da entrada: 
          <span>{this.state.valueInputMask}</span>
        </p> */}
        {/* <p>
          Valor a financiar:
          <NumberFormat value={this.state.valueFinancing} fixedDecimalScale={true} decimalScale={2} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$ '} />
        </p> */}
        <p>
          Valor da parcela:
          <NumberFormat value={this.state.valueInstallment} fixedDecimalScale={true} decimalScale={2} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$ '} />
        </p>
        <div className="receivable">
          <span>Valor a Receber</span>
          <strong>
            <NumberFormat value={this.state.valueReceivable} fixedDecimalScale={true} decimalScale={2} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$ '} />
          </strong>
        </div>
      </div>
    )
  }

  render() {

    const { installments } = this.state

    return (
      <div className="main-content">
        <CounterDown val={1800} modal={this.state.modalLock} />
        <Grid fluid>
          <Row>
            <Col md={8}>
              <BlockUi tag="div" blocking={this.state.blocking} message="Carregando, por favor aguarde">
              <Card
                content={
                  <form>
                    {this.state.error && <Alert bsStyle="danger"><span>{this.state.error}</span></Alert>}
                    <FormGroup>
                      <ControlLabel>
                        Valor do Tratamento:
                      </ControlLabel>
                      <SimpleCurrencyInput
                        precision={2}
                        separator=','
                        delimiter='.'
                        className="form-control"
                        value={this.state.valueTreatment}
                        unit='R$'
                        onInputChange={this.onChangeValueTreatment}
                      />
                    </FormGroup>
                    {/* <FormGroup>
                      <ControlLabel>
                        Valor da Entrada: (mínimo de 5%)
                      </ControlLabel>
                      <SimpleCurrencyInput
                        precision={2}
                        separator=','
                        delimiter='.'
                        className="form-control"
                        value={this.state.valueInput}
                        unit='R$'
                        onInputChange={this.onChangeValueInput}
                        onInputBlur={this.onBlurCalculate}
                      />
                    </FormGroup> */}
                    <FormGroup>
                      <ControlLabel>
                        Forma de pagamento
                      </ControlLabel>
                      <FormGroup>
                        <Radio
                          number="1"
                          option="1"
                          name="formpayment_id"
                          onChange={this.onChangeFormPaymentId}
                          checked={this.state.formpayment_id === "1"}
                          label="Cheque"
                        />
                        <Radio
                          number="2"
                          option="2"
                          name="formpayment_id"
                          onChange={this.onChangeFormPaymentId}

                          checked={this.state.formpayment_id === "2"}
                          label="Boleto"
                        />
                        <Radio
                          number="3"
                          option="3"
                          name="formpayment_id"
                          onChange={this.onChangeFormPaymentId}

                          checked={this.state.formpayment_id === "3"}
                          label="Cartão de crédito Recorrente"
                        />
                      </FormGroup>
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Quantidade de parcelas: </ControlLabel>
                      <select
                        placeholder="Selecione a quantidade de parcelas"
                        className="form-control"
                        value={this.state.installment}
                        onChange={this.onChangeInstallment}
                      >
                        <option value=""></option>
                        {installments.map(element => {
                          return (
                            <option key={element} value={element}>{element}x</option>
                          )
                        })}
                      </select>
                    </FormGroup>
                    {/* <FormGroup>
                      <ControlLabel>Você gostaria de receber o valor do tratamento à vista? </ControlLabel>
                      <FormGroup>
                        <Radio
                          number="anticipation_1"
                          option="1"
                          name="anticipation"
                          onChange={this.onChangeAnticipation}
                          checked={this.state.anticipation === "1"}
                          label="Sim"
                        />
                        <Radio
                          number="anticipation_0"
                          option="0"
                          name="anticipation"
                          onChange={this.onChangeAnticipation}
                          checked={this.state.anticipation === "0"}
                          label="Não"
                        />
                      </FormGroup>
                    </FormGroup> */}
                  </form>
                }
              />
              </BlockUi>
            </Col>
            <Col md={4}>
              {this.renderPainel()}
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}