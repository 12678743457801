import React, { Component } from 'react';
import {
    Grid, Row, Col, Alert,
    FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import Countdown from 'react-countdown-now';

import api from '../../services/api';
import { login } from '../../services/auth';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import logo from '../../assets/img/logo.png';

import { getUser, removeBlock } from '../../services/auth';

const user = JSON.parse(getUser())

const timeLogout = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        window.location.href = "/auth/login";
    } else {
        return <span style={{ display: 'block' }} className="text-center text-primary small">Tempo de expiração: {minutes}:{seconds}</span>;
    }
};

class Lock extends Component {
    constructor(props) {
        super(props);

        this.onChangePassword = this.onChangePassword.bind(this);

        this.state = {
            cardHidden: true,
            password: "",

            error: "",
            passwordError: "",

            blocking: false
        }
    }
    componentDidMount() {
        removeBlock()
        setTimeout(function () { this.setState({ cardHidden: false }); }.bind(this), 500);
    }
    onChangePassword(e) {
        this.setState({ password: e.target.value });
        e.target.value === "" ? this.setState({ passwordError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ passwordError: null, error: null });
    }

    handleSignIn = async e => {
        e.preventDefault();

        this.setState({ blocking: true });

        const { password } = this.state;

        password === "" ? this.setState({ passwordError: (<small className="text-danger">Campo obrigatório.</small>), blocking: false }) : this.setState({ passwordError: null });

        if (password !== "") {
            try {
                const response = await api.post("/auth/sign-in", { email: user.email,  password });
                if (response.status === 200) {

                    login(response.data.token);

                    window.location.href = window.location.search.replace("?redirect=", "")

                } else if (response.status === 204) {
                    this.setState({
                        error: "Senha inválida",
                        blocking: false
                    });
                }
            } catch (err) {
                this.setState({
                    error: "Houve um problema, entre em contato com o suporte",
                    blocking: false
                });
            }
        }
    };
    render() {
        return (
            <Grid>
                <Row>
                    <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                        <BlockUi tag="div" blocking={this.state.blocking} message="Carregando, por favor aguarde">
                            <form onSubmit={this.handleSignIn}>
                                <Card
                                    hidden={this.state.cardHidden}
                                    textCenter
                                    content={
                                        <div>
                                            <img src={logo} className="auth-logo" alt="ClinicalCred" />
                                            {this.state.error && <Alert bsStyle="danger">{this.state.error}</Alert>}
                                            <Countdown
                                                date={Date.now() + 900000}
                                                renderer={timeLogout}
                                            />
                                            <FormGroup>
                                                <ControlLabel>
                                                    Senha
                                                </ControlLabel>
                                                <FormControl
                                                    type="password"
                                                    className={this.state.passwordError ? 'error' : ''}
                                                    value={this.state.password}
                                                    onChange={this.onChangePassword}
                                                />
                                                {this.state.passwordError}
                                            </FormGroup>
                                            <FormGroup className="text-center">
                                                <Button type="submit" bsStyle="info" fill wd>
                                                    Desbloquear
                                                </Button>
                                                <p className="btn-back">
                                                    <Link to={"/auth/login"} className="btn-link">Sair do sistema</Link>
                                                </p>
                                            </FormGroup>
                                        </div>
                                    }
                                />
                            </form>
                        </BlockUi>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default Lock;
