import React, { Component } from 'react';
import { Grid, Row, Col, Alert, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { animateScroll as scroll } from 'react-scroll';

import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';
import Card from '../../components/Card/Card.jsx';
import Radio from '../../components/CustomRadio/CustomRadio.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import Checkbox from '../../components/CustomCheckbox/CustomCheckbox.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';

import api from '../../services/api';

export default class RoleCreate extends Component {
  constructor(props) {
    super(props);

    this.onChangeChecked = this.onChangeChecked.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,

      role_type_id: '',
      name: '',
      description: '',
      permissions: [],
      checkedPermissions: new Map(),
      // validation
      error: '',
      role_type_idError: '',
      nameError: '',
      descriptionError: '',
      permissionsError: '',

      // Loading
      blocking: true
    }
  }

  componentDidMount() {
    if (check(getPermissions(), 'role_add') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadPermissions()
  }

  async loadPermissions(){
    const rest = await api.get('permissions?perpage=200')
    this.setState({
      permissions: rest.data.data,
      blocking: false
    });
  }

  onChangeInput = e => {
    this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    if (e.target.name !== "link") {
      e.target.value === "" ? this.setState({ [e.target.name + "Error"]: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ [e.target.name + "Error"]: null, error: null });
    }
  }

  onChangeChecked(e) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState(prevState => ({ checkedPermissions: prevState.checkedPermissions.set(item, isChecked) }));
  }

  async onSubmit(e) {
    e.preventDefault();

    var {
      checkedPermissions,
      role_type_id,
      name,
      description
    } = this.state

    name === "" ? this.setState({ nameError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ nameError: null });
    description === "" ? this.setState({ descriptionError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ descriptionError: null });
    role_type_id === "" ? this.setState({ role_type_idError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ role_type_idError: null });

    var permissions = []

    checkedPermissions.forEach((checked, id) => {
      if (checked) {
        permissions.push(id)
      }
    });

    permissions.length === 0 ? this.setState({ permissionsError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ permissionsError: null });

    if (name !== "" &&
      description !== "" &&
      role_type_id !== "" &&
      permissions.length > 0
    ) {

      this.setState({ blocking: true })

      const obj = {
        role_type_id,
        name,
        description,
        permissions,
      };

      const resp = await api.post('roles', obj)

      if (resp.status === 200) {

        this.props.handleClick('tr', 'success', 'Cadastrado com sucesso!')

        this.props.history.push("/perfil");

      } else {
        scroll.scrollToTop();
        this.setState({ error: resp.data.message, blocking: false })
      }

    }else{
      scroll.scrollToTop();
    }

  }

  renderPermission() {
    const { permissions } = this.state;
    return permissions
      .map((checkbox) =>
        <div key={checkbox.id} >
          <Checkbox
            number={checkbox.id}
            value={checkbox.id}
            label={checkbox.name}
            name={checkbox.id}
            checked={this.state.checkedPermissions.get(checkbox.id)}
            onChange={this.onChangeChecked}
          />
        </div>
      );
  }

  render() {
    var {
      modalLock,
      blocking,
      error,
      role_type_id,
      role_type_idError,
      name,
      nameError,
      description,
      descriptionError,
      permissionsError
    } = this.state
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={modalLock} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-links">
                  <li className="breadcrumb-item"><Link to={"/perfil"}>Perfis</Link></li>
                  <li className="breadcrumb-item active">Novo Perfil</li>
                </ol>
              </nav>
            </Col>
          </Row>
          <Row>
            <BlockUi tag="div" blocking={blocking} message="Carregando, por favor aguarde">
              <Col md={6}>
                <form onSubmit={this.onSubmit}>
                  <Card
                    title="Novo Perfil"
                    content={
                      <div>
                        {error &&
                          <Alert bsStyle="danger">
                            {
                              error.map((e, i) => {
                                return (
                                  <li key={i}>{e.message}</li>
                                )
                              })
                            }
                          </Alert>
                        }
                        <FormGroup>
                          <ControlLabel>
                            Tipo de perfil:
                          </ControlLabel>
                          <FormGroup>
                            <Radio
                              number="role_type_id_1"
                              option="1"
                              name="role_type_id"
                              onChange={this.onChangeInput}
                              checked={role_type_id === "1"}
                              label="Administrador Global"
                            />
                            <Radio
                              number="role_type_id_2"
                              option="2"
                              name="role_type_id"
                              onChange={this.onChangeInput}
                              checked={role_type_id === "2"}
                              label="Credenciador"
                            />
                            <Radio
                              number="role_type_id_3"
                              option="3"
                              name="role_type_id"
                              onChange={this.onChangeInput}
                              checked={role_type_id === "3"}
                              label="Empresa"
                            />
                          </FormGroup>
                          {role_type_idError}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            Nome:
                          </ControlLabel>
                          <FormControl
                            type="text"
                            className={nameError ? 'error' : ''}
                            value={name}
                            name="name"
                            onChange={this.onChangeInput}
                          />
                          {nameError}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            Descrição:
                          </ControlLabel>
                          <FormControl
                            type="text"
                            className={descriptionError ? 'error' : ''}
                            value={description}
                            name="description"
                            onChange={this.onChangeInput}
                          />
                          {descriptionError}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            Permissões:
                          </ControlLabel>
                          {this.renderPermission()}
                          {permissionsError}
                        </FormGroup>
                      </div>
                    }
                    ftTextCenter
                    legend={
                      <div>
                        <Button type="submit" bsStyle="info" fill> Salvar </Button>
                        <Link to={"/perfil/"} className="btn-fill btn btn-neutral btn-back">Voltar</Link>
                      </div>
                    }
                  />
                </form>
              </Col>
            </BlockUi>
          </Row>
        </Grid>
      </div>
    )
  }
}