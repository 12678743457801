import React, { Component } from 'react';
import { Grid, Row, Col, Alert, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { animateScroll as scroll } from 'react-scroll';

import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';
import { API_URL } from "../../config/constants";
import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import api from '../../services/api';

export default class NoticeEdit extends Component {
  constructor(props) {
    super(props);
    
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeInputFile = this.onChangeInputFile.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      path: '',
      path_tmp: '',
      title: '',
      link: '',
      description: '',
      
      // validation
      error: '',
      pathError: '',
      titleError: '',
      descriptionError: '',

      // Loading
      blocking: false,
    }
  }

  componentDidMount() {
    if (check(getPermissions(), 'notice_edit') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadNotice()
  }

  async loadNotice(){
    this.setState({ blocking: true })
    const respNotice = await api.get('notices/' + this.props.match.params.id)
    this.setState({
      path: respNotice.data.notice.path,
      path_tmp: respNotice.data.notice.path,
      title: respNotice.data.notice.title,
      link: respNotice.data.notice.link,
      description: respNotice.data.notice.description,
      blocking: false
    });
  }

  onChangeInput = e => {
    this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    if (e.target.name !== "link") {
      e.target.value === "" ? this.setState({ [e.target.name + "Error"]: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ [e.target.name + "Error"]: null, error: null });
    }
  }

  onChangeInputFile = e => {
    this.setState({ [e.target.name]: e.target.files[0] })
    e.target.value === "" ? this.setState({ [e.target.name + "Error"]: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ [e.target.name + "Error"]: null, error: null });
  }

  async onSubmit(e) {
    e.preventDefault();

    const { path, title, link, description } = this.state

    path === null ? this.setState({ pathError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ pathError: null });
    title === "" ? this.setState({ titleError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ titleError: null });
    description === "" ? this.setState({ descriptionError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ descriptionError: null });

    if (path !== null && title !== "" && description !== "") {
      
      this.setState({ blocking: true })

      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      var file = path

      var data = new FormData();

      if (file){
        data.append('imagem', file);
      }
      data.append('title', title)
      data.append('link', link)
      data.append('description', description)

      const resp = await api.put(`notices/${this.props.match.params.id}`, data, config)

      if (resp.status === 200) {

        this.props.handleClick('tr', 'success', 'Cadastrado com sucesso!')

        this.props.history.push("/aviso");

      } else {
        scroll.scrollToTop();
        this.setState({ error: resp.data.message, blocking: false })
      }

    }else{
      scroll.scrollToTop();
    }

  }

  render() {
    var{
      modalLock,
      blocking,
      error,
      path_tmp,
      pathError,
      title,
      titleError,
      link,
      description,
      descriptionError
    } = this.state
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={modalLock} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Breadcrumb routes={[{ label: 'Avisos', link: '/aviso' }]} active="Editar Aviso" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <form onSubmit={this.onSubmit}>
                <BlockUi tag="div" blocking={blocking} message="Carregando, por favor aguarde">
                  <Card
                    title="Editar Aviso"
                    content={
                      <div>
                        {error &&
                          <Alert bsStyle="danger">
                            {
                              error.map((e, i) => {
                                return (
                                  <li key={i}>{e.message}</li>
                                )
                              })
                            }
                          </Alert>
                        }
                        <FormGroup>
                          <ControlLabel>
                            Imagem: <span className="small">(Tamanho: 1440x500px, formato: JPG)</span>
                          </ControlLabel>
                          <br></br>
                          <img src={`${API_URL}/notice/imagem/${path_tmp}`} style={{ width: '250px' }} />
                          <input
                            type='file'
                            className={pathError ? 'error form-control' : 'form-control'}
                            name="path"
                            onChange={this.onChangeInputFile} />
                          {pathError}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            Título:
                        </ControlLabel>
                          <FormControl
                            type="text"
                            className={titleError ? 'error' : ''}
                            value={title}
                            name="title"
                            onChange={this.onChangeInput}
                          />
                          {titleError}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            Link:
                        </ControlLabel>
                          <FormControl
                            type="text"
                            value={link}
                            name="link"
                            onChange={this.onChangeInput}
                          />
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            Descrição:
                        </ControlLabel>
                          <FormControl
                            type="text"
                            className={descriptionError ? 'error' : ''}
                            value={description}
                            name="description"
                            onChange={this.onChangeInput}
                          />
                          {descriptionError}
                        </FormGroup>
                      </div>
                    }
                    ftTextCenter
                    legend={
                      <div>
                        <Button type="submit" bsStyle="info" fill> Salvar </Button>
                        <Link to={"/aviso/"} className="btn-fill btn btn-neutral btn-back">Voltar</Link>
                      </div>
                    }
                  />
                </BlockUi>
              </form>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}