import React, { Component } from 'react';
import {
    Grid, Row, Col, Alert,
    FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import api from '../../services/api';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import logo from '../../assets/img/logo.png';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.onChangeEmail = this.onChangeEmail.bind(this);

        this.state = {
            cardHidden: true,
            email: "",

            error: "",
            emailError: "",
            success: "",

            blocking: false
        }
    }
    componentDidMount() {
        setTimeout(function () { this.setState({ cardHidden: false }); }.bind(this), 500);
    }
    onChangeEmail(e) {
        this.setState({ email: e.target.value });
        e.target.value === "" ? this.setState({ emailError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ emailError: null, error: null });
    }
    handleForgotPassword = async e => {
        e.preventDefault();

        this.setState({ blocking: true });

        const { email } = this.state;

        email === "" ? this.setState({ emailError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ emailError: null });

        if (!email) {
            this.setState({ error: "Preencha seu e-mail para continuar!", blocking: false });
        } else {
            try {
                const response = await api.post("/users/password/email", { email: email });
                
                if (response.status === 200) {
                    this.setState({
                        error: "",
                        success: "Enviado por e-mail.",
                        blocking: false
                    });
                } else if (response.status === 204) {
                    this.setState({
                        success: "",
                        error: "Desculpe, não há usuários com este endereço de e-mail.",
                        blocking: false
                    });
                }
            } catch (err) {
                this.setState({
                    error: "Houve um problema, entre em contato com nosso atendimento",
                    blocking: false
                });
            }
        }
    };
    render() {
        return (
            <Grid>
                <Row>
                    <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                        <BlockUi tag="div" blocking={this.state.blocking} message="Carregando, por favor aguarde">
                            <form onSubmit={this.handleForgotPassword}>
                                <Card
                                    hidden={this.state.cardHidden}
                                    textCenter
                                    content={
                                        <div>
                                            <img src={logo} className="auth-logo" alt="ClinicalCred" />
                                            {this.state.success && <Alert bsStyle="success">{this.state.success}</Alert>}
                                            {this.state.error && <Alert bsStyle="danger">{this.state.error}</Alert>}
                                            <FormGroup>
                                                <ControlLabel>
                                                    Email
                                                </ControlLabel>
                                                <FormControl
                                                    type="email"
                                                    className={this.state.emailError ? 'error' : ''}
                                                    value={this.state.email}
                                                    onChange={this.onChangeEmail}
                                                />
                                                {this.state.emailError}
                                            </FormGroup>
                                            <FormGroup className="text-center">
                                                <Button type="submit" bsStyle="info" fill wd>
                                                    Recuperar
                                                </Button>
                                                <p className="btn-back">
                                                    <Link to={"/auth/login"} className="btn-link">Voltar para o login</Link>
                                                </p>
                                            </FormGroup>
                                        </div>
                                    }
                                />
                            </form>
                        </BlockUi>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default ForgotPassword;
