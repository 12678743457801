import React, { Component } from 'react';
import { Collapse } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { getUser } from '../../services/auth';

const user = JSON.parse(getUser())

class HeaderLinksMobile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openUser: false
        }
    }

    render() {
        return (
            <ul className="nav nav-user-mobile">
                <li>
                    <a onClick={() => this.setState({ openUser: !this.state.openUser })} className={this.state.openUser ? 'open' : ''}>
                        <span>
                            {user ? user.admin == 1 ?
                                'ClinicalCred' :
                                user.company ?
                                    user.company.company_type === "PF" ? user.company.name : user.company.fantasy_name
                                : user.franchise ?
                                    user.franchise.fantasy_name : ''
                                : ''}
                            <span className="username">{user ? user.full_name : ''}</span>
                            <b className="caret"></b>
                        </span>
                    </a>
                    <Collapse in={this.state.openUser}>
                        <ul className="nav">
                            <li>
                                <NavLink to="/meus-dados" className="nav-link">
                                    <p>Meus Dados</p>
                                </NavLink>
                                <NavLink to="/alterar-senha" className="nav-link">
                                    <p>Alterar Senha</p>
                                </NavLink>
                                <NavLink to="/auth/lock" className="nav-link">
                                    <p>Bloquear</p>
                                </NavLink>
                                <NavLink to="/auth/login" className="nav-link">
                                    <p>Sair</p>
                                </NavLink>
                            </li>
                        </ul>
                    </Collapse>
                </li>
            </ul>
        );
    }
}
export default HeaderLinksMobile;
