import React, { Component } from 'react';
import {
  Grid, Row, Col, Alert, FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import api from '../../services/api';
import { getUser, getBlock } from '../../services/auth';

const user = JSON.parse(getUser())

export default class PasswordIndex extends Component {
  constructor(props) {
    super(props);
    
    this.onChangePasword = this.onChangePasword.bind(this);
    this.onChangeConfirmPasword = this.onChangeConfirmPasword.bind(this);
    
    this.onSubmitPass = this.onSubmitPass.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      password: '',
      confirmPasword: '',

      // validation 
      errorPass: '',
      passwordError: '',
      confirmPaswordError: '',

      // Loading
      blockingPass: false

    }
  }

  componentDidMount() {
  }

  /*
    Change Password
  */

  onChangePasword(e) {
    this.setState({ password: e.target.value });
    e.target.value === "" ? this.setState({ passwordError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ passwordError: null, error: null });
  }

  onChangeConfirmPasword(e) {
    this.setState({ confirmPasword: e.target.value });
    e.target.value === "" ? this.setState({ confirmPaswordError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ confirmPaswordError: null, error: null });
  }
  
  async onSubmitPass(e) {
    e.preventDefault();

    this.state.password === "" ? this.setState({ passwordError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ passwordError: null });
    this.state.confirmPasword === "" ? this.setState({ confirmPaswordError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ confirmPaswordError: null });

    if (this.state.password !== "" && this.state.confirmPasword !== "") {
      this.setState({ blockingPass: true })
      if (this.state.password === this.state.confirmPasword){
        const obj = {
          password: this.state.password
        };
        const resp = await api.put(`users/${user.id}/password/`, obj)
  
        if (resp.status === 200) {
  
          this.props.handleClick('tr', 'success', 'Senha alterada com sucesso!')

          this.setState({
            blockingPass: false
          })
  
        } else {
          this.setState({
            error: resp.data.message,
            blockingPass: false
          })
        }
      }else{
        this.setState({
          confirmPaswordError: (<small className="text-danger">Senha diferente.</small>),
          blockingPass: false
        })
      }
    }
  }

  render() {
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={this.state.modalLock} />
        <Grid fluid>
          <Row>
            <Col md={6}>
              <Breadcrumb active="Alterar senha" />
              <BlockUi tag="div" blocking={this.state.blockingPass} message="Carregando, por favor aguarde">
                <form onSubmit={this.onSubmitPass}>
                  <Card
                    title={'Alterar senha'}
                    content={
                      <div>
                        {this.state.error &&
                          <Alert bsStyle="danger">
                            {
                              this.state.error.map((e, i) => {
                                return (
                                  <li key={i}>{e.message}</li>
                                )
                              })
                            }
                          </Alert>
                        }
                        <FormGroup>
                          <ControlLabel>
                            Senha:
                          </ControlLabel>
                          <FormControl
                            type="password"
                            className={this.state.passwordError ? 'error' : ''}
                            value={this.state.password}
                            onChange={this.onChangePasword}
                          />
                          {this.state.passwordError}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            Confirme sua Senha:
                          </ControlLabel>
                          <FormControl
                            type="password"
                            className={this.state.confirmPaswordError ? 'error' : ''}
                            value={this.state.confirmPasword}
                            onChange={this.onChangeConfirmPasword}
                          />
                          {this.state.confirmPaswordError}
                        </FormGroup>
                      </div>
                    }
                    ftTextCenter
                    legend={
                      <div>
                        <Button type="submit" bsStyle="info" fill> Salvar </Button>
                      </div>
                    }
                  />
                </form>
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}