import { getPermissions } from './auth'

const permissions = getPermissions()

export const check = (permissions, action, data) => {
  
  if (permissions && permissions.includes(action)) {
    return true;
  }

  return false;
};

export const Can = props =>
  check(permissions, props.perform, props.data)
    ? props.yes(props.data)
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null
};
