import React, { Component } from 'react';
import {
    Grid, Row, Col, Alert,
    FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import api from '../../services/api';
import { login, refreshToken, removeAuth, setUser, setPermissions } from '../../services/auth';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import logo from '../../assets/img/logo.png';

class Login extends Component {
    constructor(props) {
        super(props);

        this.onChangeInput = this.onChangeInput.bind(this);

        this.state = {
            cardHidden: true,
            email: "",
            password: "",

            error: "",
            emailError: "",
            passwordError: "",

            blocking: false
        }
    }
    componentDidMount() {
        removeAuth()
        setTimeout(function () { this.setState({ cardHidden: false }); }.bind(this), 500);
    }

    onChangeInput(e) {
        this.setState({ [e.target.name]: e.target.value })
        e.target.value === "" ? this.setState({ [e.target.name + "Error"]: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ [e.target.name + "Error"]: null, error: null });
    }

    handleSignIn = async e => {
        e.preventDefault();

        // this.setState({ blocking: true });

        const { email, password } = this.state;
        
        email === "" ? this.setState({ emailError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ emailError: null });
        password === "" ? this.setState({ passwordError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ passwordError: null });

        if (!email && !password) {
            this.setState({ error: "Preencha e-mail e senha para continuar!", blocking: false });
        } else {
            try {
                const response = await api.post("/auth/sign-in", { email, password });

                if (response.status === 200) {

                    login(response.data.token);
                    refreshToken(response.data.refreshToken);

                    const user = await api.get("/users/me")
                    
                    if (user.data.user.innactivated_in === null){
                        if (user.data.user.admin === 0){
                            if(user.data.user.company){
                                if (user.data.user.company.innactivated_in === null){
                                    if(user.data.user.company.is_terms === 1){
                                        if(user.data.user.company.is_document === 1){
                                            if(user.data.user.is_active === 1){
        
                                                setUser(JSON.stringify(user.data.user))
        
                                                const resp = await api.get(`/users/${user.data.user.id}/permissions`);
        
                                                var objPermissions = []
        
                                                resp.data.map((obj, key) => {
                                                    objPermissions.push(obj.slug)
                                                })
        
                                                setPermissions(objPermissions)
        
                                                window.location.href = "/"
        
                                            }else{
                                                this.setState({
                                                    error: "Senha não cadastrada, verifique seu email de primeiro acesso",
                                                    blocking: false
                                                });
                                            }
                                        }else {
                                            setUser(JSON.stringify(user.data.user))
        
                                            window.location.href = "/cadastro-de-documento"
                                        }
                                    }else{
                                        setUser(JSON.stringify(user.data.user))
        
                                        window.location.href = "/cadastro-de-documento"
                                    }
                                }else{
                                    this.setState({
                                        error: "Empresa inativa",
                                        blocking: false
                                    });
                                }
                            } else if(user.data.user.franchise){
                                if (user.data.user.franchise.innactivated_in === null){
                                    if(user.data.user.franchise.is_terms === 1){
                                        if(user.data.user.franchise.is_document === 1){
                                            if(user.data.user.is_active === 1){

                                                setUser(JSON.stringify(user.data.user))

                                                const resp = await api.get(`/users/${user.data.user.id}/permissions`);

                                                var objPermissions = []

                                                resp.data.map((obj, key) => {
                                                    objPermissions.push(obj.slug)
                                                })

                                                setPermissions(objPermissions)

                                                window.location.href = "/"

                                            }else{
                                                this.setState({
                                                    error: "Senha não cadastrada, verifique seu email de primeiro acesso",
                                                    blocking: false
                                                });
                                            }
                                        }else {
                                            setUser(JSON.stringify(user.data.user))
            
                                            window.location.href = "/cadastro-de-documento-credenciador"
                                        }
                                    }else{
                                        setUser(JSON.stringify(user.data.user))

                                        window.location.href = "/cadastro-de-documento-credenciador"
                                    }
                                }else{
                                    this.setState({
                                        error: "Credenciadora inativa",
                                        blocking: false
                                    });
                                }
                            } 
                        }else {
                            if(user.data.user.is_active === 1){

                                setUser(JSON.stringify(user.data.user))

                                const resp = await api.get(`/users/${user.data.user.id}/permissions`);

                                var objPermissions = []

                                resp.data.map((obj, key) => {
                                    objPermissions.push(obj.slug)
                                })

                                setPermissions(objPermissions)

                                window.location.href = "/"

                            }else{
                                this.setState({
                                    error: "Senha não cadastrada, verifique seu email de primeiro acesso",
                                    blocking: false
                                });
                            }
                        }
                    }else{
                        this.setState({
                            error: "Usuário inativo",
                            blocking: false
                        });
                    }

                } else if (response.status === 204) {
                    this.setState({
                        error: "Login e senha inválidos",
                        blocking: false
                    });
                }
            } catch (err) {
                this.setState({
                    error: "Houve um problema com o login, verifique suas credenciais",
                    blocking: false
                });
            }
        }
    };
    render() {
        var {
            cardHidden,
            error,
            blocking,
            email,
            password,
            emailError,
            passwordError
        } = this.state
        return (
            <Grid>
                <Row>
                    <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                        <BlockUi tag="div" blocking={blocking}>
                            <form onSubmit={this.handleSignIn}>
                                <Card
                                    hidden={cardHidden}
                                    textCenter
                                    content={
                                        <div>
                                            <img src={logo} className="auth-logo" alt="ClinicalCred" />
                                            {error && <Alert bsStyle="danger">{error}</Alert>}
                                            <FormGroup>
                                                <ControlLabel>
                                                    Email
                                                </ControlLabel>
                                                <FormControl
                                                    type="email"
                                                    className={emailError ? 'error' : ''}
                                                    value={email}
                                                    name="email"
                                                    onChange={this.onChangeInput}
                                                />
                                                {emailError}
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>
                                                    Senha
                                                </ControlLabel>
                                                <FormControl
                                                    type="password"
                                                    className={passwordError ? 'error' : ''}
                                                    value={password}
                                                    name="password"
                                                    onChange={this.onChangeInput}
                                                />
                                                {passwordError}
                                            </FormGroup>
                                            <FormGroup className="text-center">
                                                <Button type="submit" bsStyle="info" fill wd>
                                                    Entrar
                                                </Button>
                                                <p className="btn-forgot-password">
                                                    Esqueceu sua senha? <Link to={"/auth/esqueci-senha"} className="btn-link">clique aqui</Link>
                                                </p>
                                            </FormGroup>
                                        </div>
                                    }
                                />
                            </form>
                        </BlockUi>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default Login;
