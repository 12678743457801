import { Grid, Row, Col } from 'react-bootstrap'
import React, { Component } from 'react'

import { check } from "../../services/Can"
import { getPermissions, getBlock } from '../../services/auth'

import CounterDown from '../../components/CounterDown/CounterDown.jsx'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import FormNewContraction from '../Contraction/components/FormNewContraction'

export default class ContratcionCreate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalLock: getBlock() ? true : false,
      id: this.props.match.params.id ? this.props.match.params.id : null,
    }

  }

  async componentDidMount() {
    if (check(getPermissions(), 'operation') === false) {
      this.props.history.push("/nao-autorizado")
    }
  }

  render() {
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={this.state.modalLock} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Breadcrumb active="Aprovação de crédito" />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={10} sm={12}>
              <FormNewContraction></FormNewContraction>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}