import React, { Component } from 'react';
import { Grid, Row, Col, Carousel } from 'react-bootstrap';

import api from '../../services/api';
import { getUser, getBlock } from '../../services/auth';
import { API_URL } from '../../config/constants';

import Card from '../../components/Card/Card.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';

export default class Intro extends Component {

  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      notices: [],
      index: 0,
      direction: null,
    };
  }

  componentDidMount() {
    this.loadNotices()
  }

  async loadNotices() {
    const response = await api.get('notices')
    this.setState({
      notices: response.data.data
    });
  }

  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  }

  renderData() {
    const { index, direction, notices } = this.state;
    var row = [];
    for (var i = 0; i < notices.length; i++) {
      row.push(
        <Carousel.Item key={notices[i].id}>
          <a href={notices[i].link ? notices[i].link : '#'}>
            <img
              className="d-block w-100"
              src={`${API_URL}/notice/imagem/${notices[i].path}?text=${notices[i].title}`}
              alt={notices[i].title}
            />
            <div>
              <h4>{notices[i].title}</h4>
              <p>{notices[i].description}</p>
            </div>
          </a>
        </Carousel.Item>
      );
    }
    return (
      <Carousel
        activeIndex={index}
        direction={direction}
        onSelect={this.handleSelect}
        indicators={false}
        className="notice-intro"
      >
        {row}
      </Carousel>
    );
  }
  render() {

    return (
      <div className="main-content">
        <CounterDown val={1800} modal={this.state.modalLock} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <h4 style={{ marginTop: '0px' }}>Seja Bem-vindo</h4>
              <Row>
                <Col xs={12}>
                  <Card
                    content={
                      this.renderData()
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}