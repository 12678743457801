import React, { Component } from 'react';
import { Grid, Row, Col, Alert, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { animateScroll as scroll } from 'react-scroll';
import cpf_cnpj from "cpf_cnpj";
import axios from "axios";

import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import Radio from '../../components/CustomRadio/CustomRadio.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import api from '../../services/api';

export default class CompanyCreate extends Component {
  constructor(props) {
    super(props);

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onBlurAddressZipcode = this.onBlurAddressZipcode.bind(this);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      user_auth: {},
      modalLock: getBlock() ? true : false,
      franchises: [],
      franchise_id: '',
      name: '',
      establishments: [],
      establishment: '',
      banks: [],
      states: [],
      company_type: 'PF',
      cpf: '',
      rg: '',
      date_of_birth: '',
      email: '',
      phone: '',
      cellphone: '',
      site: '',
      regional_council_number_pf: '',
      responsible_admin_name: '',
      responsible_admin_cpf: '',
      responsible_admin_phone: '',
      responsible_admin_email: '',
      cnpj: '',
      social_name: '',
      fantasy_name: '',
      regional_council_number_pj: '',
      bank_code: null,
      bank_holder: '',
      bank_cpf_cnpj: '',
      bank_ag: '',
      bank_cc: '',
      user_full_name: '', 
      user_usertypes: '', 
      user_cnpj: '', 
      user_cpf: '', 
      user_email: '', 
      state_id: '',
      zipcode: '',
      address: '',
      number: '',
      neighborhood: '',
      complement: '',
      city: '',

      // validation
      error: '',
      franchise_idError: '',
      nameError: '',
      establishmentError: '',
      company_typeError: '',
      cpfError: '',
      rgError: '',
      date_of_birthError: '',
      emailError: '',
      phoneError: '',
      cellphoneError: '',
      siteError: '',
      regional_council_number_pfError: '',
      responsible_admin_nameError: '',
      responsible_admin_cpfError: '',
      responsible_admin_phoneError: '',
      responsible_admin_emailError: '',
      cnpjError: '',
      social_nameError: '',
      fantasy_nameError: '',
      regional_council_number_pjError: '',
      bank_codeError: '',
      bank_holderError: '',
      bank_cpf_cnpjError: '',
      bank_agError: '',
      bank_ccError: '',
      user_full_nameError: '', 
      user_usertypesError: '', 
      user_cnpjError: '', 
      user_cpfError: '', 
      user_emailError: '', 
      
      state_idError: '',
      zipcodeError: '',
      addressError: '',
      numberError: '',
      neighborhoodError: '',
      cityError: '',

      // Loading
      blocking: false,
    }

  }

  componentDidMount() {
    if (check(getPermissions(), 'user_add') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.userAuth()
    this.loadEstablishment()
    this.loadFranchises()
    this.loadBanks()
    this.loadStates()
  }

  async userAuth(){
    const resp = await api.get('users/me')
    this.setState({
      user_auth: resp.data.user,
      franchise_id: resp.data.user.franchise_id
    })
  }

  onChangeInput(e){
    this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    e.target.value === "" ? this.setState({ [e.target.name + "Error"]: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ [e.target.name + "Error"]: null, error: null });
  }

  async loadFranchises(){
    const resp = await api.get('franchises?perPage=1000')
    this.setState({
      franchises: resp.data.data
    });
  }
  
  async loadEstablishment(){
    const resp = await api.get('establishments')
    this.setState({
      establishments: resp.data
    });
  }
  
  async loadBanks(){
    const resp = await api.get('banks')
    this.setState({
      banks: resp.data
    });
  }
  
  async loadStates(){
    const resp = await api.get('states')
    this.setState({
      states: resp.data
    });
  }

  async onBlurAddressZipcode(e) {
    // this.setState({ blocking: true })
    var cep = e.target.value.replace('-', '')
    cep = parseInt(cep)
    if (cep.toString().length === 8) {
      var respAddress = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      if (respAddress.data.erro) {
        this.setState({
          Address: '',
          Neighborhood: '',
          City: '',
          State_id: '',
          // blocking: false
        })
      } else {
        var respState = await api.get(`states/${respAddress.data.uf}`)
        this.setState({
          address: respAddress.data.logradouro.toUpperCase(),
          neighborhood: respAddress.data.bairro.toUpperCase(),
          city: respAddress.data.localidade.toUpperCase(),
          state_id: respState.data.state[0].id,
          addressError: null,
          neighborhoodError: null,
          cityError: null,
          state_idError: null,
          // blocking: false
        })
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();

    var {
      franchise_id, name, establishment, company_type, cpf, rg, date_of_birth,
      regional_council_number_pf, email, phone, cellphone, site, responsible_admin_name,
      responsible_admin_cpf, responsible_admin_phone, responsible_admin_email,
      cnpj, social_name, fantasy_name, regional_council_number_pj, bank_code,
      bank_holder, bank_cpf_cnpj, bank_ag, bank_cc, user_full_name, user_usertypes,
      user_cnpj, user_cpf, user_email, state_id, zipcode, address, number, neighborhood, complement, city
    } = this.state

    franchise_id === "" ? this.setState({ franchise_idError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ franchise_idError: null });
    name === "" ? this.setState({ nameError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ nameError: null });
    establishment === "" ? this.setState({ establishmentError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ establishmentError: null });
    company_type === "" ? this.setState({ company_typeError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ company_typeError: null });
    cpf === "" && company_type === "PF" ? this.setState({ cpfError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ cpfError: null });
    rg === "" && company_type === "PF" ? this.setState({ rgError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ rgError: null });
    date_of_birth === "" && company_type === "PF" ? this.setState({ date_of_birthError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ date_of_birthError: null });
    regional_council_number_pf === "" && company_type === "PF" ? this.setState({ regional_council_number_pfError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ regional_council_number_pfError: null });
    email === "" ? this.setState({ emailError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ emailError: null });
    phone === "" ? this.setState({ phoneError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ phoneError: null });
    cellphone === "" ? this.setState({ cellphoneError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ cellphoneError: null });
    site === "" ? this.setState({ siteError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ siteError: null });
    responsible_admin_name === "" ? this.setState({ responsible_admin_nameError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ responsible_admin_nameError: null });
    responsible_admin_cpf === "" ? this.setState({ responsible_admin_cpfError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ responsible_admin_cpfError: null });
    responsible_admin_phone === "" ? this.setState({ responsible_admin_phoneError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ responsible_admin_phoneError: null });
    responsible_admin_email === "" ? this.setState({ responsible_admin_emailError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ responsible_admin_emailError: null });
    cnpj === "" && company_type === "PJ" ? this.setState({ cnpjError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ cnpjError: null });
    social_name === "" && company_type === "PJ" ? this.setState({ social_nameError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ social_nameError: null });
    fantasy_name === "" && company_type === "PJ" ? this.setState({ fantasy_nameError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ fantasy_nameError: null });
    regional_council_number_pj === "" && company_type === "PJ" ? this.setState({ regional_council_number_pjError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ regional_council_number_pjError: null });
    bank_code === "" ? this.setState({ bank_codeError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ bank_codeError: null });
    bank_holder === "" ? this.setState({ bank_holderError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ bank_holderError: null });
    bank_cpf_cnpj === "" ? this.setState({ bank_cpf_cnpjError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ bank_cpf_cnpjError: null });
    bank_ag === "" ? this.setState({ bank_agError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ bank_agError: null });
    bank_cc === "" ? this.setState({ bank_ccError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ bank_ccError: null });
    user_full_name === "" ? this.setState({ user_full_nameError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ user_full_nameError: null });
    user_usertypes === "" ? this.setState({ user_usertypesError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ user_usertypesError: null });
    user_cnpj === "" && user_usertypes === "PF" ? this.setState({ user_cnpjError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ user_cnpjError: null });
    user_cpf === "" && user_usertypes === "PJ" ? this.setState({ user_cpfError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ user_cpfError: null });
    user_email === "" ? this.setState({ user_emailError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ user_emailError: null });
    state_id === "" ? this.setState({ state_idError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ state_idError: null });
    zipcode === "" ? this.setState({ zipcodeError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ zipcodeError: null });
    address === "" ? this.setState({ addressError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressError: null });
    number === "" ? this.setState({ numberError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ numberError: null });
    neighborhood === "" ? this.setState({ neighborhoodError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ neighborhoodError: null });
    city === "" ? this.setState({ cityError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ cityError: null });

    if (
      (
        company_type === "PF" &&
        name !== "" &&
        establishment !== "" &&
        company_type !== "" &&
        cpf !== "" &&
        rg !== "" &&
        date_of_birth !== "" &&
        regional_council_number_pf !== ""
      ) ||
      (
        company_type === "PJ" &&
        cnpj !== "" &&
        social_name !== "" &&
        fantasy_name !== "" &&
        regional_council_number_pj !== ""
      ) &&
      franchise_id !== "" &&
      email !== "" &&
      phone !== "" &&
      cellphone !== "" &&
      site !== "" &&
      responsible_admin_name !== "" &&
      responsible_admin_cpf !== "" &&
      responsible_admin_phone !== "" &&
      responsible_admin_email !== "" &&
      bank_code !== "" &&
      bank_holder !== "" &&
      bank_cpf_cnpj !== "" &&
      bank_ag !== "" &&
      bank_cc !== "" &&
      state_id !== "" &&
      zipcode !== "" &&
      address !== "" &&
      number !== "" &&
      neighborhood !== "" &&
      city !== "" &&
      user_full_name !== "" && 
      user_usertypes !== "" && 
      (
        (user_usertypes === "PF" && user_cpf !== "") ||
        (user_usertypes === "PJ" && user_cnpj !== "")
      ) &&
      user_email !== ""
    ) {

      this.setState({ blocking: true })

      var cnpjValidate = cpf_cnpj.CNPJ
      var cpfValidate = cpf_cnpj.CPF

      var _phone = phone.replace('(','')
      _phone = _phone.replace(')','')
      _phone = _phone.replace(' ','')
      _phone = _phone.replace('-','')

      var _cellphone = cellphone.replace('(','')
      _cellphone = _cellphone.replace(')','')
      _cellphone = _cellphone.replace(' ','')
      _cellphone = _cellphone.replace('-','')
      
      var _responsible_admin_phone = responsible_admin_phone.replace('(','')
      _responsible_admin_phone = _responsible_admin_phone.replace(')','')
      _responsible_admin_phone = _responsible_admin_phone.replace(' ','')
      _responsible_admin_phone = _responsible_admin_phone.replace('-','')
      
      var _date_of_birth = date_of_birth.replace('_','')
      _date_of_birth = _date_of_birth.replace('/','')
      _date_of_birth = _date_of_birth.replace('/','')

      var _zipcode = zipcode.replace('-','')

      const obj = {
        franchise_id: franchise_id,
        establishment: establishment,
        company_type: company_type,
        email: email,
        phone: _phone,
        cellphone: _cellphone,
        site: site,
        regional_council_number_pf: company_type === "PF" ? regional_council_number_pf : '',
        name: company_type === "PF" ? name : '',
        cpf: company_type === "PF" ? cpfValidate.strip(cpf) : '',
        rg: company_type === "PF" ? rg : '',
        date_of_birth: company_type === "PF" ? _date_of_birth : '',
        cnpj: company_type === "PJ" ? cnpjValidate.strip(cnpj) : '',
        social_name: company_type === "PJ" ? social_name : '',
        fantasy_name: company_type === "PJ" ? fantasy_name : '',
        regional_council_number_pj: company_type === "PJ" ? regional_council_number_pj : '',

        responsible_admin_name: responsible_admin_name,
        responsible_admin_cpf: cpfValidate.strip(responsible_admin_cpf),
        responsible_admin_phone: _responsible_admin_phone,
        responsible_admin_email: responsible_admin_email,

        bank_code: bank_code,
        bank_holder: bank_holder,
        bank_cpf_cnpj: bank_cpf_cnpj.length == 14 ? cpfValidate.strip(bank_cpf_cnpj) : cnpjValidate.strip(bank_cpf_cnpj),
        bank_ag: bank_ag,
        bank_cc: bank_cc,

        state_id: state_id,
        zipcode: _zipcode,
        address: address,
        number: number,
        neighborhood: neighborhood,
        complement: complement,
        city: city,

        user_full_name: user_full_name,
        user_usertypes: user_usertypes,
        user_cnpj: cnpjValidate.strip(user_cnpj),
        user_cpf: cpfValidate.strip(user_cpf),
        user_email: user_email,
      };

      const resp = await api.post('companies', obj)

      if (resp.status === 200) {

        this.props.handleClick('tr', 'success', 'Cadastrado com sucesso!')

        this.props.history.push("/empresa");

      } else {
        scroll.scrollToTop();
        this.setState({
          error: resp.data,
          blocking: false
        })
      }
    }else{
      scroll.scrollToTop();
    }
  }

  render() {
    var {
      modalLock, blocking, error, user_auth, franchise_id, franchises,
      franchise_idError, establishment, establishments, establishmentError,
      company_typeError, company_type, name, nameError, cpf, cpfError, rg, rgError,
      date_of_birth, date_of_birthError, email, emailError, phone, phoneError,
      cellphone, cellphoneError, site, siteError, regional_council_number_pf,
      regional_council_number_pfError, cnpj, cnpjError, social_name, social_nameError,
      fantasy_name, fantasy_nameError, email, emailError, phone, phoneError,
      cellphone, cellphoneError, regional_council_number_pj, regional_council_number_pjError,
      responsible_admin_name, responsible_admin_nameError, responsible_admin_cpf,
      responsible_admin_cpfError, responsible_admin_phone, responsible_admin_phoneError,
      responsible_admin_email, responsible_admin_emailError, user_full_name, user_full_nameError, 
      user_cnpj, user_cnpjError, user_usertypes, user_cpf, user_cpfError, user_email, 
      user_emailError, banks, bank_code, bank_codeError, bank_holder, bank_holderError, 
      bank_cpf_cnpj, bank_cpf_cnpjError, bank_ag, bank_agError, bank_cc, bank_ccError,
      zipcode, zipcodeError, address, addressError, number, numberError, neighborhood,
      neighborhoodError, complement, city, cityError, state_id, state_idError, states
    } = this.state
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={modalLock} />
        <Grid fluid>
          <Row>
            <Col md={6}>
              <Breadcrumb routes={[{ label: 'Empresas', link: '/empresa' }]} active="Nova empresa" />
              <BlockUi tag="div" blocking={blocking} message="Carregando, por favor aguarde">
                <form onSubmit={this.onSubmit}>
                  <Card
                    content={
                      <div>
                        {error &&
                          <Alert bsStyle="danger">
                            {
                              error.map((e, i) => {
                                return (
                                  <li key={i}>{e.message}</li>
                                )
                              })
                            }
                          </Alert>
                        }
                        {user_auth.admin === 1 ?
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}><ControlLabel>Credenciador:</ControlLabel></Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <select
                                      className={franchise_idError ? 'error form-control' : 'form-control'}
                                      value={franchise_id}
                                      name="franchise_id"
                                      onChange={this.onChangeInput}
                                    >
                                      <option value="">Selecione</option>
                                      <option value="0">NENHUM CREDENCIADOR</option>
                                      {
                                        franchises &&
                                          franchises.map((e, i) => {
                                            return (
                                              <option value={e.id} key={i}>{e.fantasy_name}</option>
                                            )
                                          })
                                      }
                                    </select>
                                  </Col>
                                </Row>
                                {franchise_idError}
                              </FormGroup>
                            </Col>
                          </Row>
                        : null }
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}><ControlLabel>Tipo de estabelecimento:</ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <select
                                    className={establishmentError ? 'error form-control' : 'form-control'}
                                    value={establishment}
                                    name="establishment"
                                    onChange={this.onChangeInput}
                                  >
                                    <option value="">Selecione</option>
                                    {
                                      establishments &&
                                        establishments.map((e, i) => {
                                          return (
                                            <option value={e.id} key={i}>{e.name}</option>
                                          )
                                        })
                                    }
                                  </select>
                                </Col>
                              </Row>
                              {establishmentError}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}><ControlLabel>Tipo do Canal:</ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <Radio
                                    number="company_type_PF"
                                    option="PF"
                                    name="company_type"
                                    onChange={this.onChangeInput}
                                    checked={company_type === "PF"}
                                    label="Física"
                                  />
                                  <Radio
                                    number="company_type_PJ"
                                    option="PJ"
                                    name="company_type"
                                    onChange={this.onChangeInput}
                                    checked={company_type === "PJ"}
                                    label="Jurídica"
                                  />
                                  {company_typeError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        {company_type === "PF" &&
                          <div>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> Nome: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <FormControl
                                      type="text"
                                      className={nameError ? 'error' : ''}
                                      name="name"
                                      value={name ? name : ''}
                                      onChange={this.onChangeInput}
                                    />
                                    {nameError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={4}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> CPF: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <InputMask
                                      mask={'999.999.999-99'}
                                      className={cpfError ? 'error form-control' : 'form-control'}
                                      value={cpf ? cpf : ''}
                                      name="cpf"
                                      onChange={this.onChangeInput}
                                    />
                                    {cpfError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={4}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> RG: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <FormControl
                                      type="text"
                                      className={rgError ? 'error' : ''}
                                      name="rg"
                                      value={rg ? rg : ''}
                                      onChange={this.onChangeInput}
                                    />
                                    {rgError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={4}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> Data de nascimento: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <InputMask
                                      mask="99/99/9999"
                                      maskChar='_'
                                      className={date_of_birthError ? 'error form-control' : 'form-control'}
                                      value={date_of_birth ? date_of_birth : ''}
                                      name="date_of_birth"
                                      onChange={this.onChangeInput}
                                    />
                                    {date_of_birthError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> E-mail: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <FormControl
                                      type="text"
                                      className={emailError ? 'error' : ''}
                                      name="email"
                                      value={email ? email : ''}
                                      onChange={this.onChangeInput}
                                    />
                                    {emailError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> Telefone: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <InputMask
                                      mask="(99) 99999-9999"
                                      maskChar=''
                                      className={phoneError ? 'error form-control' : 'form-control'}
                                      value={phone ? phone : ''}
                                      name="phone"
                                      onChange={this.onChangeInput}
                                    />
                                    {phoneError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col xs={6}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> Celular: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <InputMask
                                      mask="(99) 99999-9999"
                                      maskChar=''
                                      className={cellphoneError ? 'error form-control' : 'form-control'}
                                      value={cellphone ? cellphone : ''}
                                      name="cellphone"
                                      onChange={this.onChangeInput}
                                    />
                                    {cellphoneError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> Site: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <FormControl
                                      type="text"
                                      className={siteError ? 'error' : ''}
                                      name="site"
                                      value={site ? site : ''}
                                      onChange={this.onChangeInput}
                                    />
                                    {siteError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> Nº Conselho Regional: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <FormControl
                                      type="text"
                                      className={regional_council_number_pfError ? 'error' : ''}
                                      name="regional_council_number_pf"
                                      value={regional_council_number_pf ? regional_council_number_pf : ''}
                                      onChange={this.onChangeInput}
                                    />
                                    {regional_council_number_pfError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          </div>
                        }
                        {company_type === "PJ" &&
                          <div>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}><ControlLabel> CNPJ: </ControlLabel></Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <InputMask
                                      mask={'99.999.999/9999-99'}
                                      maskChar=''
                                      className={cnpjError ? 'error form-control' : 'form-control'}
                                      value={cnpj ? cnpj : ''}
                                      name="cnpj"
                                      onChange={this.onChangeInput}
                                    />
                                    {cnpjError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> Razão social: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <FormControl
                                      type="text"
                                      className={social_nameError ? 'error' : ''}
                                      name="social_name"
                                      value={social_name ? social_name : ''}
                                      onChange={this.onChangeInput}
                                    />
                                    {social_nameError}
                                </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> Nome Fantasia: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <FormControl
                                      type="text"
                                      className={fantasy_nameError ? 'error' : ''}
                                      name="fantasy_name"
                                      value={fantasy_name ? fantasy_name : ''}
                                      onChange={this.onChangeInput}
                                    />
                                    {fantasy_nameError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> E-mail: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <FormControl
                                      type="text"
                                      className={emailError ? 'error' : ''}
                                      name="email"
                                      value={email ? email : ''}
                                      onChange={this.onChangeInput}
                                    />
                                    {emailError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> Telefone: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <InputMask
                                      mask="(99) 99999-9999"
                                      maskChar=''
                                      className={phoneError ? 'error form-control' : 'form-control'}
                                      value={phone ? phone : ''}
                                      name="phone"
                                      onChange={this.onChangeInput}
                                    />
                                    {phoneError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col xs={6}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> Celular: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <InputMask
                                      mask="(99) 99999-9999"
                                      maskChar=''
                                      className={cellphoneError ? 'error form-control' : 'form-control'}
                                      value={cellphone ? cellphone : ''}
                                      name="cellphone"
                                      onChange={this.onChangeInput}
                                    />
                                    {cellphoneError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> Site: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <FormControl
                                      type="text"
                                      className={siteError ? 'error' : ''}
                                      name="site"
                                      value={site ? site : ''}
                                      onChange={this.onChangeInput}
                                    />
                                    {siteError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> Nº Conselho Regional: </ControlLabel> </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <FormControl
                                      type="text"
                                      className={regional_council_number_pjError ? 'error' : ''}
                                      name="regional_council_number_pj"
                                      value={regional_council_number_pj ? regional_council_number_pj : ''}
                                      onChange={this.onChangeInput}
                                    />
                                    {regional_council_number_pjError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          </div>
                        }
                        <h4 className="mt-10">Endereço:</h4>
                        <Row>
                          <Col xs={3}>
                            <FormGroup>
                              <ControlLabel>CEP:</ControlLabel>
                              <InputMask
                                mask="99999-999"
                                maskChar='_'
                                className={zipcodeError ? 'error form-control' : 'form-control'}
                                value={zipcode ? zipcode : ''}
                                name="zipcode"
                                onChange={this.onChangeInput}
                                onBlur={this.onBlurAddressZipcode}
                              />
                              {zipcodeError}
                            </FormGroup>
                          </Col>
                          <Col xs={9}>
                            <FormGroup>
                              <ControlLabel>Endereço:</ControlLabel>
                              <FormControl
                                type="text"
                                className={addressError ? 'error' : ''}
                                value={address ? address : ''}
                                name="address"
                                onChange={this.onChangeInput}
                              />
                              {addressError}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <FormGroup>
                              <ControlLabel>Bairro:</ControlLabel>
                              <FormControl
                                type="text"
                                className={neighborhoodError ? 'error' : ''}
                                value={neighborhood ? neighborhood : ''}
                                name="neighborhood"
                                onChange={this.onChangeInput}
                              />
                              {neighborhoodError}
                            </FormGroup></Col>
                          <Col xs={3}>
                            <FormGroup>
                              <ControlLabel>Número:</ControlLabel>
                              <FormControl
                                type="text"
                                className={numberError ? 'error' : ''}
                                value={number ? number : ''}
                                name="number"
                                onChange={this.onChangeInput}
                              />
                              {numberError}
                            </FormGroup>
                          </Col>
                          <Col xs={3}>
                            <FormGroup>
                              <ControlLabel>Complemento:</ControlLabel>
                              <FormControl
                                type="text"
                                value={complement ? complement : ''}
                                name="complement"
                                onChange={this.onChangeInput}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <FormGroup>
                              <ControlLabel>Cidade:</ControlLabel>
                              <FormControl
                                type="text"
                                className={cityError ? 'error' : ''}
                                value={city ? city : ''}
                                name="city"
                                onChange={this.onChangeInput}
                              />
                              {cityError}
                            </FormGroup>
                          </Col>
                          <Col xs={6}>
                            <FormGroup>
                              <ControlLabel>Estado:</ControlLabel>
                              <select
                                className={state_idError ? 'form-control error' : 'form-control'}
                                value={state_id ? state_id : ''}
                                name="state_id"
                                onChange={this.onChangeInput}>
                                <option value="">Selecione</option>
                                {
                                  states.map(element => {
                                    return (
                                      <option key={element.id} value={element.id}>{element.name}</option>
                                    )
                                  })
                                }
                              </select>
                              {state_idError}
                            </FormGroup>
                          </Col>
                        </Row>
                        <h4 className="mt-10">Responsável Administrativo:</h4>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Nome: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={responsible_admin_nameError ? 'error' : ''}
                                    name="responsible_admin_name"
                                    value={responsible_admin_name ? responsible_admin_name : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {responsible_admin_nameError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> CPF: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <InputMask
                                    mask={'999.999.999-99'}
                                    className={responsible_admin_cpfError ? 'error form-control' : 'form-control'}
                                    value={responsible_admin_cpf ? responsible_admin_cpf : ''}
                                    name="responsible_admin_cpf"
                                    onChange={this.onChangeInput}
                                  />
                                  {responsible_admin_cpfError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col xs={12} md={6}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Telefone: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <InputMask
                                    mask="(99) 99999-9999"
                                    maskChar=''
                                    className={responsible_admin_phoneError ? 'error form-control' : 'form-control'}
                                    value={responsible_admin_phone ? responsible_admin_phone : ''}
                                    name="responsible_admin_phone"
                                    onChange={this.onChangeInput}
                                  />
                                  {responsible_admin_phoneError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> E-mail: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={responsible_admin_emailError ? 'error' : ''}
                                    name="responsible_admin_email"
                                    value={responsible_admin_email ? responsible_admin_email : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {responsible_admin_emailError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <h4 className="mt-10">Dados do usuário de acesso</h4>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Nome: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={user_full_nameError ? 'error' : ''}
                                    name="user_full_name"
                                    value={user_full_name ? user_full_name : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {user_full_nameError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Tipo de pessoa: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <Radio
                                    number="user_usertypes_PF"
                                    option="PF"
                                    name="user_usertypes"
                                    onChange={this.onChangeInput}
                                    checked={user_usertypes === "PF"}
                                    label="Física"
                                  />
                                  <Radio
                                    number="user_usertypes_PJ"
                                    option="PJ"
                                    name="user_usertypes"
                                    onChange={this.onChangeInput}
                                    checked={user_usertypes === "PJ"}
                                    label="Jurídica"
                                    className="mb-0"
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          {user_usertypes === "PJ" ?
                            <Col xs={12} md={6}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}> <ControlLabel> CNPJ: </ControlLabel></Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <InputMask
                                      mask={'99.999.999/9999-99'}
                                      className={user_cnpjError ? 'error form-control' : 'form-control'}
                                      value={user_cnpj ? user_cnpj : ''}
                                      name="user_cnpj"
                                      onChange={this.onChangeInput}
                                    />
                                    {user_cnpjError}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            : null}
                            {user_usertypes === "PF" ?
                              <Col xs={12} md={6}>
                                <FormGroup>
                                  <Row>
                                    <Col xs={12}> <ControlLabel> CPF: </ControlLabel></Col>
                                  </Row>
                                  <Row>
                                    <Col xs={12}>
                                      <InputMask
                                        mask={'999.999.999-99'}
                                        className={user_cpfError ? 'error form-control' : 'form-control'}
                                        value={user_cpf ? user_cpf : ''}
                                        name="user_cpf"
                                        onChange={this.onChangeInput}
                                      />
                                      {user_cpfError}
                                    </Col>
                                  </Row>
                                </FormGroup>
                              </Col>
                            : null}
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> E-mail: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={user_emailError ? 'error' : ''}
                                    name="user_email"
                                    value={user_email ? user_email : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {user_emailError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <h4 className="mt-10">Dados bancários:</h4>
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Banco: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <select
                                    className={bank_codeError ? 'error form-control' : 'form-control'}
                                    value={bank_code}
                                    name="bank_code"
                                    onChange={this.onChangeInput}
                                  >
                                    <option value="">Selecione o banco</option>
                                    {
                                      banks &&
                                        banks.map((e, i) => {
                                          return (
                                            <option value={e.value} key={i}>{e.label} - {e.value}</option>
                                          )
                                        })
                                    }
                                  </select>
                                  {bank_codeError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={8}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Titular: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={bank_holderError ? 'error' : ''}
                                    name="bank_holder"
                                    value={bank_holder ? bank_holder : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {bank_holderError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col xs={12} md={4}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> CPF/CNPJ: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <InputMask
                                    mask={bank_cpf_cnpj.length <= 14 ? '999.999.999-99????' : '99.999.999/9999-99'}
                                    formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                                    maskChar=''
                                    className={bank_cpf_cnpjError ? 'error form-control' : 'form-control'}
                                    value={bank_cpf_cnpj ? bank_cpf_cnpj : ''}
                                    name="bank_cpf_cnpj"
                                    onChange={this.onChangeInput}
                                  />
                                  {bank_cpf_cnpjError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Agência: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={bank_agError ? 'error' : ''}
                                    name="bank_ag"
                                    value={bank_ag ? bank_ag : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {bank_agError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col xs={12} md={6}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}> <ControlLabel> Conta corrente: </ControlLabel></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <FormControl
                                    type="text"
                                    className={bank_ccError ? 'error' : ''}
                                    name="bank_cc"
                                    value={bank_cc ? bank_cc : ''}
                                    onChange={this.onChangeInput}
                                  />
                                  {bank_ccError}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    }
                    ftTextCenter
                    legend={
                      <div>
                        <Button type="submit" bsStyle="info" fill> Salvar </Button>
                        <Link to={"/empresa/"} className="btn-fill btn btn-neutral btn-back">Voltar</Link>
                      </div>
                    }
                  />
                </form>
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}