import React, { Component } from 'react';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import SweetAlert from 'react-bootstrap-sweetalert';
import { Grid, Row, Col, Modal, ControlLabel, FormGroup, FormControl } from 'react-bootstrap';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import cpf_cnpj from "cpf_cnpj";

import { Can, check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import Card from '../../components/Card/Card.jsx';

export default class CustomerIndex extends Component {

  constructor(props) {
    super(props);

    this.handleModalCustomerClose = this.handleModalCustomerClose.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.successDelete = this.successDelete.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      user_auth: {},
      customer: [],
      modalVisible: false,

      // Confirm
      alert: null,
      show: false,

      // Table
      customers: [],
      search: '',
      activePage: 10,
      total: '',
      perPage: '',
      lastPage: '',
      page: 1,

      blocking: true
    };
  }

  componentDidMount() {
    if (check(getPermissions(), 'customer') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadCustomers()
    this.userAuth()
  }

  async userAuth() {
    const resp = await api.get('users/me')
    this.setState({ user_auth: resp.data.user })
  }

  async handlePageChange(pageNumber) {
    this.setState({ blocking: true })
    const response = await api.get('customers?page=' + pageNumber)
    this.setState({
      customers: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: pageNumber,
      lastPage: response.data.lastPage,
      blocking: false
    });
  }

  async loadCustomers() {
    this.setState({ blocking: true })
    const response = await api.get('customers')
    this.setState({
      customers: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: response.data.page,
      lastPage: response.data.lastPage,
      blocking: false
    });
  }

  warningWithConfirmMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.successDelete(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim, exclua!"
          cancelBtnText="Cancelar"
          showCancel
        >
        </SweetAlert>
      )
    });
  }

  async successDelete(id) {
    const resp = await api.delete('customers/' + id)
		if (resp.status === 200) {
      this.loadCustomers()
      this.hideAlert()
      this.props.handleClick('tr', 'success', 'Excluído com sucesso!')
    } else if (resp.status === 203) {
      this.hideAlert()
      this.props.handleClick('tr', 'error', resp.data.message)
    }
  }
  hideAlert() {
    this.setState({ alert: null });
  }

  async onChangeSearch(e) {
    var search = e.target.value
    this.setState({ search: search });
    const response = await api.get('customers?search=' + search)
    this.setState({
      customers: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: response.data.page,
      lastPage: response.data.lastPage
    });
  }

  async handleModalCustomerShow(id) {
    const resp = await api.get('customers/' + id)

    var customer = resp.data.customer[0]
    var data_birth = customer.date_of_birth.split('-')
    var CPF = cpf_cnpj.CPF
    var CNPJ = cpf_cnpj.CNPJ
    customer.date_of_birth = `${data_birth[2]}/${data_birth[1]}/${data_birth[0]}`
    customer.cpf = CPF.format(customer.cpf)
    customer.cnpj = CNPJ.format(customer.cnpj)
    customer.customer_address = customer.customer_address ? customer.customer_address : null
    this.setState({
      customer: customer,
      modalVisible: true
    });
  }

  handleModalCustomerClose() {
    this.setState({ modalVisible: false, integrator: [] });
  }


  renderView() {
    return (
      <Modal show={this.state.modalVisible} onHide={this.handleModalCustomerClose}>
        <div className="card" style={{ margin: '0px' }}>
          <Modal.Header closeButton>
            <Modal.Title>Cliente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ padding: '0px' }}>
              <Col xs={12}>
                <FormGroup>
                  <ControlLabel>CPF</ControlLabel>
                  <p>{this.state.customer.cpf ? this.state.customer.cpf : ''}</p>
                </FormGroup>
                {
                  this.state.customer.cnpj &&
                  <FormGroup>
                    <ControlLabel>CNPJ</ControlLabel>
                    <p>{this.state.customer.cnpj}</p>
                  </FormGroup>
                }
                {
                  this.state.customer.name ?
                    <FormGroup>
                      <ControlLabel>Nome Completo</ControlLabel>
                      <p>{this.state.customer.name ? this.state.customer.name : ''}</p>
                    </FormGroup>
                  : null
                }
                {
                  this.state.customer.name_mother ?
                    <FormGroup>
                      <ControlLabel>Nome da mãe</ControlLabel>
                      <p>{this.state.customer.name_mother ? this.state.customer.name_mother : ''}</p>
                    </FormGroup>
                    : null
                }
                {
                  this.state.customer.rg ?
                    <FormGroup>
                      <ControlLabel>RG</ControlLabel>
                      <p>{this.state.customer.rg ? this.state.customer.rg : ''}</p>
                    </FormGroup>
                  : null
                }
                {
                  this.state.customer.email ?
                    <FormGroup>
                      <ControlLabel>E-mail</ControlLabel>
                      <p>{this.state.customer.email ? this.state.customer.email : ''}</p>
                    </FormGroup>
                  : null
                }
                {
                  this.state.customer.date_of_birth ?
                    <FormGroup>
                      <ControlLabel>Data de nascimento</ControlLabel>
                      <p>{this.state.customer.date_of_birth ? this.state.customer.date_of_birth : ''}</p>
                    </FormGroup>
                  : null
                }
                {
                  this.state.customer.phone1_ddd && this.state.customer.phone1_number ?
                    <FormGroup>
                      <ControlLabel>Telefone Celular</ControlLabel>
                      <p>{this.state.customer.phone1_ddd ? this.state.customer.phone1_ddd : ''} {this.state.customer.phone1_number ? this.state.customer.phone1_number : ''}</p>
                    </FormGroup>
                  : null
                }
                {
                  this.state.customer.phone2_ddd && this.state.customer.phone2_number ?
                    <FormGroup>
                      <ControlLabel>Telefone Fixo</ControlLabel>
                      <p>{this.state.customer.phone2_ddd ? this.state.customer.phone2_ddd : ''} {this.state.customer.phone2_number ? this.state.customer.phone2_number : ''}</p>
                    </FormGroup>
                  : null
                }
                {
                  this.state.customer.customer_address &&
                  <FormGroup>
                    <ControlLabel>Endereço</ControlLabel>
                    <p>{this.state.customer.customer_address.address}, {this.state.customer.customer_address.number} {this.state.customer.customer_address.complement ? `, ${this.state.customer.customer_address.complement}` : ''} - {this.state.customer.customer_address.neighborhood}, {this.state.customer.customer_address.city} - {this.state.customer.customer_address.states.initials}, {this.state.customer.customer_address.zipcode}</p>
                  </FormGroup>
                }
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
    )
  }

  renderData() {
    const { customers } = this.state
    var row = [];
    var CPF = cpf_cnpj.CPF
    if (customers.length > 0) {
      for (var i = 0; i < customers.length; i++) {
        row.push(
          <tr key={customers[i].id}>
            <td>
              {customers[i].name}
            </td>
            <td>
              {CPF.format(customers[i].cpf)}
            </td>
            <td>
              {customers[i].email}
            </td>
            <td className="td-actions">
              <button onClick={this.handleModalCustomerShow.bind(this, customers[i].id)} className="btn btn-primary btn-round btn-xs">
                <i className="far fa-eye"></i> Visualizar
                </button>
              <Can
                perform="customer_edit"
                data={{
                  id: customers[i].id
                }}
                yes={(data) => (
                  <Link to={"/cliente/edit/" + data.id} className="btn btn-primary btn-round btn-xs">
                    <i className="fa fa-edit"></i> Editar
                  </Link>
                )} />
            </td>
          </tr>
        );
      }
    } else {
      row.push(
        <tr key="1">
          <td className="text-center" colSpan={9} className="text-center">
            Nenhum Cliente cadastrado
        </td>
        </tr>
      )
    }
    return (
      <div className="table-responsive">
        {this.state.alert}
        <table className="table table-list table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>Email</th>
              <th style={{ width: "185px" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {row}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={this.state.modalLock} />
        {this.renderView()}
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Breadcrumb active="Clientes" />
              <BlockUi tag="div" blocking={this.state.blocking} message="Carregando, por favor aguarde">
                <Card
                  title="Clientes"
                  content={
                    <div>
                      <div className="btn-create">
                        <FormGroup className="pull-right btn-search">
                          <i className="fa fa-search"></i>
                          <FormControl
                            type="text"
                            placeholder="Pesquisar..."
                            value={this.state.search}
                            onChange={this.onChangeSearch}
                          />
                          {this.state.titleError}
                        </FormGroup>
                      </div>
                      {this.renderData()}
                      <Pagination
                        activePage={this.state.page}
                        itemsCountPerPage={this.state.perPage}
                        totalItemsCount={this.state.total}
                        pageRangeDisplayed={3}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  }
                />
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}