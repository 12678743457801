import React, { Component } from 'react';
import { Grid, Row, Col, Alert, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { animateScroll as scroll } from 'react-scroll';
import InputMask from 'react-input-mask';
import axios from "axios";

import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import api from '../../services/api';

export default class CustomerEdit extends Component {
  constructor(props) {
    super(props);

    this.onBlurAddressZipcode = this.onBlurAddressZipcode.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    
    this.state = {
      modalLock: getBlock() ? true : false,
      occupation_id: '',
      usertype: '',
      cpf: '',
      cnpj: '',
      name: '',
      rg: '',
      email: '',
      date_of_birth: '',
      phone1_ddd: '',
      phone1_number: '',
      phone2_ddd: '',
      phone2_number: '',
      addressId: '',
      addressState_id: '',
      addressState: '',
      addressZipcode: '',
      addressAddress: '',
      addressNumber: '',
      addressNeighborhood: '',
      addressComplement: '',
      addressCity: '',
      address: [],
      states: [],
      occupations: [],

      // Confirm
      alert: null,

      // validation
      error: '',
      occupation_idError: '',
      nameError: '',
      name_motherError: '',
      rgError: '',
      emailError: '',
      date_of_birthError: '',
      phone1_dddError: '',
      phone1_numberError: '',
      phone2_dddError: '',
      phone2_numberError: '',
      phone1Error: '',
      phone2Error: '',
      addressState_idError: '',
      addressZipcodeError: '',
      addressAddressError: '',
      addressNumberError: '',
      addressNeighborhoodError: '',
      addressCityError: '',

      // Loading
      blocking: false
    }

  }

  componentDidMount() {
    if (check(getPermissions(), 'user') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadCustomer()
    this.loadOccupations()
    this.loadStates()
    this.setState({ blocking: false })
  }

  async loadCustomer() {
    this.setState({ blocking: true })
    const resp = await api.get('customers/' + this.props.match.params.id)
		var data_birth = resp.data.customer[0].date_of_birth.split('-')
    data_birth = `${data_birth[2]}/${data_birth[1]}/${data_birth[0]}`
    this.setState({
      occupation_id: resp.data.customer[0].occupation_id,
      usertype: resp.data.customer[0].usertype,
      cpf: resp.data.customer[0].cpf,
      cnpj: resp.data.customer[0].cnpj,
      name: resp.data.customer[0].name,
      name_mother: resp.data.customer[0].name_mother,
      rg: resp.data.customer[0].rg,
      date_of_birth: data_birth,
      phone1_ddd: resp.data.customer[0].phone1_ddd,
      phone1_number: resp.data.customer[0].phone1_number,
      phone2_ddd: resp.data.customer[0].phone2_ddd,
      phone2_number: resp.data.customer[0].phone2_number,
      email: resp.data.customer[0].email,
      addressId: resp.data.customer[0].customer_address.length > 0 ? resp.data.customer[0].customer_address[0].id : "",
      addressZipcode: resp.data.customer[0].customer_address.length > 0 ? resp.data.customer[0].customer_address[0].zipcode : "",
      addressAddress: resp.data.customer[0].customer_address.length > 0 ? resp.data.customer[0].customer_address[0].address : "",
      addressNeighborhood: resp.data.customer[0].customer_address.length > 0 ? resp.data.customer[0].customer_address[0].neighborhood : "",
      addressNumber: resp.data.customer[0].customer_address.length > 0 ? resp.data.customer[0].customer_address[0].number : "",
      addressComplement: resp.data.customer[0].customer_address.length > 0 ? resp.data.customer[0].customer_address[0].complement : "",
      addressCity: resp.data.customer[0].customer_address.length > 0 ? resp.data.customer[0].customer_address[0].city : "",
      addressState_id: resp.data.customer[0].customer_address.length > 0 ? resp.data.customer[0].customer_address[0].state_id : "",
      blocking: false
    });
  }

  async loadStates() {
    const resp = await api.get('states/')
    this.setState({ states: resp.data });
  }

  async loadOccupations() {
    const resp = await api.get('occupation/')
    this.setState({ occupations: resp.data });
  }

  onChangeInput = e => {
    this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    e.target.value === "" ? this.setState({ [e.target.name+"Error"]: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ [e.target.name+"Error"]: '', error: '' });
  }

  async onBlurAddressZipcode(e) {
    var cep = e.target.value.replace('-', '')
    cep = parseInt(cep)
    if (cep.toString().length === 8) {
      this.setState({ loadingZipcode: true })
      var respAddress = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      if (respAddress.data.erro) {
        this.setState({
          loadingZipcode: false,
          addressAddress: '',
          addressNeighborhood: '',
          addressCity: '',
          addressState_id: '',
          addressNumber: '',
          addressComplement: ''
        })
      } else {
        var respState = await api.get(`states/${respAddress.data.uf}`)
        this.setState({
          loadingZipcode: false,
          addressAddress: respAddress.data.logradouro.toUpperCase(),
          addressNeighborhood: respAddress.data.bairro.toUpperCase(),
          addressCity: respAddress.data.localidade.toUpperCase(),
          addressState_id: respState.data.state[0].id,
          addressAddressError: null,
          addressNeighborhoodError: null,
          addressCityError: null,
          addressState_idError: null
        })
      }
    }
  }
  
  async onSubmit(e) {
    e.preventDefault();

    var { occupation_id, usertype, cpf, cnpj, name, name_mother, rg, date_of_birth, phone1_ddd, phone1_number, phone2_ddd, phone2_number, email, addressId, addressZipcode, addressAddress, addressNeighborhood, addressNumber, addressComplement, addressCity, addressState_id } = this.state

    occupation_id === "" || occupation_id === null ? this.setState({ occupation_idError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ occupation_idError: null })
    name === "" || name === null ? this.setState({ nameError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ nameError: null })
    name_mother === "" ? this.setState({ name_motherError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ name_motherError: null })
    rg === "" ? this.setState({ rgError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ rgError: null })
    date_of_birth === "" ? this.setState({ date_of_birthError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ date_of_birthError: null })
    phone1_ddd === "" ? this.setState({ phone1_dddError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ phone1_dddError: null })
    phone1_number === "" ? this.setState({ phone1_numberError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ phone1_numberError: null })
    phone2_ddd === "" ? this.setState({ phone2_dddError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ phone2_dddError: null })
    phone2_number === "" ? this.setState({ phone2_numberError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ phone2_numberError: null })
    email === "" ? this.setState({ emailError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ emailError: null })
    addressZipcode === "" ? this.setState({ addressZipcodeError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressZipcodeError: null })
    addressAddress === "" ? this.setState({ addressAddressError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressAddressError: null })
    addressNeighborhood === "" ? this.setState({ addressNeighborhoodError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressNeighborhoodError: null })
    addressNumber === "" ? this.setState({ addressNumberError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressNumberError: null })
    addressCity === "" ? this.setState({ addressCityError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressCityError: null })
    addressState_id === "" ? this.setState({ addressState_idError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressState_idError: null })
    
    if (
      (occupation_id !== "" || occupation_id !== null) &&
      (name !== "" || name !== null) &&
      (name_mother !== "" || name_mother !== null) &&
      (rg !== "" || rg !== null) &&
      (date_of_birth !== "" || date_of_birth !== null) &&
      (phone1_ddd !== "" || phone1_ddd !== null) &&
      (phone1_number !== "" || phone1_number !== null) &&
      (phone2_ddd !== "" || phone2_ddd !== null) &&
      (phone2_number !== "" || phone2_number !== null) &&
      (email !== "" || email !== null) &&
      (addressZipcode !== "" || addressZipcode !== null) &&
      (addressAddress !== "" || addressAddress !== null) &&
      (addressNeighborhood !== "" || addressNeighborhood !== null) &&
      (addressNumber !== "" || addressNumber !== null) &&
      (addressCity !== "" || addressCity !== null) &&
      (addressState_id !== "" || addressState_id !== null)
      ) {

      var phone1_number = phone1_number.replace('-', '')
      var phone2_number = phone2_number.replace('-', '')

      var data_birth = date_of_birth.split('/')

      const obj = {
        occupation_id: occupation_id,
        usertype: usertype,
        cpf: cpf,
        cnpj: cnpj,
        name: name,
        name_mother: name_mother,
        rg: rg,
        date_of_birth: `${data_birth[2]}-${data_birth[1]}-${data_birth[0]}`,
        phone1_ddd: phone1_ddd,
        phone1_number: phone1_number,
        phone2_ddd: phone2_ddd,
        phone2_number: phone2_number,
        email: email,
      };

      
      const resp = await api.put(`customers/${this.props.match.params.id}`, obj)
      
      if (resp.status === 200) {

        var zipcode = addressZipcode.replace('-', '')

        const objAddress = {
          customer_id: this.props.match.params.id,
          zipcode: zipcode,
          address: addressAddress,
          neighborhood: addressNeighborhood,
          number: addressNumber,
          complement: addressComplement,
          city: addressCity,
          state_id: addressState_id,
        }

        if(addressId){
          await api.put(`customer_addresses/${addressId}`, objAddress)
        }else{
          await api.post(`customer_address`, objAddress)
        }

        this.props.handleClick('tr', 'success', 'Atualizado com sucesso!')

        this.props.history.push("/cliente");

      } else {
        scroll.scrollToTop();
        this.setState({
          error: resp.data,
          blocking: false
        })
      }
    }
  }

  render() {
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={this.state.modalLock} />
        {this.state.alert}
        <Grid fluid>
          <Row>
            <Col md={10} lg={6}>
              <Breadcrumb routes={[{ label: 'Pacientes', link: '/cliente' }]} active="Paciente" />
              <BlockUi tag="div" blocking={this.state.blocking} message="Carregando, por favor aguarde">
                <form onSubmit={this.onSubmit}>
                  <Card
                    content={
                      <form onSubmit={this.onSubmitNewCustomer}>
                        <BlockUi tag="div" blocking={this.state.blockingNewForm} message="Carregando, por favor aguarde">
                          <h4 style={{ marginTop: '0px' }}>Dados Pessoais</h4>
                          {this.state.error &&
                            <Alert bsStyle="danger">
                              {
                                this.state.error.map((e, i) => {
                                  return (
                                    <li key={i}>{e.message}</li>
                                  )
                                })
                              }
                            </Alert>
                          }
                          <Row>
                            <Col xs={6}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}><ControlLabel>CPF:</ControlLabel></Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <InputMask
                                      mask={'999.999.999-99'}
                                      maskChar='_'
                                      className={'form-control'}
                                      value={this.state.cpf ? this.state.cpf : ''}
                                      name="cpf"
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <ControlLabel>Ocupação:</ControlLabel>
                                <select
                                  value={this.state.occupation_id ? this.state.occupation_id : ''}
                                  className={this.state.occupation_idError ? 'form-control error' : 'form-control'}
                                  name="occupation_id"
                                  onChange={this.onChangeInput}
                                >
                                  <option value="">Selecione</option>
                                  {
                                    this.state.occupations &&
                                    this.state.occupations.map(element => {
                                      return (
                                        <option key={element.id} value={element.id}>{element.name}</option>
                                      )
                                    })
                                  }
                                </select>
                                {this.state.occupation_idError}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}><ControlLabel>Nome Completo:</ControlLabel></Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <FormControl
                                      type="text"
                                      className={this.state.nameError ? 'error form-control' : 'form-control'}
                                      value={this.state.name ? this.state.name : ''}
                                      name="name"
                                      onChange={this.onChangeInput}
                                    />
                                  </Col>
                                </Row>
                                {this.state.nameError}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}><ControlLabel>Nome da mãe:</ControlLabel></Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <FormControl
                                      type="text"
                                      className={this.state.name_motherError ? 'error form-control' : 'form-control'}
                                      value={this.state.name_mother ? this.state.name_mother : ''}
                                      name="name_mother"
                                      onChange={this.onChangeInput}
                                    />
                                  </Col>
                                </Row>
                                {this.state.name_motherError}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}><ControlLabel>RG:</ControlLabel></Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <FormControl
                                      type="text"
                                      className={this.state.rgError ? 'error form-control' : 'form-control'}
                                      value={this.state.rg ? this.state.rg : ''}
                                      name="rg"
                                      onChange={this.onChangeInput}
                                    />
                                  </Col>
                                </Row>
                                {this.state.rgError}
                              </FormGroup>
                            </Col>
                            <Col xs={6}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}><ControlLabel>Data de nascimento:</ControlLabel></Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <InputMask
                                      mask="99/99/9999"
                                      maskChar='_'
                                      className={this.state.date_of_birthError ? 'error form-control' : 'form-control'}
                                      value={this.state.date_of_birth ? this.state.date_of_birth : ''}
                                      name="date_of_birth"
                                      onChange={this.onChangeInput}
                                    />
                                  </Col>
                                </Row>
                                {this.state.date_of_birthError}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}>
                                    <ControlLabel>Telefone Celular:</ControlLabel>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={4}>
                                    <InputMask
                                      mask="99"
                                      maskChar=''
                                      className={this.state.phone1_dddError ? 'error form-control' : 'form-control'}
                                      value={this.state.phone1_ddd ? this.state.phone1_ddd : ''}
                                      name="phone1_ddd"
                                      onChange={this.onChangeInput}
                                    />
                                  </Col>
                                  <Col xs={8} className="pl-0">
                                    <InputMask
                                      mask="99999-9999"
                                      maskChar=''
                                      className={this.state.phone1_numberError ? 'error form-control' : 'form-control'}
                                      value={this.state.phone1_number ? this.state.phone1_number : ''}
                                      name="phone1_number"
                                      onChange={this.onChangeInput}
                                    />
                                  </Col>
                                  {this.state.phone1Error}
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}>
                                    <ControlLabel>Telefone Fixo:</ControlLabel>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={4}>
                                    <InputMask
                                      mask="99"
                                      maskChar=''
                                      className={this.state.phone2_dddError ? 'error form-control' : 'form-control'}
                                      value={this.state.phone2_ddd ? this.state.phone2_ddd : ''}
                                      name="phone2_ddd"
                                      onChange={this.onChangeInput}
                                    />
                                  </Col>
                                  <Col xs={8} className="pl-0">
                                    <InputMask
                                      mask={this.state.phone2_number && this.state.phone2_number.length <= 8 ? '9999-9999?' : '99999-999?'}
                                      formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                                      maskChar=''
                                      className={this.state.phone2_numberError ? 'error form-control' : 'form-control'}
                                      value={this.state.phone2_number ? this.state.phone2_number : ''}
                                      name="phone2_number"
                                      onChange={this.onChangeInput}
                                    />
                                  </Col>
                                  {this.state.phone2Error}
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup>
                            <Row>
                              <Col xs={12}><ControlLabel>E-mail:</ControlLabel></Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <FormControl
                                  type="text"
                                  className={this.state.emailError ? 'error' : ''}
                                  value={this.state.email ? this.state.email : ''}
                                  name="email"
                                  onChange={this.onChangeInput}
                                />
                              </Col>
                            </Row>
                            {this.state.emailError}
                          </FormGroup>
                          <h4>Endereço</h4>
                          <Row>
                            <Col xs={6} md={3}>
                              <FormGroup>
                                <ControlLabel>CEP:</ControlLabel>
                                <InputMask
                                  mask="99999-999"
                                  maskChar='_'
                                  className={this.state.addressZipcodeError ? 'error form-control' : 'form-control'}
                                  value={this.state.addressZipcode ? this.state.addressZipcode : ''}
                                  name="addressZipcode"
                                  onChange={this.onChangeInput}
                                  onBlur={this.onBlurAddressZipcode}
                                />
                                {this.state.addressZipcodeError}
                                {this.state.loadingZipcode}
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={9}>
                              <FormGroup>
                                <ControlLabel>Endereço:</ControlLabel>
                                <FormControl
                                  type="text"
                                  className={this.state.addressAddressError ? 'error' : ''}
                                  value={this.state.addressAddress ? this.state.addressAddress : ''}
                                  name="addressAddress"
                                  onChange={this.onChangeInput}
                                  disabled={this.state.loadingZipcode ? true : false}
                                />
                                {this.state.addressAddressError}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6}>
                              <FormGroup>
                                <ControlLabel>Bairro:</ControlLabel>
                                <FormControl
                                  type="text"
                                  className={this.state.addressNeighborhoodError ? 'error' : ''}
                                  value={this.state.addressNeighborhood ? this.state.addressNeighborhood : ''}
                                  name="addressNeighborhood"
                                  onChange={this.onChangeInput}
                                  disabled={this.state.loadingZipcode ? true : false}
                                />
                                {this.state.addressNeighborhoodError}
                              </FormGroup></Col>
                            <Col xs={6} md={3}>
                              <FormGroup>
                                <ControlLabel>Número:</ControlLabel>
                                <FormControl
                                  type="text"
                                  className={this.state.addressNumberError ? 'error' : ''}
                                  value={this.state.addressNumber ? this.state.addressNumber : ''}
                                  name="addressNumber"
                                  onChange={this.onChangeInput}
                                />
                                {this.state.addressNumberError}
                              </FormGroup>
                            </Col>
                            <Col xs={6} md={3}>
                              <FormGroup>
                                <ControlLabel>Complemento:</ControlLabel>
                                <FormControl
                                  type="text"
                                  value={this.state.addressComplement ? this.state.addressComplement : ''}
                                  name="addressComplement"
                                  onChange={this.onChangeInput}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <FormGroup>
                                <ControlLabel>Cidade:</ControlLabel>
                                <FormControl
                                  type="text"
                                  className={this.state.addressCityError ? 'error' : ''}
                                  value={this.state.addressCity ? this.state.addressCity : ''}
                                  name="addressCity"
                                  onChange={this.onChangeInput}
                                  disabled={this.state.loadingZipcode ? true : false}
                                />
                                {this.state.addressCityError}
                              </FormGroup>
                            </Col>
                            <Col xs={6}>
                              <FormGroup>
                                <ControlLabel>Estado:</ControlLabel>
                                <select
                                  value={this.state.addressState_id ? this.state.addressState_id : ''}
                                  className={this.state.addressState_idError ? 'form-control error' : 'form-control'}
                                  name="addressState_id"
                                  onChange={this.onChangeInput}
                                  disabled={this.state.loadingZipcode ? true : false}
                                >
                                  <option value="">Selecione</option>
                                  {
                                    this.state.states &&
                                    this.state.states.map(element => {
                                      return (
                                        <option key={element.id} value={element.id}>{element.name}</option>
                                      )
                                    })
                                  }
                                </select>
                                {this.state.addressState_idError}
                              </FormGroup>
                            </Col>
                          </Row>
                        </BlockUi>
                      </form>
                    }
                    ftTextCenter
                    legend={
                      <div>
                        <Button type="submit" bsStyle="info" fill> Salvar </Button>
                        <Link to={"/cliente/"} className="btn-fill btn btn-neutral btn-back">Voltar</Link>
                      </div>
                    }
                  />
                </form>
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}