import React, { Component } from 'react';
import api from '../../services/api';
import { Grid, Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import moment from "moment";
import 'moment/locale/pt-br';
import cpf_cnpj from "cpf_cnpj";
import SweetAlert from 'react-bootstrap-sweetalert'
import ReactExport from "react-data-export";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DateRangePicker } from 'react-dates';

import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';
import Card from '../../components/Card/Card.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class RequestsIndex extends Component {

  constructor(props) {
    super(props);

    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onChangeCleanSearch = this.onChangeCleanSearch.bind(this);
    this.alertAlert = this.alertAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    
    this.state = {
      modalLock: getBlock() ? true : false,
      user_auth: {},
      // Filter
      startDate: null,
      endDate: null,
      focusedInput: null,
      alert: false,

      // Table
      requests: [],
      
      blocking: false
    };
  }

  componentDidMount() {
    if (check(getPermissions(), 'report_request') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.userAuth()
  }
  
  async userAuth() {
    const resp = await api.get('users/me')
    this.setState({ user_auth: resp.data.user })
  }

  hideAlert() {
    this.setState({ alert: null })
  }

  alertAlert(title, message) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={title}
          onConfirm={(this.hideAlert)}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
        >
        {message}
        </SweetAlert>
      )
    });
  }
  
  async onChangeSearch() {

    var { startDate, endDate } = this.state, _dt_start = '', _dt_end = ''
    
    if(!startDate || !endDate) {
      this.alertAlert('Informe o período de emissão', '')
      return false
    }

    _dt_start = moment(startDate).format("YYYY-MM-DD")
    _dt_end = moment(endDate).format("YYYY-MM-DD")
    
    this.setState({ blocking: true });

    const response = await api.get(`requests?dt_start=${_dt_start}&dt_end=${_dt_end}`)
    
    this.setState({
      requests: response.data,
      blocking: false
    });
  }
  
  onChangeCleanSearch() {
    this.setState({ startDate: '', endDate: '', requests: [] });
  }

  renderData() {
    const requests = this.state.requests
    var row = []
    var CPF = cpf_cnpj.CPF
    if (requests.length > 0) {
      for (var i = 0; i < requests.length; i++) {
        row.push(
          <tr key={i}>
            <td> {CPF.format(requests[i].cpf)} </td>
            <td> {requests[i].tipoconsulta} </td>
            <td> {requests[i].message} </td>
            <td> {requests[i].requests_created_at} </td>
            <td> {requests[i].company} </td>
            <td> {requests[i].operator} </td>
          </tr>
        );
      }
    } else {
      row.push(
        <tr key="1">
          <td className="text-center" colSpan={9} className="text-center">
            Nenhum registro encontrado
          </td>
        </tr>
      )
    }
    return (
      <div className="table-responsive">
        {this.state.alert}
        <table className="table table-list table-striped">
          <thead>
            <tr>
              <th className="text-center">CPF</th>
              <th className="text-center">Tipo de Consulta</th>
              <th className="text-center">Cliente</th>
              <th className="text-center">Data da emissão</th>
              <th className="text-center">Operador</th>
              <th className="text-center">Empresa</th>
            </tr>
          </thead>
          <tbody>
            {row}
          </tbody>
        </table>
      </div>
    );
  }

  renderFilter() {
    return (
      <Row>
        <Col md={12}>
          <Row>
            <Col xs={12}><ControlLabel>Período de emissão:</ControlLabel></Col>
          </Row>
          <Row>
            <Col xs={12}>
              <DateRangePicker
                startDateId="startDate"
                startDatePlaceholderText="Início"
                endDateId="endDate"
                endDatePlaceholderText="Fim"
                displayFormat={() => "DD/MM/YYYY"}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onDatesChange={({ startDate, endDate }) => { this.setState({ startDate, endDate }) }}
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}
                isOutsideRange={() => false}
                hideKeyboardShortcutsPanel
              />
              <button className="btn-fill btn btn-info" onClick={this.onChangeSearch.bind(this)} style={{ margin: '0 0 0 10px' }}> <i className="fa fa-search"></i> Pesquisar </button>
              <button className="btn-fill btn btn-info" onClick={this.onChangeCleanSearch.bind(this)} style={{ margin: '0 0 0 10px' }}> <i className="fa fa-close"></i> Limpar </button>
              {
                this.state.requests.length > 0 ?
                  <ExcelFile filename="relatorio_solicitacoes" element={<button className="btn-fill btn btn-info" style={{ margin: '0 0 0 10px' }}><i className="fa fa-file-excel"></i> Exportar XLS</button>}>
                    <ExcelSheet data={this.state.requests} name="relatorio_solicitacoes">
                      <ExcelColumn label="CPF" value="cpf" />
                      <ExcelColumn label="Tipo de Consulta" value="tipoconsulta" />
                      <ExcelColumn label="Cliente" value="message" />
                      <ExcelColumn label="Retorno" value="return" />
                      <ExcelColumn label="Data Emissão" value="requests_created_at" />
                      <ExcelColumn label="Empresa" value="company" />
                      <ExcelColumn label="Operador" value="operator" />
                    </ExcelSheet>
                  </ExcelFile>
                  : null
                }
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <div className="main-content">
        {this.state.alert}
        <CounterDown val={1800} modal={this.state.modalLock} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-links">
                  <li className="breadcrumb-item active">Relatório de Soliciações</li>
                </ol>
              </nav>
              <BlockUi tag="div" blocking={this.state.blocking} message="Carregando, por favor aguarde">
                <Card
                  content={
                    <div>
                      {this.renderFilter()}
                      <Row>
                        <Col md={12}><hr /></Col>
                      </Row>
                      {this.renderData()}
                    </div>
                  }
                />
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}