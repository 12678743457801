import React, { Component } from 'react';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import SweetAlert from 'react-bootstrap-sweetalert';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Grid, Row, Col, FormGroup, FormControl } from 'react-bootstrap';

import { Can, check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

export default class FranchiseIndex extends Component {

  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.successDelete = this.successDelete.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      // Confirm
      alert: null,
      show: false,

      // Table
      franchises: [],
      search: '',
      activePage: 10,
      total: '',
      perPage: '',
      lastPage: '',
      page: 1,

      blocking: true
    };
  }

  componentDidMount() {
    if (check(getPermissions(), 'franchise') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadFranchise()
  }

  async handlePageChange(pageNumber) {
    this.setState({ blocking: true })
    const response = await api.get('franchises?page=' + pageNumber)
    this.setState({
      franchises: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: pageNumber,
      lastPage: response.data.lastPage,
      blocking: false
    });
  }

  async loadFranchise() {
    this.setState({ blocking: true })
    const response = await api.get('franchises')
    this.setState({
      franchises: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: response.data.page,
      lastPage: response.data.lastPage,
      blocking: false
    });
  }

  warningWithConfirmMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.successDelete(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim, exclua!"
          cancelBtnText="Cancelar"
          showCancel
        >
        </SweetAlert>
      )
    });
  }

  warningWithConfirmStatusMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.statusChange(id)}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim"
          cancelBtnText="Cancelar"
          showCancel
        >
        </SweetAlert>
      )
    });
  }

  async statusChange(id) {
    this.setState({ blocking: true })
    await api.put('franchises/status/' + id)
    this.loadFranchise()
    this.setState({ blocking: false, alert: false })
  }

  async successDelete(id) {
    const resp = await api.delete('franchises/' + id)
    if (resp.status === 200) {
      this.loadFranchise()
      this.hideAlert()
      this.props.handleClick('tr', 'success', 'Excluído com sucesso!')
    }else if (resp.status === 203) {
      this.hideAlert()
      this.props.handleClick('tr', 'warning', resp.data.message)
    }
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  async onChangeSearch(e) {
    var search = e.target.value
    this.setState({ search: search });
    const response = await api.get('franchises?search=' + search)
    this.setState({
      franchises: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: response.data.page,
      lastPage: response.data.lastPage
    });
  }

  renderData() {
    const { franchises, alert } = this.state
    var row = [];
    for (var i = 0; i < franchises.length; i++) {
      row.push(
        <tr key={franchises[i].id}>
          <td>
            {franchises[i].Franchise_type === "PF" ? franchises[i].name : franchises[i].social_name}
          </td>
          <td>
            { franchises[i].innactivated_in ?
              <span className="badge badge-danger">Inativo</span>
              : 
              <span className="badge badge-success">Ativo</span>
            }
          </td>
          <td className="td-actions">
            { franchises[i].innactivated_in ?
              <Button onClick={this.warningWithConfirmStatusMessage.bind(this, franchises[i].id)} className="btn btn-primary btn-round btn-xs">
                <span><i className="fas fa-thumbs-up"></i> Ativar</span>
              </Button>
            : 
              <Button onClick={this.warningWithConfirmStatusMessage.bind(this, franchises[i].id)} className="btn btn-primary btn-round btn-xs">
                <span><i className="fas fa-thumbs-down"></i> Inativar</span>
              </Button>
            }
            <Can
              perform="franchise_edit"
              data={{
                id: franchises[i].id
              }}
              yes={(data) => (
                <Link to={"/credenciador/documento/analise/" + data.id} className="btn btn-primary btn-round btn-xs">
                  <i className="fas fa-file-contract"></i> Analisar Documento
                </Link>
              )} />
            <Can
              perform="franchise_edit"
              data={{
                id: franchises[i].id
              }}
              yes={(data) => (
                <Link to={"/credenciador/documento/" + data.id} className="btn btn-primary btn-round btn-xs">
                  <i className="fas fa-file-contract"></i> Enviar Documento
                </Link>
              )} />
            <Can
              perform="franchise_edit"
              data={{
                id: franchises[i].id
              }}
              yes={(data) => (
                <Link to={"/credenciador/edit/" + data.id} className="btn btn-primary btn-round btn-xs">
                  <i className="fa fa-edit"></i> Editar
                </Link>
              )} />
            <Can
              perform="franchise_delete"
              data={{
                id: franchises[i].id
              }}
              yes={(data) => (
                <button onClick={this.warningWithConfirmMessage.bind(this, data.id)} className="btn btn-primary btn-round btn-xs">
                  <i className="fa fa-trash"></i> Excluir
                </button>
              )} />
          </td>
        </tr>
      );
    }
    return (
      <div className="table-responsive">
        {alert}
        <table className="table table-list table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th style={{ width: "100px" }}>Status</th>
              <th style={{ width: "575px" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {row}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    var { modalLock, blocking, search, page, perPage, total } = this.state
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={modalLock} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Breadcrumb active="Credenciadores" />
              <BlockUi tag="div" blocking={blocking} message="Carregando, por favor aguarde">
                <Card
                  title="Credenciadores"
                  content={
                    <div>
                      <div className="btn-create">
                        <Can
                          perform="franchise_add"
                          yes={() => (
                            <Link to={'/credenciador/create'} className="btn-fill btn btn-info pull-right">Novo Credenciador</Link>
                          )} />
                        <FormGroup className="pull-right btn-search">
                          <i className="fa fa-search"></i>
                          <FormControl
                            type="text"
                            placeholder="Pesquisar..."
                            value={search}
                            onChange={this.onChangeSearch}
                          />
                        </FormGroup>
                      </div>
                      {this.renderData()}
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={perPage}
                        totalItemsCount={total}
                        pageRangeDisplayed={3}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  }
                />
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}