import React, { Component } from 'react';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import SweetAlert from 'react-bootstrap-sweetalert';
import { Grid, Row, Col, FormGroup, FormControl } from 'react-bootstrap';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import { Can, check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';

export default class UserIndex extends Component {

  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.successDelete = this.successDelete.bind(this);
    this.successSendPassword = this.successSendPassword.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      user_auth: {},

      // Confirm
      alert: null,
      show: false,

      // Table
      users: [],
      search: '',
      activePage: 10,
      total: '',
      perPage: '',
      lastPage: '',
      page: 1,

      blocking: true
    };
  }

  componentDidMount() {
    if (check(getPermissions(), 'user') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadUser()
    this.userAuth()
  }

  async userAuth() {
    const resp = await api.get('users/me')
    this.setState({ user_auth: resp.data.user })
  }

  async handlePageChange(pageNumber) {
    this.setState({ blocking: true })
    const response = await api.get('users?page=' + pageNumber)
    this.setState({
      users: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: pageNumber,
      lastPage: response.data.lastPage,
      blocking: false
    });
  }

  async loadUser() {
    this.setState({ blocking: true })
    const response = await api.get('users')
    this.setState({
      users: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: response.data.page,
      lastPage: response.data.lastPage,
      blocking: false
    });
  }

  warningWithConfirmMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.successDelete(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim, exclua!"
          cancelBtnText="Cancelar"
          showCancel
        >
        </SweetAlert>
      )
    });
  }

  warningWithConfirmStatusMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.statusChange(id)}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim"
          cancelBtnText="Cancelar"
          showCancel
        >
        </SweetAlert>
      )
    });
  }

  async statusChange(id) {
    this.setState({ blocking: true })
    await api.put('users/status/' + id)
    this.loadUser()
    this.setState({ blocking: false, alert: false })
  }
  
  confirmSendPasswordMail(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Deseja enviar a solicitação de nova senha por email?"
          onConfirm={() => this.successSendPassword(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim, enviar!"
          cancelBtnText="Cancelar"
          showCancel
        >
        </SweetAlert>
      )
    });
  }

  async successDelete(id) {
    const resp = await api.delete('users/' + id)
    if (resp.status === 200) {
      this.loadUser()
      this.hideAlert()
      this.props.handleClick('tr', 'success', 'Excluído com sucesso!')
    }else {
      this.hideAlert()
      this.props.handleClick('tr', 'warning', resp.data.message)
    }
  }
  async successSendPassword(id) {
    const resp = await api.post(`users/password/${id}`)
    if (resp.status === 200) {
      this.hideAlert()
      this.props.handleClick('tr', 'success', 'Enviado com sucesso!')
    }
  }
  hideAlert() {
    this.setState({ alert: null });
  }

  async onChangeSearch(e) {
    var search = e.target.value
    this.setState({ search: search });
    const response = await api.get('users?search=' + search)
    this.setState({
      users: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: response.data.page,
      lastPage: response.data.lastPage
    });
  }

  renderData() {
    const { users, user_auth, alert } = this.state
    var row = [];
    for (var i = 0; i < users.length; i++) {
      row.push(
        <tr key={users[i].id}>
          <td>
            {users[i].full_name}
          </td>
          {
            user_auth.admin === 1 ?
              users[i].company !== null ?
                <td>{users[i].company.company_type === "PF" ? users[i].company.name : users[i].company.social_name}</td>
              : <td>-</td>
            : user_auth.franchise_id !== null ?
              
              users[i].company !== null ?
                <td>{users[i].company.company_type === "PF" ? users[i].company.name : users[i].company.social_name}</td>
              : <td>-</td>
            :null
          }
          {
            user_auth.admin === 1 ?
              users[i].franchise !== null ?
                <td>{users[i].franchise.social_name}</td>
              : <td>-</td>
            : null
          }
          <td>
            {
              users[i].roles.map(role => {
                return (
                  <span className="badge" key={role.id} style={{ margin: '2px' }}>{role.name}</span>
                )
              })
            }
          </td>
          <td>
            { users[i].innactivated_in ?
              <span className="badge badge-danger">Inativo</span>
              : 
              <span className="badge badge-success">Ativo</span>
            }
          </td>
          <td className="td-actions">
            { users[i].innactivated_in ?
              <Button onClick={this.warningWithConfirmStatusMessage.bind(this, users[i].id)} className="mb-5 btn btn-primary btn-round btn-xs">
                <span><i className="fas fa-thumbs-up"></i> Ativar</span>
              </Button>
            : 
              <Button onClick={this.warningWithConfirmStatusMessage.bind(this, users[i].id)} className="mb-5 btn btn-primary btn-round btn-xs">
                <span><i className="fas fa-thumbs-down"></i> Inativar</span>
              </Button>
            }
            <Can
              perform="user_password"
              data={{
                id: users[i].id
              }}
              yes={(data) => (
                <button onClick={this.confirmSendPasswordMail.bind(this, data.id)} className="mb-5 btn btn-primary btn-round btn-xs">
                  <i className="fa fa-send"></i> Enviar Senha
                </button>
              )} />
            <Can
              perform="user_password"
              data={{
                id: users[i].id
              }}
              yes={(data) => (
                <Link to={"/usuario/senha/" + data.id} className="mb-5 btn btn-primary btn-round btn-xs">
                  <i className="fa fa-lock"></i> Atualizar Senha
                </Link>
              )} />
            <Can
              perform="user_edit"
              data={{
                id: users[i].id
              }}
              yes={(data) => (
                <Link to={"/usuario/edit/" + data.id} className="mb-5 btn btn-primary btn-round btn-xs">
                  <i className="fa fa-edit"></i> Editar
                </Link>
              )} />
            <Can
              perform="user_delete"
              data={{
                id: users[i].id
              }}
              yes={(data) => (
                <button onClick={this.warningWithConfirmMessage.bind(this, data.id)} className="mb-5 btn btn-primary btn-round btn-xs">
                  <i className="fa fa-trash"></i> Excluir
                </button>
              )} />
          </td>
        </tr>
      );
    }
    return (
      <div className="table-responsive">
        {alert}
        <table className="table table-list table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              {
              (user_auth.admin === 1 || user_auth.franchise_id !== null) &&
                <th>Empresa</th>
              }
              {
              user_auth.admin === 1 &&
                <th>Credenciador</th>
              }
              <th>Perfil</th>
              <th style={{ width: "100px" }}>Status</th>
              <th style={{ width: "375px" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {row}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    var { modalLock, blocking, search, page, perPage, total } = this.state
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={modalLock} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-links">
                  <li className="breadcrumb-item active">Usuários</li>
                </ol>
              </nav>
              <BlockUi tag="div" blocking={blocking} message="Carregando, por favor aguarde">
                <Card
                  title="Usuários"
                  content={
                    <div>
                      <div className="btn-create">
                        <Can
                          perform="user_add"
                          yes={() => (
                            <Link to={'/usuario/create'} className="btn-fill btn btn-info pull-right">Novo usuário </Link>
                          )} />
                        <FormGroup className="pull-right btn-search">
                          <i className="fa fa-search"></i>
                          <FormControl
                            type="text"
                            placeholder="Pesquisar..."
                            value={search}
                            onChange={this.onChangeSearch}
                          />
                        </FormGroup>
                      </div>
                      {this.renderData()}
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={perPage}
                        totalItemsCount={total}
                        pageRangeDisplayed={3}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  }
                />
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}