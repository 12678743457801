import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Breadcrumb extends Component {
	render() {
		return (
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb breadcrumb-links">
					{
						this.props.routes ? this.props.routes.map((element, index) => {
							return (
								<li key={index} className="breadcrumb-item">
									{
										element.link ?
											<Link to={element.link}>{element.label}</Link>
											: element.label 
									}
								</li>
							)
						})
						: null
					}
					{
						this.props.active ?
							<li className="breadcrumb-item active">{this.props.active}</li>
						: null
					}
				</ol>
			</nav>
		);
	}
}

export default Breadcrumb;
