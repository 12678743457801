import React, { Component } from 'react';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import SweetAlert from 'react-bootstrap-sweetalert';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Grid, Row, Col, FormGroup, FormControl } from 'react-bootstrap';

import { Can, check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';
import Card from '../../components/Card/Card.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

export default class PermissionIndex extends Component {

  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.successDelete = this.successDelete.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      // Confirm
      alert: null,

      // Table
      permissions: [],
      search: '',
      activePage: 10,
      total: '',
      perPage: '',
      lastPage: '',
      page: 1,

      blocking: true
    };
  }

  async handlePageChange(pageNumber) {
    this.setState({ blocking: true });
    const response = await api.get('permissions?page=' + pageNumber)
    this.setState({
      permissions: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: pageNumber,
      lastPage: response.data.lastPage,
      blocking: false
    });
  }

  componentDidMount() {
    if (check(getPermissions(), 'permission') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadPermission()
  }

  async loadPermission() {
    const response = await api.get('permissions')
    this.setState({
      permissions: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: response.data.page,
      lastPage: response.data.lastPage,
      blocking: false
    });
  }

  warningWithConfirmMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.successDelete(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim, exclua!"
          cancelBtnText="Cancelar"
          showCancel
        >
        </SweetAlert>
      )
    });
  }

  async successDelete(id) {
    const resp = await api.delete('permissions/' + id)
    if (resp.status === 200) {
      this.loadPermission()
      this.hideAlert()
      this.props.handleClick('tr', 'success', 'Excluído com sucesso!')
    }else{
      // Mensagem de erro FAZER
    }
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  async onChangeSearch(e) {
    var search = e.target.value
    this.setState({ search: search });
    const response = await api.get('permissions?search=' + search)
    this.setState({
      permissions: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: response.data.page,
      lastPage: response.data.lastPage
    });
  }

  renderData() {
    const permissions = this.state.permissions
    var row = [];
    if (permissions.length > 0){
      for (var i = 0; i < permissions.length; i++) {
        row.push(
          <tr key={permissions[i].id}>
            <td>
              {permissions[i].name}
            </td>
            <td>
              {permissions[i].slug}
            </td>
            <td className="td-actions">
              <Can
                perform="permission_edit"
                data={{
                  id: permissions[i].id
                }}
                yes={(data) => (
                  <Link to={"/permissao/edit/" + data.id} className="btn btn-primary btn-round btn-xs">
                    <i className="fa fa-edit"></i> Editar
                </Link>
                )} />
              <Can
                perform="permission_delete"
                data={{
                  id: permissions[i].id
                }}
                yes={(data) => (
                  <button onClick={this.warningWithConfirmMessage.bind(this, data.id)} className="btn btn-primary btn-round btn-xs">
                    <i className="fa fa-trash"></i> Excluir
                </button>
                )} />
            </td>
          </tr>
        );
      }
    }else{
      row.push(
        <tr key="1">
          <td colSpan={4} className="text-center">
            Nenhuma permissão encontrada
          </td>
        </tr>
      )
    }
    return (
      <div className="table-responsive">
        {this.state.alert}
        <table className="table table-list table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Slug</th>
              <th style={{ width: "175px" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {row}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    var { modalLock, blocking, search, titleError, page, perPage, total } = this.state
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={modalLock} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Breadcrumb active="Permissões" />
              <BlockUi tag="div" blocking={blocking} message="Carregando, por favor aguarde">
                <Card
                  title="Permissões"
                  content={
                    <div>
                      <div className="btn-create">
                        <Can
                          perform="permission_add"
                          yes={() => (
                            <Link to={'/permissao/create'} className="btn-fill btn btn-info pull-right">Nova Permissão </Link>
                          )} />
                        <FormGroup className="pull-right btn-search">
                          <i className="fa fa-search"></i>
                          <FormControl
                            type="text"
                            placeholder="Pesquisar..."
                            value={search}
                            onChange={this.onChangeSearch}
                          />
                          {titleError}
                        </FormGroup>
                      </div>
                      {this.renderData()}
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={perPage}
                        totalItemsCount={total}
                        pageRangeDisplayed={3}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  }
                />
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}