import { Row, Col, Alert, OverlayTrigger, Tooltip, FormGroup, ControlLabel } from 'react-bootstrap'
import React, { Component } from 'react'
import BlockUi from 'react-block-ui'
import 'react-block-ui/style.css'
import SimpleCurrencyInput from 'react-simple-currency'
import InputMask from 'react-input-mask'
import SweetAlert from 'react-bootstrap-sweetalert'
import moment from "moment"
import cpf_cnpj from "cpf_cnpj"

import Card from '../../../components/Card/Card.jsx'
import Button from '../../../components/CustomButton/CustomButton.jsx'
import Radio from '../../../components/CustomRadio/CustomRadio.jsx'

import api from '../../../services/api'

class formNewContraction extends Component {

  constructor(props) {
    super(props)

    this.hideAlert = this.hideAlert.bind(this)
    this.dangerOperation = this.dangerOperation.bind(this)
    this.successOperation = this.successOperation.bind(this)

    this.onSubmit = this.onSubmit.bind(this)

    this.onChangeInput = this.onChangeInput.bind(this)
    this.onChangeAmount = this.onChangeAmount.bind(this)

    this.state = {
      user_auth: {},
      blocking: false,
      alert: false,

      occupations: [],
      occupation: null,
      formpayments: [],
      form_of_payment: null,
      usertype: null, // (1) Paciente | (2) Terceiro, Pessoa Física | (3) Terceiro, Pessoa Jurídica
      cpf: null,
      rf_occupation: null,
      rf_cpf: null,
      rf_cnpj: null,
      amount: null,

      error: null,
      occupationError: null,
      form_of_paymentError: null,
      usertypeError: null,
      cpfError: null,
      rf_occupationError: null,
      rf_cpfError: null,
      rf_cnpjError: null,
      amountError: null
    }
  }

  componentDidMount() {
    this.userAuth()
    this.loadOccupation()
    this.loadFormpayment()
  }

  async userAuth(){
    const resp = await api.get('users/me')
    this.setState({ user_auth: resp.data.user })
  }

  async loadOccupation(){
    const restOccupation = await api.get('occupation')
    this.setState({ occupations: restOccupation.data })
  }
  
  async loadFormpayment(){
    const restFormpayment = await api.get('formpayment')
    this.setState({ formpayments: restFormpayment.data })
  }

  onChangeInput = e => {
    let name = e.target.name
    if (name === "usertype") {
      this.setState({
        cpf: null,
        rf_cpf: null,
        rf_cnpj: null,
        rf_occupation: null,
        cpfError: null,
        rf_cpfError: null,
        rf_cnpjError: null,
        rf_occupationError: null,
      })
    }
    this.setState({ [name]: e.target.value })
    e.target.value === "" ? this.setState({ [name + "Error"]: (<small className='text-danger'>Campo obrigatório.</small>) }) : this.setState({ [name + "Error"]: null, error: null })
  }

  onChangeAmount(rawValue, displayValue) {
    this.setState({ amount: rawValue })
    rawValue === 0 ? this.setState({ amountError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ amountError: null, error: null })
  }

  hideAlert() {
    this.setState({ alert: null })
  }

  dangerOperation(title, message) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title={title}
          onConfirm={this.hideAlert}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
        ><p>{message}</p>
        </SweetAlert>
      )
    });
  }

  successOperation(id, message) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title={message}
          onConfirm={() => {
            window.location.href = `/contratacao/${id}`;
          }}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
        >
        </SweetAlert>
      )
    });
  }

  async requestCreditall(data){
    try {

      const requestCreditall = await api.post('requests', {
        cnpj: data.cnpj,
        cpf: data.cpf,
        tipoconsulta: data.tipoconsulta,
        user_id: data.user_id,
        company_id: data.company_id,
        message: data.message,
        return: data.return
      })

      return requestCreditall
      
    } catch (error) {
      return error
    }
  }

  /* ==================================
    Integrações Creditall
  ================================== */
  async preanalysisCreditall(data) {

    try {

      const respPreanalysis = await api.post('prod/preanalysis', {
        cnpj: data.cnpj,
        cpf: data.cpf,
        tipoconsulta: data.tipoconsulta,
      })

      return respPreanalysis.data.result

    } catch (error) {
      return error
    }
  }

  /* ==================================
    Form
  ================================== */
  async onSubmit(e) {
    e.preventDefault()

    const { occupation, form_of_payment, usertype, cpf, rf_occupation, rf_cpf, rf_cnpj, amount } = this.state

    this.setState({ blocking: true })

    var cnpjValidate = cpf_cnpj.CNPJ,
      cpfValidate = cpf_cnpj.CPF,
      documentValidate = true,
      amountContract = 0,
      requestedAmount = 0,
      approvedAmount = 0

    const now = moment()

    occupation === null ? this.setState({ occupationError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ occupationError: null })
    form_of_payment === null ? this.setState({ form_of_paymentError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ form_of_paymentError: null })
    usertype === null ? this.setState({ usertypeError: (<small className="text-danger" style={{ marginTop: '-5px', display: 'block' }}>Campo obrigatório</small>) }) : this.setState({ usertypeError: null })
    cpf === null ? this.setState({ cpfError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ cpfError: null })
    rf_cpf === null && (usertype === "2" || usertype === "3") ? this.setState({ rf_cpfError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ rf_cpfError: null })
    rf_occupation === null && (usertype === "2" || usertype === "3") ? this.setState({ rf_occupationError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ rf_occupationError: null })
    rf_cnpj === null && usertype === "3" ? this.setState({ rf_cnpjError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ rf_cnpjError: null })
    amount === '' ? this.setState({ amountError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ amountError: null })

    // Valida CPF do responsável financeiro
    if (rf_cpf && (usertype === "2" || usertype === "3")) {
      if (!cpfValidate.isValid(cpfValidate.strip(rf_cpf))) {
        documentValidate = false
        this.setState({ rf_cpfError: (<small className="text-danger">CPF inválido</small>), blocking: false })
      }
    }

    // Valida CNPJ do responsável financeiro
    if (rf_cnpj && usertype === "3") {
      if (!cnpjValidate.isValid(cnpjValidate.strip(rf_cnpj))) {
        documentValidate = false
        this.setState({ rf_cnpjError: (<small className="text-danger">CNPJ inválido</small>), blocking: false })
      }
    }

    // Valida CPF do paciente
    if (cpf && !cpfValidate.isValid(cpfValidate.strip(cpf))) {
      documentValidate = false
      this.setState({ cpfError: (<small className="text-danger">CPF inválido</small>), blocking: false })
    }

    if (
      occupation &&
      (rf_occupation || usertype === "1") &&
      form_of_payment &&
      (
        (rf_cnpj && usertype === "3") ||
        (rf_cpf && (usertype === "2" || usertype === "3")) ||
        usertype === "1"
      ) &&
      cpf &&
      amount &&
      documentValidate) {

      requestedAmount = amount / 100

      // Paciente é o responsável financeiro 
      if (usertype === "1") {

        const respPreanalysisCreditall = await this.preanalysisCreditall({
          cpf: cpfValidate.strip(cpf),
          tipoconsulta: form_of_payment === "1" || form_of_payment === "3" ? 1 : 2 // 1-Boleto 2-Cheque
        })

        await this.requestCreditall({
          cpf: cpfValidate.strip(cpf),
          tipoconsulta: form_of_payment === "1" || form_of_payment === "3" ? 1 : 2, // 1-Boleto 2-Cheque 
          user_id: this.state.user_auth.id,
          company_id: this.state.user_auth.company.id,
          message: respPreanalysisCreditall.Nome ? respPreanalysisCreditall.Nome : '-',
          return: JSON.stringify(respPreanalysisCreditall),
        })

        //  1- Negado 2- Aprovado
        if (respPreanalysisCreditall.Situacao) {
          if (respPreanalysisCreditall.Situacao == 1) {
            this.dangerOperation('Solicitação negada','')
          } else {

            if (respPreanalysisCreditall.Limite_Saldo) {
              if (typeof respPreanalysisCreditall.Data_Nascimento !== "object"){
                var limite_saldo = respPreanalysisCreditall.Limite_Saldo
                limite_saldo = limite_saldo.replace(".", "")
                limite_saldo = limite_saldo.replace(",", ".")
                limite_saldo = parseFloat(limite_saldo)
                amountContract = requestedAmount
                if (requestedAmount >= limite_saldo) {
                  amountContract = limite_saldo
                }
  
                var date_of_birth = respPreanalysisCreditall.Data_Nascimento.split('/')
                
                var dataContract = {
                  requested_amount: requestedAmount.toFixed(2),
                  usertype: 1,
                  formpayment_id: form_of_payment,
                  patient_occupation: occupation,
                  patient_name: respPreanalysisCreditall.Nome,
                  patient_date_of_birth: `${date_of_birth[2]}-${date_of_birth[1]}-${date_of_birth[0]}`,
                  patient_cpf: cpfValidate.strip(cpf),
                  patient_amount: amountContract.toFixed(2),
                  patient_pre_analysis: JSON.stringify(respPreanalysisCreditall),
                  patient_status_id: 4,
                  first_installment: now.add(30, "days").format("YYYY-MM-DD")
                }
  
                const respContraction = await api.post('contractions', dataContract)
                
                if (respContraction.status === 200) {
                  if (dataContract.requested_amount === dataContract.patient_amount) {
                    this.successOperation(respContraction.data.contraction.id, 'Solicitação Aprovada')
                  } else {
                    this.successOperation(respContraction.data.contraction.id, 'Solicitação Parcialmente Aprovada')
                  }
                } else if (respContraction.status === 203) {
                  this.setState({
                    error: respContraction.data
                  })
                }
              }else{
                this.dangerOperation('Não é possível continuar. Retorno da financeira: ','DOCTO NÃO DISPONIVEL NA BASE DA SINTESE CADASTRAL')
                this.setState({ blocking: false })
                return false
              }
            } else {
              this.dangerOperation('Não é possível continuar. Retorno da financeira: ', 'Sistema indisponível')
              this.setState({ blocking: false })
              return false
            }

          }
        } else {
          this.dangerOperation('Não é possível continuar. Retorno da financeira: ','Acesso Negado')
          this.setState({ blocking: false })
          return false
        }
      } else {

        /*
          RESPONSÁVEL FINANCEIRO
        */
        const respPreanalysisCreditallResponsible = await this.preanalysisCreditall({
          cpf: cpfValidate.strip(rf_cpf),
          cnpj: cnpjValidate.strip(rf_cnpj),
          tipoconsulta: form_of_payment === "1" || form_of_payment === "3" ? 1 : 2 // 1-Boleto 2-Cheque
        })

        await this.requestCreditall({
          cpf: cpfValidate.strip(rf_cpf),
          tipoconsulta: form_of_payment === "1" || form_of_payment === "3" ? 1 : 2, // 1-Boleto 2-Cheque 
          user_id: this.state.user_auth.id,
          company_id: this.state.user_auth.company.id,
          message: respPreanalysisCreditallResponsible.Resposta.Nome ? respPreanalysisCreditallResponsible.Resposta.Nome : '-',
          return: JSON.stringify(respPreanalysisCreditallResponsible),
        })

        //  1- Negado 2- Aprovado
        if (respPreanalysisCreditallResponsible.Resposta.Situacao) {
          if (typeof respPreanalysisCreditallResponsible.Resposta.Data_Nascimento !== "object") {
            if (respPreanalysisCreditallResponsible.Resposta.Limite_Saldo){
              amountContract = requestedAmount
              if (respPreanalysisCreditallResponsible.Resposta.Situacao == 2) {
                var limite_saldoResponsible = respPreanalysisCreditallResponsible.Resposta.Limite_Saldo
                limite_saldoResponsible = limite_saldoResponsible.replace(".", "")
                limite_saldoResponsible = limite_saldoResponsible.replace(",", ".")
                limite_saldoResponsible = parseFloat(limite_saldoResponsible)
                if (requestedAmount >= limite_saldoResponsible) {
                  amountContract = limite_saldoResponsible
                }
                approvedAmount += amountContract
              }

              var date_of_birthResponsible = respPreanalysisCreditallResponsible.Resposta.Data_Nascimento.split('/')

              var dataContractResponsiblePatient = {
                requested_amount: requestedAmount,
                usertype: usertype,
                formpayment_id: form_of_payment,
                first_installment: now.add(30, "days").format("YYYY-MM-DD"),
                rf_occupation: rf_occupation,
                rf_name: respPreanalysisCreditallResponsible.Resposta.Nome,
                rf_date_of_birth: `${date_of_birthResponsible[2]}-${date_of_birthResponsible[1]}-${date_of_birthResponsible[0]}`,
                rf_cnpj: cnpjValidate.strip(rf_cnpj),
                rf_cpf: cpfValidate.strip(rf_cpf),
                rf_amount: amountContract.toFixed(2),
                rf_pre_analysis: JSON.stringify(respPreanalysisCreditallResponsible.Resposta),
                rf_status_id: respPreanalysisCreditallResponsible.Resposta.Situacao == 2 ? 4 : 1,
              }
            } else {
              this.dangerOperation('Não é possível continuar. Retorno da financeira: ','Sistema indisponível')
              this.setState({ blocking: false })
              return false
            }
          }else {
            this.dangerOperation('Não é possível continuar. Retorno da financeira: ', 'DOCTO NÃO DISPONIVEL NA BASE DA SINTESE CADASTRAL')
            this.setState({ blocking: false })
            return false
          }
        } else {
          this.dangerOperation('Não é possível continuar. Retorno da financeira: ','Acesso Negado')
          this.setState({ blocking: false })
          return false
        }

        /*
          PACIENTE
        */
        const respPreanalysisCreditallPaciente = await this.preanalysisCreditall({
          cpf: cpfValidate.strip(cpf),
          tipoconsulta: form_of_payment === "1" || form_of_payment === "3" ? 1 : 2 // 1-Boleto 2-Cheque
        })

        await this.requestCreditall({
          cpf: cpfValidate.strip(cpf),
          tipoconsulta: form_of_payment === "1" || form_of_payment === "3" ? 1 : 2, // 1-Boleto 2-Cheque 
          user_id: this.state.user_auth.id,
          company_id: this.state.user_auth.company.id,
          message: respPreanalysisCreditallPaciente.Resposta.Nome ? respPreanalysisCreditallPaciente.Resposta.Nome : '-',
          return: JSON.stringify(respPreanalysisCreditallPaciente),
        })

        if (respPreanalysisCreditallPaciente.Resposta.Situacao) {
          if (typeof respPreanalysisCreditallPaciente.Resposta.Data_Nascimento !== "object") {
            if (respPreanalysisCreditallPaciente.Resposta.Limite_Saldo) {
              amountContract = requestedAmount
              if (respPreanalysisCreditallPaciente.Resposta.Situacao == 2) {
                var limite_saldo = respPreanalysisCreditallPaciente.Resposta.Limite_Saldo
                limite_saldo = limite_saldo.replace(".", "")
                limite_saldo = limite_saldo.replace(",", ".")
                limite_saldo = parseFloat(limite_saldo)
                if (amountContract >= limite_saldo) {
                  amountContract = limite_saldo
                }
                approvedAmount += amountContract
              }

              var date_of_birth = respPreanalysisCreditallPaciente.Resposta.Data_Nascimento.split('/')

              var dataContractPatient = {
                patient_occupation: occupation,
                patient_name: respPreanalysisCreditallPaciente.Resposta.Nome,
                patient_date_of_birth: `${date_of_birth[2]}-${date_of_birth[1]}-${date_of_birth[0]}`,
                patient_cpf: cpfValidate.strip(cpf),
                patient_amount: amountContract.toFixed(2),
                patient_pre_analysis: JSON.stringify(respPreanalysisCreditallPaciente.Resposta),
                patient_status_id: respPreanalysisCreditallPaciente.Resposta.Situacao == 2 ? 4 : 1
              }
            } else {
              this.dangerOperation('Não é possível continuar. Retorno da financeira: ','Sistema indisponível')
              this.setState({ blocking: false })
              return false
            }
          }else {
            this.dangerOperation('Não é possível continuar. Retorno da financeira: ','DOCTO NÃO DISPONIVEL NA BASE DA SINTESE CADASTRAL')
            this.setState({ blocking: false })
            return false
          }
          
        } else {
          this.dangerOperation('Não é possível continuar. Retorno da financeira: ','Acesso Negado')
          this.setState({ blocking: false })
          return false
        }


        const dataContract = { ...dataContractResponsiblePatient, ...dataContractPatient }

        if (dataContract.patient_status_id === 4 || dataContract.rf_status_id === 4) {

          const respContraction = await api.post('contractions', dataContract)

          if (respContraction.status === 200){
            if (approvedAmount >= requestedAmount){
              this.successOperation(respContraction.data.contraction.id, 'Solicitação aprovada')
            }else{
              this.successOperation(respContraction.data.contraction.id, 'Solicitação parcialmente aprovada')
            }
          } else if (respContraction.status === 203) {
            this.setState({
              error: respContraction.data
            })
          }

        } else {
          this.dangerOperation('Solicitação negada','')
        }
      }

      this.setState({ blocking: false })
    } else {
      this.setState({ blocking: false })
    }

  }

  render() {
    return (
      <BlockUi tag="div" message="Carregando, por favor aguarde" blocking={this.state.blocking}>
        {this.state.alert}
        <form onSubmit={this.onSubmit}>
          <Card
            content={
              <div>
                {this.state.error &&
                  <Alert bsStyle="danger"> { this.state.error.map((e, i) => { return ( <li key={i}>{e.message}</li> ) }) } </Alert>
                }
                <FormGroup>
                  <Row>
                    <Col xs={12}>
                      <ControlLabel>Forma de pagamento:</ControlLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <select
                        className={this.state.form_of_paymentError ? 'form-control error' : 'form-control'}
                        value={this.state.form_of_payment ? this.state.form_of_payment : ''}
                        name="form_of_payment"
                        onChange={this.onChangeInput}
                      >
                        <option value="">Selecione</option>
                        {
                          this.state.formpayments.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>{item.description}</option>
                            )
                          })
                        }
                      </select>
                      {this.state.form_of_paymentError}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col xs={12}><ControlLabel>Responsável pela Solicitação de Crédito e Pagamento:</ControlLabel></Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Radio
                        number="usertype_1"
                        option="1"
                        name="usertype"
                        onChange={this.onChangeInput}
                        checked={this.state.usertype === "1"}
                        label="Paciente"
                      />
                      <Radio
                        number="usertype_2"
                        option="2"
                        name="usertype"
                        onChange={this.onChangeInput}
                        checked={this.state.usertype === "2"}
                        label="Terceiro, Pessoa Física"
                      />
                      <Radio
                        number="usertype_3"
                        option="3"
                        name="usertype"
                        onChange={this.onChangeInput}
                        checked={this.state.usertype === "3"}
                        label="Terceiro, Pessoa Jurídica"
                      />
                      {this.state.usertypeError}
                    </Col>
                  </Row>
                </FormGroup>
                {this.state.usertype !== null &&
                  <div>
                    <FormGroup>
                      <Row>
                        <Col xs={12}>
                          <ControlLabel>
                            CPF do Paciente:
                        </ControlLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <InputMask
                            mask={'999.999.999-99'}
                            className={this.state.cpfError ? 'error form-control' : 'form-control'}
                            value={this.state.cpf ? this.state.cpf : ''}
                            name="cpf"
                            onChange={this.onChangeInput}
                          />
                          {this.state.cpfError}
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col xs={12}>
                          <ControlLabel>Ocupação do Paciente:</ControlLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <select
                            className={this.state.occupationError ? 'form-control error' : 'form-control'}
                            value={this.state.occupation ? this.state.occupation : ''}
                            name="occupation"
                            onChange={this.onChangeInput}
                          >
                            <option value="">Selecione</option>
                            {
                              this.state.occupations.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>{item.name}</option>
                                )
                              })
                            }
                          </select>
                          {this.state.occupationError}
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                }
                {this.state.usertype === "2" &&
                  <FormGroup>
                    <Row>
                      <Col xs={12}>
                        <ControlLabel>
                          CPF do Responsável Financeiro:
                      </ControlLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <InputMask
                          mask={'999.999.999-99'}
                          className={this.state.rf_cpfError ? 'error form-control' : 'form-control'}
                          value={this.state.rf_cpf ? this.state.rf_cpf : ''}
                          name="rf_cpf"
                          onChange={this.onChangeInput}
                        />
                        {this.state.rf_cpfError}
                      </Col>
                    </Row>
                  </FormGroup>
                }
                {this.state.usertype === "3" &&
                  <FormGroup>
                    <Row>
                      <Col xs={12}>
                        <ControlLabel>
                          CNPJ do Responsável Financeiro:
                      </ControlLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <InputMask
                          mask={'99.999.999/9999-99'}
                          className={this.state.rf_cnpjError ? 'error form-control' : 'form-control'}
                          value={this.state.rf_cnpj ? this.state.rf_cnpj : ''}
                          name="rf_cnpj"
                          onChange={this.onChangeInput}
                        />
                        {this.state.rf_cnpjError}
                      </Col>
                    </Row>
                  </FormGroup>
                }
                {this.state.usertype === "3" &&
                  <FormGroup>
                    <Row>
                      <Col xs={12}>
                        <ControlLabel>
                          CPF do Representante do CNPJ:
                      </ControlLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <InputMask
                          mask={'999.999.999-99'}
                          className={this.state.rf_cpfError ? 'error form-control' : 'form-control'}
                          value={this.state.rf_cpf ? this.state.rf_cpf : ''}
                          name="rf_cpf"
                          onChange={this.onChangeInput}
                        />
                        {this.state.rf_cpfError}
                      </Col>
                    </Row>
                  </FormGroup>
                }
                {this.state.usertype === "2" || this.state.usertype === "3" ?
                  <FormGroup>
                    <Row>
                      <Col xs={12}>
                        <ControlLabel>Ocupação do Responsável Financeiro:</ControlLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <select
                          className={this.state.rf_occupationError ? 'form-control error' : 'form-control'}
                          value={this.state.rf_occupation ? this.state.rf_occupation : ''}
                          name="rf_occupation"
                          onChange={this.onChangeInput}
                        >
                          <option value="">Selecione</option>
                          {
                            this.state.occupations.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>{item.name}</option>
                              )
                            })
                          }
                        </select>
                        {this.state.rf_occupationError}
                      </Col>
                    </Row>
                  </FormGroup>
                  : null
                }
                <FormGroup>
                  <Row>
                    <Col xs={12}><ControlLabel>Valor Solicitado:</ControlLabel></Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <SimpleCurrencyInput
                        precision={2}
                        separator=','
                        delimiter='.'
                        className={this.state.amountError ? 'error form-control' : 'form-control'}
                        value={this.state.amount ? this.state.amount : ''}
                        unit='R$'
                        onInputChange={this.onChangeAmount}
                      />
                      {this.state.amountError}
                    </Col>
                  </Row>
                </FormGroup>
              </div>
            }
            ftTextCenter
            legend={
              <FormGroup>
                <Button type="submit" bsStyle="info" fill> Enviar para análise de crédito </Button>
              </FormGroup>
            }
          />
        </form>
      </BlockUi>
    )
  }

}

export default formNewContraction