import CompanyIndex from '../views/Company/Index.jsx';
import FranchiseIndex from '../views/Franchise/Index.jsx';
import UserIndex from '../views/User/Index.jsx';
import RoleIndex from '../views/Role/Index.jsx';
import NoticeIndex from '../views/Notice/Index.jsx';
import PermissionIndex from '../views/Permission/Index.jsx';
import FinancialIndex from '../views/Financial/Index.jsx';
import ContractionIndex from '../views/Contraction/Index.jsx';
import IntegratorIndex from '../views/Integrator/Index.jsx';
import ReportContractionIndex from '../views/Report/Contraction';
import RequestIndex from '../views/Report/Request';
import ContratcionCreate from '../views/Contraction/Create.jsx';
import CustomerIndex from '../views/Customer/Index.jsx';
import SimulatorCreate from '../views/Simulator/Create.jsx';

import { check } from "../services/Can";
import { getPermissions } from '../services/auth';

var operationRoutes = {
  id: 1,
  name: 'Operação',
  routes: []
};
var registerRoutes = {
  id: 2,
  name: 'Cadastro',
  routes: []
};
var reportRoutes = {
  id: 3,
  name: 'Relatório',
  routes: []
};
var adminRoutes = {
  id: 4,
  name: 'Administração',
  routes: []
};
var developRoutes = {
  id: 5,
  name: 'Desenvolvedor',
  routes: []
};

if (check(getPermissions(), 'simulation')) {
  operationRoutes.routes.push({ path: "/simulador", name: "Simulador", icon: "fas fa-dollar-sign", component: SimulatorCreate })
}
if (check(getPermissions(), 'customer')) {
  operationRoutes.routes.push({ path: "/cliente", name: "Cadastro de Cliente", icon: "fas fa-user", component: CustomerIndex })
}

if (check(getPermissions(), 'operation')) {
  operationRoutes.routes.push({ path: "/aprovacao-de-credito", name: "Aprovação de crédito", icon: "fas fa-dollar-sign", component: ContratcionCreate })
  operationRoutes.routes.push({ path: "/contratacao", name: "Contratações", icon: "fas fa-clipboard-list", component: ContractionIndex })
}

if (check(getPermissions(), 'financial')) {
  registerRoutes.routes.push({ path: "/financeira", name: "Financeiras", icon: "fa fa-building", component: FinancialIndex })
}
if (check(getPermissions(), 'integrator')) {
  registerRoutes.routes.push({ path: "/integrador", name: "Integradores", icon: "fa fa-plug", component: IntegratorIndex })
}

if (check(getPermissions(), 'report_contract')) {
  reportRoutes.routes.push({ path: "/relatorio/contratacoes", name: "Contratações", icon: "fa fa-table", component: ReportContractionIndex })
}
if (check(getPermissions(), 'report_request')) {
  reportRoutes.routes.push({ path: "/relatorio/solicitacoes", name: "Solicitações", icon: "fa fa-table", component: RequestIndex })
}

if (check(getPermissions(), 'franchise')) {
  adminRoutes.routes.push({ path: "/credenciador", name: "Credenciador", icon: "fas fa-city", component: FranchiseIndex })
}
if (check(getPermissions(), 'company')) {
  adminRoutes.routes.push({ path: "/empresa", name: "Empresa", icon: "fa fa-building", component: CompanyIndex })
}
if (check(getPermissions(), 'user')) {
  adminRoutes.routes.push({ path: "/usuario", name: "Usuários", icon: "fa fa-users", component: UserIndex })
}
if (check(getPermissions(), 'role')) {
  adminRoutes.routes.push({ path: "/perfil", name: "Perfis", icon: "fas fa-user-lock", component: RoleIndex })
}
if (check(getPermissions(), 'notice')) {
  adminRoutes.routes.push({ path: "/aviso", name: "Avisos", icon: "fa fa-exclamation-circle", component: NoticeIndex })
}

if (check(getPermissions(), 'permission')) {
  developRoutes.routes.push({ path: "/permissao", name: "Permissões de usuário", icon: "fa fa-lock", component: PermissionIndex })
}

var appRoutes = [];


if (check(getPermissions(), 'operation') || check(getPermissions(), 'simulation') || check(getPermissions(), 'pendency')) {
  appRoutes.push(operationRoutes)
}
if (check(getPermissions(), 'financial')) {
  appRoutes.push(registerRoutes)
}
if (check(getPermissions(), 'report_contract') || check(getPermissions(), 'report_request')) {
  appRoutes.push(reportRoutes)
}
if (check(getPermissions(), 'company') || check(getPermissions(), 'user') || check(getPermissions(), 'role') || check(getPermissions(), 'notice')) {
  appRoutes.push(adminRoutes)
}
if (check(getPermissions(), 'permission') || check(getPermissions(), 'method')) {
  appRoutes.push(developRoutes)
}



export default appRoutes;
