import React, { Component } from 'react';
import moment from "moment";
import api from '../../services/api';
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import cpf_cnpj from "cpf_cnpj";

import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';
import Card from '../../components/Card/Card.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

export default class ContractionIndex extends Component {

  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.onChangeCustomer_name = this.onChangeCustomer_name.bind(this);
    this.onChangeCustomer_cpf_cnpj = this.onChangeCustomer_cpf_cnpj.bind(this);
    this.onChangeStart_date = this.onChangeStart_date.bind(this);
    this.onChangeEnd_date = this.onChangeEnd_date.bind(this);
    
    this.state = {
      modalLock: getBlock() ? true : false,
      // Filter
      customer_name: '',
      customer_cpf_cnpj: '',
      start_date: '',
      end_date: '',

      // Table
      user_auth: {},
      contractions: [],
      search: '',
      activePage: 10,
      total: '',
      perPage: '',
      lastPage: '',
      page: 1,

      search: false,
      blocking: true
    };
  }

  async handlePageChange(pageNumber) {
    const response = await api.get('contractions?page=' + pageNumber)
    this.setState({
      contractions: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: pageNumber,
      lastPage: response.data.lastPage
    });
  }

  async userAuth() {
    const resp = await api.get('users/me')
    this.setState({ user_auth: resp.data.user })
  }

  componentDidMount() {
    if (check(getPermissions(), 'operation') === false) {
      this.props.history.push("/nao-autorizado")
    }
    this.userAuth()
    this.loadContractions()
  }

  async loadContractions() {
    const response = await api.get('contractions')
    var contractions = response.data.data, created_at
    if (contractions.length > 0) {
      for (var i = 0; i < contractions.length; i++) {
        created_at = moment(contractions[i].created_at);
        contractions[i].created_at = created_at.format("DD/MM/YYYY HH:mm:ss")
      }
    }
    this.setState({
      contractions,
      total: response.data.total,
      perPage: response.data.perPage,
      page: response.data.page,
      lastPage: response.data.lastPage,
      blocking: false
    });
  }

  onChangeCustomer_name(e) {
    this.setState({ customer_name: e.target.value.toUpperCase() });
  }
  onChangeCustomer_cpf_cnpj(e) {
    this.setState({ customer_cpf_cnpj: e.target.value });
  }
  onChangeStart_date(e) {
    this.setState({ start_date: e.target.value });
  }
  onChangeEnd_date(e) {
    this.setState({ end_date: e.target.value });
  }

  async onChangeSearch(e) {
    this.setState({ blocking: true })
    var { customer_name,  customer_cpf_cnpj } = this.state
    var cpf_cnpj = customer_cpf_cnpj.replace('-','').replace('.','').replace('.','').replace('/','')
    const response = await api.get(`contractions?customer_name=${customer_name}&cpf_cnpj=${cpf_cnpj}`)
    var contractions = response.data.data, created_at
    if (contractions.length > 0) {
      for (var i = 0; i < contractions.length; i++) {
        created_at = moment(contractions[i].created_at);
        contractions[i].created_at = created_at.format("DD/MM/YYYY HH:mm:ss")
      }
    }
    this.setState({
      contractions,
      total: response.data.total,
      perPage: response.data.perPage,
      page: response.data.page,
      lastPage: response.data.lastPage,
      blocking: false,
      search: true
    });
  }

  async onChangeCleanSearch(e) {
    this.setState({ blocking: true })
    const response = await api.get('contractions')
    var contractions = response.data.data, created_at
    if (contractions.length > 0) {
      for (var i = 0; i < contractions.length; i++) {
        created_at = moment(contractions[i].created_at);
        contractions[i].created_at = created_at.format("DD/MM/YYYY HH:mm:ss")
      }
    }
    this.setState({
      contractions,
      total: response.data.total,
      perPage: response.data.perPage,
      page: response.data.page,
      lastPage: response.data.lastPage,
      blocking: false,
      customer_name: '',
      customer_cpf_cnpj: '',
      start_date: '',
      end_date: '',
      search: false
    });
  }

  renderData() {
    const { contractions, user_auth } = this.state
    var row = [];
    var CPF = cpf_cnpj.CPF
    var CNPJ = cpf_cnpj.CNPJ
    var created_at
    if (contractions.length > 0) {
      for (var i = 0; i < contractions.length; i++) {
        
        created_at = moment(contractions[i].created_at);
        // contractions[i].created_at = created_at.format("DD/MM/YYYY HH:mm:ss")
        
        row.push(
          <tr key={contractions[i].id}>
            <td className="text-center">{contractions[i].id}</td>
            <td className="text-center">{contractions[i].customer.cnpj ? CNPJ.format(contractions[i].customer.cnpj) : CPF.format(contractions[i].customer.cpf)}</td>
            <td className="text-center">{contractions[i].customer.name}</td>
            {
              user_auth && user_auth.admin === 1 ?
                <td className="text-center">
                  {contractions[i].company.company_type === "PJ" ? contractions[i].company.fantasy_name : contractions[i].company.name }
                </td>
              : null
            }
            <td className="text-center">{contractions[i].created_at}</td>
            <td className="td-actions">
              <Link to={"/contratacao/" + contractions[i].id} className="btn btn-primary btn-round btn-xs">
                <i className="fa fa-eye"></i> Visualizar
              </Link>
            </td>
          </tr>
        );
      }
    } else {
      row.push(
        <tr key="1">
          <td className="text-center" colSpan={9} className="text-center">
            Nenhuma contratação encontrada
          </td>
        </tr>
      )
    }
    return (
      <div className="table-responsive">
        {this.state.alert}
        <table className="table table-list table-striped">
          <thead>
            <tr>
              <th className="text-center" style={{ width: "135px" }}>Numero Controle</th>
              <th className="text-center">CPF/CNPJ</th>
              <th className="text-center">Nome/Razão</th>
              {
                user_auth && user_auth.admin === 1 ?
                  <th className="text-center">Empresa</th>
                : null
              }
              <th className="text-center">Data/Hora</th>
              <th className="text-center" style={{ width: "115px" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {row}
          </tbody>
        </table>
      </div>
    );
  }

  renderFilter(){
    return (
      <Row>
        <Col md={3}>
          <FormGroup>
            <Row>
              <Col xs={12}><ControlLabel>Nome do Paciente:</ControlLabel></Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormControl
                  type="text"
                  value={this.state.customer_name}
                  onChange={this.onChangeCustomer_name}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Row>
              <Col xs={12}><ControlLabel>CPF/CNPJ do Paciente:</ControlLabel></Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormControl
                  type="text"
                  value={this.state.customer_cpf_cnpj}
                  onChange={this.onChangeCustomer_cpf_cnpj}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col md={3}>
          <Row>
            <Col xs={12}><ControlLabel>&nbsp;</ControlLabel></Col>
          </Row>
          <Row>
            <Col xs={12}>
              <button className="btn-fill btn btn-info" onClick={this.onChangeSearch.bind(this)} style={{ margin: '0 5px' }}> <i className="fa fa-search"></i> Pesquisar </button>
              <button className="btn-fill btn btn-info" disabled={!this.state.search} onClick={this.onChangeCleanSearch.bind(this)} style={{ margin: '0 5px' }}> <i className="fa fa-close"></i> Limpar </button>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={this.state.modalLock} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Breadcrumb active='Contratações' />
              <BlockUi tag="div" blocking={this.state.blocking} message="Carregando, por favor aguarde">
                <Card
                  content={
                    <div>
                      {this.renderFilter()}
                      <Row>
                        <Col md={12}><hr /></Col>
                      </Row>
                      {this.renderData()}
                      <Pagination
                        activePage={this.state.page}
                        itemsCountPerPage={this.state.perPage}
                        totalItemsCount={this.state.total}
                        pageRangeDisplayed={3}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  }
                />
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}