import React, { Component } from 'react';
import { Grid, Row, Col, Alert, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';

import api from '../../services/api';

export default class UserPassword extends Component {
  constructor(props) {
    super(props);
    this.onChangePasword = this.onChangePasword.bind(this);
    this.onChangeConfirmPasword = this.onChangeConfirmPasword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      title: '',
      password: '',
      confirmPasword: '',
      // validation
      error: '',
      passwordError: '',
      confirmPaswordError: '',

      // Loading
      blocking: false,
    }
  }

  componentDidMount() {
    if (check(getPermissions(), 'user_password') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadUser()
  }

  async loadUser(){
    const resp = await api.get('users/' + this.props.match.params.id)
    this.setState({ title: resp.data.user[0].full_name });
  }

  onChangePasword(e) {
    this.setState({ password: e.target.value });
    e.target.value === "" ? this.setState({ passwordError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ passwordError: null, error: null });
  }

  onChangeConfirmPasword(e) {
    this.setState({ confirmPasword: e.target.value });
    e.target.value === "" ? this.setState({ confirmPaswordError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ confirmPaswordError: null, error: null });
  }

  async onSubmit(e) {
    e.preventDefault();

    this.state.password === "" ? this.setState({ passwordError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ passwordError: null });
    this.state.confirmPasword === "" ? this.setState({ confirmPaswordError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ confirmPaswordError: null });

    if (this.state.password !== "" && this.state.confirmPasword !== "") {
      this.setState({ blocking: true })
      if (this.state.password === this.state.confirmPasword){
        const obj = {
          password: this.state.password
        };
        const resp = await api.put(`users/${this.props.match.params.id}/password/`, obj)
  
        if (resp.status === 200) {
  
          this.props.handleClick('tr', 'success', 'Senha alterada com sucesso!')
  
          this.props.history.push("/usuario");
  
        } else {
          this.setState({
            error: resp.data.message,
            blocking: false
          })
        }
      }else{
        this.setState({
          confirmPaswordError: (<small className="text-danger">Senha diferente.</small>),
          blocking: false
        })
      }
    }
  }

  render() {
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={this.state.modalLock} />
        <Grid fluid>
          <Row>
            <Col md={6}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-links">
                  <li className="breadcrumb-item"><Link to={"/usuario"}>Usuários</Link></li>
                  <li className="breadcrumb-item active">Alterar senha</li>
                </ol>
              </nav>
              <BlockUi tag="div" blocking={this.state.blocking} message="Carregando, por favor aguarde">
                <form onSubmit={this.onSubmit}>
                  <Card
                    title={`Alterar senha - ${this.state.title}`}
                    content={
                      <div>
                        {this.state.error &&
                          <Alert bsStyle="danger">
                            {
                              this.state.error.map((e, i) => {
                                return (
                                  <li key={i}>{e.message}</li>
                                )
                              })
                            }
                          </Alert>
                        }
                        <FormGroup>
                          <ControlLabel>
                            Senha:
                          </ControlLabel>
                          <FormControl
                            type="password"
                            className={this.state.passwordError ? 'error' : ''}
                            value={this.state.password}
                            onChange={this.onChangePasword}
                          />
                          {this.state.passwordError}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            Confirme sua Senha:
                          </ControlLabel>
                          <FormControl
                            type="password"
                            className={this.state.confirmPaswordError ? 'error' : ''}
                            value={this.state.confirmPasword}
                            onChange={this.onChangeConfirmPasword}
                          />
                          {this.state.confirmPaswordError}
                        </FormGroup>
                      </div>
                    }
                    ftTextCenter
                    legend={
                      <div>
                        <Button type="submit" bsStyle="info" fill> Salvar </Button>
                        <Link to={"/usuario/"} className="btn-fill btn btn-neutral btn-back">Voltar</Link>
                      </div>
                    }
                  />
                </form>
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}