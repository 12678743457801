import React, { Component } from 'react';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import SweetAlert from 'react-bootstrap-sweetalert';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Grid, Row, Col, FormGroup, FormControl } from 'react-bootstrap';

import { Can, check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';
import Card from '../../components/Card/Card.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

export default class FinancialIndex extends Component {

  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.successDelete = this.successDelete.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      // Confirm
      alert: null,

      // Table
      financials: [],
      search: '',
      activePage: 10,
      total: '',
      perPage: '',
      lastPage: '',
      page: 1,
      
      blocking: true
    };
  }

  async handlePageChange(pageNumber) {
    this.setState({ blocking: true })
    const response = await api.get('financials?page=' + pageNumber)
    this.setState({
      financials: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: pageNumber,
      lastPage: response.data.lastPage,
      blocking: false
    });
  }

  componentDidMount() {
    if (check(getPermissions(), 'financial') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadFinancial()
  }

  async loadFinancial() {
    this.setState({ blocking: true })
    const response = await api.get('financials')
    this.setState({
      financials: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: response.data.page,
      lastPage: response.data.lastPage,
      blocking: false
    });
  }

  warningWithConfirmMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.successDelete(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim, exclua!"
          cancelBtnText="Cancelar"
          showCancel
        >
        </SweetAlert>
      )
    });
  }

  async successDelete(id) {
    const resp = await api.delete('financials/' + id)
    if (resp.status === 200) {
      this.loadFinancial()
      this.hideAlert()
      this.props.handleClick('tr', 'success', 'Excluído com sucesso!')
    }
  }
  hideAlert() {
    this.setState({ alert: null });
  }

  async onChangeSearch(e) {
    var search = e.target.value
    this.setState({ search: search });
    const response = await api.get('financials?search=' + search)
    this.setState({
      financials: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      page: response.data.page,
      lastPage: response.data.lastPage
    });
  }

  renderData() {
    const { financials, alert } = this.state
    var row = [];
    for (var i = 0; i < financials.length; i++) {
      row.push(
        <tr key={financials[i].id}>
          <td>
            {financials[i].name}
          </td>
          <td>
            {financials[i].webservice}
          </td>
          <td className="td-actions">
            <Can
              perform="financial_edit"
              data={{
                id: financials[i].id
              }}
              yes={(data) => (
                <Link to={"/financeira/edit/" + data.id} className="btn btn-primary btn-round btn-xs">
                  <i className="fa fa-edit"></i> Editar
                </Link>
              )} />
            <Can
              perform="financial_delete"
              data={{
                id: financials[i].id
              }}
              yes={(data) => (
                <button onClick={this.warningWithConfirmMessage.bind(this, data.id)} className="btn btn-primary btn-round btn-xs">
                  <i className="fa fa-trash"></i> Excluir
              </button>
              )} />
          </td>
        </tr>
      );
    }
    return (
      <div className="table-responsive">
        {alert}
        <table className="table table-list table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Webservice</th>
              <th style={{ width: "175px" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {row}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={this.state.modalLock} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Breadcrumb active="Financeiras" />
              <BlockUi tag="div" blocking={this.state.blocking} message="Carregando, por favor aguarde">
                <Card
                  title="Financeiras"
                  content={
                    <div>
                      <div className="btn-create">
                        <Can
                          perform="financial_add"
                          yes={() => (
                            <Link to={'/financeira/create'} className="btn-fill btn btn-info pull-right">Nova Financeira </Link>
                          )} />
                        <FormGroup className="pull-right btn-search">
                          <i className="fa fa-search"></i>
                          <FormControl
                            type="text"
                            placeholder="Pesquisar..."
                            value={this.state.search}
                            onChange={this.onChangeSearch}
                          />
                          {this.state.titleError}
                        </FormGroup>
                      </div>
                      
                      {this.renderData()}
                      <Pagination
                        activePage={this.state.page}
                        itemsCountPerPage={this.state.perPage}
                        totalItemsCount={this.state.total}
                        pageRangeDisplayed={3}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  }
                />
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}