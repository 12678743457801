import Auth from '../containers/Auth/Auth.jsx';
import App from '../containers/App/App.jsx';


var indexRoutes = [
  { path: "/auth/login", name: "Auth", component: Auth },
  { path: "/auth/lock", name: "Lock", component: Auth },
  { path: "/auth/esqueci-senha", name: "ForgotPassword", component: Auth },
  { path: "/auth/recuperar-senha/:token/:email", name: "RecoverPassword", component: Auth },
  { path: "/auth/criar-senha/:token_active/:email", name: "createPassword", component: Auth },
  { path: "/", name: "Home", component: App }
];

export default indexRoutes;
