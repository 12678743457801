import React, { Component } from 'react';
import { Grid, Row, Col, Alert, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import api from '../../services/api';

export default class FinancialEdit extends Component {
  constructor(props) {
    super(props);
    
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      title: '',
      name: '',
      webservice: '',
      // validation
      error: '',
      nameError: '',
      webserviceError: '',

      // Loading
      blocking: false,
    }
  }

  componentDidMount() {
    if (check(getPermissions(), 'financial_edit') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadFinancial()
  }

  async loadFinancial(){
    this.setState({ blocking: true })
    const resp = await api.get('financials/' + this.props.match.params.id)
    this.setState({
      title: resp.data.financial.name,
      name: resp.data.financial.name,
      webservice: resp.data.financial.webservice,
      blocking: false
    });
  }

  onChangeInput = e => {
    this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    if (e.target.name !== "link") {
      e.target.value === "" ? this.setState({ [e.target.name + "Error"]: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ [e.target.name + "Error"]: null, error: null });
    }
  }
  
  async onSubmit(e) {
    e.preventDefault();
    const { name, webservice } = this.state

    name === "" ? this.setState({ nameError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ nameError: null });
    webservice === "" ? this.setState({ webserviceError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ webserviceError: null });

    if (name !== "" && webservice !== "") {
      this.setState({ blocking: true })
      const obj = {
        name,
        webservice
      };
      const resp = await api.put('financials/' + this.props.match.params.id, obj)

      if (resp.status === 200) {

        this.props.handleClick('tr', 'success', 'Atualizado com sucesso!')

        this.props.history.push("/financeira");

      } else {
        this.setState({
          error: resp.data,
          blocking: false
        })
      }
    }
  }

  render() {
    var { 
      modalLock,
      blocking,
      error,
      title,
      name,
      nameError,
      webservice,
      webserviceError
    } = this.state
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={modalLock} />
        <Grid fluid>
          <Row>
            <Col md={6}>
              <Breadcrumb routes={[{ label: 'Financeiras', link: '/financeira' }]} active="Editar Financeira" />
              <BlockUi tag="div" blocking={blocking} message="Carregando, por favor aguarde">
                <form onSubmit={this.onSubmit}>
                  <Card
                    title={`Editar Financeira - ${title}`}
                    content={
                      <div>
                        {error &&
                          <Alert bsStyle="danger">  
                            {
                              error.map((e, i) => {
                                return (
                                  <li key={i}>{e.message}</li>
                                )
                              })
                            }
                          </Alert>
                        }
                        <FormGroup>
                          <ControlLabel>
                            Nome:
                        </ControlLabel>
                          <FormControl
                            type="text"
                            className={nameError ? 'error' : ''}
                            name="name"
                            value={name}
                            onChange={this.onChangeInput}
                          />
                          {nameError}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            Webservice:
                        </ControlLabel>
                          <FormControl
                            type="text"
                            className={webserviceError ? 'error' : ''}
                            value={webservice}
                            name="webservice"
                            onChange={this.onChangeInput}
                          />
                          {webserviceError}
                        </FormGroup>
                      </div>
                    }
                    ftTextCenter
                    legend={
                      <div>
                        <Button type="submit" bsStyle="info" fill> Salvar </Button>
                        <Link to={"/financeira/"} className="btn-fill btn btn-neutral btn-back">Voltar</Link>
                      </div>
                    }
                  />
                </form>
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}