import React, { Component } from 'react';
import {
    Grid, Row, Col, Alert,
    FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import api from '../../services/api';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import logo from '../../assets/img/logo.png';

class CreatePassword extends Component {
  constructor(props) {
    super(props);

    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this);

    this.state = {
      cardHidden: true,
      token_active: this.props.match.params.token_active,
      email: this.props.match.params.email,
      password: "",
      passwordConfirm: "",

      error: "",
      passwordError: "",
      passwordConfirmError: "",
      success: "",

      blocking: false
    }
  }
  componentDidMount() {
    setTimeout(function () { this.setState({ cardHidden: false }); }.bind(this), 500);
  }
  onChangePassword(e) {
    this.setState({ password: e.target.value });
    e.target.value === "" ? this.setState({ passwordError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ passwordError: null, error: null });
  }
  onChangePasswordConfirm(e) {
    this.setState({ passwordConfirm: e.target.value });
    e.target.value === "" ? this.setState({ passwordConfirmError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ passwordConfirmError: null, error: null });
  }
  handleCreatePassword = async e => {
    e.preventDefault();

    this.setState({ blocking: true });
    
    const { passwordConfirm, password, email, token_active } = this.state;

    password === "" ? this.setState({ passwordError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ passwordError: null });
    passwordConfirm === "" ? this.setState({ passwordConfirmError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ passwordConfirmError: null });

    if (!passwordConfirm && !password) {
      this.setState({ error: "Preencha sua senha para continuar!", blocking: false });
    } else {
      if (passwordConfirm !== password) {
        this.setState({ error: "Senhas diferentes!", blocking: false });
      }else{
        try {
          const obj = {
            password: password,
            email: email,
            token_active: token_active
          }

          const response = await api.post("/users/active", obj);

          if (response.status === 200) {
            this.setState({
              error: "",
              success: "Senha cadastrada com sucesso.",
              blocking: false
            });
          } else if (response.status === 204) {
            this.setState({
              success: "",
              error: "Desculpe, link inválido.",
              blocking: false
            });
          }
        } catch (err) {
          this.setState({
            error: "Houve um problema, entre em contato com nosso atendimento",
            blocking: false
          });
        }
      }
    }
  };
  render() {
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <BlockUi tag="div" blocking={this.state.blocking} message="Carregando, por favor aguarde">
              <form onSubmit={this.handleCreatePassword}>
                <Card
                  hidden={this.state.cardHidden}
                  textCenter
                  content={
                    <div>
                      <img src={logo} className="auth-logo" alt="ClinicalCred"/>
                      {this.state.success && <Alert bsStyle="success">{this.state.success}</Alert>}
                      {this.state.error && <Alert bsStyle="danger">{this.state.error}</Alert>}
                      <FormGroup>
                        <ControlLabel>
                          Senha
                        </ControlLabel>
                        <FormControl
                          placeholder="Digite sua nova senha"
                          type="password"
                          className={this.state.passwordError ? 'error' : ''}
                          value={this.state.password}
                          onChange={this.onChangePassword}
                        />
                        {this.state.passwordError}
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>
                          Confirme Senha
                        </ControlLabel>
                        <FormControl
                          placeholder="Confirme sua nova senha"
                          type="password"
                          className={this.state.passwordConfirmError ? 'error' : ''}
                          value={this.state.passwordConfirm}
                          onChange={this.onChangePasswordConfirm}
                        />
                        {this.state.passwordConfirmError}
                      </FormGroup>
                      <FormGroup className="text-center">
                        <Button type="submit" bsStyle="info" fill wd>
                          Enviar
                        </Button>
                        <p className="btn-back">
                          <Link to={"/auth/login"} className="btn-link">Ir para o login</Link>
                        </p>
                      </FormGroup>
                    </div>
                  }
                />
              </form>
            </BlockUi>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default CreatePassword;
