import React, { Component } from 'react';
import { Grid, Row, Col, Alert, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';
import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import api from '../../services/api';

export default class IntegratorEdit extends Component {
  constructor(props) {
    super(props);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeIp = this.onChangeIp.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      title: '',
      name: '',
      email: '',
      ip: '',
      // validation
      error: '',
      nameError: '',
      emailError: '',
      ipError: '',

      // Loading
      blocking: false,
    }
  }

  componentDidMount() {
    if (check(getPermissions(), 'integrator_edit') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.loadIntegrator()
  }

  async loadIntegrator(){
    this.setState({ blocking: true })
    const resp = await api.get('integrators/' + this.props.match.params.id)
    this.setState({
      name: resp.data.integrator.name,
      email: resp.data.integrator.email,
      ip: resp.data.integrator.ip,
      blocking: false
    });
  }

  onChangeName(e) {
    this.setState({ name: e.target.value.toUpperCase() });
    e.target.value === "" ? this.setState({ nameError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ nameError: null, error: null });
  }
  onChangeEmail(e) {
    this.setState({ email: e.target.value.toUpperCase() });
    e.target.value === "" ? this.setState({ emailError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ emailError: null, error: null });
  }
  onChangeIp(e) {
    this.setState({ ip: e.target.value.toUpperCase() });
    e.target.value === "" ? this.setState({ ipError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ ipError: null, error: null });
  }

  async onSubmit(e) {
    e.preventDefault();

    this.state.name === "" ? this.setState({ nameError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ nameError: null });
    this.state.email === "" ? this.setState({ emailError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ emailError: null });
    this.state.ip === "" ? this.setState({ ipError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ ipError: null });

    if (this.state.name !== "" && this.state.email !== "" && this.state.ip !== "") {
      
      this.setState({ blocking: true })

      const obj = {
        name: this.state.name,
        email: this.state.email,
        ip: this.state.ip
      };
      
      const resp = await api.put('integrators/' + this.props.match.params.id, obj)

      if (resp.status === 200) {

        this.props.handleClick('tr', 'success', 'Atualizado com sucesso!')

        this.props.history.push("/integrador");

      } else {
        this.setState({
          error: resp.data.message,
          blocking: false
        })
      }
    }
  }

  render() {
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={this.state.modalLock} />
        <Grid fluid>
          <Row>
            <Col md={6}>
              <Breadcrumb routes={[{ label: 'Integradores', link: '/integrador' }]} active="Editar Integrador" />
              <BlockUi tag="div" blocking={this.state.blocking} message="Carregando, por favor aguarde">
                <form onSubmit={this.onSubmit}>
                  <Card
                    title='Editar Integrador'
                    content={
                      <div>
                        {this.state.error &&
                          <Alert bsStyle="danger">
                            {
                              this.state.error.map((e, i) => {
                                return (
                                  <li key={i}>{e.message}</li>
                                )
                              })
                            }
                          </Alert>
                        }
                        <FormGroup>
                          <ControlLabel>
                            Nome:
                          </ControlLabel>
                          <FormControl
                            type="text"
                            className={this.state.nameError ? 'error' : ''}
                            value={this.state.name}
                            onChange={this.onChangeName}
                          />
                          {this.state.nameError}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            Email:
                          </ControlLabel>
                          <FormControl
                            type="text"
                            className={this.state.emailError ? 'error' : ''}
                            value={this.state.email}
                            onChange={this.onChangeEmail}
                          />
                          {this.state.emailError}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            IP:
                          </ControlLabel>
                          <FormControl
                            type="text"
                            className={this.state.ipError ? 'error' : ''}
                            value={this.state.ip}
                            onChange={this.onChangeIp}
                          />
                          {this.state.ipError}
                        </FormGroup>
                      </div>
                    }
                    ftTextCenter
                    legend={
                      <div>
                        <Button type="submit" bsStyle="info" fill> Salvar </Button>
                        <Link to={"/integrador/"} className="btn-fill btn btn-neutral btn-back">Voltar</Link>
                      </div>
                    }
                  />
                </form>
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}