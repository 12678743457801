import React, { Component } from 'react';
import { Grid, Row, Col, Modal, Alert, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import InputMask from 'react-input-mask';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from "axios";

import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';
import Radio from '../../components/CustomRadio/CustomRadio.jsx';
import Checkbox from '../../components/CustomCheckbox/CustomCheckbox.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';

import api from '../../services/api';

export default class UserCreate extends Component {
  constructor(props) {
    super(props);

    this.handleModalAddressClose = this.handleModalAddressClose.bind(this);
    this.handleModalAddressShow = this.handleModalAddressShow.bind(this);
    this.handleModalPhoneClose = this.handleModalPhoneClose.bind(this);
    this.handleModalPhoneShow = this.handleModalPhoneShow.bind(this);

    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeCompany_id = this.onChangeCompany_id.bind(this);
    this.onChangeFranchise_id = this.onChangeFranchise_id.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onBlurAddressZipcode = this.onBlurAddressZipcode.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitPhone = this.onSubmitPhone.bind(this);
    this.onSubmitAddresses = this.onSubmitAddresses.bind(this);

    this.state = {
      modalLock: getBlock() ? true : false,
      user_auth: {},
      
      type: '',
      company_id: '',
      franchise_id: '',
      usertypes: '',
      full_name: '',
      email: '',
      cpf: '',
      cnpj: '',
      roles: [],
      addresses: [],
      states: [],
      phones: [],
      companies: [],
      franchises: [],

      phoneId: '',
      phoneDdd: '',
      phoneNumber: '',

      addressId: '',
      addressState_id: '',
      addressUser_id: '',
      addressZipcode: '',
      addressAddress: '',
      addressNumber: '',
      addressNeighborhood: '',
      addressComplement: '',
      addressLandmark: '',
      addressCity: '',

      checkedRoles: new Map(),

      // Confirm
      alert: null,
      show: false,

      // Modal
      modalAddressVisible: false,
      modalPhoneVisible: false,

      // validation
      error: '',
      typeError: '',
      usertypesError: '',
      full_nameError: '',
      emailError: '',
      cpfError: '',
      cnpjError: '',
      rolesError: '',

      phoneDddError: '',
      phoneNumberError: '',

      addressState_idError: '',
      addressUser_idError: '',
      addressZipcodeError: '',
      addressAddressError: '',
      addressNumberError: '',
      addressNeighborhoodError: '',
      addressCityError: '',

      // Loading
      blocking: false,
      blockingPhone: false,
      blockingAddress: false,
    }

  }

  componentDidMount() {
    if (check(getPermissions(), 'user_add') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.userAuth()
    this.loadRoles()
    this.loadStates()
    this.loadCompanies()
    this.loadFranchises()
    this.setState({ blocking: false })
  }

  async userAuth(){
    const resp = await api.get('users/me')
    this.setState({
      user_auth: resp.data.user,
      company_id: resp.data.user.company_id ? resp.data.user.company_id : '',
      franchise_id: resp.data.user.franchise_id ? resp.data.user.franchise_id : '',
    })
    if(
      this.state.user_auth.franchise_id !== null &&
      this.state.user_auth.company_id !== null &&
      this.state.user_auth.admin === 0){
      this.loadRoles(3)
    }else{
      if(
        this.state.user_auth.franchise_id !== null &&
        this.state.user_auth.company_id === null &&
        this.state.user_auth.admin === 0){
        this.loadRoles(3)
      }
    }
  }

  async loadCompanies() {
    const resp = await api.get('companies?perpage=100')
    this.setState({ companies: resp.data.data });
  }

  async loadFranchises() {
    const resp = await api.get('franchises?perpage=100')
    this.setState({ franchises: resp.data.data });
  }

  async loadStates() {
    const resp = await api.get('states/')
    this.setState({ states: resp.data });
  }

  async loadRoles(type) {
    if(type > 0){
      const resp = await api.get(`roles?role_type_id=${type}&perpage=100`)
      this.setState({
        roles: resp.data.data
      });
    }
  }

  onChangeType(e) {
    let value = e.target.value 
    this.loadRoles(value)
    this.setState({ type: value, checkedRoles: new Map() });
    value === "" ? this.setState({ typeError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ typeError: null, error: null });
  }
  onChangeCompany_id(e) {
    this.setState({
      company_id: e.target.value.toUpperCase(),
      franchise_id: '',
    });
    e.target.value === "" ? this.setState({ company_idError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ company_idError: null, error: null });
  }
  onChangeFranchise_id(e) {
    this.setState({
      franchise_id: e.target.value.toUpperCase(),
      company_id: '',
    });
    e.target.value === "" ? this.setState({ franchise_idError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ franchise_idError: null, error: null });
  }

  onChangeInput = e => {
    this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    e.target.value === "" ? this.setState({ [e.target.name + "Error"]: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ [e.target.name + "Error"]: null, error: null });
  }
  async onBlurAddressZipcode(e) {
    this.setState({ blockingAddress: true })
    var cep = e.target.value.replace('-', '')
    cep = parseInt(cep)
    if (cep.toString().length === 8) {
      var respAddress = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      if (respAddress.data.erro) {
        this.setState({
          addressAddress: '',
          addressNeighborhood: '',
          addressCity: '',
          addressState_id: '',
          blockingAddress: false
        })
      } else {
        var respState = await api.get(`states/${respAddress.data.uf}`)
        this.setState({
          addressAddress: respAddress.data.logradouro.toUpperCase(),
          addressNeighborhood: respAddress.data.bairro.toUpperCase(),
          addressCity: respAddress.data.localidade.toUpperCase(),
          addressState_id: respState.data.state[0].id,
          addressAddressError: null,
          addressNeighborhoodError: null,
          addressCityError: null,
          addressState_idError: null,
          blockingAddress: false
        })
      }
    }
  }
  
  onChangeRole(e) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState(prevState => ({ checkedRoles: prevState.checkedRoles.set(item, isChecked) }));
    this.setState({ rolesError: null });
  }

  async onSubmit(e) {
    e.preventDefault();

    var {
      type, company_id, franchise_id, usertypes, full_name,
      email, cpf, cnpj, checkedRoles, user_auth, phones, addresses
    } = this.state

    this.setState({ blocking: true })

    type === "" ? this.setState({ typeError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ typeError: null });
    company_id === "" ? this.setState({ company_idError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ company_idError: null });
    franchise_id === "" ? this.setState({ franchise_idError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ franchise_idError: null });
    usertypes === "" ? this.setState({ usertypesError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ usertypesError: null });
    full_name === "" ? this.setState({ full_nameError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ full_nameError: null });
    email === "" ? this.setState({ emailError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ emailError: null });
    cpf === "" ? this.setState({ cpfError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ cpfError: null });
    cnpj === "" ? this.setState({ cnpjError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ cnpjError: null });
    
    var roles = []
    
    checkedRoles.forEach((checked, id) => {
      if (checked) {
        roles.push(id)
      }
    });
    
    roles.length === 0 ? this.setState({ rolesError: (<small className="text-danger">Campo obrigatório.</small>) }) : this.setState({ rolesError: null });

    if (
      (
        user_auth.admin === 0 ||
        (user_auth.franchise_id && user_auth.company_id !== null) ||
        ( type !== "" && (
          (type === "3" && company_id !== "") ||
          (type === "2" && franchise_id !== "")
          || type === "1" )
        )
      ) && 
      usertypes !== "" &&
      ( (usertypes === "PF" && cpf !== "") || (usertypes === "PJ" && cnpj !== "") ) &&
      full_name !== "" &&
      email !== "" &&
      roles.length > 0
    ) {
      
      var cpf = cpf.replace('.', '')
      cpf = cpf.replace('.', '')
      cpf = cpf.replace('-', '')

      var cnpj = cnpj.replace('.', '')
      cnpj = cnpj.replace('.', '')
      cnpj = cnpj.replace('-', '')
      cnpj = cnpj.replace('/', '')

      const obj = {
        type: type,
        franchise_id: user_auth.franchise_id ? user_auth.franchise_id : franchise_id,
        company_id: company_id,
        usertypes: usertypes,
        full_name: full_name,
        email: email,
        cpf: cpf,
        cnpj: cnpj,
        roles: roles,
      };

      const resp = await api.post('users', obj)
      
      if (resp.status === 200) {

        var user_id = resp.data.data.id

        await api.post(`users/password/${user_id}`)

        var phonesData = []
        var _phones = {}

        phones.forEach(element => {
          phonesData.push({
            user_id: user_id,
            ddd: element.ddd,
            number: element.number.replace('-', ''),
          })
        });

        _phones.phones = phonesData

        await api.post('phones', _phones)

        var addressesData = []
        var _addresses = {}
        var zipcode = ''

        addresses.forEach(element => {
          zipcode = element.zipcode
          zipcode = parseInt(zipcode.replace('-', ''))
          addressesData.push({
            user_id: user_id,
            state_id: element.state_id,
            zipcode: zipcode,
            address: element.address,
            number: element.number,
            neighborhood: element.neighborhood,
            complement: element.complement,
            landmark: element.landmark,
            city: element.city
          })
        });

        _addresses.addresses = addressesData

        await api.post('addresses', _addresses)

        this.props.handleClick('tr', 'success', 'Cadastrado com sucesso!')

        this.props.history.push("/usuario");

      } else {
        this.setState({
          error: resp.data,
          blocking: false
        })
      }
      // this.setState({
      //   blocking: false
      // })
    }else {
      this.setState({
        blocking: false
      })
    }
  }

  async onSubmitAddresses(e) {
    e.preventDefault();

    var {
      addressState_id, addressZipcode, addressAddress, addressNumber,
      addressNeighborhood, addressCity, addressAction, addresses, 
      addressComplement, addressLandmark, phones, addressId
    } = this.state

    addressState_id === "" ? this.setState({ addressState_idError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressState_idError: null });
    addressZipcode === "" ? this.setState({ addressZipcodeError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressZipcodeError: null });
    addressAddress === "" ? this.setState({ addressAddressError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressAddressError: null });
    addressNumber === "" ? this.setState({ addressNumberError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressNumberError: null });
    addressNeighborhood === "" ? this.setState({ addressNeighborhoodError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressNeighborhoodError: null });
    addressCity === "" ? this.setState({ addressCityError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ addressCityError: null });

    if (
      addressState_id !== "" &&
      addressZipcode !== "" &&
      addressAddress !== "" &&
      addressNumber !== "" &&
      addressNeighborhood !== "" &&
      addressCity !== ""
    ) {

      this.setState({ blockingAddress: true })

      if (addressAction === "create") {

        const obj = {
          key: addresses.length,
          state_id: addressState_id,
          zipcode: addressZipcode,
          address: addressAddress,
          number: addressNumber,
          neighborhood: addressNeighborhood,
          complement: addressComplement,
          landmark: addressLandmark,
          city: addressCity
        };

        var addressesObj = addresses

        addressesObj.push(obj)

        this.setState({
          modalAddressVisible: false,
          addresses: addressesObj,
          blockingAddress: false
        });

      } else if (addressAction === "edit") {

        var addressesObj = phones
        for (let i = 0; i < addressesObj.length; i++) {
          if (addressesObj[i].key === addressId) {
            addressesObj[i].state_id = addressState_id
            addressesObj[i].zipcode = addressZipcode
            addressesObj[i].address = addressAddress
            addressesObj[i].number = addressNumber
            addressesObj[i].neighborhood = addressNeighborhood
            addressesObj[i].complement = addressComplement
            addressesObj[i].landmark = addressLandmark
            addressesObj[i].city = addressCity
          }
        }
        this.setState({
          modalAddressVisible: false,
          addresses: addressesObj,
          blockingAddress: false
        });
      }

    }
  }
  
  async onSubmitPhone(e) {
    e.preventDefault();

    const { phoneDdd, phoneNumber, phoneAction, phones, phoneId } = this.state

    phoneDdd === "" ? this.setState({ phoneDddError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ phoneDddError: null });
    phoneNumber === "" ? this.setState({ phoneNumberError: (<small className="text-danger">Campo obrigatório</small>) }) : this.setState({ phoneNumberError: null });

    if (
      phoneDdd !== "" &&
      phoneNumber !== ""
    ) {

      this.setState({ blockingPhone: true })

      if (phoneAction === "create") {

        const obj = {
          key: phones.length,
          ddd: phoneDdd,
          number: phoneNumber
        };

        var phonesObj = phones

        phonesObj.push(obj)

        this.setState({
          modalPhoneVisible: false,
          blockingPhone: false,
          phones: phonesObj
        });

      } else if (phoneAction === "edit") {
        var phonesObj = phones
        for (let i = 0; i < phonesObj.length; i++) {
          if (phonesObj[i].key === phoneId) {
            phonesObj[i].ddd = phoneDdd
            phonesObj[i].number = phoneNumber
          }
        }
        this.setState({
          modalPhoneVisible: false,
          blockingPhone: false,
          phones: phonesObj
        });
      }

    }
  }

  // Address
  handleModalAddressClose() {
    this.setState({ modalAddressVisible: false });
  }

  handleModalAddressShow() {
    this.setState({
      addressAction: 'create',
      addressId: null,
      addressState_id: '',
      addressUser_id: '',
      addressZipcode: '',
      addressAddress: '',
      addressNumber: '',
      addressNeighborhood: '',
      addressComplement: '',
      addressLandmark: '',
      addressCity: '',
      modalAddressVisible: true
    });
  }

  async editAddressModal(id) {
    var addressesObj = this.state.addresses
    for (let i = 0; i < addressesObj.length; i++) {
      if (addressesObj[i].key === id) {
        this.setState({
          addressAction: 'edit',
          addressId: addressesObj[i].key,
          addressState_id: addressesObj[i].state_id,
          addressUser_id: addressesObj[i].user_id,
          addressZipcode: addressesObj[i].zipcode,
          addressAddress: addressesObj[i].address,
          addressNumber: addressesObj[i].number,
          addressNeighborhood: addressesObj[i].neighborhood,
          addressComplement: addressesObj[i].complement,
          addressLandmark: addressesObj[i].landmark,
          addressCity: addressesObj[i].city,
          modalAddressVisible: true
        })
        break
      }
    }
  }

  removeAddressConfirmMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.successRemoveAddress(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim, exclua!"
          cancelBtnText="Cancelar"
          showCancel
        >
        </SweetAlert>
      )
    });
  }

  async successRemoveAddress(id) {
    var addressesObj = this.state.addresses

    for (let i = 0; i < addressesObj.length; i++) {
      if (addressesObj[i].key === id) {
        addressesObj.splice(i, 1)
        break
      }
    }

    this.setState({
      modalAddressVisible: false,
      addresses: addressesObj
    });
    this.hideAlert()
    this.props.handleClick('tr', 'success', 'Excluído com sucesso!')
  }

  // Phone
  handleModalPhoneClose() {
    this.setState({ modalPhoneVisible: false });
  }

  handleModalPhoneShow() {
    this.setState({
      phoneAction: 'create',
      phoneId: null,
      phoneDdd: '',
      phoneNumber: '',
      modalPhoneVisible: true
    })
  }

  async editPhoneModal(id) {
    var phonesObj = this.state.phones
    for (let i = 0; i < phonesObj.length; i++) {
      if (phonesObj[i].key === id) {
        this.setState({
          phoneAction: 'edit',
          phoneId: phonesObj[i].key,
          phoneDdd: phonesObj[i].ddd,
          phoneNumber: phonesObj[i].number,
          modalPhoneVisible: true
        })
        break
      }
    }
  }

  removePhoneConfirmMessage(id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Você tem certeza?"
          onConfirm={() => this.successRemovePhone(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim, exclua!"
          cancelBtnText="Cancelar"
          showCancel
        >
        </SweetAlert>
      )
    });
  }

  async successRemovePhone(id) {
    var phonesObj = this.state.phones

    for (let i = 0; i < phonesObj.length; i++) {
      if (phonesObj[i].key === id) {
        phonesObj.splice(i, 1)
        break
      }
    }

    this.setState({
      modalPhoneVisible: false,
      phones: phonesObj
    });
    this.hideAlert()
    this.props.handleClick('tr', 'success', 'Excluído com sucesso!')
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  renderRole() {
    const { roles, checkedRoles } = this.state;
    return roles
      .map((checkbox) =>
        <div key={checkbox.id}>
          <Checkbox
            number={checkbox.id}
            value={checkbox.id}
            label={checkbox.name}
            name={checkbox.id}
            checked={checkedRoles.get(checkbox.id)}
            onChange={this.onChangeRole}
          />
        </div>
      );
  }

  renderAddresses() {
    var {
      modalAddressVisible, blockingAddress, addressZipcode, addressZipcodeError, 
      addressAddress, addressAddressError, addressNeighborhood,
      addressNeighborhoodError, addressNumber, addressNumberError,
      addressComplement, addressCity, addressCityError, addressState_id,
      addressState_idError, states
    } = this.state
    return (
      <Modal show={modalAddressVisible} onHide={this.handleModalAddressClose}>
        <BlockUi tag="div" blocking={blockingAddress} className="card" style={{ margin: '0px' }} message="Carregando, por favor aguarde">
          <Modal.Header closeButton>
            <Modal.Title>Endereço</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ padding: '0px' }}>
              <Col xs={3}>
                <FormGroup>
                  <ControlLabel>CEP:</ControlLabel>
                  <InputMask
                    mask="99999-999"
                    maskChar='_'
                    className={addressZipcodeError ? 'error form-control' : 'form-control'}
                    value={addressZipcode ? addressZipcode : ''}
                    name="addressZipcode"
                    onChange={this.onChangeInput}
                    onBlur={this.onBlurAddressZipcode}
                  />
                  {addressZipcodeError}
                </FormGroup>
              </Col>
              <Col xs={9}>
                <FormGroup>
                  <ControlLabel>Endereço:</ControlLabel>
                  <FormControl
                    type="text"
                    className={addressAddressError ? 'error' : ''}
                    value={addressAddress ? addressAddress : ''}
                    name="addressAddress"
                    onChange={this.onChangeInput}
                  />
                  {addressAddressError}
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ padding: '0px' }}>
              <Col xs={6}>
                <FormGroup>
                  <ControlLabel>Bairro:</ControlLabel>
                  <FormControl
                    type="text"
                    className={addressNeighborhoodError ? 'error' : ''}
                    value={addressNeighborhood ? addressNeighborhood : ''}
                    name="addressNeighborhood"
                    onChange={this.onChangeInput}
                  />
                  {addressNeighborhoodError}
                </FormGroup></Col>
              <Col xs={3}>
                <FormGroup>
                  <ControlLabel>Número:</ControlLabel>
                  <FormControl
                    type="text"
                    className={addressNumberError ? 'error' : ''}
                    value={addressNumber ? addressNumber : ''}
                    name="addressNumber"
                    onChange={this.onChangeInput}
                  />
                  {addressNumberError}
                </FormGroup>
              </Col>
              <Col xs={3}>
                <FormGroup>
                  <ControlLabel>Complemento:</ControlLabel>
                  <FormControl
                    type="text"
                    value={addressComplement ? addressComplement : ''}
                    name="addressComplement"
                    onChange={this.onChangeInput}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ padding: '0px' }}>
              <Col xs={6}>
                <FormGroup>
                  <ControlLabel>Cidade:</ControlLabel>
                  <FormControl
                    type="text"
                    className={addressCityError ? 'error' : ''}
                    value={addressCity ? addressCity : ''}
                    name="addressCity"
                    onChange={this.onChangeInput}
                  />
                  {addressCityError}
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <ControlLabel>Estado:</ControlLabel>
                  <select
                    className={addressState_idError ? 'form-control error' : 'form-control'}
                    value={addressState_id ? addressState_id : ''}
                    name="addressState_id">
                    onChange={this.onChangeInput}>
                    <option value="">Selecione</option>
                    {
                      states.map(element => {
                        return (
                          <option key={element.id} value={element.id}>{element.name}</option>
                        )
                      })
                    }
                  </select>
                  {addressState_idError}
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ padding: '0px' }}>
              <Col xs={12}>
                <FormGroup>
                  <ControlLabel>Ponto de referência:</ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.addressLandmark ? this.state.addressLandmark : ''}
                    name="addressLandmark"
                    onChange={this.onChangeInput}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleModalAddressClose} className="btn-fill btn btn-neutral btn-back">
              Cancelar
            </Button>
            <Button onClick={this.onSubmitAddresses} bsStyle="info" fill>
              Salvar
            </Button>
          </Modal.Footer>
        </BlockUi>
      </Modal>
    )
  }

  renderPhone() {
    var {
      modalPhoneVisible, blockingPhone, phoneDdd,
      phoneDddError, phoneNumber, phoneNumberError
    } = this.state
    return (
      <Modal show={modalPhoneVisible} onHide={this.handleModalPhoneClose}>
        <BlockUi tag="div" blocking={blockingPhone} className="card" style={{ margin: '0px' }} message="Carregando, por favor aguarde"> 
          <Modal.Header closeButton>
            <Modal.Title>Telefone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ padding: '0px' }}>
              <Col xs={3}>
                <FormGroup>
                  <ControlLabel>DDD:</ControlLabel>
                  <InputMask
                    mask="99"
                    maskChar=''
                    className={phoneDddError ? 'error form-control' : 'form-control'}
                    value={phoneDdd ? phoneDdd : ''}
                    name="phoneDdd"
                    onChange={this.onChangeInput}
                  />
                  {phoneDddError}
                </FormGroup>
              </Col>
              <Col xs={9}>
                <FormGroup>
                  <ControlLabel>Telefone:</ControlLabel>
                  <InputMask
                    mask="99999-9999"
                    maskChar='_'
                    className={phoneNumberError ? 'error form-control' : 'form-control'}
                    value={phoneNumber ? phoneNumber : ''}
                    name="phoneNumber"
                    onChange={this.onChangeInput}
                  />
                  {phoneNumberError}
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleModalPhoneClose} className="btn-fill btn btn-neutral btn-back">
              Cancelar
            </Button>
            <Button onClick={this.onSubmitPhone} bsStyle="info" fill>
              Salvar
            </Button>
          </Modal.Footer>
        </BlockUi>
      </Modal>
    )
  }

  render() {
    var {
      modalLock, alert, blocking, error, user_auth, type, typeError, company_idError,
      company_id, companies, type, franchises, franchise_id, franchise_idError,
      full_name, full_nameError, email, emailError, usertypes, usertypesError,
      cpf, cpfError, cnpj, cnpjError, rolesError, addresses, phones
    } = this.state
    return (
      <div className="main-content">
        <CounterDown val={1800} modal={modalLock} />
        {alert}
        {this.renderAddresses()}
        {this.renderPhone()}
        <Grid fluid>
          <Row>
            <Col md={6}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-links">
                  <li className="breadcrumb-item"><Link to={"/usuario"}>Usuários</Link></li>
                  <li className="breadcrumb-item active">Novo Usuário</li>
                </ol>
              </nav>
              <BlockUi tag="div" blocking={blocking} message="Carregando, por favor aguarde">
                <form onSubmit={this.onSubmit}>
                  <Card
                    content={
                      <div>
                        {error &&
                          <Alert bsStyle="danger">
                            {
                              error.map((e, i) => {
                                return (
                                  <li key={i}>{e.message}</li>
                                )
                              })
                            }
                          </Alert>
                        }
                        <h4 style={{ marginTop: '0px' }}>Dados Pessoais</h4>
                        {
                          user_auth &&
                            user_auth.admin == "1" &&
                              <FormGroup>
                                <ControlLabel>
                                  Tipo de usuário:
                                </ControlLabel>
                                <FormGroup>
                                  <Radio
                                    number="type_1"
                                    option="1"
                                    name="type"
                                    onChange={this.onChangeType}
                                    checked={type === "1"}
                                    label="Adiministrador ClinicalCred"
                                  />
                                  <Radio
                                    number="type_2"
                                    option="2"
                                    name="type"
                                    onChange={this.onChangeType}
                                    checked={type === "2"}
                                    label="Credenciador"
                                  />
                                  <Radio
                                    number="type_3"
                                    option="3"
                                    name="type"
                                    onChange={this.onChangeType}
                                    checked={type === "3"}
                                    label="Empresa"
                                  />
                                </FormGroup>
                                {typeError}
                              </FormGroup>
                        }
                        {
                          (
                            (user_auth.admin == "1" && type === "3") || 
                            (user_auth.franchise_id && user_auth.company_id === null)
                          ) &&
                              
                              <FormGroup>
                                <ControlLabel>Empresa:</ControlLabel>
                                <select
                                  className={company_idError ? 'form-control error' : 'form-control'}
                                  value={company_id ? company_id : ''}
                                  onChange={this.onChangeCompany_id}>
                                  <option value="">Selecione</option>
                                  {
                                    companies &&
                                    companies.map(element => {
                                      return (
                                        <option key={element.id} value={element.id}>
                                        {element.company_type === "PF" ? element.name : element.social_name}
                                        </option>
                                      )
                                    })
                                  }
                                </select>
                                {company_idError}
                              </FormGroup>
                        }
                        {
                          user_auth.admin == "1" &&
                            type === "2" &&
                              <FormGroup>
                                <ControlLabel>Credenciador:</ControlLabel>
                                <select
                                  className={franchise_idError ? 'form-control error' : 'form-control'}
                                  value={franchise_id ? franchise_id : ''}
                                  onChange={this.onChangeFranchise_id}>
                                  <option value="">Selecione</option>
                                  {
                                    franchises &&
                                    franchises.map(element => {
                                      return (
                                        <option key={element.id} value={element.id}>{element.fantasy_name}</option>
                                      )
                                    })
                                  }
                                </select>
                                {franchise_idError}
                              </FormGroup>
                        }
                        <FormGroup>
                          <ControlLabel>
                            Nome completo:
                          </ControlLabel>
                          <FormControl
                            type="text"
                            className={full_nameError ? 'error' : ''}
                            value={full_name ? full_name : ''}
                            name="full_name"
                            onChange={this.onChangeInput}
                          />
                          {full_nameError}
                        </FormGroup>

                        <FormGroup>
                          <ControlLabel>
                            E-mail:
                          </ControlLabel>
                          <FormControl
                            type="text"
                            className={emailError ? 'error' : ''}
                            value={email ? email : ''}
                            name="email"
                            onChange={this.onChangeInput}
                          />
                          {emailError}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            Tipo de pessoa:
                          </ControlLabel>
                          <FormGroup>
                            <Radio
                              number="usertype_PF"
                              option="PF"
                              name="usertypes"
                              onChange={this.onChangeInput}
                              checked={usertypes === "PF"}
                              label="Física"
                            />
                            <Radio
                              number="usertype_PJ"
                              option="PJ"
                              name="usertypes"
                              onChange={this.onChangeInput}
                              checked={usertypes === "PJ"}
                              label="Jurídica"
                            />
                          </FormGroup>
                          {usertypesError}
                        </FormGroup>

                        {usertypes === "PF" &&
                          <FormGroup>
                            <ControlLabel>
                              CPF:
                            </ControlLabel>
                            <InputMask
                              mask="999.999.999-99"
                              maskChar='_'
                              className={cpfError ? 'error form-control' : 'form-control'}
                              value={cpf ? cpf : ''}
                              name="cpf"
                              onChange={this.onChangeInput}
                            />
                            {cpfError}
                          </FormGroup>
                        }
                        {usertypes === "PJ" &&
                          <FormGroup>
                            <ControlLabel>
                              CNPJ:
                            </ControlLabel>
                            <InputMask
                              mask="99.999.999/9999-99"
                              maskChar='_'
                              className={cnpjError ? 'error form-control' : 'form-control'}
                              value={cnpj ? cnpj : ''}
                              name="cnpj"
                              onChange={this.onChangeInput}
                            />
                            {cnpjError}
                          </FormGroup>
                        }
                        <FormGroup>
                          <ControlLabel>
                            Perfil:
                          </ControlLabel>
                          {this.renderRole()}
                          {rolesError}
                        </FormGroup>

                        <div className="box-address">
                          <header>
                            <h4>Endereços</h4>
                            <Button onClick={this.handleModalAddressShow}>
                              Adicionar
                            </Button>
                          </header>
                          <section>
                            {
                              addresses.map(element => {
                                return (
                                  <article key={element.id}>
                                    <p>
                                      {element.address}, {element.number} {element.complement} - {element.neighborhood}, {element.city}, {element.zipcode}
                                      {/* {element.address}, {element.number} {element.complement} - {element.neighborhood}, {element.city} - {element.states.initials}, {element.zipcode} */}
                                      <small>{element.landmark}</small>
                                    </p>
                                    <div className="buttons">
                                      <button type="button" onClick={this.editAddressModal.bind(this, element.id)} className="btn btn-primary btn-round btn-xs">
                                        <i className="fa fa-edit"></i> Editar
                                      </button>
                                      <button type="button" onClick={this.removeAddressConfirmMessage.bind(this, element.id)} className="btn btn-primary btn-round btn-xs">
                                        <i className="fa fa-trash"></i> Excluir
                                      </button>
                                    </div>
                                  </article>
                                )
                              })
                            }
                          </section>
                        </div>

                        <div className="box-phone">
                          <header>
                            <h4>Telefones</h4>
                            <Button onClick={this.handleModalPhoneShow}>
                              Adicionar
                            </Button>
                          </header>
                          <section>
                            {
                              phones.map(element => {
                                return (
                                  <article key={element.key}>
                                    <p>({element.ddd}) {element.number}</p>
                                    <div className="buttons">
                                      <button type="button" onClick={this.editPhoneModal.bind(this, element.key)} className="btn btn-primary btn-round btn-xs">
                                        <i className="fa fa-edit"></i> Editar
                                      </button>
                                      <button type="button" onClick={this.removePhoneConfirmMessage.bind(this, element.key)} className="btn btn-primary btn-round btn-xs">
                                        <i className="fa fa-trash"></i> Excluir
                                      </button>
                                    </div>
                                  </article>
                                )
                              })
                            }
                          </section>
                        </div>
                      </div>
                    }
                    ftTextCenter
                    legend={
                      <div>
                        <Button type="submit" bsStyle="info" fill> Salvar </Button>
                        <Link to={"/usuario/"} className="btn-fill btn btn-neutral btn-back">Voltar</Link>
                      </div>
                    }
                  />
                </form>
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

}