/*eslint-disable*/
import React from "react";
import {  Row }  from 'react-bootstrap';

import PropTypes from "prop-types";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <div className="container-fluid">
          <Row>
            <div className="credits ml-auto">
              <div className="copyright">
                &copy; {1900 + new Date().getYear()}, ClinicalCred - Crédito para sua saúde
              </div>
            </div>
          </Row>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
