import React, { Component } from 'react';
import api from '../../services/api';
import { Grid, Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import moment from "moment";
import 'moment/locale/pt-br';
import cpf_cnpj from "cpf_cnpj";
import SweetAlert from 'react-bootstrap-sweetalert'
import ReactExport from "react-data-export";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DateRangePicker } from 'react-dates';

import { check } from "../../services/Can";
import { getPermissions, getBlock } from '../../services/auth';
import Card from '../../components/Card/Card.jsx';
import CounterDown from '../../components/CounterDown/CounterDown.jsx';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ReportContractionIndex extends Component {

  constructor(props) {
    super(props);

    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onChangeCleanSearch = this.onChangeCleanSearch.bind(this);
    this.alertAlert = this.alertAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    
    this.state = {
      modalLock: getBlock() ? true : false,
      user_auth: {},
      // Filter
      startDate: null,
      endDate: null,
      focusedInput: null,
      status: '',
      alert: false,


      // Table
      contractions: [],
      
      blocking: false
    };
  }

  componentDidMount() {
    if (check(getPermissions(), 'report_contract') === false) {
      this.props.history.push("/nao-autorizado");
    }
    this.userAuth()
  }
  
  async userAuth() {
    const resp = await api.get('users/me')
    this.setState({ user_auth: resp.data.user })
  }

  onChangeStatus(e) {
    this.setState({ status: e.target.value });
  }

  hideAlert() {
    this.setState({ alert: null })
  }

  alertAlert(title, message) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={title}
          onConfirm={(this.hideAlert)}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
        >
        {message}
        </SweetAlert>
      )
    });
  }
  
  async onChangeSearch() {

    var { status, startDate, endDate } = this.state, _dt_start = '', _dt_end = ''
    
    if(!startDate || !endDate) {
      this.alertAlert('Informe o período de emissão', '')
      return false
    }

    _dt_start = moment(startDate).format("YYYY-MM-DD")
    _dt_end = moment(endDate).format("YYYY-MM-DD")
    
    this.setState({ blocking: true });

    const response = await api.get(`contractions_export?status_id=${status}&dt_start=${_dt_start}&dt_end=${_dt_end}`)

    var _contractions = []
    var CPF = cpf_cnpj.CPF
    var _authorizer = null, number_authorizer = null
    for (var i = 0; i < response.data.length; i++) {
      _authorizer = null
      number_authorizer = null
      if(response.data[i].authorizer){
        _authorizer = JSON.parse(response.data[i].authorizer)
        number_authorizer = Number.isInteger(_authorizer.Numero_Aprovacao) ? _authorizer.Numero_Aprovacao : '-'
      }
      _contractions.push({
        customer_name: response.data[i].customer_name,
        customer_cpf: CPF.format(response.data[i].customer_cpf),
        contractions_customers_created_at: response.data[i].contractions_customers_created_at,
        pendencies_updated_at: response.data[i].pendencies_updated_at,
        status: response.data[i].status,
        amount: response.data[i].amount,
        installment: response.data[i].installment ? response.data[i].installment : '-',
        user_name: response.data[i].user_name,
        customer_name: response.data[i].customer_name,
        number_authorizer: number_authorizer,
      })
    }
    
    this.setState({
      contractions: _contractions,
      blocking: false
    });
  }
  
  onChangeCleanSearch() {
    this.setState({ status: '', startDate: '', endDate: '', contractions: [] });
  }

    
  renderData() {
    const contractions = this.state.contractions
    var row = []
    if (contractions.length > 0) {
      for (var i = 0; i < contractions.length; i++) {
        row.push(
          <tr key={i}>
            
            <td>{contractions[i].customer_name}</td>
            <td>{contractions[i].customer_cpf}</td>
            <td>{contractions[i].contractions_customers_created_at}</td>
            <td>{contractions[i].pendencies_updated_at}</td>
            <td>{contractions[i].status}</td>
            <td>{contractions[i].amount}</td>
            <td>{contractions[i].installment}</td>
            {
              this.state.user_auth.admin ===  1 ?
                <td>{contractions[i].number_authorizer}</td>
              : null
            }
            {
              check(getPermissions(), 'report_all') ?
                <td>{contractions[i].user_name}</td>
              : null
            }
            {
              this.state.user_auth.admin ===  1 ?
                <td>{contractions[i].customer_name}</td>
              : null
            }
          </tr>
        );
      }
    } else {
      row.push(
        <tr key="1">
          <td className="text-center" colSpan={10} className="text-center">
            Nenhum registro encontrado
          </td>
        </tr>
      )
    }
    return (
      <div className="table-responsive">
        {this.state.alert}
        <table className="table table-list table-striped">
          <thead>
            <tr>
              <th className="text-center">Nome</th>
              <th className="text-center">CPF</th>
              <th className="text-center">Data Emissão</th>
              <th className="text-center">Data Liberado</th>
              <th className="text-center">Status</th>
              <th className="text-center">Valor</th>
              <th className="text-center">Parcela</th>
              {
                this.state.user_auth.admin === 1 ?
                  <th className="text-center">Número de Aprovação</th>
                : null
              }
              {
                check(getPermissions(), 'report_all') ?
                  <th className="text-center">Operador</th>
                : null
              }
              {
                this.state.user_auth.admin === 1 ?
                  <th className="text-center">Empresa</th>
                : null
              }
            </tr>
          </thead>
          <tbody>
            {row}
          </tbody>
        </table>
      </div>
    );
  }

  renderFilter() {
    return (
      <Row>
        <Col md={3}>
          <Row>
            <Col xs={12}><ControlLabel>Período de emissão:</ControlLabel></Col>
          </Row>
          <Row>
            <Col xs={12}>
              <DateRangePicker
                startDateId="startDate"
                startDatePlaceholderText="Início"
                endDateId="endDate"
                endDatePlaceholderText="Fim"
                displayFormat={() => "DD/MM/YYYY"}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onDatesChange={({ startDate, endDate }) => { this.setState({ startDate, endDate }) }}
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}
                isOutsideRange={() => false}
                hideKeyboardShortcutsPanel
              />
            </Col>
            
          </Row>
        </Col>
        <Col md={8}>
          <FormGroup>
            <Row>
              <Col xs={12}><ControlLabel>Status:</ControlLabel></Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <select
                  className={'form-control'}
                  value={this.state.status}
                  name="status"
                  onChange={this.onChangeStatus}
                  style={{ margin: '0 0 5px' }}
                >
                  <option value="">Selecione o status</option>
                  <option value="1">Negado</option>
                  <option value="4">Aprovados</option>
                  <option value="5">Finalizado</option>
                </select>
              </Col>
              <Col xs={12} md={6}>
                <button className="btn-fill btn btn-info" onClick={this.onChangeSearch.bind(this)} style={{ margin: '0px 5px 5px 0px' }}> <i className="fa fa-search"></i> Pesquisar </button>
                <button className="btn-fill btn btn-info" onClick={this.onChangeCleanSearch.bind(this)} style={{ margin: '0px 5px 5px 0px' }}> <i className="fa fa-close"></i> Limpar </button>
                {
                  this.state.contractions.length > 0 ?
                    <ExcelFile filename="relatorio_contratacao" element={<button className="btn-fill btn btn-info" style={{ margin: '0px 5px 5px 0px' }}><i className="fa fa-file-excel"></i> Exportar XLS</button>}>
                      <ExcelSheet data={this.state.contractions} name="Contratações">
                        <ExcelColumn label="Nome" value="customer_name" />
                        <ExcelColumn label="CPF" value="customer_cpf" />
                        <ExcelColumn label="Data Emissão" value="contractions_customers_created_at" />
                        <ExcelColumn label="Data Liberado" value="pendencies_updated_at" />
                        <ExcelColumn label="Valor" value="amount" />
                        <ExcelColumn label="Parcela" value="installment" />
                        <ExcelColumn label="Status" value="status" />
                        {
                          this.state.user_auth.admin === 1 ?
                            <ExcelColumn label="Número de Aprovação" value="number_authorizer" />
                            : null
                        }
                        {
                          check(getPermissions(), 'report_all') ?
                            <ExcelColumn label="Operador" value="user_name" />
                            : null
                        }
                        {
                          this.state.user_auth.admin === 1 ?
                            <ExcelColumn label="Empresa" value="company_name" />
                            : null
                        }
                      </ExcelSheet>
                    </ExcelFile>
                    : null
                }
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <div className="main-content">
        {this.state.alert}
        <CounterDown val={1800} modal={this.state.modalLock} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-links">
                  <li className="breadcrumb-item active">Relatório de contratações</li>
                </ol>
              </nav>
              <BlockUi tag="div" blocking={this.state.blocking} message="Carregando, por favor aguarde">
                <Card
                  content={
                    <div>
                      {this.renderFilter()}
                      <Row>
                        <Col md={12}><hr /></Col>
                      </Row>
                      {this.renderData()}
                    </div>
                  }
                />
              </BlockUi>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}